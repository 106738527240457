import {ElevenStepsWrapper} from "../../parts/ElevenStepsWrapper";
import React, {useEffect, useState} from "react";
import signImg from "../../../../assets/img/sign.png";
import {crumbsConfig} from "../../config";

export const DocumentReviewStep = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
        ['Document Review']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            {step === 1 && <ElevenStepsWrapper
                onNext={() => nextComponent()}
                onBack={prevComponent}
                crumbs={crumbs}
                activePin={5}
            >
                <StepOne />
            </ElevenStepsWrapper>}
        </>
    )
}


const StepOne = () => {

    return (
        <section>
            <h2 className='text-[28px] leading-[30px] font-bold'>Document Review</h2>
            <div
                className='mt-[36px] lg:px-[44px] lg:py-[36px] p-[22px] bg-[#F7F7F7] w-full rounded-md border border-solid bordrer-[#DBDBDB]'>
                <p className='text-lg leading-7 lg:leading-[30px] text-[#666666] font-normal'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum.
                    Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. Donec egestas lectus at sapien
                    hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut
                    consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi. Mauris lobortis
                    condimentum nisi, et fermentum risus luctus eget. Cras scelerisque enim et massa scelerisque in
                    dapibus justo rutrum. Duis sit amet mauris et dui dignissim posuere. Sed quis massa et risus
                    pulvinar rhoncus quis sit amet magna. Vivamus ac rhoncus arcu.<br/><br/>
                    Quisque sit amet tortor nulla, sit amet tempus metus. Vivamus placerat scelerisque sapien vitae
                    aliquet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae;
                    Mauris massa eros, porttitor sed tempus in, pretium mattis sapien. Praesent fringilla odio vitae est
                    vehicula facilisis. Vestibulum accumsan molestie dictum. Integer nec sodales leo. Phasellus
                    tincidunt massa vel purus malesuada id dapibus tortor iaculis. Vestibulum ante ipsum primis in
                    faucibus orci luctus et ultrices posuere cubilia Curae; Mauris interdum, dui eget congue venenatis,
                    libero neque porta quam, nec facilisis enim nisl in tortor. Suspendisse suscipit ligula id ipsum
                    gravida euismod. Duis mollis tempor neque a pulvinar.
                    <br/><br/>
                    In eget malesuada augue. Nam porta, metus nec scelerisque convallis, turpis tellus accumsan sapien,
                    quis consequat ipsum dolor quis risus. Sed ullamcorper luctus nulla, vel ultricies elit interdum
                    vel. Vivamus iaculis sollicitudin mi pulvinar interdum. Suspendisse dolor velit, sollicitudin sed
                    auctor non, blandit id mauris. Vivamus ligula mi, cursus ultrices commodo tincidunt, porta
                    ullamcorper nisi. Nunc feugiat ante vel dolor consequat in scelerisque leo molestie. Nunc vitae
                    purus ligula. Ut blandit venenatis eleifend. Integer faucibus semper gravida. Ut facilisis
                    scelerisque quam id lobortis. Cras volutpat arcu sed orci porta a fermentum tellus placerat.
                    <br/><br/>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget blandit urna. Ut vel nibh dui. In
                    at dui quam. Vivamus massa purus, dapibus nec lobortis ut, lacinia quis risus. Morbi libero odio,
                    dapibus sit amet suscipit consequat, hendrerit nec eros. Aliquam ornare pharetra vehicula. Curabitur
                    blandit, sem sit amet auctor congue, erat turpis facilisis urna, non rhoncus ipsum ligula et velit.
                    Donec ligula purus, ornare sed pellentesque at, viverra eget nisl. Phasellus bibendum vulputate
                    purus, non consectetur leo rutrum eu. Nullam facilisis sollicitudin elit eu posuere. Sed vulputate
                    ligula non ipsum placerat sodales.
                    <br/><br/>
                    Nullam laoreet tincidunt purus vel volutpat. Sed auctor diam ac lorem elementum id malesuada lorem
                    euismod. Fusce ullamcorper orci vitae enim condimentum et luctus libero iaculis. Fusce ut mauris mi,
                    et luctus lorem. Donec ut pharetra ligula. Donec eu nisl nisi. Etiam mollis orci in arcu egestas
                    semper. Donec aliquam pharetra libero et semper.

                </p>
                <div
                    className='mt-[44px] flex flex-col justify-center items-center gap-[20px] w-fit max-md:mx-auto lg:ml-auto'>
                    <p className='text-lg leading-[30px] text-[#333333] font-bold'>Marc Spector</p>
                    <img src={signImg} alt='sign' height={70} width={206}/>
                    <p className='text-lg leading-[30px] text-[#333333] font-bold'>Candidate</p>
                </div>
            </div>
        </section>
    )
}