import BreadCrumbWhite from "../../../components/BreadCrumbWhite";
import {Footer} from "../../../components/parts/Footer";
import React from "react";
import {NextStepSectionSBM01} from "../base/NextStepSectionSBM01";
import {TabsSBM01} from "../base/TabsSBM01";
import {ProgressBarSBM01} from "../base/ProgressBarSBM01";


export const FourStepsWrapper = ({children, onNext, onBack, activePin, crumbs=[], hideTabs=false}) => {

    return (
        <>
            <BreadCrumbWhite crumbs={crumbs} />

            <div className='flex-auto'>
                <div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
                    {!hideTabs && <div className="max-w-[682px]">
                        <h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950 mb-[44px]">Marc Spector</h2>
                        <TabsSBM01/>
                    </div>}

                    <ProgressBarSBM01 activeTab={activePin} />

                    {children}

                    <NextStepSectionSBM01 next={onNext} prev={onBack} />

                </div>
                <Footer hideCopyright={true} />
            </div>
        </>
    )
}