import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';
import FileInputField from '../../../components/base/FileInputField';
import FileInputFieldMulty from '../../../components/base/FileInputFieldMulty';
import InputField from '../../../components/base/InputField';
import PrimaryButton from '../../../components/base/PrimaryButton';
import PageLoader from '../../../components/PageLoader';
import { deleteDocumentByIdFetch, getAllDocumentsFetch, sendToApplicantLinkOnDocumentsReviewFetch, uploadDocumentsToAppicationFetch } from '../../../redux/thunks/jobApplicationDocumentsThunks';
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';


const DocumentReview = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [isAddingDocument, setIsAddingDocument] = useState(false)
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	const { documents, isLoading } = useSelector(state => state.jobApplicationDocuments)

	const [isSending, setIsSending] = useState(false)

	const [formData, setFormData] = useState([])

	useEffect(() => {
		setActiveStep(5)
	}, [])

	useEffect(() => {
		if (!applicationId) return
		dispatch(getAllDocumentsFetch({ applicationId }))
	}, [])


	if (!job?.applicationForm) {
		return null
	}

	const handleChange = (name, value, index) => {
		const copy = [...formData]
		copy[index][name] = value
		setFormData(copy)
	}

	const handleUpload = async () => {
		const body = new FormData()

		formData.forEach((item, i) => {
			body.append("documents", item.documents)
			body.append(`payload[${i}][title]`, item.title || " ")
			body.append(`payload[${i}][description]`, item.description || " ")
		})

		const res = await dispatch(uploadDocumentsToAppicationFetch({ applicationId, body })).unwrap()
		if (res) {
			setIsAddingDocument(false)
			setFormData([])
		}
	}

	const handleDeleteDocuments = (documentId) => {
		dispatch(deleteDocumentByIdFetch({ applicationId, documentId }))
	}

	const handleSendDocuments = async () => {
		setIsSending(true)
		const res = await dispatch(sendToApplicantLinkOnDocumentsReviewFetch({ applicationId }))
		setIsSending(false)

	}

	const handleUploadMany = (files) => {
		setFormData(prev => {
			const newData = files.map(file => ({
				title: null,
				description: null,
				documents: file
			}));
			return [...prev, ...newData];
		});
	};
	const handleCancelAddingDocument = () => {
		setFormData([]);
	};

	const handlePrev = () => {
		navigate(`/SBM01_10_3/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_16/${jobId}/${applicationId}`)
	}



	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-[60px] max-md:gap-[40px] max-w-[1432px] px-4 w-full pt-10 pb-20 lg:pt-[60px] lg:pb-24 mx-auto'>
				<section>
					<h2 className='text-[28px] leading-[30px] font-bold'>Document Review</h2>
					<div className='mt-11'>
						{
							!documents?.length && !formData.length ?
								<h2 className='text-2xl font-bold text-center mb-11'>There is no documents yet</h2>
								:
								null
						}
						{
							formData?.length ?
								<div className='grid lg:grid-cols-2 gap-8 mb-6'> {
									formData?.map((item, index) => (
										<div key={index}>
											<InputField
												label='Title'
												className='bg-[#FFFFFF] border border-solid border-[#DEDED9]'
												value={item.title}
												onChange={(e) => handleChange("title", e.target.value, index)}
											/>
											<InputField
												label='Description'
												className='bg-[#FFFFFF] border border-solid border-[#DEDED9]'
												value={item.description}
												onChange={(e) => handleChange("description", e.target.value, index)}
											/>
											<div className='flex items-center gap-4 justify-between'>
												<FileInputField
													hideActions
													acceptProp={{ 'application/pdf': ['.pdf'] }}
													handleChange={(file) => handleChange("documents", file, index)}
													fileProp={item.documents}
												/>
												<PrimaryButton
													onClick={() => setFormData(prev => prev.filter((item, i) => index !== i))}
													className={`px-11 bg-red-600 mb-4`}>
													Remove
												</PrimaryButton>
											</div>
										</div>
									))
								}
								</div>
								: null
						}
						<FileInputFieldMulty
							acceptProp={{ 'application/pdf': ['.pdf'] }}
							handleChange={handleUploadMany}
						/>
						{
							formData?.length ?
								<div className='flex justify-between gap-7 flex-wrap mt-4'>
									<PrimaryButton
										onClick={handleCancelAddingDocument}
										className={`px-11 ${formData?.length ? "bg-red-600" : ""}`}>
										Cancel
									</PrimaryButton>
									<PrimaryButton className={"px-11"} onClick={handleUpload}>Upload Documents</PrimaryButton>
								</div>
								: null
						}
					</div>
					{
						!isLoading && documents?.length ?
							<div className='mt-[36px] lg:px-[44px] lg:py-[36px] p-[22px] bg-[#F7F7F7] w-full rounded-md border border-solid bordrer-[#DBDBDB]'>
								<div className="mt-6 font-[500] text-[18px] flex flex-col gap-6">
									{documents?.map((document, index) => (
										<div className='flex flex-col gap-1' key={index}>
											<div className='flex justify-between gap-6 flex-wrap'>
												<div>
													<h4 key={index} className="flex items-start font-semibold text-xl flex-wrap">{document?.title}</h4>
													<p>{document?.description}</p>
												</div>
												<PrimaryButton onClick={() => handleDeleteDocuments(document?.id)} className={"bg-red-600 px-11 !h-[52px]"}>Delete</PrimaryButton>
											</div>
											{
												document?.url ?
													<a
														href={process.env.REACT_APP_API_URL + document?.url}
														className='font-bold text-[#37B34A]'
														target="_blank"
													>
														{document?.filename}
													</a>
													: null
											}
										</div>
									)
									)}
								</div>
								{/* <div className='mt-[44px] flex flex-col justify-center items-center gap-[20px] w-fit max-md:mx-auto lg:ml-auto'>
									<p className='text-lg leading-[30px] text-[#333333] font-bold'>{application.fullName}</p>
									<img src={process.env.REACT_APP_API_URL + application.applicationForm?.eSignature} alt="sign" />
									<p className='text-lg leading-[30px] text-[#333333] font-bold'>Candidate</p>
								</div> */}
							</div>
							: isLoading ?
								<PageLoader />
								: null
					}
					{
						documents?.length ?
							<div className='flex justify-end mt-11'>
								<PrimaryButton isLoading={isSending} onClick={handleSendDocuments} className="px-11">Send documents on review</PrimaryButton>
							</div>
							:
							null
					}

				</section>
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
		</div>
	)
}

export default DocumentReview