import React, { useState } from 'react'
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import { Footer } from '../components/parts/Footer'
import MilestoneBar from "../components/parts/MilestoneVisualization"
import { Link } from 'react-router-dom';



// Milestone Component
const Milestone = ({ colorClass, text, imgSrc, imgAlt }) => (
  <div className="flex items-center gap-3 py-1.5">
    <div className={`shrink-0 w-3 h-3 ${colorClass} rounded-full`} />
    <div className="flex-auto font-semibold">{text}</div>
    {
      imgSrc && <img src={imgSrc} alt={imgAlt} className="object-cover absolute inset-0 size-full" />
    }
  </div>
);



function TimeToHireReport() {
  return (
    <div className="flex flex-col max-lg:items-center max-lg:text-center grow px-8 py-10 rounded-md border border-solid border-zinc-300 text-stone-500 max-md:px-5 max-md:max-w-full">
      <h2 className="text-2xl max-md:max-w-full">Time to Hire Report</h2>
      <div className="flex flex-col px-7 mt-11 max-md:px-5 max-md:mt-10">
        <div className="text-4xl leading-8">7d</div>
        <div className="mt-4 text-xl">Avg Time</div>
      </div>
      <p className="mt-11 text-base leading-7 max-md:mt-10 max-md:max-w-full">
        <span className="font-bold">Lorem ipsum dolor sit amet</span>, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.{" "}
      </p>
      <Link to={"/SBM04_3"} className="mt-11 text-base font-semibold text-green-500 max-md:mt-10 max-md:max-w-full">
        View Hiring Velocity Insight
      </Link>
    </div>
  );
}

function OffersReport() {
  return (
    <Link to={"/SBM04_4"} className="flex flex-col justify-center max-lg:items-center max-lg:text-center px-8 py-8 rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 items-center max-md:flex-wrap">
        <h2 className="grow self-start text-2xl">Offers</h2>
        <div className="flex flex-auto max-lg:items-center max-lg:text-center gap-5 max-lg:flex-col">
          <div className="flex flex-col text-center px-7 flex-shrink-0 my-auto max-md:px-5">
            <div className="text-4xl leading-8">24</div>
            <div className="mt-4 text-xl">Total Hires</div>
          </div>
          <p className="flex-auto text-base leading-7 max-md:max-w-full">
            <span className="font-bold">Lorem ipsum dolor sit amet</span>, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.{" "}
          </p>
        </div>
      </div>
    </Link>
  );
}

function ApplicantVolumeReport() {
  return (
    <div className="flex justify-center max-lg:items-center max-lg:text-center px-16 py-8 mt-7 rounded-md border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col items-center w-full lg:max-w-[311px]">
        <h2 className="text-2xl">Applicant Volume & Source</h2>
        <div className="flex flex-col px-14 mx-5 mt-7 max-md:px-5 max-md:mx-2.5">
          <div className="self-center text-4xl leading-8">522</div>
          <div className="mt-4 text-xl">Quality Applicants</div>
        </div>
        <Link to={"/SBM04_2"} className="self-center mt-7 text-base font-semibold text-green-500">
          View Applicant Sourcing Insight
        </Link>
      </div>
    </div>
  );
}

function HiringInsightsReport() {
  return (
    <div className="flex lg:gap-7 gap-5 max-lg:flex-col">
      <div className="flex flex-col max-lg:w-full lg:w-2/5">
        <TimeToHireReport />
      </div>
      <div className="flex flex-col lg:gap-7 gap-5 max-lg:w-full lg:w-3/5">
        <div className="flex flex-col grow self-stretch text-stone-500">
          <OffersReport />
          <ApplicantVolumeReport />
        </div>
      </div>
    </div>
  );
}


const SBM04 = () => {

  const [job, setJob] = useState()
  const [timePeriod, setTimePeriod] = useState()
  const hadleSelectJob = () => {
    setJob()
  }
  const handleSelectTimePerio = () => {
    setTimePeriod()
  }

  const jobs = [
    { value: 'cleaning_technician', label: 'Cleaning Technician' },
    { value: 'job_2', label: 'job 2' },
    { value: 'job_3', label: 'job 3' },
  ];
  const milestones = [
    { colorClass: "bg-rose-800", text: "Clarification" },
    { colorClass: "bg-red-600", text: "1st Interview" },
    { colorClass: "bg-orange-400", text: "Post Interview Survey" },
    { colorClass: "bg-orange-300", text: "2nd Interview" },
    { colorClass: "bg-amber-200", text: "Doc Review" },
    { colorClass: "bg-yellow-100", text: "Rating of the Job" },
    { colorClass: "bg-lime-200", text: "AI Evaluation" },
    { colorClass: "bg-lime-300", text: "Final Hiring Decisions" },
    { colorClass: "bg-green-400", text: "Training Insights" },
    { colorClass: "bg-green-600", text: "Offer Meeting" },
    { colorClass: "bg-emerald-800", text: "Training Milestones" },
  ];


  return (
    <>
      <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Insights']} />
      <section className="flex flex-auto justify-center py-9 lg:py-11 w-full text-zinc-800">
        <div className="flex flex-col gap-9 lg:gap-11 items-center w-full max-w-[1432px] px-4">
          <div className="flex flex-col w-full">
            <div className="w-full flex max-md:flex-col gap-3 lg:gap-5 justify-between text-lg max-md:flex-wrap">
              <div className="flex flex-col flex-1 max-w-[550px]">
                <SelectField
                  inputBg="bg-white rounded-md border border-solid border-zinc-300"
                  label="Job"
                  name="job"
                  id="job"
                  value={job}
                  onChange={hadleSelectJob}
                  options={jobs}
                />
              </div>
              <div className="flex flex-col flex-1 max-w-[550px] whitespace-nowrap">
                <InputField
                  inputBg="bg-white rounded-md border border-solid border-zinc-300"
                  label="Time Period"
                  labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                  type="date" name="timePeriod" id="timePeriod"
                  value={timePeriod}
                  onChange={handleSelectTimePerio}
                />
              </div>
            </div>

          </div>
          <HiringInsightsReport />
          <div className="flex flex-col justify-center w-full">
            <header className="flex gap-5 justify-between p-9 lg:px-11 lg:py-14 w-full font-bold bg-neutral-100 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
              <h1 className="flex-auto my-auto lg:text-3xl lg:leading-10 text-[28px] leading-9 text-green-500">Pipeline Summary</h1>
              <div className="flex flex-col text-lg text-stone-500">
                <p>Opened:<span className=""> Apr 6, 2023</span></p>
                <p className="lg:mt-5">Status: <span className="">Open</span></p>
              </div>
            </header>
            <section className="justify-center lg:px-10 max-lg:mt-9 lg:py-10 py-9 px-8 w-full border-r border-b border-l max-lg:border-t border-solid border-[#DEDED9]">
              <div className="flex gap-5 max-lg:flex-col">
                <div className="flex flex-col w-full lg:w-[27%]">
                  <div className="grow">
                    {milestones.map((milestone, index) => (
                      <Milestone key={index} {...milestone} />
                    ))}
                  </div>
                </div>
                <div className="flex flex-auto">
                  <div className="flex flex-auto grow justify-center items-center text-lg font-bold text-white whitespace-nowrap max-md:max-w-full">
                    <MilestoneBar />
                  </div>
                </div>
                <div className='flex items-start lg:justify-end lg:w-[27%]'>
                  <Link to={"/SBM04_1_1"} className="flex items-center justify-center w-full lg:max-w-[236px] px-4 lg:h-16 h-[60px] mt-5 lg:mt-11 lg:text-[22px] text-[19px] font-bold text-center text-white uppercase whitespace-nowrap bg-green-500 rounded-md">
                    Export CSV
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <Footer hideCopyright={true} />
    </>
  )
}

export default SBM04