import React, { useEffect, useState } from 'react';
import InputField from "../../components/base/InputField";
import SelectField from "../../components/base/SelectField";
import Checkbox from '../../components/base/Checkbox';
import { Country, State, City }  from 'country-state-city';

const Step1 = ({ payload, setPayload, inComponent = false, industriesList, bottomPaste = null }) => {
    let allTimezones = Country.getAllCountries().map(item => item.timezones).flat().filter((value, index, self) =>
        index === self.findIndex((t) => (
            (t.label === value.label) && ((t.zoneName === value.zoneName) || (t.gmtOffsetName === value.gmtOffsetName))
        ))
    );
    
    const timezones = allTimezones.map(item => ({ value: item.gmtOffsetName, label: item.zoneName }));

    const manageRoles = [
        { value: "no", label: "No - will not manage other employees" },
        { value: "Senior Manager", label: "Senior Manager" },
    ];

    const employeeTypes = [
        { value: "Full-time", label: "Full Time - Salary" },
        { value: "Part-time", label: "Part Time" },
    ];

    const countries = Country.getAllCountries().map(item => ({ value: item.isoCode, label: item.name }));

    const cities = payload.country && payload.state ? City.getCitiesOfState(payload.country, payload.state)?.map(item => ({ value: item.name, label: item.name })) : [];
    
    const states = payload.country ? State.getStatesOfCountry(payload.country)?.map(item => ({ value: item.isoCode, label: item.name })) : [];

    const industries = industriesList?.map(item => ({ value: item.id, label: item.name}))

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({ ...payload, [name]: value });
    };

    useEffect(() => {
        if(!payload?.state && states?.length){
            setPayload({ ...payload, state: states[0].value });
        }
    }, [payload, states]);

    return (<>
        <div className={`flex flex-col items-center w-full ${inComponent ? 'lg:mb-10' : ' lg:mt-10'}`}>
            { !inComponent ? <div className='mx-auto text-center flex flex-col gap-2 mb-11'>
                <div className="text-4xl font-bold text-zinc-800">
                    Let’s Get Started
                </div>
                <p>These details serve as the foundation of your job.</p>
            </div> : null }
            <div className={`flex flex-col text-lg w-full bg-white rounded-md ${inComponent ? '' : 'px-[32px] lg:px-[60px] py-[36px] lg:py-[50px] border border-solid border-zinc-300'}`}>
                <h2 className='text-3xl font-bold text-zinc-800 mb-[36px] lg:mb-12 max-md:text-[28px] max-md:leading-[28px]'>Job Details</h2>
                <div className='flex flex-col gap-10'>
                    <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3'>
                        <div>
                            <InputField
                                inputBg="bg-white rounded-[0px] border border-solid border-zinc-300"
                                labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                label="Job Title"
                                type="text"
                                name="jobTitle"
                                id="jobTitle"
                                value={payload.jobTitle}
                                onChange={handleChange}
                                placeholder='Administrative Assistant'
                            />
                        </div>
                        <div>
                            <SelectField
                                inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                label="Industry"
                                name="industrieId"
                                id="industrieId"
                                value={payload.industrieId}
                                onChange={handleChange}
                                options={industries || []}
                            />
                        </div>
                        <div>
                            <SelectField
                                inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                label="Timezone"
                                name="timezone"
                                id="timezone"
                                value={payload.timezone}
                                onChange={handleChange}
                                options={timezones || []}
                            />
                        </div>
                        <div>
                            <SelectField
                                inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                label="Manage Role"
                                name="manageRole"
                                id="manageRole"
                                value={payload.manageRole}
                                onChange={handleChange}
                                options={manageRoles || []}
                            />
                        </div>
                        <div>
                            <SelectField
                                inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                label="Employee Type"
                                name="employeeType"
                                id="employeeType"
                                value={payload.employeeType}
                                onChange={handleChange}
                                options={employeeTypes || []}
                            />
                        </div>
                    </div>
                    <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 mt-[-18px]'>
                        <div className="flex gap-3 whitespace-nowrap">
                            <Checkbox
                                checked={payload.remoteJob}
                                onChange={(value)=>{
                                    setPayload({ ...payload, "remoteJob": value });
                                }}
                                text={<div className="grow my-auto text-[18px]">Remote Job or Not</div>}
                            />
                        </div>
                    </div>
                    <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3'>
                        <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3'>
                            <SelectField
                                inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                label="Country"
                                name="country"
                                id="country"
                                value={payload.country}
                                onChange={handleChange}
                                options={countries || []}
                            />
                        </div>
                        <div className="grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3">
                            <div className='col-span-2'>
                                <InputField
                                    inputBg="bg-white rounded-[0px] border border-solid border-zinc-300"
                                    labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                    label="Adress"
                                    type="text"
                                    name="address"
                                    id="address"
                                    value={payload.address}
                                    onChange={handleChange}
                                    placeholder='Lorem ipsum dolor 327'
                                />
                            </div>
                        </div>
                        <div className="grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3">
                            <div>
                                <SelectField
                                    inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                    label="City"
                                    name="city"
                                    id="city"
                                    value={payload.city}
                                    onChange={handleChange}
                                    options={cities || []}
                                />
                            </div>
                            <div>
                                <SelectField
                                    inputBg="bg-white rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                    label="States"
                                    name="state"
                                    id="state"
                                    value={payload.state}
                                    onChange={handleChange}
                                    options={states || []}
                                />
                            </div>
                            <div>
                                <InputField
                                    inputBg="bg-white rounded-[0px] border border-solid border-zinc-300"
                                    labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                    label="Zip Code"
                                    type="text"
                                    name="zipCode"
                                    id="zipCode"
                                    value={payload.zipCode}
                                    onChange={handleChange}
                                    placeholder='Zip Code'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                { bottomPaste }
            </div>
        </div>
    </>)
}

export default Step1