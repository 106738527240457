import React, {useState} from "react";
import { ReactComponent as PlayIcon } from "../../../assets/img/play-icon.svg";


export const HeroSectionSBM01 = () => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <section className="flex overflow-hidden relative flex-col justify-center items-stretch w-full lg:px-[70px] mb-11 lg:mb-[70px]">
            <div className="relative w-full h-full aspect-[1.6]">
                <video
                    className="object-cover absolute inset-0 size-full"
                    autoPlay={isPlaying}
                    loop
                    muted
                    poster='https://cdn.builder.io/api/v1/image/assets/TEMP/49194a0e11f7e653597a46b285fa2bbe564c802a799738d7d7664974f701f8fb?apiKey=a3d56698a9414207bfc14be655369da7&'
                >
                    <source src="{{ext_4}}" type="video/mp4" />
                </video>
                <button
                    className="absolute inset-0 w-full flex items-center justify-center hover:opacity-70"
                    onClick={handlePlayPause}
                >
                    {!isPlaying
                        ?
                        (
                            <span className='flex aspect-square w-[70px] lg:w-[138px]'>
                                <PlayIcon />
                            </span>
                        )
                        :
                        null}
                </button>
            </div>
        </section>
    );
};
