import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { getAllJobsFetch, getJobByIdFetch } from "../redux/thunks/jobsThunks";
import PageLoader from "../components/PageLoader";
import api from "../api";
import Editor from "../components/CKEditor";
import CreateSignatureModal from "../components/modals/CreateSignatureModal";
import { toast } from "react-toastify";
import './signLetter.css'

// Reusable component for feature sections
const FeatureSection = ({ title, description, imageURL, altText="" }) => (
	<div className="flex gap-6 justify-between max-md:flex-wrap max-md:max-w-full">
		<img loading="lazy" src={imageURL} alt={altText} className="self-start w-[65px] h-[65px] object-contain" />
		<div className="flex flex-col flex-1 max-md:max-w-full">
			<div className="text-xl font-bold leading-8 max-md:max-w-full">{title}</div>
			<div className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full">{description}</div>
		</div>
	</div>
);

// Reusable component for qualification or requirement items
const QualificationItem = ({ imageURL, altText="", title }) => (
	<div className="flex lg:flex-col gap-3 max-lg:gap-5 items-center grow text-lg leading-8 whitespace-nowrap text-zinc-800">
		<img loading="lazy" src={imageURL} alt={altText} className="self-center w-[65px] h-[65px] object-contain" />
		<div>{title}</div>
	</div>
);
function SignLetter() {

	const { token } = useParams();
	console.log(token);
	const [offerData, setOfferData] = useState(null);
	const [rteInstanse, setRteInstanse] = useState(null)
	useEffect(() => {})
	const [createSignatureModalOpen, setCreateSignatureModalOpen] = useState(false);
	const [base64Image, setBase64Image] = useState(null);
	console.log(base64Image)
	const insertImage = (blockId, imageUrl) => {
        var iframe = rteInstanse.iframe
        var iframeContent = iframe.contentDocument || iframe.contentWindow.document;
        var paragraph = iframeContent.getElementById(blockId);

        if (paragraph) {
            console.log(paragraph);
            rteInstanse.selectControl(paragraph)
			// var img= rteInstanse.insertByTagName("IMG");
			// img.src=imageUrl;
			// rteInstanse.insertHTML(`<img id='${blockQuery}' src='${imageUrl}'></img>`);
            rteInstanse.insertImageByUrl(imageUrl);
			var img = iframeContent.querySelector(`img[src="${imageUrl}"]`);
			console.log(img)
			img.id = blockId
			img.style = "width: 206px; height: 70px; object-fit: contain"
        }
        
    };
	const setSignatureFile = (imgBase64) => {
        function DataURIToBlob(dataURI) {
            const splitDataURI = dataURI.split(',')
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    
            const ia = new Uint8Array(byteString.length)
            for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i)
    
            return new Blob([ia], { type: mimeString })
        }

        const file = DataURIToBlob(imgBase64);

        return file;
    }
	useEffect(() => {
		if(!token) return
		api.jobApplications.getOfferInfo(token).then(response => {
			console.log(response)
			if(!response.success) return
			// setOfferData(response.data);
			const {employmentType,
				payRate,
				payPeriod,
				payFrequency,
				startDate,
				recruterName,
				candidateName,
				signApplicant,
				companySign,
				offerLetter
			} = response.data
			const variables = { employmentType, payRate, payPeriod, payFrequency, startDate, recruterName, candidateName, recruterSign: ' ', candidateSign: ' '  };
			let resultStr = offerLetter;
			for (const key in variables) {
				const regex = new RegExp(`{{${key}}}`, 'g'); // Create a regex to match the placeholder
				resultStr = resultStr.replace(regex, variables[key] || key);  // Replace all occurrences
			}
			console.log(resultStr);
			if (signApplicant) {
				setShowButtons(false)
				const tempDiv = document.createElement('div');
				tempDiv.innerHTML = resultStr;
				const signatureDiv = tempDiv.querySelector('#candidate-sign');
				const image = new Image();
				image.src = 'https://ej.webstaginghub.com' + signApplicant;
				image.id = 'candidate-sign'
				image.style = "width: 206px; height: 70px; object-fit: contain"
				signatureDiv.parentNode.replaceChild(image, signatureDiv);
				resultStr = tempDiv.innerHTML
			}

			setOfferData({...response.data, offerLetter: resultStr})
		})
	}, [token])
	const breadcrumbItems = [
		{
			name: 'Job',
			to: '/AP_01',
			isCurrent: true
		},
	];

	const handleSign = async () => {
		const formdata = new FormData();
		const blobFile = setSignatureFile(base64Image);
		formdata.append("signature", blobFile);

		const response = await api.jobApplications.signSignatureApplicant(token, formdata)
		toast(response.message, {
			type: response.success ? 'success' : 'error'
		})
		if(response.success) {
			setShowButtons(false)
		}
	}
	const [showButtons, setShowButtons] = useState(true)

	return (<>
		{!offerData ? <PageLoader /> :
		<div className="mt-[24px] mb-[70px] flex gap-x-[60px] gap-y-20 max-lg:flex-col">
			<div id="sign-letter-wrapper" className="flex flex-col grow self-stretch w-full">
					<h1 className="text-center text-3xl font-bold leading-10 text-zinc-800 max-md:max-w-full">Offer Letter</h1>
					<Editor
						wrprClassNames="my-4" 
						
						data={offerData.offerLetter} 
						// onChange={val => setPayload((prev) => ({
						// 	...prev,
						// 	offerLetter: val
						// }))}
						setInstanse={rte => setRteInstanse(rte)}
						readOnly={true}
					/>
					{showButtons && <div className="flex items-center justify-center gap-5">
					<button
						className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
						onClick={(e) => {
							e.preventDefault();
							setCreateSignatureModalOpen(true)
						}}
					>
						Sign
					</button>
					<button
						disabled={!base64Image}
						className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
						onClick={handleSign}
					>
						Complete and Continue
					</button>
					</div>}
					
						
					{createSignatureModalOpen ? <CreateSignatureModal
						open={createSignatureModalOpen}
						handleClose={()=>{ setCreateSignatureModalOpen(false); }}
						setSignature={(base64Image)=>{
							setBase64Image(base64Image)
							insertImage('candidate-sign', base64Image)
						}}
						name={offerData.candidateName}
					/> : null }
				</div>
		</div>}
	</>
	);
}

export default SignLetter