import React, { useState } from "react";
import crossIcon from "../assets/img/icon-cross.svg";
import InputField from "../components/base/InputField";
import PrimaryButton from "../components/base/PrimaryButton";
import PrimaryLink from "../components/base/PrimaryLink";
import api from '../api';
import { useSelector } from "react-redux";
import { getCurrentPage } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import PageLoader from "../components/PageLoader";

function HOM1_6_1() {
	const currentPage = useSelector(getCurrentPage);
	const getValue = (key) => findValue(currentPage?.blocks, key);

	const [email, setEmail] = useState("")
	const [isEmailInvalid, setIsEmailInvalid] = useState(false);

	const [emailSendedTo, setEmailSendedTo] = useState(null);

	const validateEmail = (email) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(email);
	};

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (!validateEmail(email)) {
			setIsEmailInvalid(true)
			return;
		}
		try {
			const response = await api.auth.forgotPassword({ email });
			if (response?.success) {
				setEmailSendedTo(email);

				setIsEmailInvalid(false)
				setPopupOpened(true)
				setEmail("")
			}
		} catch (error) {
			console.log(error);
		}
	}

	const [popupOpened, setPopupOpened] = React.useState(true);

	const successContent = emailSendedTo ? getValue('content_page_success_message')?.split("test@test.com")?.join(emailSendedTo) : getValue('content_page_success_message');

	return (
		<>
			{
				currentPage ?
					<>
						<div className="flex flex-col justify-center font-bold text-center">
							<header className="flex overflow-hidden relative flex-col justify-center items-center lg:px-16 py-4 lg:py-12 w-full min-h-full">
								<nav className="flex relative flex-col items-center mb-[90px] mx-auto w-full px-[16px] max-w-[760px]">
									<h1 className="mt-8 text-[70px] text-white leading-[79.8px] max-md:max-w-full max-md:text-[40px] max-md:leading-[48px]">
										{getValue('content_page_form_title')}
									</h1>
									<div className="self-center mt-6 h-1 bg-[#37B34A] w-[78px]" />
									<p className="mt-8 md:text-xl text-lg text-white font-normal">
										{getValue('content_page_description')}
									</p>
									<form onSubmit={handleSubmit} className="w-full max-w-[560px]">
										<div className="w-full mt-[70px]">
											<InputField
												placeholder="Email"
												label={getValue('content_field_label')}
												labelClassName='lg:text-[26px] text-[20px] text-white font-bold text-center'
												id="email"
												name="email"
												value={email}
												error={isEmailInvalid ? "Invalid email format" : ""}
												onInput={(e) => setEmail(e.target.value)}
											/>
											<PrimaryButton type="Sumbit" className='md:w-[216px] max-lg:h-[60px] w-full mt-6 mx-auto'>
												{getValue('content_page_form_button_text')}
											</PrimaryButton>
										</div>
										<div dangerouslySetInnerHTML={{
											__html: getValue('content_page_footer_links')
										}} />
										{/* <p className="mt-[90px] md:text-xl text-lg text-balance text-white font-normal w-full text-center">Don’t have an account? <PrimaryLink>Sign Up</PrimaryLink> now, it takes just a few seconds. Already registered? <PrimaryLink>Sign in</PrimaryLink> here.</p> */}
									</form>
								</nav>
							</header>
						</div>
						{ popupOpened && <PasswordResetInstructions
							handleClose={() => setPopupOpened(false)}
							content={successContent}
						/> }
					</> : <PageLoader color="light" />
			}
		</>
	);
}

const PasswordResetInstructions = ({ isOpened, handleClose, content }) => {
	return (
		<section className="z-10 absolute top-0 left-0 right-0 lg:m-[44px] m-4 flex gap-5 justify-between items-start self-stretch p-6 lg:py-7 lg:px-8 lg:text-xl lg:leading-[28px] text-lg leading-7 text-[#184D20] bg-[#CBFFCB] rounded-xl max-md:flex-wrap max-md:pl-5">
			<div className="flex gap-3 max-md:flex-wrap">
				<img
					src="https://cdn.builder.io/api/v1/image/assets/TEMP/07d00f17158aa3cb54a5cbf822fd69be4b968e2973896d755d20c1390030e322?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&"
					alt="Notification icon"
					loading="lazy"
					className="shrink-0 my-auto w-9 aspect-square"
				/>
				<div
					className="flex-auto max-md:max-w-full font-normal" 
					dangerouslySetInnerHTML={{
						__html: content
					}}
				>
				</div>
			</div>
			<button onClick={handleClose}>
				<img
					src={crossIcon}
					alt="Close icon"
					loading="lazy"
					className="absolute top-[8px] right-[8px]"
				/>
			</button>
		</section>
	);
};

export default HOM1_6_1;