import { useState, useEffect } from "react";
import checkIcon from "../../assets/img/icon-check.svg";

const Checkbox = ({ checked, onChange, text, className='' }) => {
  const [isChecked, setIsChecked] = useState(checked);
  const handleCheck = () => {
    setIsChecked(!isChecked)
    onChange && onChange(!isChecked)
  }

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div
      className={`flex gap-3.5 my-auto text-sm text-black ${className}`} style={{ cursor: "pointer" }}
      onClick={handleCheck}
      tabIndex="0"
      role="button"
      // aria-label="I’m not a robot checkbox"
    >
      <div
        className={`w-6 h-6 bg-white rounded-sm border-2
         ${isChecked ? 'border-green-500 flex justify-center items-center' : 'border-stone-300'}`}
      >
        {isChecked && <img src={checkIcon} alt="Checked" />}
      </div>
      { text ? text : null }
    </div>
  );
};

export default Checkbox;