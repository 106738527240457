import BreadCrumbWhite from "../components/BreadCrumbWhite";
import Confirmation from "../components/Confirmation";

const SBM02_7_5_1_1 = () => {
  return (
    <>
      <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Job Setup', "Current Job Details", "Application Form", "Contact Us", "Thank You"]} />
      <Confirmation />
    </>
  )
}

export default SBM02_7_5_1_1