import React, { useEffect, useState } from 'react'
import { Footer } from "../components/parts/Footer"
import { ReactComponent as PlayIcon } from "../assets/img/play-icon.svg";
import { FitScaleEvaluationTable } from "../components/base/FitScaleEvaluationTable"
import TextareaField from '../components/base/TextareaField';
import SelectField from "../components/base/SelectField";


const SalesAssociateHeader = ({ name }) => {
    return (
        <header className=" bg-neutral-100">
            <div className='max-w-[1432px] w-full px-[22px] mx-auto'>
                <div className='flex flex-col items-start py-[24px] md:py-14'>
                    <h2 className="text-lg font-semibold text-sky-900">
                        Sales Associate / Candidates
                    </h2>
                    <h1 className="mt-1.5 text-[36px] md:text-4xl font-bold leading-10 text-zinc-950">
                        {name}
                    </h1>
                </div>
            </div>
        </header>
    )
}

const HeroSection = () => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <section className="flex overflow-hidden relative flex-col justify-center items-stretch w-full lg:px-[70px] mb-11 lg:mb-[70px]">
            <div className="relative w-full h-full aspect-[1.6]">
                <video
                    className="object-cover absolute inset-0 size-full"
                    autoPlay={isPlaying}
                    loop
                    muted
                    poster='https://cdn.builder.io/api/v1/image/assets/TEMP/49194a0e11f7e653597a46b285fa2bbe564c802a799738d7d7664974f701f8fb?apiKey=a3d56698a9414207bfc14be655369da7&'
                >
                    <source src="{{ext_4}}" type="video/mp4" />
                </video>
                <button
                    className="absolute inset-0 w-full flex items-center justify-center hover:opacity-70"
                    onClick={handlePlayPause}
                >
                    {!isPlaying
                        ?
                        (
                            <span className='flex aspect-square w-[70px] lg:w-[138px]'>
                                <PlayIcon />
                            </span>
                        )
                        :
                        null}
                </button>
            </div>
        </section>
    );
};

// const FitScaleEvaluationTable = ({ value = 1, onChange }) => {
//     const createRatingScale = (selectedIdx) => Array.from({ length: 7 }).map((_, idx) => {
//         return idx + 1 === selectedIdx;
//     });

//     const [selectedRatings, setSelectedRatings] = useState(createRatingScale(value));


//     const handleRatingClick = (index) => {
//         setSelectedRatings(createRatingScale(index));
//         onChange && onChange(index)
//     };

//     return (
//         <div className="flex flex-col py-8">
//             <table className="max-w-[480px] border-collapse text-center">
//                 <thead>
//                     <tr>
//                         <th className='border-neutral-300 border-b border-solid'></th>
//                         {Array.from({ length: 7 }).map((_, idx) => (
//                             <th key={idx} className="px-2.5 py-2 text-lg font-semibold text-zinc-800 border-neutral-300 border-b border-solid">{idx + 1}</th>
//                         ))}
//                         <th className='border-neutral-300 border-b border-solid'></th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     <tr>
//                         <td className="px-2.5 py-2 text-base font-semibold text-zinc-800">Poor Fit</td>
//                         {selectedRatings.map((isSelected, idx) => (
//                             <td
//                                 key={idx}
//                                 className={`p-2 cursor-pointer`}
//                                 onClick={() => handleRatingClick(idx + 1)}
//                             >
//                                 <div
//                                     className="flex flex-col justify-center items-center px-1 border border-solid bg-stone-50 border-stone-300 h-[18px] rounded-[360px] w-[18px] cursor-pointer"
//                                 >
//                                     {isSelected && (
//                                         <div className="shrink-0 w-2.5 h-2.5 bg-green-500 rounded-[360px]" />
//                                     )}
//                                 </div>
//                             </td>
//                         ))}
//                         <td className="px-2.5 py-2 text-base font-semibold text-zinc-800">Perfectly Fit</td>
//                     </tr>
//                 </tbody>
//             </table>
//         </div>
//     );
// }

const TotalScore = ({ title, score }) => (
    <div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
        <div className="grow justify-center items-center px-16 py-4 font-semibold bg-neutral-100 w-fit max-md:px-5">
            {title}
        </div>
        <div
            className="flex gap-2 justify-center px-5 py-4 min-w-[96px] bg-white border border-solid border-zinc-300 max-md:px-5"
        >
            <div>{score}%</div>
        </div>
    </div>
);


const InterviewQuestion = ({ question, fitRating, isRequired, onChange, index }) => {
    const [formData, setFormData] = useState({
        question: question,
        fitRating: fitRating,
        comment: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        if (formData, onChange) {
            onChange(formData)
        }
    }, [formData])

    return (
        <>
            <div className="mt-7 mb-4 lg:mt-10 text-base lg:text-lg font-semibold text-zinc-800 max-md:mt-[36px] max-md:max-w-full">
                {index ? index : ""}. {question}{" "}
                {isRequired && <span className="text-red-600">*</span>}
            </div>
            <FitScaleEvaluationTable
                labelLow="Poor fit"
                labelHigh="Perfect fit"
                value={formData.fitRating}
                onChange={(value) => setFormData(prevState => ({ ...prevState, fitRating: value }))}
            />
            <div className='mt-9 max-w-[640px] w-full'>
                <div className="text-lg font-semibold text-zinc-800 w-full leading-[20px] max-md:text-[16px]">
                    Comments
                </div>
                <TextareaField
                    labelClassName={"hidden"}
                    value={formData.comment}
                    onChange={handleChange}
                    name="comment"
                    id="comment"
                    inputBg='shrink-0 mt-2 lg:mt-4 max-lg:!h-[150px] max-w-full bg-white border border-solid border-zinc-300 h-[164px] w-[640px] !rounded-[0px]'
                />
            </div>
        </>
    )
};

const InterviewSection = () => {
    const [questions, setQuestions] = useState([
        {
            question: "Lorem ipsum dolor sit amet?",
            fitRating: 1,
            comment: "",
            isRequired: true
        },
        {
            question: "Lorem ipsum dolor sit amet?",
            fitRating: 1,
            comment: "",
            isRequired: true
        },
        {
            question: "Lorem ipsum dolor sit amet?",
            fitRating: 1,
            comment: "",
            isRequired: true
        },
    ])
    const [interviewer, setInterviewer] = useState()

    const interviewers = [{
        value: "marc_spector",
        label: "Marc Spector"
    }]
    const handleSelect = (value) => {
        setInterviewer(value)
    }

    const handleChange = (formData) => {
        const { question, fitRating, comment } = formData
        const questionIndex = questions.findIndex(item => item.question === formData.question);

        if (questionIndex !== -1) {
            const newData = [...questions];
            newData[questionIndex] = { question, fitRating, comment };
            setQuestions(newData);
        } else {
            setQuestions(prev => [...prev, { question, fitRating, comment }]);
        }
    };
    return (
        <div className="flex flex-col items-stretch">
            <div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
                2<sup>nd</sup> Interview
            </div>
            <div className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full max-md:mt-[24px]" />
            <div className="flex max-lg:flex-col -mb-4 justify-start gap-2 lg:gap-24 self-stretch mt-9 w-full">
                <div className="my-auto lg:text-lg font-semibold text-zinc-800">
                    Interviewer Name
                </div>
                <SelectField
                    inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 max-w-[815px] w-full"
                    name="department"
                    id="department"
                    value={interviewer}
                    onChange={handleSelect}
                    options={interviewers}
                />
            </div>
            {
                questions.map((question, idx) => (
                    <InterviewQuestion
                        key={idx}
                        index={idx + 1}
                        {...question}
                        onChange={handleChange}
                    />
                ))
            }
            <TotalScore title="Total Score" score={90} />
        </div>
    );
};

const NextStepSection = () => {
    return (
        <section>
            <hr className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full max-md:mt-[80px]" />
            <div className="flex gap-5 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap max-md:max-w-full">
                <button className="flex justify-center px-12 py-5 bg-gray-500 rounded-md max-md:px-5 max-md:w-full">
                    Previous
                </button>
                <button className="flex justify-center items-center px-16 py-5 bg-green-500 rounded-md max-md:px-5 max-md:w-full">
                    Next
                </button>
            </div>
        </section>
    );
};

const SBM01_10_3_1 = () => {
    return (
        <>
            <SalesAssociateHeader name="Marc Spector" />

            <section className='flex flex-col w-full flex-auto pt-10 pb-20 lg:pt-20 lg:pb-24 mx-auto max-w-[1440px] px-5'>
                <HeroSection />
                <InterviewSection />
                <NextStepSection />
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM01_10_3_1