import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";


export const RadioSection = ({ className, inputLabel, labelClassName, inputClassName, payload, setPayload, fields }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }
    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm[fields?.active] ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        [fields?.active]: !payload.applicationForm[fields?.active]
                    }
                });
            }
        },
        {
            label: "Add more choices...",
            handler: () => { }
        },
    ]

    if (isEditing && isModal) {

        return (
            payload && <ModalForm onSave={(e) => { setIsModal(false); setIsEditing(false) }} formWrapClass="m-auto">
                <div>
                    <EditableInput
                        className="lg:text-[22px] text-[20px] font-bold"
                        type="text"
                        name={fields?.title}
                        id={fields?.title}
                        value={payload?.applicationForm[fields?.title]}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                    <div className="flex max-sm:justify-between mt-[32px]">
                        {
                            payload?.applicationForm[fields?.choices]?.map((item, index) => {
                                return <label className="cursor-pointer radio-green-container w-16 md:mr-[24px] font-medium max-md:w-[50%]">
                                    <input
                                        type="radio"
                                        className="radio-green-sub aspect-square w-5 mr-[10px]"
                                        name={"radio-"+payload?.applicationForm[fields?.title]}
                                        id={"radio-"+index}
                                    />
                                    <EditableInput
                                        className="font-semibold text-[18px]"
                                        type="text"
                                        name={index}
                                        id={index}
                                        value={item}
                                        onChange={(e)=>{
                                            setPayload({
                                                ...payload,
                                                applicationForm: {
                                                    ...payload.applicationForm,
                                                    [fields?.choices]: payload.applicationForm[fields?.choices].map((itemC, itemCIndex) => itemCIndex === index ? e.target.value :  itemC) 
                                                }
                                            });
                                        }}
                                        disabled={!isEditing}
                                    />
                                </label>
                            })
                        }
                    </div>
                    {
                        fields.inputLabel || inputLabel ?
                        <div className='mt-[24px]'>
                            <EditableInput
                                className={`text-lg font-semibold mb-2 ${labelClassName ? labelClassName : ""}`}
                                type="text"
                                name={fields.inputLabel}
                                id={fields.inputLabel}
                                value={payload?.applicationForm[fields.inputLabel] || inputLabel}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <textarea type="text"
                                className={`grey-input-custom ${inputClassName ? inputClassName : ""}`}
                                placeholder=""
                                style={{ resize: "none" }}
                            />
                        </div>
                        : null
                    }
                </div>
            </ModalForm>
        )
    }

    return (
        payload && <form onSubmit={handleSubmit}>
            <div className="flex justify-between mb-[24px]">
                <EditableInput
                    className="lg:text-[22px] text-[20px] font-bold"
                    type="text"
                    name={fields?.title}
                    id={fields?.title}
                    value={payload?.applicationForm[fields?.title]}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton>
                        :
                        <ButtonOptions options={options} />
                }
            </div>
            { payload?.applicationForm[fields?.active] ? 
                <div className={`flex flex-col gap-[24px] w-full max-w-[650px] ${className ? className : ""}`}>
                    <div>
                        <div className="flex max-md:justify-between">
                            {
                                payload?.applicationForm[fields?.choices]?.map((item, index) => {
                                    return <label className="cursor-pointer radio-green-container w-16 md:mr-[24px] font-medium max-md:w-[50%]">
                                        <input
                                            type="radio"
                                            className="radio-green-sub aspect-square w-5 mr-[10px]"
                                            name={"radio-"+payload?.applicationForm[fields?.title]}
                                            id={"radio-"+index}
                                        />
                                        <EditableInput
                                            className="font-semibold text-[18px]"
                                            type="text"
                                            name={index}
                                            id={index}
                                            value={item}
                                            onChange={(e)=>{
                                                setPayload({
                                                    ...payload,
                                                    applicationForm: {
                                                        ...payload.applicationForm,
                                                        [fields?.choices]: payload.applicationForm[fields?.choices].map((itemC, itemCIndex) => itemCIndex === index ? e.target.value :  itemC) 
                                                    }
                                                });
                                            }}
                                            disabled={!isEditing}
                                        />
                                    </label>
                                })
                            }
                        </div>
                    </div>
                    {
                        fields.inputLabel || inputLabel ?
                            <div>
                                <EditableInput
                                    className={`text-lg font-semibold mb-2 ${labelClassName ? labelClassName : ""}`}
                                    type="text"
                                    name={fields.inputLabel}
                                    id={fields.inputLabel}
                                    value={payload?.applicationForm[fields.inputLabel] || inputLabel}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                />
                                <textarea type="text"
                                    className={`grey-input-custom ${inputClassName ? inputClassName : ""}`}
                                    placeholder=""
                                    style={{ resize: "none" }}
                                />
                            </div>
                        : null
                    }
                </div>
            : null }
        </form>
    )
}