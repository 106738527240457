import React, { useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from '../components/parts/Footer';
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import Checkbox from '../components/base/Checkbox';
import TextareaField from '../components/base/TextareaField';
import magicWandIcon from "../assets/img/magic-wand.svg";
import JobCreationProcess from '../components/JobCreationProcess';
import { ReactComponent as EditIcon } from "../assets/img/bx-edit.svg";
import { ReactComponent as SettingsIcon } from "../assets/img/settings.svg";
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import ReactTextareaAutosize from 'react-textarea-autosize';
import PrimaryButton from '../components/base/PrimaryButton';
import { EditableInput } from "./SBM02_5/EditableInput";
import { HiringProcess } from '../components/base/ProgressStepsSBM';

const NextStepSection = () => {
    return (
        <section>

            <hr className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className="flex gap-5 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <button className="flex items-center justify-center px-12 h-[52px] bg-gray-400 rounded-md max-md:px-5">
                    Back
                </button>
                <button className="flex items-center justify-center px-16 h-[52px] bg-green-500 rounded-md max-md:px-5">
                    Next
                </button>
            </div>
        </section>
    );
};

const EditableTextarea = ({ className, ...props }) => {
    return <ReactTextareaAutosize
        className={classNames(`text-[18px] text-[#6D6D6D] focus:outline-none focus:ring-2 focus:ring-green-500 bg-white h-[44px] w-full resize-none overflow-y-hidden leading-[30px]`, className)}
        {...props}
    />
}


const ButtonOptions = ({ options }) => {
    return <div className='relative'>
        <SettingsIcon name="my-anchor-element" className='cursor-pointer' />
        <Tooltip
            anchorSelect="[name^='my-anchor-element']"
            clickable
            noArrow
            style={{
                width: 280,
                borderRadius: 12,
                padding: 0,
                background: 'white',
                boxShadow: '0px 4px 8px 0px #5B68713D, 0px 0px 1px 0px #1A202452',
                marginTop: -15
            }}
            // isOpen
            place='right-start'
            offset={14}
            opacity='inherit'
        >
            <ul>
                {options.map((option, index) => <li
                    key={index}
                    onClick={option.handler}
                    className='text-[#333333] px-[28px] h-[54px] flex items-center text-lg border-b border-solid border-[#D6D6D6] cursor-pointer'
                >
                    {option.text}
                </li>)}
            </ul>
        </Tooltip>
    </div>
}

const SBM02_5 = () => {
    const [formData, setFormData] = useState({
        section1: {
            item1: {
                isEdit: false,
                disabled: false,
                heading: 'It takes X minutes',
                content: 'Tell Applicants what to expect on the job Application Form. \nYou may use up to 10 line of copy'
            }
        }
    });
    const timezones = [
        { value: "usa", label: "USA" }
    ];

    const stepsData = [
        { stepNumber: "1", label: "New Job Details", isActive: true },
        { stepNumber: "2", label: "Description", isActive: true },
        { stepNumber: "3", label: "Preview", isActive: true },
        { stepNumber: "4", label: "Pipelines", isActive: true },
        { stepNumber: "5", label: "Qualification Attributes", isActive: true },
        { stepNumber: "6", label: "Interview Questions", isActive: true },
        { stepNumber: "7", label: "Application Form", isActive: false },
        { stepNumber: "8", label: "Disposition Status", isActive: false }
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue(name, value);
    };
    const setFormValue = (name, value) => {
        const [section, item, role] = name.split('-');
        setFormData(prevState => {
            const result = { ...prevState }
            result[section][item][role] = value;
            return result;
        })
    }

    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Job Setup', "New Job Details", "Description", "Preview", "Pipeline", "Qualification Attributes", "Interview Questions", "Application Form"]} />
            <div className="flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto">
                <section className="flex w-full text-center overflow-auto py-4 max-md:pb-[0px]">
                    <HiringProcess stepsData={stepsData} />
                </section>
                <div className='mx-auto text-center flex flex-col gap-2 mb-11'>
                    <h1 className="text-4xl font-bold text-zinc-800">
                        Let’s Get Started
                    </h1>
                    <h2 className='max-w-[783px] mx-auto'>
                        These details serve as the foundation of your job.
                    </h2>
                </div>
                <div className='w-full max-w-[1026px] mx-auto rounded-md border border-solid border-[#D6D6D6] pt-[44px] px-[44px] pb-[70px] flex flex-col gap-[44px]'>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px] text-[30px] leading-[36px] font-semibold text-[#333333]'>
                        Application Form
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                        <div className='flex items-center justify-between'>
                            <div className='flex items-center justify-between gap-[12px] text-[30px] leading-[36px] font-bold text-[#333333]'>
                                It takes 15 minutes
                                <button>
                                    <EditIcon className='w-[28px] h-[28px]' />
                                </button>
                            </div>
                            <SelectField
                                parentClass='w-[258px] flex-none'
                                inputBg="bg-white rounded-md border border-solid border-[#DEDED9] gray-arrow"
                                name="timezone"
                                id="timezone"
                                value={formData.timezone}
                                onChange={handleChange}
                                options={timezones}
                            />
                        </div>
                        <p className='mt-[16px] text-[18px] leading-[36px] font-semibold text-[#37B34A] underline underline-offset-[3px]'>Recommend Defult Setup</p>
                    </div>
                    <div className='border-b border-solid border-[#D6D6D6] pb-[44px] flex flex-col gap-[32px]'>
                        <div className='flex justify-between items-start'>
                            <div className='flex flex-col gap-[12px] w-full max-w-[650px]'>
                                <EditableInput
                                    className="text-[32px] font-bold"
                                    type="text"
                                    name="section1-item1-heading"

                                    value={formData.section1.item1.heading}
                                    onChange={handleChange}
                                    placeholder='Heading...'
                                    disabled={!formData.section1.item1.isEdit}
                                />
                                <EditableTextarea
                                    className=""
                                    name="section1-item1-content"

                                    value={formData.section1.item1.content}
                                    onChange={handleChange}
                                    placeholder='Content...'
                                    disabled={!formData.section1.item1.isEdit}
                                />
                            </div>
                            {formData.section1.item1.isEdit ?
                                <PrimaryButton
                                    className='min-w-[112px] h-[52px] text-lg font-bold'
                                    onClick={() => setFormValue('section1-item1-isEdit', false)}
                                >
                                    Save
                                </PrimaryButton> :
                                <ButtonOptions
                                    options={[
                                        {
                                            text: 'Edit the title / texts...',
                                            handler: () => setFormValue('section1-item1-isEdit', true)
                                        },
                                        {
                                            text: 'Disable the entire section...',
                                            handler: () => setFormValue('section1-item1-disabled', true)
                                        }
                                    ]}
                                />
                            }

                        </div>
                    </div>
                </div>
                <NextStepSection />
            </div>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM02_5