import React, { useState } from "react";
import Step5 from "../NewJobDetails/Step5";
import NextStepSection from "./NextStepSection";

const QualificationAttributes = ({
    payload,
    setPayload,
    setContactFormOpen,
    industriesList,
    qaPresetAttributesSelected,
    setQaPresetAttributesSelected,
    save,
    statusToggle,
}) => {
    return (
        <div className="mt-[40px]">
            <Step5
                payload={payload}
                setPayload={setPayload}
                setContactFormOpen={setContactFormOpen}
                industriesList={industriesList}
                qaPresetAttributesSelected={qaPresetAttributesSelected}
                setQaPresetAttributesSelected={setQaPresetAttributesSelected}
                bottomPaste={<div className="mb-[50px]">
                    <NextStepSection
                        save={save}
                        statusToggle={statusToggle}
                        payload={payload}
                    />
                </div>}
            />
        </div>
    )
}

export default QualificationAttributes