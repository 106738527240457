const summaryBase = 'api/summary';

export const summary = (instance) => ({
    async createSummary(payload) {
        const { data } = await instance.post(`${summaryBase}`, payload.body);
        return data;
    },
    async getMySummary(payload) {
        const { data } = await instance.get(`${summaryBase}/me`);
        return data;
    },
    async getSummaryCandidate(summaryId,payload) {
        const { data } = await instance.get(`${summaryBase}/${summaryId}`);
        return data;
    },
    async getSummaryPhotoFile(id,name, payload) {
        const { data } = await instance.get(`${summaryBase}/${id}/${name}`);
        return data;
    },
    async getSummaryPDFFile(id,name , payload) {
        const { data } = await instance.get(`${summaryBase}/${id}/${name}`);
        return data;
    },
});

