import { ProgressBarFour } from "../../../components/base/ProgressBarFour";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ProgressBarSBM01 = ({ activeTab = 1 }) => {
    const { jobId, applicationId } = useParams();
    const progressData = [
        { stepNumber: "1", label: "Application", isActive: false, link: `/SBM01_10_14_2_1/${jobId}/${applicationId}` },
        { stepNumber: "2", label: "Communications", isActive: false, link: `/SBM01_10_14_2_2/${jobId}/${applicationId}` },
        { stepNumber: "3", label: "Assessment", isActive: false, link: `/SBM01_10_14_2_3/${jobId}/${applicationId}` },
        { stepNumber: "4", label: "Resume", isActive: false, link: `/SBM01_10_14_2_12/${jobId}/${applicationId}` },
    ]

    const [modifyStep, setModifyStep] = useState(null)

    useEffect(() => {
        const a = progressData.map((step, index) => {
            if (step.stepNumber == activeTab) {
                step.isActive = true
                return step
            } else {
                return step
            }
        })

        setModifyStep(a)
    }, [activeTab]);

    return (
        <section className="flex w-full text-center overflow-auto py-4">
            {modifyStep && <ProgressBarFour stepsData={modifyStep} />}
        </section>
    )
}