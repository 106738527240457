import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import api from "../api";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import PageLoader from '../components/PageLoader';
import { Footer } from '../components/parts/Footer';
import { crumbsConfig } from './SBM01_Form/config';


const ResumeUploadSection = ({ jobData, applicationData }) => {

    return (
        <div className="flex flex-col  text-zinc-800 max-md:mt-10 lg:max-w-[720px]">
            {jobData.applicationForm.you_must_copy_and_paste_your_resume_here_active && <>
                <div className="flex flex-col max-md:max-w-full">
                    <h2 className="text-[22px] md:text-3xl font-bold leading-7 lg:leading-10 max-md:max-w-full">
                        {"Resume"}
                    </h2>
                    <textarea
                        className="grey-textarea mt-[24px]"
                        value={applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_please_copy_and_paste_answer}
                    />
                    <div className="font-[500] text-[18px] flex flex-col gap-[16px] mb-[32px]">
                        {
                            applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached_answer && (
                                <iframe
                                    src={process.env.REACT_APP_API_URL + applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_alternatively_you_can_also_attached_answer}
                                    width="100%"
                                    height="1200px"
                                    className="pdf-viewer mt-[36px] w-full h-[calc(100vh-100px)]"
                                    title="Document Viewer"
                                ></iframe>
                            )
                        }
                    </div>
                    {
                        applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_linkedin_profile_url_answer && (
                            <div>
                                <p className="mb-[16px] text-[18px] md:text-lg font-semibold leading-[30px] max-md:max-w-full">
                                    {"LinkedIn profile"}
                                </p>
                                <a href={applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_linkedin_profile_url_answer} target="_blank" class="cursor-pointer underline font-medium text-[#06D6A0]">{applicationData.applicationForm.you_must_copy_and_paste_your_resume_here_linkedin_profile_url_answer}</a>
                            </div>
                        )
                    }
                </div>
            </>}
        </div>
    );
};

const NextStepSection = ({ jobId }) => {
    return (
        <section>
            <hr className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className="flex gap-4 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap">
                <Link to={`/SBM01_2/${jobId}/`} className="flex justify-center items-center px-12 py-1 h-[52px] bg-gray-500 rounded-md w-full lg:max-w-[162px]">
                    Previous
                </Link>
            </div>
        </section>
    );
};

const SBM01_2_2 = () => {
    const { jobId, applicationId } = useParams();

    const [jobData, setJobData] = useState(null);

    const [applicationData, setApplicationData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const getJobApplicationById = async () => {
        setIsLoading(true)
        const jobRes = await api.jobs.getJobsById(jobId);
        setJobData(jobRes?.data);

        const res = await api.jobApplications.getJobApplicationById(jobId, applicationId);
        setApplicationData(res?.data);

        setIsLoading(false)
    }

    useEffect(() => {
        getJobApplicationById();
    }, [])


    const crumbs =
        [crumbsConfig.sub, crumbsConfig.jobs, ['Applicants', `/SBM01_2/${jobId}?tab="applicants`], ["Resume", "/"]]

    return (
        <>

            <BreadCrumbWhite crumbs={crumbs} />

            {
                !isLoading && jobData && applicationData ? (
                    <div className='flex-auto'>

                        <div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>

                            <ResumeUploadSection
                                jobData={jobData}
                                applicationData={applicationData}
                            />
                            <NextStepSection jobId={jobId} />
                        </div>
                    </div>
                ) : isLoading ? <PageLoader /> : null
            }

            <Footer hideCopyright={true} />
        </>

    )
}

export default SBM01_2_2