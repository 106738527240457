import * as React from "react";
import sponsorLogo from "../assets/img/sponsor_logo.png";
import Breadcrumbs from "../components/base/Breadcrumbs";
import InputField from "../components/base/InputField";
import { Link, useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchAllIndustries, fetchDemoApplication} from "../redux/thunks/companiesThunks";
import {useDispatch, useSelector} from "react-redux";
import SelectField from "../components/base/SelectField";
import SelectBox from "../components/base/SelectBox";
import { getCurrentPage } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import getImageUrl from "../assets/helpers/getImageUrl";
import PageLoader from "../components/PageLoader";


const HOM1_5 = ({setMessage}) => {
    const currentPage = useSelector(getCurrentPage);
    const getValue = (key) => findValue(currentPage?.blocks, key);
    console.log(currentPage?.blocks);
    const breadcrumbItems = [
        { name: 'Home', to: "/HOM1_1", isCurrent: false },
        { name: 'Book a Demo', to: "/HOM_1_5_1", isCurrent: true },
    ];


    return (!currentPage ? <PageLoader color="light" /> : <>
        <div className="max-w-[1272px] px-4 mx-auto mb-12">
            <Breadcrumbs
                links={breadcrumbItems}
            />
            <HomePage setMessage={setMessage} getValue={getValue}/>
        </div>
        <RequestSuccessPage getValue={getValue}/>
    </>)
};

// Reusable components

const InfoSection = ({ imageSrc, title, description, alt }) => (
    <div className="flex flex-col gap-3.5 justify-between mt-6 lg:mt-9 text-white whitespace-normal max-md:flex-wrap max-md:max-w-full">
        <div className="flex gap-3.5 justify-between">
            <img loading="lazy" src={imageSrc} alt={alt} className="aspect-square w-10 lg:w-[50px]" />
            <div className="flex-auto my-auto font-bold lg:text-4xl text-2xl leading-10">{title}</div>
        </div>
        <p className="mt-2.5 lg:text-[26px] lg:leading-[40px] text-lg leading-7 font-normal">{description}</p>
    </div>
);


const HomePage = ({setMessage, getValue}) => {
    const currentPage = useSelector(getCurrentPage);
    const infoSectionsData = [
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/7b629586c89471c057d15cf01bea0a000b08d6ec173409dc1679a2c707b5c194?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&",
            title: "Fast",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            alt: "Illustration for Fast",
        },
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/8a1aafbd8fe29a6573ba737678b8197ee66814489f01cd74e83d90175fb0ecff?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&",
            title: "Easy",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            alt: "Illustration for Easy",
        },
        {
            imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/79f1ffd7e2009a2efb96c87a678b80dadaa966a8599c351dddf131f487b5f4fc?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&",
            title: "Open",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            alt: "Illustration for Open",
        },
    ];


    return (
        <main className="flex flex-col items-center overflow-hidden">
            <section className="justify-center self-stretch w-full max-md:max-w-full">
                <div className="flex gap-11 lg:gap-[70px] max-lg:flex-col">
                    <div className="flex flex-col lg:w-[54%] w-full">
                        <div className="flex flex-col max-lg:items-center max-lg:px-3 max-lg:text-center text-pretty mt-8 text-2xl font-bold leading-10 text-white max-md:lg-6">
                            <span className="lg:text-[70px] text-[40px] leading-[48px] lg:leading-[80px] max-md:max-w-full max-md:text-4xl max-md:leading-[51px]">
                                {getValue('title_top_page')}
                            </span>
                            <span className="mt-7 h-1 bg-[#37B34A] w-[78px]"></span>
                            <p className="mt-7 lg:text-[26px] lg:leading-[40px] text-lg leading-7 font-normal max-md:max-w-full">
                            {getValue('description_top_page')}
                            </p>
                        </div>
                        {getValue('items_top_page_list')?.data?.map((section, index) => (
                            <InfoSection key={index} title={section.title_top_page} description={section.description_top_page} imageSrc={getImageUrl(section.image_top_page)} />
                        ))}
                    </div>
                    <aside className="flex flex-col lg:w-[46%] w-full">
                        <CurrentForm 
                            onSuccess={(message) => setMessage(message)} 
                            getValue={getValue}
                        />
                    </aside>
                </div>
                <footer className="mt-20 text-2xl font-bold leading-9 text-white whitespace-normal max-md:mt-10">
                    <h2 className="text-center">{getValue('trust_title_top_page')}</h2>
                    <nav className="mx-auto md:px-5 px-12 mt-8 w-full grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-12 lg:gap-20 justify-center place-items-center">
                        {getValue('trust_title_top_page_list')?.data?.map((item, index) => 
                            <img 
                                key={index} 
                                loading="lazy" 
                                src={getImageUrl(item.image_trust_block)} 
                                alt="Trusted Company 1" 
                                className="h-full w-full object-contain"
                             />
                        )}
                    </nav>
                </footer>
            </section>
        </main>
    );
};

function InfoSection1({ title, content }) {
    return (
        <div className="w-full">
            <div className="flex flex-col gap-5 max-lg:flex-col">
                <div className="flex items-baseline gap-3">
                    <div className="min-w-4 w-4 aspect-square bg-[#37B34A]"></div>
                    <div>
                        <h3 className="lg:text-3xl lg:leading-[52px] text-2xl leading-[30px] font-bold text-zinc-800 max-lg:mb-2">{title}</h3>
                        <p className="text-lg leading-8 text-stone-500">{content}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function RequestSuccessPage({getValue}) {
    const sectionsData = [
        { title: "Lorem Ipsum", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus." },
        { title: "Dolor Sit", content: "Dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." },
        { title: "Amet Consectetur", content: "Amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna." },
        { title: "Consectetur Adipiscing", content: "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." },
        { title: "Elit Sed Do", content: "Elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam." },
        { title: "Eiusmod Tempor", content: "Eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco." }
    ];

    return (
        <main className="px-16 py-24 justify-center bg-white max-md:px-5 flex flex-col flex-auto items-center">
            <header className="col-span-2 text-center">
                <h1 className="lg:text-[50px] text-4xl font-bold text-zinc-800">{getValue('title_submitting_request')}</h1>
                <hr className="mt-7 h-1 bg-[#37B34A] w-[78px] mx-auto" />
            </header>
            <section className="grid grid-cols-2 gap-6 lg:gap-5 mt-6 lg:mt-10 max-w-4xl w-full max-md:grid-cols-1">
                {getValue('list_items_submitting_request')?.data?.map((section, index) => (
                    <InfoSection1 key={index} title={section.title} content={section.description} />
                ))}
            </section>
        </main>
    );
}


function CurrentForm ({onSuccess, getValue}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [agree, setAgree] = React.useState(false)

    const [industryOptions, setIndustryOptions] = React.useState([
        {label: "Choose", value: ''}
    ])

    useEffect(() => {
        const req = async () => {
            const res = await dispatch(fetchAllIndustries())

            if (res?.payload && res.payload.length > 0) {
                setIndustryOptions([{label: "Choose", value: ''}, ...res.payload.map(el => ({label: el.name, value: el.id}))])
            }
        }
        
        req()
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        companyURL: '',
        companyEmployees: '',
        jobTitle: '',
        industry: ''
    })

    const [formErrors, setFormErrors] = useState({})

    const handleChangeForm = (e) => {
        const {value, id} = e.target;
        setFormErrors(prevState => ({ ...prevState, [id]: false }));
        setFormData(prevState => ({...prevState, [id]: value}))
    }


    const handleSubmit = async (e) => {
        e.preventDefault()

        const fields = Object.entries(formData)
        let anyErrors = false

        fields.forEach(el => {
            if (!el[1]) {
                setFormErrors(prevState => ({...prevState, [el[0]]: true}))
                anyErrors = true
            }
        })
        if(!agree) {
            setFormErrors(prevState => ({...prevState, agree: true}))
            return
        }

        if (anyErrors) return

        const res = await dispatch(fetchDemoApplication(formData))
        navigate('/HOM1_5_1', {
            state: res.payload
        })
        // console.log(res, 'res application')

        // if (res.payload?.message) {
        //     onSuccess && onSuccess(res?.payload?.message)
        // }

        // console.log("as");
    }



    return (
        <form className="flex flex-col grow px-8 py-9 lg:px-[55px] lg:py-[50px] w-full bg-white rounded-md"
              onSubmit={handleSubmit}>
            <InputField label={getValue('name_form_top_page')} id="name"
                onChange={handleChangeForm}
                value={formData.name}
                error={formErrors['name']}
            />
            <InputField label={getValue('email_form_top_page')} type="email" id="email"
                onChange={handleChangeForm}
                value={formData.email}
                error={formErrors['email']}
            />
            <InputField label={getValue('phone_number_form_top_page')} type="tel" id="phoneNumber"
                onChange={handleChangeForm}
                value={formData.phoneNumber}
                error={formErrors['phoneNumber']}
            />
            <InputField label={getValue('company_url_form_top_page')} id="companyURL"
                onChange={handleChangeForm}
                value={formData.companyURL}
                error={formErrors['companyURL']}
            />
            {/*<InputField label={getValue('company_employees_form_top_page')} type="number" id="companyEmployees"*/}
            {/*    onChange={handleChangeForm}*/}
            {/*    value={formData.companyEmployees}*/}
            {/*    error={formErrors['companyEmployees']}*/}
            {/*/>*/}
            
            {/*<InputField label="Company # of Employees" type="number" id="companyEmployees"*/}
            {/*    onChange={handleChangeForm}*/}
            {/*    value={formData.companyEmployees}*/}
            {/*    error={formErrors['companyEmployees']}*/}
            {/*/>*/}
            <SelectBox options={[{label: "Choose", value: ''}, { value: '1-10', label: '1-10 Employees' },
                { value: '11-50', label: '11-50 Employees' },
                { value: '51-200', label: '51-200 Employees' }]} label="Company # of Employees"
                       onSelect={val => {
                           setFormData(prevState => ({...prevState, 'companyEmployees': val}))
                           setFormErrors(prevState => ({...prevState, 'companyEmployees': false }));

                       }}
                       className={'!mt-[10px] '}
                       value={formData.companyEmployees} svg={2}
                       error={formErrors.companyEmployees}
            />

            <InputField label={getValue('job_title_form_top_page')} id="jobTitle" parentClassName={'!mt-[25px]'}
                onChange={handleChangeForm}
                value={formData.jobTitle}
                error={formErrors['jobTitle']}
            />

            <SelectBox options={industryOptions} label={getValue('industry_form_top_page')}
               onSelect={val => {
                   setFormData(prevState => ({...prevState, 'industry': val}))
                   setFormErrors(prevState => ({ ...prevState, 'industry': false }));
               }}
               value={formData.industry} svg={2}
               error={formErrors['industry']}
            />


            <div className="flex justify-center flex-col items-center">
                <div className="flex justify-center mt-8">
                    <input type="checkbox" id="agreement" name='agreement' className="hidden" onChange={(e) => {
                        setAgree(e.target.checked);
                    }}/>
                    <label htmlFor="agreement" className="flex items-center gap-5 lg:gap-4 cursor-pointer">
                  <span
                      className={`flex h-[28px] min-w-[58px] items-center rounded-full aspect-square p-1 transition-all ${agree ? 'bg-[#37B34A]' : 'bg-gray-300'}`}
                      aria-hidden="true">
                    <span
                        className={`h-[20px] w-[20px] rounded-full transition-all ${agree ? 'ml-[30px] bg-white' : 'bg-[#37B34A]'}`}></span>
                  </span>
                        <span className="ml-2 text-base text-zinc-800">By selecting this, you agree to the <Link to="/HOM_1_12" target="_blank"
                                                                                                              className="text-blue-500">Privacy Policy</Link></span>
                    </label>
                </div>
                {formErrors?.agree && <p className="text-red-500 text-sm mt-1">You must agree the Privacy Policy</p>}
                <button type="submit"
                        className="w-full mt-5 flex items-center justify-center h-[60px] lg:h-[64px] text-[19px] lg:text-2xl font-bold text-white uppercase bg-[#37B34A] rounded-md px-[65px] hover:bg-[#002E67]">
                    {getValue('submit_form_top_page')}
                </button>
            </div>
        </form>
    )
}

export default HOM1_5;