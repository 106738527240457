import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';
import api from '../../../api';
import PageLoader from '../../../components/PageLoader';
import PrimaryButton from '../../../components/base/PrimaryButton';
import crossIcon from '../../../assets/img/cross.svg'
import checkIcon from '../../../assets/img/bx-check.svg'
import { toast } from 'react-toastify';

const AIRecommendation = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)
	const [data, setData] = useState()
	useEffect(() => {
		setActiveStep(8)
		setIsLoading(true)
		api.hiringAlgorithm.getResult(applicationId).then(response => {
			console.log(response)
			if(!response.success) {
				setError(response.message)
				return
			}
			setData(response.data)
		}).finally(() => setIsLoading(false))
	}, [applicationId])

	const handleProcess = async () => {
		setError(null)
		const response = await api.hiringAlgorithm.sendProcessRequest(applicationId);
		console.log(response)
		toast(response.message, {
			type: response.success ? 'success' : 'error'
		})
		if(response.success) {
			setData(response.data)
		}
	}
	const handlePrev = () => {
		navigate(`/SBM01_10_16/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_5_5/${jobId}/${applicationId}`)
	}

	return (
		<div className='flex-auto'>
			{isLoading ? <PageLoader /> : error ? 
			<div className='flex items-center justify-between'>
				<p className='text-4xl font-bold text-zinc-950'>
					{error}
				</p>
				<PrimaryButton className='px-4' onClick={handleProcess}>
					Request to process job application
				</PrimaryButton>
			</div>
			 : data ? data.status === 'pending' ? <p className='text-4xl font-bold text-zinc-950 text-center'>
			 Waiting for response
		 </p> :
			<div className='flex flex-col gap-11 max-w-[1440px] px-5 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
				<section className="pb-11 lg:pb-16 border-b border-solid border-zinc-300">
					<StandardsComponent data={data} />
				</section>
				{/*<PerformanceReview data={data}/>*/}
				<PerformanceVariables data={data} />
				<PrimaryButton className='px-4 w-fit' onClick={handleProcess}>
					Request to process job application
				</PrimaryButton>
			</div>: null}
			<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
		</div>
	)
}

const standards = [
	{ id: 1, level: "High Standards", imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/96fa0a208b800e04a9747eaac11c3a98e3a912adfb9728bfb25e2142b74ebb23?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&", alt: "High standard icon" },
	{ id: 2, level: "Medium Standards", imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/96fa0a208b800e04a9747eaac11c3a98e3a912adfb9728bfb25e2142b74ebb23?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&", alt: "Medium standard icon" },
	{ id: 3, level: "Average Standards", imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/96fa0a208b800e04a9747eaac11c3a98e3a912adfb9728bfb25e2142b74ebb23?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&", alt: "Average standard icon" },
];

const StandardCard = ({ level, alt, accept }) => (
	<section className="flex flex-col">
		<div className="flex flex-col grow justify-center font-bold md:whitespace-nowrap text-zinc-800">
			<h2 className="text-4xl leading-8">{level}:</h2>
			<div className="flex gap-2.5 mt-4 lg:mt-7 text-2xl leading-7">
				<img loading="lazy" src={accept ? checkIcon : crossIcon} alt={alt} className="shrink-0 w-8 aspect-square" />
				<div className="flex-auto">{accept ? 'Accept' : "Don't accept" }</div>
			</div>
		</div>
	</section>
);

const StandardsComponent = ({data}) => {
	const standards = [
		{ id: 1, level: "High Result", accept: data.high_result, alt: "High standard icon" },
		{ id: 3, level: "Medium Result", accept: data.medium_result, alt: "Medium standard icon" },
		{ id: 3, level: "Average Result", accept: data.average_result, alt: "Average standard icon" },
		// { id: 2, level: "High Weighted Result", accept: data.high_weighted_result, alt: "Average standard icon" },
		// { id: 3, level: "Medium Weighted Result", accept: data.medium_weighted_result, alt: "Medium standard icon" },
		// { id: 3, level: "Average Weighted Result", accept: data.average_weighted_result, alt: "Average standard icon" },
	];
	
	return (<div className="flex gap-[60px] max-lg:flex-col flex-wrap">
		{standards.map((standard) => (
			<StandardCard key={standard.id} level={standard.level} alt={standard.alt} accept={standard.accept}/>
		))}
	</div>
)};

const dataStrengths = [
	{ id: 21, name: "client_service_empathy", percentage: 43 },
	{ id: 26, name: "team_or_solo", percentage: 33 },
	{ id: 15, name: "job_outlook", percentage: 28 },
	{ id: 5, name: "overall_for_the_3rd_in_person_interview", percentage: 24 },
	{ id: 20, name: "feedbacks", percentage: 23 },
];

const dataWeaknesses = [
	{ id: 21, name: "ftm_score", percentage: -109 },
	{ id: 26, name: "safety", percentage: -42 },
	{ id: 15, name: "snag_fit_survey_tough_minded", percentage: -28 },
	{ id: 5, name: "trustworthiness_integrity", percentage: -19 },
	{ id: 20, name: "self_evaluation_score_for_the_job", percentage: -10 },
];

const PerformanceReview = ({data, isBottom=false}) => {
	const { application } = useOutletContext()
	return(
	<div className="w-full">
		<h2 className="lg:text-[40px] text-4xl font-bold text-[#333333]">{isBottom ? 'Bottom Variables' : 'Top Variables'}</h2>
		<p className="lg:text-[20px] text-lg text-zinc-800 my-4">The variables driving {application.fullName}`s score are:</p>
		<div className="flex flex-wrap md:flex-nowrap gap-[54px] lg:mt-[54px] mt-11">
			<div className="w-full overflow-auto">
				<table className="w-full text-left border-collapse text-[#666666] font-semibold">
					<thead>
						<tr className="bg-neutral-100 whitespace-nowrap">
							<th className="px-[18px] h-[46px] border font-semibold">term</th>
							<th className="px-[18px] h-[46px] border font-semibold text-center">value</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="px-[18px] h-[56px] border">high_penalty</td>
							<td className={`px-[18px] h-[56px] border font-semibold text-white text-center bg-[#37B34A]`}>
								{data.high_penalty}
							</td>
						</tr>
						<tr>
							<td className="px-[18px] h-[56px] border">medium_penalty</td>
							<td className={`px-[18px] h-[56px] border font-semibold text-white text-center bg-[#37B34A]`}>
								{data.medium_penalty}
							</td>
						</tr>
						<tr>
							<td className="px-[18px] h-[56px] border">score</td>
							<td className={`px-[18px] h-[56px] border font-semibold text-white text-center bg-[#37B34A]`}>
								{data.score}
							</td>
						</tr>
						<tr>
							<td className="px-[18px] h-[56px] border">weighted_score</td>
							<td className={`px-[18px] h-[56px] border font-semibold text-white text-center bg-[#37B34A]`}>
								{data.weighted_score}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			{/*<div className="w-full overflow-auto">*/}
			{/*	<h2 className="lg:text-[30px] text-2xl font-bold  text-[#333333] mb-4">Top Strengths</h2>*/}
			{/*	<table className="w-full text-left border-collapse text-[#666666] font-semibold">*/}
			{/*		<thead>*/}
			{/*			<tr className="bg-neutral-100 whitespace-nowrap">*/}
			{/*				<th className="px-[18px] h-[46px] border"></th>*/}
			{/*				<th className="px-[18px] h-[46px] border font-semibold">term</th>*/}
			{/*				<th className="px-[18px] h-[46px] border font-semibold text-center">percent difference</th>*/}
			{/*			</tr>*/}
			{/*		</thead>*/}
			{/*		<tbody>*/}
			{/*			{dataStrengths.map(({ id, name, percentage }) => (*/}
			{/*				<tr key={id}>*/}
			{/*					<td className="px-[18px] h-[56px] border text-center">{id}</td>*/}
			{/*					<td className="px-[18px] h-[56px] border">{name}</td>*/}
			{/*					<td className={`px-[18px] h-[56px] border font-semibold text-white text-center ${percentage > 0 ? 'bg-[#37B34A]' : 'bg-[#FF0002]'}`}>{`${percentage > 0 ? '+' : ''}${percentage}%`}</td>*/}
			{/*				</tr>*/}
			{/*			))}*/}
			{/*		</tbody>*/}
			{/*	</table>*/}
			{/*</div>*/}
			{/* <div className="w-full overflow-auto">
				<h2 className="lg:text-[30px] text-2xl font-bold  text-[#333333] mb-4">Top Strengths</h2>
				<table className="w-full text-left border-collapse text-[#666666] font-semibold">
					<thead>
						<tr className="bg-neutral-100 whitespace-nowrap">
							<th className="px-[18px] h-[46px] border"></th>
							<th className="px-[18px] h-[46px] border font-semibold">term</th>
							<th className="px-[18px] h-[46px] border font-semibold text-center">percent difference</th>
						</tr>
					</thead>
					<tbody>
						{dataWeaknesses.map(({ id, name, percentage }) => (
							<tr key={id}>
								<td className="px-[18px] h-[56px] border text-center">{id}</td>
								<td className="px-[18px] h-[56px] border">{name}</td>
								<td className={`px-[18px] h-[56px] border font-semibold text-white text-center ${percentage > 0 ? 'bg-[#37B34A]' : 'bg-[#FF0002]'}`}>{`${percentage > 0 ? '+' : ''}${percentage}%`}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div> */}
		</div>
	</div>
)};

const PerformanceVariables = ({data}) => {

	const {bottom_variables, top_variables} = data

	// const { application } = useOutletContext()
	return(
		<div className="w-full">
			{/*<h2 className="lg:text-[40px] text-4xl font-bold text-[#333333]">{isBottom ? 'Bottom Variables' : 'Top Variables'}</h2>*/}
			{/*<p className="lg:text-[20px] text-lg text-zinc-800 my-4">The variables driving {application.fullName}`s score are:</p>*/}
			<div className="flex flex-wrap md:flex-nowrap gap-[54px] lg:mt-[54px] mt-11">
				<div className="w-full overflow-auto">
					<h2 className="lg:text-[30px] text-2xl font-bold  text-[#333333] mb-4">Top Variables</h2>
					<table className="w-full text-left border-collapse text-[#666666] font-semibold">
						<thead>
							<tr className="bg-neutral-100 whitespace-nowrap">
								{/*<th className="px-[18px] h-[46px] border"></th>*/}
								<th className="px-[18px] h-[46px] border font-semibold">term</th>
								<th className="px-[18px] h-[46px] border font-semibold text-center">percent difference</th>
							</tr>
						</thead>
						<tbody>
							{top_variables && top_variables.map(({ name, value }, idx) => (
								<tr key={idx + 'table top'}>
									{/*<td className="px-[18px] h-[56px] border text-center">{id}</td>*/}
									<td className="px-[18px] h-[56px] border">{name}</td>
									<td className={`px-[18px] h-[56px] border font-semibold text-white text-center bg-[#37B34A]`}>{value}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="w-full overflow-auto">
				<h2 className="lg:text-[30px] text-2xl font-bold  text-[#333333] mb-4">Bottom Variables</h2>
				<table className="w-full text-left border-collapse text-[#666666] font-semibold">
					<thead>
						<tr className="bg-neutral-100 whitespace-nowrap">
							{/*<th className="px-[18px] h-[46px] border"></th>*/}
							<th className="px-[18px] h-[46px] border font-semibold">term</th>
							<th className="px-[18px] h-[46px] border font-semibold text-center">percent difference</th>
						</tr>
					</thead>
					<tbody>
						{bottom_variables && bottom_variables.map(({ name, value }, idx) => (
							<tr key={idx + 'table bot'}>
								{/*<td className="px-[18px] h-[56px] border text-center">{id}</td>*/}
								<td className="px-[18px] h-[56px] border">{name}</td>
								<td className={`px-[18px] h-[56px] border font-semibold text-white text-center bg-[#FF0002]`}>{value}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			</div>
		</div>
	)};



export default AIRecommendation