import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import SelectBox from "../../components/base/SelectBox";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";
import { AdditionalInputs } from "./AdditionalInputs";


export const JobsCountSection = ({ payload, setPayload }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }
    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
    ]

    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <div className='w-full'>
                    <div className='max-w-[560px] w-full'>
                        <EditableInput
                            className="font-bold lg:text-[22px] max-lg:text-[18px]"
                            type="text"
                            name="how_many_jobs_have_you_help_wihin_the_last_two_years_title"
                            id="how_many_jobs_have_you_help_wihin_the_last_two_years_title"
                            value={payload.applicationForm.how_many_jobs_have_you_help_wihin_the_last_two_years_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <div className="w-full max-w-[110px] h-[54px] input-ap max-md:max-w-[100%]">
                            <SelectBox options={payload.applicationForm.how_many_jobs_have_you_help_wihin_the_last_two_years_choices?.map(item => ({ label: item }))} svg={2} />
                        </div>

                        <AdditionalInputs
                            className="w-full"
                            btnName={'Add New'}
                            isEditing={isEditing}
                            isArrayData
                            data={payload.applicationForm.how_many_jobs_have_you_help_wihin_the_last_two_years_choices}
                            setData={(value) => {
                                setPayload({
                                    ...payload,
                                    applicationForm: {
                                        ...payload.applicationForm,
                                        how_many_jobs_have_you_help_wihin_the_last_two_years_choices: value
                                    }
                                });
                            }}
                        />
                    </div>
                </div>
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-between">
                <EditableInput
                    className="font-bold lg:text-[22px] max-lg:text-[18px]"
                    type="text"
                    name="how_many_jobs_have_you_help_wihin_the_last_two_years_title"
                    id="how_many_jobs_have_you_help_wihin_the_last_two_years_title"
                    value={payload.applicationForm.how_many_jobs_have_you_help_wihin_the_last_two_years_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton> :
                        <ButtonOptions options={options} />
                }
            </div>
            <div className={`max-w-[560px] w-full ${!isEditing ? 'mt-[20px]' : ''}`}>
                {
                    !isEditing ? (
                        <div className="w-full max-w-[312px] h-[54px] input-ap max-md:max-w-[100%]">
                            <SelectBox options={payload.applicationForm.how_many_jobs_have_you_help_wihin_the_last_two_years_choices?.map(item => ({ label: item }))} svg={2} />
                        </div>
                    ) : (
                        <AdditionalInputs
                            className="w-full"
                            btnName={'Add New'}
                            isEditing={isEditing}
                            isArrayData
                            data={payload.applicationForm.how_many_jobs_have_you_help_wihin_the_last_two_years_choices}
                            setData={(value) => {
                                setPayload({
                                    ...payload,
                                    applicationForm: {
                                        ...payload.applicationForm,
                                        how_many_jobs_have_you_help_wihin_the_last_two_years_choices: value
                                    }
                                });
                            }}
                        />
                    )
                }
            </div>

        </form>
    )
}