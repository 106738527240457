import BreadCrumbWhite from "../components/BreadCrumbWhite";
import React, {useState} from "react";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import Checkbox from "../components/base/Checkbox";
import { JobDetailsNav } from "../components/base/JobDetailsNav";

const NextStepSection = () => {
    return (
        <section>
            <div className="flex gap-5 justify-center self-stretch w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <button className="flex justify-center py-[14px] w-[166px] bg-[#37B34A] rounded-md max-md:px-5 max-md:w-full hover:opacity-70">
                    Save
                </button>
                <button className="flex justify-center items-center py-[14px] w-[166px] bg-[#003578] rounded-md max-md:px-5 max-md:w-full hover:opacity-70">
                    Archive Job
                </button>
            </div>
        </section>
    );
};

const SBM02_7_1 = () => {
    const [formData, setFormData] = useState({
        jobTitle: '',
        industry: '',
        timezone: '',
        manageRole: '',
        employeeType: '',
        country: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
    });
    const [agree, setAgree] = useState(false);
    const timezones = [
        { value: "usa", label: "USA" }
    ];
    const manageRoles = [
        { value: "no", label: "No - will not manage other employees" }
    ];
    const employeeTypes = [
        { value: "full_time", label: "Full Time - Salary" }
    ];
    const countries = [
        { value: "united_states", label: "United States" }
    ];
    const cities = [
        { value: "chicago", label: "Chicago" }
    ];
    const states = [
        { value: "illionis", label: "Illionis" }
    ];
    const zipCodes = [
        { value: "76567", label: "76567" }
    ];


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <>
            <BreadCrumbWhite crumbs={[
                'Subscriber Dashboard',
                'Jobs Setup',
                'Current Job Details'
            ]} />

            <div className='flex-auto'>
                <div className='flex flex-col gap-[60px] max-md:gap-[40px] max-w-[1432px] px-4 w-full pt-14 max-md:pt-[40px] pb-24 max-md:pb-[80px] mx-auto'>
                    <JobDetailsNav />

                    <div className='text-center flex gap-3 items-center justify-center flex-col max-md:gap-[8px]'>
                        <span className='text-[40px] font-bold text-[#333333] max-md:text-[36px]'>Let’s Get Started</span>
                        <span className='text-[18px] leading-[30px]'>These details serve as the foundation of your job.
                        </span>
                    </div>

                    <div className="flex flex-col px-[60px] max-md:px-[32px] py-[50px] max-md:py-[36px] text-lg w-full bg-white rounded-md border border-solid border-zinc-300">
                        <h2 className='text-3xl font-bold text-zinc-800 mb-12 max-md:text-[28px]'>Job Details</h2>
                        <div className='flex flex-col gap-10'>
                            <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3'>
                                <div>
                                    <InputField
                                        inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300"
                                        labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                        label="Job Title"
                                        type="text"
                                        name="jobTitle"
                                        id="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={handleChange}
                                        placeholder='Administrative Assistant'
                                    />
                                </div>
                                <div>
                                    <InputField
                                        inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300"
                                        labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                        label="Industry"
                                        type="text"
                                        name="industry"
                                        id="industry"
                                        value={formData.industry}
                                        onChange={handleChange}
                                        placeholder='Maid Service'
                                    />
                                </div>
                                <div>
                                    <SelectField
                                        inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                        label="Timezone"
                                        name="timezone"
                                        id="timezone"
                                        value={formData.timezone}
                                        onChange={handleChange}
                                        options={timezones}

                                    />
                                </div>
                                <div>
                                    <SelectField
                                        inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                        label="Manage Role"
                                        name="manageRole"
                                        id="manageRole"
                                        value={formData.manageRole}
                                        onChange={handleChange}
                                        options={manageRoles}
                                    />
                                </div>
                                <div>
                                    <SelectField
                                        inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                        label="Employee Type"
                                        name="employeeType"
                                        id="employeeType"
                                        value={formData.employeeType}
                                        onChange={handleChange}
                                        options={employeeTypes}
                                    />
                                </div>
                            </div>
                            <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 max-md:mt-[-16px]'>
                                <div className="flex gap-3 whitespace-nowrap">
                                    <Checkbox />
                                    <div className="grow my-auto">Remote Job or Not</div>
                                </div>
                            </div>
                            <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3'>
                                <div className='grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3'>
                                    <SelectField
                                        inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                        label="Country"
                                        name="country"
                                        id="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        options={countries}
                                    />
                                </div>
                                <div className="grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3">
                                    <div className='col-span-2'>
                                        <InputField
                                            inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300"
                                            labelClassName="text-[18px] font-bold text-zinc-800 leading-[18px]"
                                            label="Adress"
                                            type="text"
                                            name="adress"
                                            id="adress"
                                            value={formData.address}
                                            onChange={handleChange}
                                            placeholder='Lorem ipsum dolor 327'
                                        />
                                    </div>
                                </div>
                                <div className="grid md:grid-cols-3 grid-cols-1 gap-8 gap-y-3 col-span-3">
                                    <div>
                                        <SelectField
                                            inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                            label="City"
                                            name="city"
                                            id="city"
                                            value={formData.city}
                                            onChange={handleChange}
                                            options={cities}
                                        />
                                    </div>
                                    <div>
                                        <SelectField
                                            inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                            label="States"
                                            name="state"
                                            id="state"
                                            value={formData.state}
                                            onChange={handleChange}
                                            options={states}
                                        />
                                    </div>
                                    <div>
                                        <SelectField
                                            inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 gray-arrow"
                                            label="Zip Code"
                                            name="zipCode"
                                            id="zipCode"
                                            value={formData.zipCode}
                                            onChange={handleChange}
                                            options={zipCodes}
                                        />
                                    </div>
                                </div>
                            </div>
                            <NextStepSection />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SBM02_7_1