const trainingMilestonesBase = 'api/training-milestones';

export const trainingMilestones = (instance) => ({
   
    async getAllTrainingMilestones(jobApplicationId) {
        const { data } = await instance.get(`${trainingMilestonesBase}/all/${jobApplicationId}`);
        return data;
    },
    async createTrainingMilestones(jobApplicationId, payload) {
        const { data } = await instance.post(`${trainingMilestonesBase}/${jobApplicationId}`, payload);
        return data;
    },
    async updateTrainingMilestones(jobApplicationId, payload) {
        const { data } = await instance.put(`${trainingMilestonesBase}/${jobApplicationId}`, payload);
        return data;
    },
    async deleteTrainingMilestone(jobApplicationId, milestoneId) {
        const { data } = await instance.delete(`${trainingMilestonesBase}/${jobApplicationId}/${milestoneId}`);
        return data;
    },
});

