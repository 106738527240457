import React, { useId, useState, useEffect } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";
import { Tooltip } from 'react-tooltip';
import { ReactComponent as PenIcon } from "../assets/img/pen-icon.svg";
import { Link } from 'react-router-dom';
import api from "../api";
import { getUser } from "../redux/slices/applicationSlice";
import { useSelector } from "react-redux";
import PageLoader from "../components/PageLoader";

const CopyToClipboard = ({ link }) => {
    const id = useId()
    const [copied, setCopied] = useState(false)
    const CopyToClipboard = () => {
        if (!navigator.clipboard) return
        navigator.clipboard.writeText(link);
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1000);

    }
    return (
        <div className='flex max-xl:flex-col w-full items-center pl-1 md:pl-2 pr-1 bg-white min-h-[52px] rounded-md border border-solid border-zinc-300'>
            <Link to={'/SBM02_8'} className='text-green-500 whitespace-nowrap max-xl:pb-2 max-xl:text-center xl:pr-2 max-xl:border-b max-xl:w-full xl:border-r border-solid border-zinc-300 mr-2'>Job Aplication link</Link>
            <div className='flex max-md:flex-col flex-auto max-xl:w-full'>
                <input className='bg-transparent truncate pr-1 flex-auto italic font-normal' type="text" disabled value={link} />
                <button
                    name={`my-anchor-element-${id}`}
                    onClick={CopyToClipboard}
                    className='flex justify-center active:bg-white active:text-green-500 gap-1 items-center rounded-md m-1 h-11 text-sm px-2 bg-green-500 text-white'>
                    <span className='flex'>
                        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 512 512">
                            <path d="M128 64c0-35.3 28.7-64 64-64H352V128c0 17.7 14.3 32 32 32H512V448c0 35.3-28.7 64-64 64H192c-35.3 0-64-28.7-64-64V336H302.1l-39 39c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l39 39H128V64zm0 224v48H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H128zM512 128H384V0L512 128z" fill='currentColor' />
                        </svg>
                    </span>
                    Copy
                </button>
                {
                    copied ?
                        <Tooltip
                            place="top"
                            effect="solid"
                            anchorSelect={`[name^='my-anchor-element-${id}']`}
                            defaultIsOpen={true}
                        >
                            Copied
                        </Tooltip>
                        :
                        null
                }
            </div>
        </div>)
}

const JobDetails = ({ data }) => (
    <div className='flex items-start gap-1'>
        <div className='flex items-center justify-center shrink-0 w-9 h-9'>
            <div className="w-2 h-2 bg-sky-900" />
        </div>
        <div className="flex flex-col grow shrink-0 items-start basis-0 w-fit font-semibold leading-9 text-stone-500 ">
            <Link to={'/job-edit/' + data.id + `/current-job-details`} className="flex max-lg:justify-between items-center items-center gap-1 self-stretch text-xl leading-9 font-bold text-green-500 max-md:max-w-full">
                {data.jobTitle} {data.status === "archived" ? <span className="text-[#BEBEBE]">(Archived)</span> : null}
                <span className='flex w-8 h-8'>
                    <PenIcon />
                </span>
            </Link>
            <div className="self-stretch font-normal">{data.city}</div>
            {data.status !== "archived" ? <CopyToClipboard link={`${window.location.origin}/job/` + data.id} /> : null}
        </div>
    </div>
);

const SBM02 = () => {
    const [formData, setFormData] = useState({
        industrieId: '',
        city: '',
        // status: '',
        limit: 1000
    });


    const me = useSelector(getUser)

    const [loaded, setLoaded] = useState(false);
    const [jobs, setJobs] = useState([]);

    const [industriesList, setIndustriesList] = useState([]);

    const departments = [
        { value: '', label: 'All Departments', },
        ...(jobs?.data?.length && industriesList?.length ? [...new Set(jobs?.data?.map(item => item?.industrieId))].map(item => ({ value: item, label: industriesList.find(itemI => itemI.id === item)?.name || "-" })) : []),
    ];

    const locations = [
        { value: '', label: 'All Locations', },
        ...(jobs?.data?.length ? [...new Set(jobs?.data?.map(item => item?.city))].map(item => ({ value: item, label: item })) : [])
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const getJobs = async () => {
        let res = await api.jobs.getJobsByCompany({
            companyId: me.company?.id || me?.companies[0]?.company?.id,
            filters: Object.entries(formData).map(item => item[1] ? `&${item[0]}=${item[1]}` : '').join("")
        });
        setLoaded(true);
        setJobs(res);
    }

    const getIndustriesList = async () => {
        let res = await api.jobs.getIndustriesList();
        setIndustriesList(res.data)
    }

    useEffect(() => {
        getIndustriesList();
    }, []);

    useEffect(() => {
        if (me?.company?.id || me?.companies[0]?.company?.id) {
            getJobs();
        }
    }, [me, formData]);

    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Jobs']} />
            <section className="flex mx-auto justify-center items-center max-w-[1432px] px-4 pt-9 pb-20 lg:pt-14 lg:pb-24 w-full text-zinc-800">
                <div className="flex flex-col items-center w-full max-md:max-w-full">
                    <div className='mx-auto text-center flex flex-col gap-2 mb-10'>
                        <div className="text-4xl font-bold leading-10 text-zinc-800 max-md:max-w-full">
                            Current Job Openings
                        </div>
                        <p className='text-pretty'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    {!loaded ?
                        <PageLoader />
                        :
                        <div className="flex flex-col py-9 px-8 lg:px-16 lg:py-12 text-lg w-full bg-white rounded-md border border-solid border-zinc-300">
                            <div className="flex max-lg:flex-col lg:flex-nowrap gap-[14px] lg:gap-8 w-full text-neutral-400">
                                <div className="flex items-center w-full lg:w-64">
                                    <SelectField
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        name="industrieId"
                                        id="industrieId"
                                        parentClass=''
                                        value={formData.industrieId}
                                        onChange={handleChange}
                                        options={departments}
                                    />
                                </div>
                                <div className="flex items-center w-full lg:w-64">
                                    <SelectField
                                        inputBg="bg-white rounded-md border border-solid border-zinc-300"
                                        name="city"
                                        id="city"
                                        parentClass=''
                                        value={formData.city}
                                        onChange={handleChange}
                                        options={locations}
                                    />
                                </div>
                                <div className="flex items-center max-lg:w-full lg:ml-auto">
                                    <Link to={'/new-job'} className='flex justify-center flex-auto items-center whitespace-nowrap bg-[#37B34A] rounded-md text-white hover:bg-[#002E67] h-[54px] w-64 text-lg font-bold'>Add a new Job</Link>
                                </div>
                            </div>
                            <div className="shrink-0 my-9 lg:my-12 h-px border border-solid bg-neutral-200 border-neutral-200" />
                            {jobs?.data?.length ?
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-9">
                                    {
                                        jobs?.data?.map(job => (
                                            <JobDetails key={job.id} data={job} />
                                        ))
                                    }
                                </div> :
                                <div className="text-center text-4xl font-bold leading-10 text-zinc-800 max-md:max-w-full">Jobs List Empty</div>
                            }
                        </div>}
                </div>
            </section>
        </>
    )
}

export default SBM02