import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { JobDetailsNav } from "../components/base/JobDetailsNav";

const NextStepSectionBottom = () => {
    return (
        <section>
            <div className="flex gap-5 justify-between self-stretch w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <button className="flex justify-center py-[15px] px-[64px] bg-[#B2B2B2] rounded-md max-md:px-5 max-md:flex-1 hover:opacity-70">
                    Back
                </button>
                <button className="flex justify-center items-center py-[15px] px-[64px] bg-green-500 rounded-md max-md:px-5 max-md:flex-1 hover:opacity-70">
                    Next
                </button>
            </div>
        </section>
    );
};
const NextStepSection = () => {
    return (
        <section>
            <div className="flex gap-4 justify-center self-stretch w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <button className="flex justify-center py-[14px] px-[62px] bg-[#37B34A] rounded-md max-md:px-5">
                    Save
                </button>
                <button className="flex justify-center items-center py-[14px] px-[40px] bg-[#003578] rounded-md max-md:px-5">
                    Archive Job
                </button>
            </div>
        </section>
    );
};

// function JobDetailsNav() {
//     const navItems = [
//         { label: "Current Job Details", isActive: false, route: "tab_1" },
//         { label: "Description", isActive: false, route: "tab_2" },
//         { label: "Pipelines", isActive: false, route: "tab_3" },
//         { label: "Qualification Attributes", isActive: false, route: "tab_4" },
//         { label: "Interview Questions", isActive: true, route: "tab_5" },
//         { label: "Application Form", isActive: false, route: "tab_6" },
//         { label: "Disposition Status", isActive: false, route: "tab_7" },
//     ]; return (
//         <nav className="flex gap-0 flex-auto whitespace-nowrap md:justify-center text-base font-semibold bg-neutral-100 text-zinc-800 max-md:overflow-x-auto overflow-y-hidden"> {
//             navItems.map((item, index) => (
//                 <NavItem key={index} route={item.route} label={item.label} isActive={item.isActive} />))}
//         </nav>
//     );
// }
// function NavItem({ label, isActive, route }) {
//     const baseClasses = "flex justify-center px-7 h-[68px] hover:bg-green-500 hover:text-white items-center border-l flex-1 border-solid border-zinc-300 max-md:px-5";
//     const activeClasses = isActive ? "text-white bg-green-500" : "bg-neutral-100";
//     return (
//         <Link to={{hash: route}} className={`${baseClasses} ${activeClasses}`}> {label} </Link>
//     );
// }

const variations = [
    {
        id: "maid_service",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
                `Attitude towards<br/>Cleaning`,
                `Fitness for <br/>the Job`
            ],
            [
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                `Travel<br/>Sensitivity`,
                `Comfortable<br/>with Pets`,
                'Team or Solo',
                `Criminal<br/>Background`,
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "commercial_cleaning",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Attitude towards<br/>Cleaning`,
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Team or Solo',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "food_cleaning",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "retail",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "caregiving",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
                `Fitness for <br/>the Job`,
            ],
            [
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Travel <br/> Sensitivity',
                'Comfortable <br/> with Pets',
                'Team or Solo',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "facility",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
                `Fitness for <br/>the Job`,
            ],
            [
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Comfortable <br/> with Pets',
                'Team or Solo',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "security",
        data: [
            [
                'Stability',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "call_centers",
        data: [
            [
                'Stability',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Criminal <br/> Background',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
]


const SBM02_7_4_V2 = () => {

    const { search } = useLocation()
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get('id');
    const [data, setData] = useState([])

    const questions = [
        'Interview Question 1 : Tell me about yourself:',
        'Interview Question 2 : What is the biggest challenge you have faced?',
        'Interview Question 3 : Who is your best boss?',
        'Interview Question 4 : Who is your worst boss?',
        'Interview Question 5 : Has you pay increased?',
        'Interview Question 6 : How would you rate your performance in this job?'
    ]

    useEffect(() => {
        const variation = variations.find(variation => variation.id === id);
        setData(variation?.data || variations[0]?.data);
    }, [search, id]);

    return (
        <>
            <BreadCrumbWhite crumbs={[
                'Subscriber Dashboard',
                'Job Setup',
                'Current Job Details',
                "Interview Questions",
            ]} />

            <div className='flex-auto'>
                <div className='flex flex-col gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
                    <section className="flex w-full text-center overflow-auto md:py-4">
                        <JobDetailsNav />
                    </section>
                    <div className='text-center flex gap-3 items-center justify-center flex-col'>
                        <span className='text-[40px] font-bold max-lg:text-[36px] max-lg:leading-[44px]'>Interview Questions</span>
                        <span className='text-[18px] leading-[30px] max-lg:text-[16px] max-lg:leading-[22px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet,<br/>
                            bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut<br/>
                            consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi. 
                        </span>
                    </div>

                    <div className="lg:border border-neutral-200 px-[60px] py-[50px] max-md:p-[0px]">
                        <div className="flex flex-col gap-6">
                            <label htmlFor="check" className="checkbox-green-container bigger">
                                <input type="checkbox" hidden id="check"  />
                                <div className="checkmark-green scale-150"></div>
                                <span className="checkmark-label-optional font-semibold text-[26px] flex-1 max-md:text-[18px]">Interview Questions for the Highly Recommended Qualification Attributes</span>
                            </label>

                            {data.map((el,idx) =>
                                <div className="lg:pl-12 gap-3 flex max-lg:overflow-x-auto max-lg:overflow-y-hidden whitespace-nowrap" key={idx}>
                                    {el.map((itm, i) => <div key={i}
										className="description-tip-modal-sbm-container relative border-b-[#DEDED9] border-b-[4px]  h-[68px] flex flex-auto items-center justify-center text-center max-md:px-[16px]"
									>
                                        <span className='text-[#003578] font-semibold'
											dangerouslySetInnerHTML={{__html: itm}}
										></span>

                                        { idx === 0 && i === 0 && <div className="description-tip-modal-sbm max-lg:!hidden">
                                            <p className="text-2xl font-bold">Interview Questions for Stability</p>
                                            {questions.map((el, idx) => <span key={idx} className="text-[18px]">{el}</span>)}
                                        </div>}
                                        { idx === 0 && i === 0 && <div
                                            className="description-tip-modal-sbm text-left max-lg:flex lg:!hidden"
                                            style={{ position: 'fixed', width: 'calc(100% - 44px)', left: '22px', whiteSpace: 'initial' }}
                                        >
                                            <p className="text-2xl font-bold">Interview Questions for Stability</p>
                                            {questions.map((el, idx) => <span key={idx} className="text-[18px]">{el}</span>)}
                                        </div>}
                                        {/* openModalIndex setOpenModalIndex */}
                                    </div>)}
                                </div>
                            )}
 
                        </div>

                        <hr className="my-[50px] border-b border-neutral-200" />

                        {/* <NextStepSection /> */}

                        <div className='text-center text-3xl italic mt-[50px] text-[#666666] max-md:text-[18px]'>
                            <span>
                                <span className="text-[#37B34A] underline">CONTACT</span> your onboards specialist if you decide to customize further
                            </span>
                        </div>

                    </div>


                    <NextStepSectionBottom />
                </div>


            </div>
        </>
    )
}

export default SBM02_7_4_V2