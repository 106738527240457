import {ElevenStepsWrapper} from "../../parts/ElevenStepsWrapper";
import React, {useEffect, useState} from "react";
import {StepOne} from "./StepOne";
import {StepTwo} from "./StepTwo";
import {crumbsConfig} from "../../config";

export const FirstInterviewSurvey = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
        ['1st Interview'],
        ['Post 1st Interview Survey']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>

            {step === 1 && <ElevenStepsWrapper
                activePin={3}
                crumbs={crumbs}
                onNext={() => setStep(2)}
                onBack={prevComponent}
            >
                <StepOne />
            </ElevenStepsWrapper>}

            {step === 2 && <StepTwo onNext={() => nextComponent()} onBack={() => setStep(1)} />}

        </>
    )
}