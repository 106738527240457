import Clarifications from "./Clarifications"
import AIRecommendation from "./AIRecommendation"
import DocumentReview from "./DocumentReview"
import FirstInterview from "./FirstInterview"
import OfferMeeting from "./OfferMeeting"
import FinalHiringDecision from "./FinalHiringDecision"
import TrainingMilestones from "./TrainingMilestones"
import TrainingInsights from "./TrainingInsights"
import RatingOfTheJob from "./RatingOfTheJob"
import SecondInterview from "./SecondInterview"
import PostFirstInterviewSurvey from "./PostFirstInterviewSurvey"
import Scores from "./Scores"


export const ReportCardPages = {
    Clarifications,
    AIRecommendation,
    DocumentReview,
    FirstInterview,
    OfferMeeting,
    FinalHiringDecision,
    TrainingMilestones,
    TrainingInsights,
    RatingOfTheJob,
    SecondInterview,
    PostFirstInterviewSurvey,
    Scores
}