import classNames from 'classnames';
import JobCreationProcess from '../../components/JobCreationProcess';
import React, { useEffect, useId, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from "../../assets/img/bx-edit.svg";
import { ReactComponent as CalendarIcon } from "../../assets/img/calendar-ico.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/settings.svg";
import BreadCrumbWhite from "../../components/BreadCrumbWhite";
import FileInputField from "../../components/base/FileInputField";
import InputField from "../../components/base/InputField";
import PrimaryButton from "../../components/base/PrimaryButton";
import SelectBox from "../../components/base/SelectBox";
import SelectField from "../../components/base/SelectField";
import { Footer } from '../../components/parts/Footer';
import { Tooltip } from 'react-tooltip';
import { ModalForm } from "./ModalForm";
import { useViewportWidth } from "./ResizeViewport";
import { DescriptionSection } from "./DescriptionSection";
import { EditableInput } from "./EditableInput";
import { ButtonOptions } from "./ButtonOptions";
import { RequirementsSection } from "./RequirementsSection";
import { DurationSection } from "./DurationSection";
import { ConsentSection } from "./ConsentSection";
import { RadioSection } from "./RadioSection";
import { ReferalSection } from "./ReferalSection";
import { TermsSection } from "./TermsSection";
import { AboutTitleSection } from "./AboutTitleSection";
import { AboutFormSection } from "./AboutFormSection";
import { EmploymentHistorySection } from "./EmploymentHistorySection";
import { EmployersCountSection } from "./EmployersCountSection";
import { EmergencyContactForm } from "./EmergencyContactForm";
import { InputSection } from "./InputSection";
import { JobsCountSection } from "./JobsCountSection";
import { CriticalRequirementsSection } from "./CriticalRequirementsSection";
import { AvailabilityFormSection } from "./AvailabilityFormSection";
import { ResumeUploadSection } from "./ResumeUploadSection";
import { ConclusionSection } from "./ConclusionSection";
import { DateFormSection } from "./DateFormSection";
import ContactUsLinkBlock from '../../components/parts/ContactUsLinkBlock';
import { HiringProcess } from '../../components/base/ProgressStepsSBM';

const NextStepSection = () => {
    return (
        <section>
            <div className="flex gap-5 max-md:gap-4 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap ">
                <Link to={'/SBM02_4'} className="flex justify-center items-center px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px]">
                    Back
                </Link>
                <Link to={'/SBM02_6'} className="flex justify-center items-center px-16 py-1 h-[52px] bg-green-500 rounded-md w-full lg:max-w-[162px]">
                    Next
                </Link>
            </div>
        </section>
    );
};


// const ButtonOptions = ({ options }) => {
//     const [isEditOpen, setIsEditOpen] = useState(false);
//     return <div className='relative'>
//         <button
//             onClick={() => { setIsEditOpen(!isEditOpen) }}
//         >
//             <SettingsIcon />
//         </button>
//         <EditSection options={options} isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} />
//     </div>
// }









const ProgressBar = ({ completed, containerClasses = "" }) => {
    const containerStyles = {
        height: 4,
        width: '100%',
        backgroundColor: "#EFEEF0",
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: '#003578',
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    return (
        <div style={containerStyles} className={containerClasses}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${completed}%`}</span>
            </div>
        </div>
    );
}

const StepIndicatorItem = ({ stepNumber, label, isActive }) => {
    const baseStyles = "justify-center items-center self-center w-10 h-10 text-xl leading-8 font-bold text-white border-4 border-white border-solid shadow-md rounded-[360px]";
    const activeStyles = "bg-green-500";
    const inactiveStyles = "bg-neutral-200";

    return (
        <div className="flex flex-col flex-auto max-w-40 px-2">
            <div className={`${baseStyles} ${isActive ? activeStyles : inactiveStyles}`}>
                {stepNumber}
            </div>
            <div className="mt-3.5 text-base font-semibold text-neutral-600">
                {label.split("\n").map((line, index, arr) => (
                    <React.Fragment key={index}>
                        {line}{index < arr.length - 1 && <br />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

const SBM02_5_new = () => {
    const [formData, setFormData] = useState({
        jobTitle: '',
        industry: '',
        timezone: '',
        manageRole: '',
        employeeType: '',
        country: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        section1Heading: 'It takes 15 minutes'
    });
    const [agree, setAgree] = useState(false);
    const timezones = [
        { value: "usa", label: "USA" }
    ];
    const manageRoles = [
        { value: "no", label: "No - will not manage other employees" }
    ];
    const employeeTypes = [
        { value: "full_time", label: "Full Time - Salary" }
    ];
    const countries = [
        { value: "united_states", label: "United States" }
    ];
    const cities = [
        { value: "chicago", label: "Chicago" }
    ];
    const states = [
        { value: "illionis", label: "Illionis" }
    ];
    const zipCodes = [
        { value: "76567", label: "76567" }
    ];
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    const steps = [
        { stepNumber: "1", label: "New Job Details", isActive: true },
        { stepNumber: "2", label: "Description", isActive: true },
        { stepNumber: "3", label: "Preview", isActive: true },
        { stepNumber: "4", label: "Pipelines", isActive: true },
        { stepNumber: "5", label: "Qualification Attributes", isActive: true },
        { stepNumber: "6", label: "Interview Questions", isActive: true },
        { stepNumber: "7", label: "Application Form", isActive: true },
        { stepNumber: "8", label: "Disposition Status", isActive: false },
    ];
    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Job Setup', "New Job Details", "Description", "Preview", "Pipeline", "Qualification Attributes", "Interview Questions", "Application Form"]} />
            <div className="flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto">
                <section className="flex w-full text-center overflow-auto py-4">
                    <HiringProcess stepsData={steps} />
                </section>
                <div className='mx-auto text-center flex flex-col gap-2 mb-4'>
                    <h1 className="text-4xl font-bold text-zinc-800">
                        Let’s Get Started
                    </h1>
                    <h2 className='max-w-[783px] mx-auto'>
                        These details serve as the foundation of your job.
                    </h2>
                </div>
                <div className='w-full max-w-[1026px] mx-auto'>
                    <div className='rounded-md border border-solid border-[#D6D6D6] lg:pt-[44px] lg:px-[44px] lg:pb-[70px] lg:gap-[44px] flex flex-col
                        py-[36px] px-[32px] gap-[32px] '>
                        <div className='border-b border-solid border-[#D6D6D6] lg:pb-[44px] pb-[32px] lg:text-[30px] text-[28px] leading-[36px] font-semibold text-[#333333]'>
                            Application Form
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] lg:pb-[44px] pb-[32px]'>
                            <div className='flex lg:items-center lg:justify-between flex-col lg:flex-row lg:gap-0 gap-6'>
                                <div className='flex items-center justify-between  gap-[12px] lg:text-[30px] text-[24px] leading-[36px] font-bold text-[#333333]'>
                                    It takes 15 minutes
                                    <button>
                                        <EditIcon className='w-[28px] h-[28px]' />
                                    </button>
                                </div>
                                <SelectField
                                    parentClass='w-[258px] flex-none'
                                    placeholder={'a'}
                                    inputBg="bg-white rounded-md border border-solid border-[#DEDED9] gray-arrow"
                                    name="timezone"
                                    id="timezone"
                                    value={formData.timezone}
                                    onChange={handleChange}
                                    options={timezones}
                                />
                            </div>
                            <p className='mt-[16px] text-[18px] leading-[36px] font-semibold text-[#37B34A] underline underline-offset-[3px]'>Recommend Defult Setup</p>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px]'>
                                <DescriptionSection />
                                <RequirementsSection />
                                <DurationSection />
                            </div>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px]'>
                                <ConsentSection />
                                <RadioSection
                                    title={"Are you able to legally work in the United State?"}
                                    positive={"Yes"}
                                    negative={"No"}
                                />
                                <ReferalSection />
                                <TermsSection />
                            </div>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px] max-md:gap-[24px]'>
                                <AboutTitleSection />
                                <AboutFormSection />
                            </div>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px]'>
                                <EmploymentHistorySection />
                            </div>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px]'>
                                <RadioSection
                                    title={"May we contact your previous supervisor for reference?"}
                                    positive={"Yes"}
                                    negative={"No"}
                                />
                                <EmployersCountSection />
                                <EmergencyContactForm />
                            </div>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px]'>
                                <RadioSection
                                    className={"max-w-[450px]"}
                                    title={"Do you have any relative who is an employee of this company?"}
                                    inputLabel="If so, who?"
                                    inputClassName={"h-[54px]"}
                                />
                                <RadioSection
                                    className={"max-w-[450px]"}
                                    title={"Did someone refer you to the position?"}
                                    inputLabel="If so, who?"
                                    inputClassName={"h-[54px]"}
                                />
                                <InputSection title={"The source you heard about this job?"} />
                            </div>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px]'>
                                <JobsCountSection />
                                <RadioSection
                                    className={"max-w-[450px]"}
                                    title={"Have you ever been terminated from a job?"}
                                    inputLabel="If so, who?"
                                    labelClassName={"font-normal"}
                                    inputClassName={"min-h-[100px]"}
                                />
                            </div>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px]'>
                                <CriticalRequirementsSection />
                            </div>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px]'>
                                <AvailabilityFormSection />
                                <DateFormSection />
                            </div>
                        </div>
                        <div className='border-b border-solid border-[#D6D6D6] pb-[44px]'>
                            <div className='flex flex-col gap-[32px]'>
                                <ResumeUploadSection />
                            </div>
                        </div>
                        <div className='flex flex-col gap-8'>
                            <ConclusionSection />
                            {/* <p className="text-[26px] italic leading-[30px] text-left text-stone-500">
                                <a href='#' className="text-green-500 underline uppercase">Contact</a> your
                                onboards specialist if you decide to customize further
                            </p> */}
                            <ContactUsLinkBlock to='/SBM02_5_1' hr={false} />
                        </div>
                    </div>
                    <NextStepSection />
                </div>
            </div>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM02_5_new