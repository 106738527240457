import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import SelectBox from "../../components/base/SelectBox";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";
import { AdditionalInputs } from "./AdditionalInputs";


const Settings = ({ handleEdit, isEditing, payload, setPayload }) => {
    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => handleEdit(true)
        },
        // {
        //     label: "Disable the entire section...",
        //     handler: () => { }
        // },
        {
            label: payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        what_is_the_maximum_number_of_employers_an_applicant_can_enter_active: !payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_active
                    }
                });
            }
        },
    ]
    return (
        isEditing ?
            <PrimaryButton
                type="submit"
                className={"px-9 lg:h-[52px] lg:text-lg"}
            >
                Save
            </PrimaryButton>
            :
            <ButtonOptions options={options} />
    )
}

export const EmployersCountSection = ({ payload, setPayload }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setFormData(prevState => ({ ...prevState, [name]: value }));

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }


    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <Form handleChange={handleChange} isEditing={isEditing} isModal={isModal} />
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit} className='flex justify-between items-start relative' >
            <Form
                handleChange={handleChange}
                isEditing={isEditing}
                settings={<Settings
                    isEditing={isEditing}
                    handleEdit={setIsEditing}
                    payload={payload}
                    setPayload={setPayload}
                />}
                payload={payload}
                setPayload={setPayload}
            />

        </form>
    )
}

function Form({ handleChange, isEditing, isModal, handleEdit, settings, payload, setPayload }) {
    const [additionInputs, setAdditionInputs] = useState({})


    const handleRemoveAdditional = (key) => {
        const a = { ...additionInputs }
        delete a[key]
        setAdditionInputs(a)
    }

    const addAdditional = () => {
        let randomNumber = Math.floor(Math.random() * 100) + 1;
        setAdditionInputs(prev => ({ ...prev, [randomNumber]: '' }))
    }
    const handleChangeAdditional = (e) => {
        const { name, value } = e.target;
        setAdditionInputs(prev => ({ ...prev, [name]: value }))
    };

    const mob = window.innerWidth < 768

    return (
        <div className='w-full'>
            <div className="flex justify-between mb-3">
                <EditableInput
                    className="font-bold lg:text-[22px] text-[18px] pr-12"
                    type="text"
                    name="what_is_the_maximum_number_of_employers_an_applicant_can_enter_title"
                    id="what_is_the_maximum_number_of_employers_an_applicant_can_enter_title"
                    value={payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {settings}
            </div>
            {payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_active ?
                <div className='max-w-[560px] w-full'>
                    {
                        !isEditing ? (
                            <div className="w-full max-w-[110px] h-[54px] input-ap max-md:max-w-[100%]">
                                <SelectBox options={payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_choices?.map(item => ({ label: item }))} svg={2} />
                            </div>
                        ) : (
                            <AdditionalInputs
                                className="w-full"
                                btnName={'Add New'}
                                isEditing={isEditing}
                                isArrayData
                                data={payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_choices}
                                setData={(value) => {
                                    setPayload({
                                        ...payload,
                                        applicationForm: {
                                            ...payload.applicationForm,
                                            what_is_the_maximum_number_of_employers_an_applicant_can_enter_choices: value
                                        }
                                    });
                                }}
                            />
                        )
                    }

                    {isModal && <>
                        <AdditionalInputs
                            className="w-full"
                            btnName={'Add New'}
                            isEditing={isEditing}
                            isArrayData
                            data={payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_choices}
                            setData={(value) => {
                                setPayload({
                                    ...payload,
                                    applicationForm: {
                                        ...payload.applicationForm,
                                        what_is_the_maximum_number_of_employers_an_applicant_can_enter_choices: value
                                    }
                                });
                            }}
                        />
                    </>}

                    <div className='mt-6 w-full'>
                        <EditableInput
                            className="flex text-base font-bold mb-2"
                            type="text"
                            name="what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_1"
                            id="what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_1"
                            value={payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_1}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        {/*<label className='flex text-base font-bold mb-2'></label>*/}
                        <textarea className='grey-input-custom col' style={{ resize: "none", minHeight: mob ? 150 : 82 }}></textarea>
                    </div>
                    <div className='mt-6 w-full'>
                        <EditableInput
                            className="flex text-base font-bold mb-2"
                            type="text"
                            name="what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_2"
                            id="what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_2"
                            value={payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_2}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        {/*<label className='flex text-base font-bold mb-2'></label>*/}
                        <textarea className='grey-input-custom col' style={{ resize: "none", minHeight: mob ? 150 : 82 }}></textarea>
                    </div>
                    <div className='mt-6 w-full'>
                        <EditableInput
                            className="flex text-base font-bold mb-2"
                            type="text"
                            name="what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_3"
                            id="what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_3"
                            value={payload.applicationForm.what_is_the_maximum_number_of_employers_an_applicant_can_enter_field_3}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        {/*<label className='flex text-base font-bold mb-2'>First Professional Reference Full Name, Phone Number, and the Company Name where you worked with the reference.</label>*/}
                        <textarea className='grey-input-custom col' style={{ resize: "none", minHeight: mob ? 150 : 82 }}></textarea>
                    </div>
                </div> : null}
        </div>
    )
}