import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import SelectBox from "../../components/base/SelectBox";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ReactComponent as CalendarIcon } from "../../assets/img/calendar-ico.svg";
import { ModalForm } from "./ModalForm";


const Settings = ({ handleEdit, isEditing, payload, setPayload }) => {
    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => handleEdit(true)
        },
        {
            label: payload.applicationForm.most_recent_employment_history_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        most_recent_employment_history_active: !payload.applicationForm.most_recent_employment_history_active
                    }
                });
            }
        },
    ]
    return (
        <div className='flex items-center gap-6'>
            {
                isEditing ?
                <PrimaryButton
                    type="submit"
                    className={"px-9 lg:h-[52px] lg:text-lg"}
                >
                    Save
                </PrimaryButton>
                :
                <ButtonOptions options={options} />
            }
        </div>
    )
}

export const EmploymentHistorySection = ({ payload, setPayload, }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);


    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }

    const addItem = () => {
        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                most_recent_employment_history: [
                    ...payload.applicationForm.most_recent_employment_history,
                    {
                        company_name: "Company Name",
                        job_title: "Job Title",
                        supervisor: "Supervisor",
                        phone: "Phone",
                        address: "Address",
                        city: "City",
                        state: "State",
                        postal_code: "Postal Code",
                        list_your_responsibilities: "List your responsibilities:",
                        reason_for_leaving: "Reason for Leaving",
                    }
                ]
            }
        });
    }

    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <Form
                    isEditing={isEditing}
                    payload={payload}
                    setPayload={setPayload}
                />
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit} className='flex justify-between items-start' >
            <Form
                isEditing={isEditing}
                settings={<Settings
                    isEditing={isEditing}
                    handleEdit={setIsEditing}
                    payload={payload}
                    setPayload={setPayload}
                />}
                additionalButton={
                    <PrimaryButton
                        type="submit"
                        className={"px-9 lg:h-[52px] lg:text-lg flex-auto lg:max-w-[184px]"}
                        onClick={(e)=>{
                            e.preventDefault();
                            addItem();
                        }}
                    >
                        Add More
                    </PrimaryButton>
                }
                payload={payload}
                setPayload={setPayload}
            />
        </form>
    )
}

function Form({ isEditing, handleEdit, settings, additionalButton, payload, setPayload }) {
    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };

    const itemHandleChange = (e, index) => {
        const { name, value } = e.target;

        const editedArray = [...payload.applicationForm.most_recent_employment_history]?.map((item, itemIndex) => itemIndex === index ? { ...item, [name]: value } : item);

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                most_recent_employment_history: editedArray
            }
        });
    };
    
    const removeListItem = (indexValue) => {
        const editArray = [...payload.applicationForm.most_recent_employment_history];

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                most_recent_employment_history: editArray.filter((item, index) => { return index !== indexValue })
            }
        });
    }

    const DateLine = ({ title = "Start Date" }) => {
        return <div className="col-span-3 flex items-center gap-3.5 text-lg whitespace-nowrap max-lg:grid max-lg:grid-cols-2 pointer-events-none">
            <div className="flex justify-between w-full max-lg:col-span-3">
                <p className='font-bold text-lg min-w-24'>{ title}:</p>
                <div className='max-lg:flex hidden items-center'>
                    <CalendarIcon />
                </div>
            </div>

            <div className="w-full h-[54px] input-ap max-lg:col-span-3">
                <SelectBox options={[{ label: 'Month' } ]} svg={2} />
            </div>
            <div className="shrink-0 w-2 h-px bg-zinc-300 max-lg:hidden" />
            <div className="w-full h-[54px] input-ap  ">
                <SelectBox options={[{ label: 'Day' } ]} svg={2} />
            </div>
            <div className="shrink-0 w-2 h-px bg-zinc-300 max-lg:hidden" />
            <div className="w-full h-[54px] input-ap max-lg:ml-[10px] ">
                <SelectBox options={[{ label: 'Year' } ]} svg={2} />
            </div>
            <div className='hidden items-center lg:flex'>
                <CalendarIcon />
            </div>
        </div>
    }

    return (
        <div className='w-full'>
            <div className="flex max-lg:flex-col gap-6">
                <div className="flex justify-between flex-auto">
                    <EditableInput
                        className="font-bold lg:text-[30px] text-[24px]"
                        type="text"
                        name="most_recent_employment_history_title"
                        id="most_recent_employment_history_title"
                        value={payload.applicationForm.most_recent_employment_history_title}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                    {settings}
                </div>
                { additionalButton }
            </div>
            {
                payload?.applicationForm?.most_recent_employment_history_active ?
                payload?.applicationForm?.most_recent_employment_history?.map((item, index)=>{
                    return <div key={index} className="flex items-start">
                    <div className={`max-w-[560px] w-full ${index !== 0 ? 'border-t border-solid border-[#D6D6D6] mt-[16px]' : ''}`}>
                        <div className="mt-6 grid lg:grid-cols-3 grid-cols-1 w-full gap-[16px]">
                            <EditableInput
                                className="grey-input-custom col-span-3 !py-[14px] !h-[54px]"
                                type="text"
                                name="company_name"
                                id="company_name"
                                value={item.company_name}
                                onChange={(e) => { itemHandleChange(e, index) }}
                                disabled={!isEditing}
                                placeholder="Company Name"
                            />

                            <div className='grid md:grid-cols-2 col-span-3 gap-[16px]'>
                                <EditableInput
                                    className="grey-input-custom col-span-1 !py-[14px] !h-[54px]"
                                    type="text"
                                    name="job_title"
                                    id="job_title"
                                    value={item.job_title}
                                    onChange={(e) => { itemHandleChange(e, index) }}
                                    disabled={!isEditing}
                                    placeholder="Job Title"
                                />
                                <EditableInput
                                    className="grey-input-custom col-span-1 !py-[14px] !h-[54px]"
                                    type="text"
                                    name="supervisor"
                                    id="supervisor"
                                    value={item.supervisor}
                                    onChange={(e) => { itemHandleChange(e, index) }}
                                    disabled={!isEditing}
                                    placeholder="Supervisor"
                                />
                            </div>

                            <EditableInput
                                className="grey-input-custom col-span-3 !py-[14px] !h-[54px]"
                                type="text"
                                name="phone"
                                id="phone"
                                value={item.phone}
                                onChange={(e) => { itemHandleChange(e, index) }}
                                disabled={!isEditing}
                                placeholder="Phone"
                            />
                            <EditableInput
                                className="grey-input-custom col-span-3 !py-[14px] !h-[54px]"
                                type="text"
                                name="address"
                                id="address"
                                value={item.address}
                                onChange={(e) => { itemHandleChange(e, index) }}
                                disabled={!isEditing}
                                placeholder="Address"
                            />
                            <EditableInput
                                className="grey-input-custom max-lg:col-span-3 !py-[14px] !h-[54px]"
                                type="text"
                                name="city"
                                id="city"
                                value={item.city}
                                onChange={(e) => { itemHandleChange(e, index) }}
                                disabled={!isEditing}
                                placeholder="City"
                            />

                            <div className="w-full h-[54px] input-ap max-lg:col-span-3">
                                <div className="relative">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute right-[18px] top-[19px] pointer-events-none">
                                        <g clip-path="url(#clip0_236_482)">
                                            <path d="M11.7795 5.08266C11.7464 5.00271 11.6903 4.93437 11.6184 4.88628C11.5465 4.83819 11.4619 4.81252 11.3753 4.8125H2.62534C2.53877 4.81243 2.45411 4.83805 2.3821 4.88612C2.31009 4.93419 2.25396 5.00254 2.22082 5.08253C2.18768 5.16251 2.17902 5.25053 2.19593 5.33545C2.21284 5.42036 2.25456 5.49834 2.31581 5.55953L6.69081 9.93453C6.73145 9.97521 6.7797 10.0075 6.83281 10.0295C6.88592 10.0515 6.94285 10.0628 7.00034 10.0628C7.05784 10.0628 7.11477 10.0515 7.16788 10.0295C7.22099 10.0075 7.26924 9.97521 7.30988 9.93453L11.6849 5.55953C11.746 5.49831 11.7877 5.42033 11.8045 5.33545C11.8214 5.25056 11.8126 5.16259 11.7795 5.08266Z" fill="#999999"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_236_482"><rect width="14" height="14" fill="white"></rect></clipPath>
                                        </defs>
                                    </svg>
                                    <EditableInput
                                        className="grey-input-custom !py-[14px] !h-[54px]"
                                        type="text"
                                        name="state"
                                        id="state"
                                        value={item.state}
                                        onChange={(e) => { itemHandleChange(e, index) }}
                                        disabled={!isEditing}
                                        placeholder="State"
                                    />
                                </div>
                            </div>
                            <EditableInput
                                className="grey-input-custom max-lg:col-span-3 !py-[14px] !h-[54px]"
                                type="text"
                                name="postal_code"
                                id="postal_code"
                                value={item.postal_code}
                                onChange={(e) => { itemHandleChange(e, index) }}
                                disabled={!isEditing}
                                placeholder="Postal Code"
                            />
                            
                            <DateLine title="Start Date" />
                            <DateLine title="End Date" />
                        </div>
                        <div className='mt-6 w-full'>
                            <EditableInput
                                className="flex text-lg font-bold mb-2"
                                type="text"
                                name="list_your_responsibilities"
                                id="list_your_responsibilities"
                                value={item.list_your_responsibilities}
                                onChange={(e) => { itemHandleChange(e, index) }}
                                disabled={!isEditing}
                            />
                            <textarea className='grey-input-custom col' style={{ resize: "none", minHeight: 138 }}></textarea>
                        </div>
                        <div className='mt-6 w-full'>
                            <EditableInput
                                className="flex text-lg font-bold mb-2"
                                type="text"
                                name="reason_for_leaving"
                                id="reason_for_leaving"
                                value={item.reason_for_leaving}
                                onChange={(e) => { itemHandleChange(e, index) }}
                                disabled={!isEditing}
                            />
                            <textarea className='grey-input-custom col' style={{ resize: "none", minHeight: 138 }}></textarea>
                        </div>
                    </div>
                    { payload?.applicationForm?.most_recent_employment_history?.length > 1 ?
                        <a
                            href="#" className={`flex items-center justify-center ml-[16px] rounded-full hover:bg-[#F8F8F8] ${index === 0 ? 'mt-[37px]' : 'mt-[53px]'}`}
                            onClick={(e)=>{
                                e.preventDefault();
                                removeListItem(index);
                            }}
                        >
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5423 6.87109L12.9457 11.4666L8.35019 6.87109L6.81836 8.40293L11.4139 12.9984L6.81836 17.5939L8.35019 19.1258L12.9457 14.5303L17.5423 19.1258L19.0741 17.5939L14.4786 12.9984L19.0741 8.40293L17.5423 6.87109Z" fill="#FF0000"/>
                            </svg>
                        </a>
                    : null }
                </div> })
                : null
            }
        </div>
    )
}