import React, { useEffect, useState } from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import { Footer } from "../components/parts/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getUser } from "../redux/slices/applicationSlice";
import { fetchAllIndustries, fetchBuildCompanies, fetchUpdateBuildCompanies } from "../redux/thunks/companiesThunks";
import SelectBox from "../components/base/SelectBox";
import { updateUserFetch } from "../redux/thunks/applicationThunks";
import TextareaField from "../components/base/TextareaField";
import { toast } from "react-toastify";
import Editor from "../components/CKEditor";
import api from "../api";
import { employmentTypes, payFrequencies, payPeriods } from "./SBM01/config";
import PageLoader from "../components/PageLoader";

const OfferTemplates = () => {
    const [crumbs, setCrumbs] = useState([['Subscriber Dashboard', '/SBM01'], ['Settings', '/SBM05_ADM'], ['Admin Settings']])
    const [templates, setTemplates] = useState([]);
    const initialPayload = {
		label: "",
		employmentType: "",
		payRate: "",
		payPeriod: "",
		payFrequency: "",
		recruterName: "",
		content: `<div style="font-family: Arial, sans-serif; color: #333; padding: 20px; border: 2px solid #0073e6; border-radius: 10px; max-width: 600px; margin: 0 auto;">
<h1 style="font-size: 28px; color: #0073e6; text-align: center;">Employment Opportunity</h1>
<br />
<br />
<br />
<br />
<br />
<div style="display: flex;justify-content: space-between">
<div>
<p style="text-align: right; font-size: 16px; color: rgb(85, 85, 85); line-height: 1.5;">
<span style="color: rgb(85, 85, 85); font-size: 16px; font-weight: 700">{{recruterName}}</span>
<br />
</p>
<div style="font-size: 16px;color: rgb(85, 85, 85);line-height: 1.5;margin-left: auto;width: 206px;height: 70px;border: 2px solid #0073e6;border-radius: 10px;display: flex;align-items: center;justify-content: center; box-sizing: content-box;">
<div id="company-sign">{{recruterSign}}</div>
</div>
</div>
<div>
<p style="text-align: right; font-size: 16px; color: rgb(85, 85, 85); line-height: 1.5;">
<span style="color: rgb(85, 85, 85); font-size: 16px; font-weight: 700">{{candidateName}}</span>
<br />
</p>
<div style="font-size: 16px;color: rgb(85, 85, 85);line-height: 1.5;margin-left: auto;width: 206px;height: 70px;border: 2px solid #0073e6;border-radius: 10px;display: flex;align-items: center;justify-content: center; box-sizing: content-box;">
<div id="candidate-sign">{{candidateSign}}</div>
</div>
</div>
</div>
<p style="font-size: 16px; color: #555; line-height: 1.5; text-align: center;">The Human Resources Team</p>
</div>`
	}
    const [payload, setPayload] = useState(initialPayload);
    const [selectedTemplateId, setSelectedTemplateId] = useState(-1);
    const user = useSelector(getUser)
    const companyId = user?.company?.id || user?.companies?.[0]?.company?.id

    useEffect(() => {
		if(!companyId) return
		api.offerTemplates.getAll(companyId).then(response => {
			if(!response.success) {
				return;
			}
			setTemplates(response.data)
		})
        api.companyTeam.getTeamMembers(companyId).then(response => {
			if (!response.success || !response?.data?.length) {
				return;
			}
			setInterviewers(response.data.map(member => ({ value: `${member?.user?.firstName} ${member?.user?.lastName}`, label: `${member?.user?.firstName} ${member?.user?.lastName}` })))
		})
	}, [companyId])
    const [interviewers, setInterviewers] = useState([]);
	const handleCreate = async() => {
        const response = await api.offerTemplates.create(companyId, payload);
        toast(response.success ? "Created Successfully!" : response.message, {
            type: response.success ? 'success' : 'error'
        })
    }
    const handleUpdate = async() => {
        const response = await api.offerTemplates.update(companyId, payload, selectedTemplateId);
        toast(response.success ? "Updated Successfully!" : response.message, {
            type: response.success ? 'success' : 'error'
        })
    }
    const handleDelete = async() => {
        if(selectedTemplateId === -1) {
            setPayload(initialPayload)
            return
        }
        const response = await api.offerTemplates.delete(companyId, selectedTemplateId);
        toast(response.success ? "Deleted Successfully!" : response.message, {
            type: response.success ? 'success' : 'error'
        })
        if(response.success) {
            setPayload(initialPayload)
            setTemplates(prev => prev.filter(({id}) => id !== selectedTemplateId))
            setSelectedTemplateId(-1)
        }
    }
    return (
        <>
            <BreadCrumbWhite crumbs={crumbs} />
            {payload ? <main className="flex relative flex-col pt-10 pb-20 lg:py-[90px] z-[1] max-w-[1272px] px-4 w-full m-auto flex-1">
            <div className="flex gap-12 max-lg:flex-col">
				<aside className="flex flex-col max-md:ml-0 max-md:w-full">
					<div className="flex flex-col px-8 lg:py-14 py-9 text-lg rounded-md border border-solid border-zinc-300">
						<h2 className="text-xl font-bold text-zinc-800">Select Template</h2>
						<SelectBox
							options={[{label: 'Create New Template', value: -1} ,...templates.map(item => ({label: item.label, value: item.id}))]}
							className="mt-4 !h-[54px]"
							value={selectedTemplateId}
							onSelect={(value) => {
								setSelectedTemplateId(value);
								const template = templates.find(({id}) => id === value);
                                if(!template) {
                                    setPayload(initialPayload)
                                    return
                                }
								console.log(template.content);
								setPayload((prev) => ({
									...prev,
									employmentType: template.employmentType,
									payRate: template.payRate,
									payPeriod: template.payPeriod,
									payFrequency: template.payFrequency,
									recruterName: template.recruterName,
									content: template.content,
                                    label: template.label
								}))
							}}
							placeholder='Select Offer Template...'
						/>
                        <h2 className="mt-8 text-xl font-bold text-zinc-800">
                            Template Name
                        </h2>
						<InputField
                            parentClassName='!mb-0'
                            inputBg='mt-4 bg-white border border-solid border-[#DEDED9]'
                            value={payload.label}
                            onInput={(e) => {
                                setPayload((prev) => ({
                                    ...prev,
                                    label: e.target.value
                                }))
                            }}
                            placeholder="Enter temlate name..."
                        />
						<h2 className="mt-8 text-xl font-bold text-zinc-800">Employment Type</h2>
						<SelectBox
							options={employmentTypes}
							className="mt-4 !h-[54px]"
							value={payload.employmentType}
							onSelect={(value) => {
								setPayload((prev) => ({
									...prev,
									employmentType: value
								}))
							}}
                            placeholder='Select Employment Type..'
						/>
						

						<hr className="shrink-0 mt-8 h-px bg-zinc-300" />

						<h2 className="mt-8 text-xl font-bold text-zinc-800">Compensation</h2>
						<div className="flex gap-3.5 justify-center mt-8">
							<div className="flex flex-col flex-1 justify-center">
								<div className="text-neutral-500">Pay Rate</div>
								<InputField
									inputBg='bg-white border border-solid border-[#DEDED9]'
									value={payload.payRate}
									onInput={(e) => {
										setPayload((prev) => ({
											...prev,
											payRate: e.target.value
										}))
									}}
                                    placeholder="15"
								/>
							</div>
							<div className="mb-[14px] flex items-center lg:mb-[18px] h-[54px] mt-auto text-neutral-500">per</div>
							<div className="flex flex-col flex-1 justify-center">
								<div className="text-neutral-500">Pay Period</div>
								<SelectBox
									options={payPeriods}
									className="mb-[18px] !h-[54px]"
									value={payload.payPeriod}
									onSelect={(value) => {
										setPayload((prev) => ({
											...prev,
											payPeriod: value
										}))
									}}
                                    placeholder='Select Period'
								/>
							</div>
						</div>

						<div className="text-neutral-500">Pay Frequency</div>
						<SelectBox
							options={payFrequencies}
							className="mt-3.5 !h-[54px]"
							value={payload.payFrequency}
							onSelect={(value) => {
								setPayload((prev) => ({
									...prev,
									payFrequency: value
								}))
							}}
                            placeholder="Select Frequency..."
						/>


						<div className="mt-4 lg:mt-6 text-neutral-500">Recruiter Name</div>
						<SelectBox
							options={interviewers}
							className="mt-3.5 !h-[54px]"
							value={payload.recruterName}
							onSelect={(value) => {
								setPayload((prev) => ({
									...prev,
									recruterName: value
								}))
							}}
							placeholder={'Select recruiter...'}
						/>

						<hr className="shrink-0 mt-8 h-px bg-zinc-300" />
                        {selectedTemplateId === -1 ? <button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={handleCreate}
						>
							Create Offer Template
						</button> :
						<button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-green-500 rounded-md max-md:px-5"
							onClick={handleUpdate}
						>
							Update Offer Template
						</button>}
						<button
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-8 font-bold text-center text-white whitespace-nowrap bg-red-500 rounded-md max-md:px-5"
							onClick={handleDelete}
						>
							Delete Offer Template
						</button>
						{/* <button
							onClick={handleEmailLetter}
							className="justify-center items-center px-16 flex-shrink-0 flex h-[52px] mt-3.5 font-bold text-center text-white whitespace-nowrap bg-sky-900 rounded-md max-md:px-5"
						>
							{emailLoading ? <PageLoader color='light' className='h-[40px]'/> : 'Email Offer Letter'}
							
						</button> */}
					</div>
				</aside>
			
			<main className="flex flex-col w-full mx-auto">
                    <Editor 
                        wrprClassNames="h-full" 
                        data={payload.content} 
                        onChange={val => setPayload((prev) => ({
                            ...prev,
                            content: val
                        }))}
                        // setInstanse={rte => setRteInstanse(rte)}
                        readOnly={false}
                    />
			</main>
		</div>
            </main> : <PageLoader />}
            <Footer hideCopyright={true} />
        </>
    );
};

export default OfferTemplates;