import { Link, useLocation } from "react-router-dom";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { HiringProcess } from "../components/base/ProgressStepsSBM";
import React, { useEffect, useState } from "react";
import ContactUsLinkBlock from "../components/parts/ContactUsLinkBlock";

const NextStepSection = () => {
    return (
        <section>
            <div className="flex gap-5 justify-between self-stretch w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
            <Link to={'/SBM02_3'} className="flex justify-center items-center px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px]">
                    Back
                </Link>
                <Link to={'/SBM02_5'} className="flex justify-center items-center px-16 py-1 h-[52px] bg-green-500 rounded-md w-full lg:max-w-[162px]">
                    Next
                </Link>
            </div>
        </section>
    );
};

const variations = [
    {
        id: "maid_service",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
                `Attitude towards<br/>Cleaning`,
                `Fitness for <br/>the Job`
            ],
            [
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                `Travel<br/>Sensitivity`,
                `Comfortable<br/>with Pets`,
                'Team or Solo',
                `Criminal<br/>Background`,
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "commercial_cleaning",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Attitude towards<br/>Cleaning`,
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Team or Solo',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "food_cleaning",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "retail",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "caregiving",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
                `Fitness for <br/>the Job`,
            ],
            [
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Travel <br/> Sensitivity',
                'Comfortable <br/> with Pets',
                'Team or Solo',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "facility",
        data: [
            [
                'Stability',
                'Physicality',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
                `Fitness for <br/>the Job`,
            ],
            [
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Comfortable <br/> with Pets',
                'Team or Solo',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "security",
        data: [
            [
                'Stability',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Criminal <br/> Background             ',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
    {
        id: "call_centers",
        data: [
            [
                'Stability',
                'Quality',
                `Client Service<br />- Empathy`,
                'Safety',
                'Team Work',
                'World View',
                'Manageability',
                `Trustworthiness<br/>Integrity`,
            ],
            [
                `Fitness for <br/>the Job`,
                `Open<br/>Mindedness`,
                'Coachability',
                'Conscientiousness',
                'Criminal <br/> Background',
                'Feedbacks',
                'Job Outlook',
                'Benefits of <br/> the Job'
            ]
        ]
    },
]


const SBM02_4 = () => {
    const { search } = useLocation()
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get('id');
    const [data, setData] = useState([])

    const stepsData = [
        { stepNumber: "1", label: "New Job Details", isActive: true },
        { stepNumber: "2", label: "Description", isActive: true },
        { stepNumber: "3", label: "Preview", isActive: true },
        { stepNumber: "4", label: "Pipelines", isActive: true },
        { stepNumber: "5", label: "Qualification Attributes", isActive: true },
        { stepNumber: "6", label: "Interview Questions", isActive: true },
        { stepNumber: "7", label: "Application Form", isActive: false },
        { stepNumber: "8", label: "Disposition Status", isActive: false }
    ];

    const questions = [
        'Interview Question 1 : Tell me about yourself:',
        'Interview Question 2 : What is the biggest challenge you have faced?',
        'Interview Question 3 : Who is your best boss?',
        'Interview Question 4 : Who is your worst boss?',
        'Interview Question 5 : Has you pay increased?',
        'Interview Question 6 : How would you rate your performance in this job?'
    ]

    useEffect(() => {
        const variation = variations.find(variation => variation.id === id);
        setData(variation?.data || variations[0]?.data);
    }, [search, id]);
    


    return (
        <>
            <BreadCrumbWhite crumbs={[
                'Subscriber Dashboard',
                'Jobs Setup',
                'New Job Details',
                "Description",
                "Preview",
                "Pipelines",
                "Qualification Attributes",
                "Interview Questions"
            ]} />

            <div className='flex-auto'>
                <div className='flex flex-col gap-[60px] max-md:gap-[40px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto max-md:pb-[80px] max-md:pt-[24px]'>
                    <section className="flex w-full text-center overflow-auto py-4">
                        <HiringProcess stepsData={stepsData} />
                    </section>

                    <div className='text-center flex gap-3 items-center justify-center flex-col'>
                        <span className='text-[40px] font-bold max-md:text-[36px]'>Interview Questions</span>
                        <span className='text-[18px] leading-[30px] max-md:text-[16px] max-md:leading-[22px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, <br className="max-md:hidden" />
                            bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut <br className="max-md:hidden" />
                            consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi.
                        </span>
                    </div>

                    <div className="lg:border border-neutral-200 px-[60px] py-[50px] max-md:p-[0px]">
                        <div className="flex flex-col gap-6">
                            <label htmlFor="check" className="checkbox-green-container bigger">
                                <input type="checkbox" hidden id="check" />
                                <div className="checkmark-green scale-150"></div>
                                <span className="checkmark-label-optional font-semibold text-[26px] flex-1 max-md:text-[18px]">Interview Questions for the Highly Recommended Qualification Attributes</span>
                            </label>

                            {data.length && data.map((el, idx) =>
                                <div className="lg:pl-12 gap-3 flex max-lg:overflow-x-auto max-lg:overflow-y-hidden whitespace-nowrap" key={idx}>
                                    {el.map((itm, i) => <div key={i}
                                        className="description-tip-modal-sbm-container relative border-b-[#DEDED9] border-b-[4px]  h-[68px] flex flex-auto items-center justify-center text-center max-md:px-[16px]"
                                    >
                                        <span className='text-[#003578] font-semibold'
                                            dangerouslySetInnerHTML={{ __html: itm }}
                                        ></span>

                                        {idx === 0 && i === 0 && <div className="description-tip-modal-sbm max-lg:!hidden">
                                            <p className="text-2xl font-bold">Interview Questions for Stability</p>
                                            {questions.map((el, idx) => <span className="text-[18px]" key={idx}>{el}</span>)}
                                        </div>}
                                        {idx === 0 && i === 0 && <div
                                            className="description-tip-modal-sbm text-left max-lg:flex lg:!hidden"
                                            style={{ position: 'fixed', width: 'calc(100% - 44px)', left: '22px', whiteSpace: 'initial' }}
                                        >
                                            <p className="text-2xl font-bold">Interview Questions for Stability</p>
                                            {questions.map((el, idx) => <span className="text-[18px]" key={idx}>{el}</span>)}
                                        </div>}
                                        {/* openModalIndex setOpenModalIndex */}
                                    </div>)}
                                </div>
                            )}

                        </div>

                        <ContactUsLinkBlock to="/SBM02_4_1"/>

                        <hr className="mt-[80px] border-b border-neutral-200 md:hidden" />
                    </div>

                    <NextStepSection />
                </div>
            </div>
        </>
    )
}

export default SBM02_4