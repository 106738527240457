const authBase = 'api/auth';

export const auth = (instance) => ({
    async login(payload) {
        const { data } = await instance.post(`${authBase}/login`, payload);
        if(data?.data && data.data?.token) {
            instance.interceptors.request.use((config) => {
                config.headers.Authorization = `Bearer ${data.data.token}`
                return config;
            
            })
            
            
        }
        return data;
    },

    async registerApplicant(payload) {
        // email password fullName
        const { data } = await instance.post(`${authBase}/register-applicant`, payload);
        return data;
    },

    async registerCompany(payload) {
        // name description industry
        const { data } = await instance.post(`${authBase}/register-company`, payload);
        return data;
    },

    async forgotPassword(payload) {
        // email
        const { data } = await instance.post(`${authBase}/forgot-password`, payload);
        return data;
    },

    async restorePassword(payload) {
        // password token email
        const { data } = await instance.post(`${authBase}/restore-password`, payload);
        return data;
    },

    async logout(payload) {
        const { data } = await instance.get(`${authBase}/logout`);
        return data;
    },
});