import React from "react";

export const NextStepSectionSBM01 = ({ next, prev }) => {


    return (
        <section>
            <hr className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className="flex gap-4 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap">
                {
                    prev &&
                    <button onClick={() => prev()} className="flex justify-center items-center px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px] hover:opacity-70">
                        Back
                    </button>
                }
                {
                    next &&
                    <button onClick={() => next()} className="ml-auto flex justify-center items-center px-16 py-1 h-[52px] bg-green-500 rounded-md w-full lg:max-w-[162px] hover:opacity-70">
                        Next
                    </button>
                }
            </div>
        </section>
    )
}