import React, { useState } from "react";
import SelectBox from "../components/base/SelectBox";
import Checkbox from "../components/base/Checkbox";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { RegisterApplicantSteps, initialAnswers } from "../components/parts/RegisterApplicantSteps";
import CreateSignatureModal from "../components/modals/CreateSignatureModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { object, string, number, date, InferType, boolean, ValidationError } from 'yup';
import InputError from "../components/base/InputError";

const checkrequiredFields = (obejct, fields) => {
    let isValid = true;
    fields.forEach(field => {
        if(!obejct[field]) {
            isValid = false
        }
    })
    return isValid;
}

const AP_37 = () => {
    const { job: {applicationForm} } = useSelector(state => state.jobs);
    const [formData, setFormData] = useOutletContext();
    const handleChange = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }))
    }
    const { pathname } = useLocation();
    const jobLink = pathname.split('/consent')[0]
    const [agree, setAgree] = useState(false)
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState(null);
    const [createSignatureModalOpen, setCreateSignatureModalOpen] = useState(false);

    const breadcrumbItems = [
        {
            name: 'Job',
            to: jobLink,
            isCurrent: false
        },
        {
            name: 'Consent',
            isCurrent: true
        },
    ]
    const { id } = useParams();
 
    const handleSubmit = async (e) => {
        e.preventDefault();
        let stepSchema = object({
            are_you_able_to_legally_work_in_the_united_state_answer: string().required('This field is required'),
            how_did_you_hear_about_this_job_answer: string().required('This field is required'),
            please_review_the_following_terms_disclosures_and_consent_yes_i_have_read_answer: boolean().oneOf([true], 'You must agree with terms').required('You must agree with terms'),
            eSignatureDate: date().required('This field is required'),
            eSignature: string().required('E Signature is required')
        });
        try {
            setFormErrors(null);
            const res = await stepSchema.validate(formData, {
                abortEarly: false
            });
            localStorage.setItem(`job-${id}-answers`, JSON.stringify(formData));
            localStorage.setItem(`job-${id}-steps`, JSON.stringify(initialAnswers.map(answer => ({...answer, isDone: answer.number <= 2 ? true : false}))));
            navigate(`${jobLink}/you!`)
        } catch(err) {
            if (err.name === 'ValidationError') {
                const errors = err.inner.reduce((acc, innerError) => {
                  acc[innerError.path] = innerError.message;
                  return acc;
                }, {});
                setFormErrors(errors);
                toast.error('Please, fill in all filelds to continue')
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }
            
        }
    }

    const please_review_the_following_terms_disclosures_and_consent_files = applicationForm.please_review_the_following_terms_disclosures_and_consent_files_text.map((text, index) => ({
        text,
        name: applicationForm.please_review_the_following_terms_disclosures_and_consent_files_names[index],
        file: applicationForm[`please_review_the_following_terms_disclosures_and_consent_files_${index+1}`]
    }))

    console.log('please_review_the_following_terms_disclosures_and_consent_files:', please_review_the_following_terms_disclosures_and_consent_files);

    return (<>
        <Breadcrumbs links={breadcrumbItems} colorStyle="secondary" />
        <main className="flex gap-[60px] max-md:flex-col max-md:gap-0 pt-[24px] lg:pt-[28px] pb-[80px] lg:pb-[90px]">
            <RegisterApplicantSteps step={2} />
            <section className="flex flex-col ml-5 w-[65%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col  text-zinc-800 max-md:mt-10 max-md:max-w-full">
                    {applicationForm.consent_active && <>
                        <h1 className="text-3xl font-bold leading-10 max-md:max-w-full">
                            {applicationForm.consent_title}
                        </h1>
                        <p className="mt-6 text-lg font-light leading-[30px] max-md:max-w-full">
                            {applicationForm.consent_text}
                        </p>
                    </>}
                    {applicationForm.are_you_able_to_legally_work_in_the_united_state_active &&
                        <div
                            className="flex flex-col pt-11 mt-11 border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                            <h2 className="lg:text-[22px] text-[28px] leading-9 font-bold lg:leading-[30px] max-md:max-w-full">
                                {applicationForm.are_you_able_to_legally_work_in_the_united_state_title}
                            </h2>
                            <div className="mt-[24px] lg:flex grid grid-cols-2">
                                {applicationForm.are_you_able_to_legally_work_in_the_united_state_choices.map(item =>
                                    <label key={item}  htmlFor={`are_you_able_to_legally_work_in_the_united_state_choices-${item}`}
                                        className="cursor-pointer radio-green-container max-w-[59px] mr-[24px] font-medium"
                                    >
                                        <input
                                            onChange={() => handleChange("are_you_able_to_legally_work_in_the_united_state_answer", item)}
                                            checked={formData.are_you_able_to_legally_work_in_the_united_state_answer === item}
                                            type="radio"
                                            className="radio-green-sub mr-[10px]" 
                                            name="are_you_able_to_legally_work_in_the_united_state_choices"
                                            id={`are_you_able_to_legally_work_in_the_united_state_choices-${item}`}
                                        />
                                        <span>{item}</span>
                                    </label>
                                )}
                            </div>
                            <InputError message={formErrors?.are_you_able_to_legally_work_in_the_united_state_answer}/>
                        </div>
                    }
                    {applicationForm.how_did_you_hear_about_this_job_active &&
                        <div
                            className="flex flex-col pt-11 mt-11 border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                            <h2 className="lg:text-[22px] text-[28px] leading-9 lg:leading-[30px] font-bold max-md:max-w-full">
                                {applicationForm.how_did_you_hear_about_this_job_title}
                            </h2>

                            <div className="lg:w-[350px] mt-[24px] input-ap">
                                <SelectBox
                                    options={applicationForm.how_did_you_hear_about_this_job_choices.map(item => ({
                                        label: item,
                                        value: item
                                    }))}
                                    value={formData.how_did_you_hear_about_this_job_answer}
                                    onSelect={(value) => handleChange("how_did_you_hear_about_this_job_answer", value)}
                                    svg={2}
                                />
                            </div>
                            <InputError message={formErrors?.how_did_you_hear_about_this_job_answer}/>
                        </div>
                    }
                    {applicationForm.please_review_the_following_terms_disclosures_and_consent_active &&
                        <div
                            className="flex flex-col pt-11 mb-[80px] mt-11 border-t border-solid border-stone-300 max-md:mt-10 max-md:max-w-full">
                            <h2 className="lg:text-[22px] text-[28px] leading-9 lg:leading-[30px] font-bold max-md:max-w-full">
                                {applicationForm.please_review_the_following_terms_disclosures_and_consent_title}
                            </h2>

                            <p className="mt-6 text-lg font-light leading-[30px] max-md:max-w-full">
                                {applicationForm.please_review_the_following_terms_disclosures_and_consent_text}
                            </p>

                            <div className="mt-6 font-[500] text-[18px] flex flex-col gap-[16px]">
                                {please_review_the_following_terms_disclosures_and_consent_files.map(({text, name, file}, index) => 
                                    <h5 key={index} className="flex items-start font-semibold text-lg flex-wrap"><span
                                        className="block min-w-[133px] mr-[12px]">{text}</span> <a
                                            href={process.env.REACT_APP_API_URL+file} target="_blank"
                                            className="cursor-pointer underline font-medium text-[#06D6A0]"
                                        >{name}</a>
                                    </h5>
                                )}
                            </div>

                            <div className="mt-6">
                                <label className="flex items-center gap-[14px]">
                                    <div className="flex">
                                        <Checkbox 
                                        checked={formData.please_review_the_following_terms_disclosures_and_consent_yes_i_have_read_answer}
                                        onChange={(isChecked) => handleChange('please_review_the_following_terms_disclosures_and_consent_yes_i_have_read_answer' ,isChecked)
                                        
                                        }/>
                                    </div>
                                    <p className="text-[#6D6D6D] text-lg leading-[30px]">
                                        {applicationForm.please_review_the_following_terms_disclosures_and_consent_yes_i_have_read}
                                    </p>
                                </label>
                                <InputError message={formErrors?.please_review_the_following_terms_disclosures_and_consent_yes_i_have_read_answer}/>
                            </div>

                            <div className="mt-6 font-semibold">
                                <div className="flex items-end whitespace-nowrap font-semibold text-lg">
                                    E Signature <span className={`underline-for-text-part cursor-pointer ${formData?.eSignature ? '!h-[50px]' : ''}`} onClick={(e)=>{
                                        e.preventDefault();
                                        setCreateSignatureModalOpen(true);
                                    }}>
                                        { formData?.eSignature ? <img src={formData?.eSignature} alt="" className="max-h-[100%]" /> : null }
                                    </span>
                                </div>
                                <InputError message={formErrors?.eSignature}/>
                                <div className="flex items-center whitespace-nowrap font-semibold text-lg mt-6 gap-[12px]">
                                    <label htmlFor="date" className="my-auto font-semibold">
                                        Date
                                    </label>
                                    <input
                                        id="date"
                                        type="date"
                                        value={formData.eSignatureDate}
                                        onChange={(e) => handleChange('eSignatureDate', e.target.value)}
                                        className="md:max-w-[230px] flex-1 justify-center items-center  py-2 border-b border-solid border-stone-300 max-md:px-5"
                                    />
                                </div>
                                <InputError message={formErrors?.eSignatureDate}/>
                            </div>
                        </div>
                    }
                    
                    <footer className="flex flex-col justify-center mt-11 text-lg font-bold text-center text-white whitespace-nowrap border-t border-solid border-stone-300 max-md:mt-[0px] max-md:max-w-full">
                        <div className="flex gap-5 justify-between pt-10 max-md:flex-wrap max-md:max-w-full">
                            <button
                                className="flex items-center h-[52px] justify-center px-12 py-5 bg-gray-500 rounded-md max-md:px-5 max-md:flex-1"
                                onClick={(e) => { e.preventDefault(); navigate(`${jobLink}/it-takes-x-minutes`); }}
                            >Previous</button>
                            <button
                                className="flex items-center h-[52px] justify-center px-9 py-5 bg-teal-500 rounded-md max-md:px-5 max-md:flex-1"
                                onClick={handleSubmit}
                            >Save & Continue</button>
                        </div>
                    </footer>
                </div>
            </section>
        </main>

        {createSignatureModalOpen ? <CreateSignatureModal
            open={createSignatureModalOpen}
            handleClose={()=>{ setCreateSignatureModalOpen(false); }}
            setSignature={(base64Image)=>{
                handleChange("eSignature", base64Image)
            }}
        /> : null }
    </>)
}

export default AP_37