import React, { useState, useEffect, Fragment } from "react";
import Checkbox from "../../components/base/Checkbox";
import ContactUsLinkBlock from "../../components/parts/ContactUsLinkBlock";
import api from "../../api";

const Step5 = ({ setContactFormOpen, payload, setPayload, industriesList, setQaPresetAttributesSelected, bottomPaste = null }) => {
    const selectedIndustrie = industriesList.find(item => +item.id === +payload?.industrieId);

    const [data, setData] = useState(null);

    const getQaPresetAttributesListByIndustryId = async(id) => {
        let res = await api.jobs.getQaPresetAttributesListByIndustryId(payload.industrieId);
        setData(res.data)
    }

	useEffect(() => {
        if(payload.industrieId && selectedIndustrie){
            getQaPresetAttributesListByIndustryId();
        }
	}, [selectedIndustrie]);

    return (
        <>
            <div className='text-center flex gap-3 items-center justify-center flex-col mb-10 lg:mb-[60px]'>
                <span className='lg:text-[40px] text-4xl font-bold'>Evaluating the Critical Requirements of Your Job</span>
                <span className='lg:text-[18px] text-base leading-[22px] lg:leading-[30px]'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet,
                    bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut
                    consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi.
                </span>
            </div>

            <div className="lg:border border-neutral-200 lg:px-[60px] lg:py-[50px]">
                {
                    data?.map((item, index) => (
                    <Fragment key={index}>
                        <div>
                            <Checkbox
                                className="gap-4"
                                text={<span className={`checkmark-label-optional font-semibold text-lg lg:text-[26px] ${payload.qaPresetId === item.id ? 'text-[#37B34A]' : 'text-[#333333]'}`}>
                                    { item.title }
                                </span>}
                                checked={payload.qaPresetId === item.id}
                                onChange={(val) => {
                                    if(payload.qaPresetId !== item.id){
                                        setPayload({ ...payload, qaPresetId: item.id });
                                        setQaPresetAttributesSelected(item);
                                    } else {
                                        setPayload({ ...payload, qaPresetId: null });
                                        setQaPresetAttributesSelected(null);
                                    }
                                }}
                            />
                            <div className="lg:pl-12 mt-5 lg:mt-6 flex w-full overflow-auto">
                                {
                                    item?.attributes?.map((attributItem, idx) => (
                                        <div
                                            key={idx}
                                            className=" bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7] h-[68px] px-2 max-lg:min-w-32 flex flex-auto items-center justify-center text-center whitespace-nowrap">
                                            <span className='text-[#003578] text-base leading-5 font-semibold' dangerouslySetInnerHTML={{ __html: attributItem.name }}></span>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        { index !== data?.length - 1 ? <hr className="my-[50px] border-b border-neutral-200" /> : null }
                    </Fragment>
                )) }

                <ContactUsLinkBlock
                    setContactFormOpen={setContactFormOpen}
                    topPaste={bottomPaste}
                />
            </div>
        </>
    )
}

export default Step5