

import { useEffect } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { NextStepSectionSBM01 } from "../base/NextStepSectionSBM01"
import CommunicationTable from "../parts/CommunicationTable"

const Communications = () => {
	const navigate = useNavigate()
	const { job: jobData, application: applicationData, jobId, applicationId, setActiveStep } = useOutletContext()
	useEffect(() => {
		setActiveStep(2)
	}, [])

	const handlePrev = () => {
		navigate(`/SBM01_10_14_2_1/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_14_2_3/${jobId}/${applicationId}`)
	}

	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
				<div className="flex flex-col gap-7">
					<p className="text-2xl font-bold">Communication History</p>
					<CommunicationTable />
					<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
				</div>
			</div>
		</div>
	)
}


export default Communications