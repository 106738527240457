import React from "react";
import { Footer } from "../components/parts/Footer";
import { useState } from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectBox from "../components/base/SelectBox";
import Checkbox from "../components/base/Checkbox";

import CallModal from "../components/modals/CallModal";
import RejectCandidate from "../components/modals/RejectCandidate";
import SmsModal from "../components/modals/SmsModal";
import EmailModal from "../components/modals/EmailModal";
import AddTagModal from "../components/modals/AddTagModal";

import Button from "../components/base/Button";
import { Link } from "react-router-dom";
import {crumbsConfig} from "./SBM01_Form/config";


const TableHeadintTH = ({ text }) => (
    <th className="text-base p-[13px] font-semibold whitespace-nowrap bg-neutral-100 text-zinc-800">
        <div className="flex items-center justify-center gap-2">
            {text}
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/07ed9900e8cabe1dde851ffd213733e70a63d7b4d6b50bd5a67fba228f7c1d62?apiKey=bb18ecc98f7243e0b35c0b167087ee07&" alt="" />
        </div>
    </th>
);

const CandidateProgress = ({ color, percentage }) => (
    <div className="flex justify-center gap-2.5 self-stretch my-auto text-base whitespace-nowrap text-zinc-800">
        <div className={`shrink-0 w-5 h-5 ${color} rounded-[360px]`} />
        <div className="my-auto">{percentage}</div>
    </div>
);

const ActionButton = ({ onClick, text, bgColor }) => (
    <button type="button" className={`grow justify-center px-6 py-3 ${bgColor} rounded-md max-md:px-5`} onClick={onClick}>{text}</button>
);
const CheckboxInput = ({ label, checked = false, onChange, className = 'h-[18px] w-[18px]' }) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = () => {
        const newValue = !isChecked
        setIsChecked(newValue)
        onChange && onChange(newValue)
    }
    return (
        <label className="flex gap-3.5 cursor-pointer">
            {/* <div className={`flex justify-center items-center px-0.5 my-auto border border-solid bg-stone-50 border-stone-300 ${className} `}>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleChange}
                    className="hidden"
                />
                {
                    isChecked ?
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1499 2.5461L11.1844 1.88985C10.9173 1.70896 10.5513 1.77838 10.3725 2.0434L5.63995 9.02237L3.46507 6.84749C3.23791 6.62032 2.86771 6.62032 2.64055 6.84749L1.81393 7.67411C1.58676 7.90127 1.58676 8.27146 1.81393 8.50073L5.15828 11.8451C5.34548 12.0323 5.63995 12.1753 5.90497 12.1753C6.17 12.1753 6.43712 12.0091 6.6096 11.7588L12.3055 3.3559C12.4864 3.09088 12.417 2.72699 12.1499 2.5461Z" fill="#37B34A" />
                        </svg>
                        :
                        null
                }
            </div> */}
            <Checkbox />
            {label && <div className="flex-auto text-lg leading-8 whitespace-nowrap text-neutral-500">
                {label}
            </div>}
        </label>
    )
};
const CandidateRow = ({ onClick, isCheck, name, date, source, application, resume, coverLetter, progressColor, progressPercentage }) => (
    <tr>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <CheckboxInput checked={isCheck} className="w-[24] h-[24px] min-w-[24px]" />
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className="text-lg font-bold text-green-500">{name}</div>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className=" text-stone-500">{date}</div>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className="text-stone-500">{source}</div>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className="font-bold text-green-500">{application}</div>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className="font-bold text-green-500">{resume}</div>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <CandidateProgress color={progressColor} percentage={progressPercentage} />
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className="text-base text-zinc-800">{coverLetter}</div>
        </td>
        <td className="text-lg px-[13px] py-[22px] text-center whitespace-nowrap">
            <div className="flex items-center gap-[8px] font-semibold text-white ">
                <ActionButton text="Reject" bgColor="bg-red-600" onClick={onClick} />
                <ActionButton text="Advance" bgColor="bg-green-500" />
            </div>
        </td>
    </tr>
);
const InterviewStage = ({ stageName, isActive }) => {
    return (
        <div className={`grow flex justify-center items-center text-[16px] font-semibold px-[10px] min-h-[90px] py-2 min-w-[173px] text-center border-gray-200 border-solid ${isActive ? "border-sky-900 text-neutral-500" : "border-gray-200"} border-b-[5px] max-md:px-5`}>
            <span>{stageName}</span>
        </div>
    );
};
const CandidateCard = ({ isCheck, name, applicationDate }) => {
    return (
        <section className="flex justify-center mt-[36px]">
            <div className="flex items-center justify-center">
                <CheckboxInput checked={isCheck} className="aspect-square lg:w-[50px] w-[24]" />
            </div>
            <div className="flex flex-auto gap-5 pl-5 py-5 text-lg">
                <Link to={'/SBM01_10_14'} className="font-bold text-green-500">{name}</Link>
                <div className="flex-auto text-stone-500 max-md:max-w-full">Applied Date {applicationDate}</div>
            </div>
        </section>
    );
};

const SBM01_10 = () => {
    const [rejectPopup, setRejectPopup] = useState(false)
    const [callPopup, setCallPopup] = useState(false)
    const [smsPopup, setSmsPopup] = useState(false)
    const [emailPopup, setEmailPopup] = useState(false)
    const [addTagPopup, setAddTagPopup] = useState(false)
    const [formData, setFormData] = useState({
        showItems: '10',
        status: 'open',
        sortBy: 'date'
    });
    const modalsDataCall = [
        {
            name: 'Call',
            data: [
                { date: "Wed, 25 May 2023", time: "14.00", },
                { date: "Mon, 22 May 2023", time: "14.00", },
                { date: "Fri, 19 May 2023", time: "14.00", },
                { date: "Wed, 03 May 2023", time: "14.00", },
            ]
        },
    ]
    const modalsDataEmail = [
        {
            name: 'Email',
            data: [
                { date: "Wed, 25 May 2023", time: "14.00", },
                { date: "Mon, 22 May 2023", time: "14.00", },
                { date: "Fri, 19 May 2023", time: "14.00", },
                { date: "Wed, 03 May 2023", time: "14.00", },
            ]
        },
    ]
    const modalsDataSms = [
        {
            name: 'Sms',
            data: [
                { date: "Wed, 25 May 2023", time: "14.00", },
                { date: "Mon, 22 May 2023", time: "14.00", },
                { date: "Fri, 19 May 2023", time: "14.00", },
                { date: "Wed, 03 May 2023", time: "14.00", },
                { date: "Mon, 22 April 2023", time: "14.00", },
                { date: "Fri, 19 April 2023", time: "14.00", },
                { date: "Wed, 25 March 2023", time: "14.00", },
                { date: "Mon, 22 March 2023", time: "14.00", },
                { date: "Fri, 19 March 2023", time: "14.00", },
                { date: "Wed, 25 February 2023", time: "14.00", },
            ]
        }
    ]
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    // const tabsAction = (value) => {
    //     setVisibleTab(value)
    // }
    const statusArr = [
        { value: 'open', label: 'Open' },
        { value: 'close', label: 'Close' },
    ];

    const showTableItems = [
        { value: '10', label: '10' },
        { value: '20', label: '20' },
        { value: '50', label: '50' },
    ]
    const sortBy = [
        { value: 'date', label: 'Date' },
        { value: 'name', label: 'Name' },
    ]
    const candidatesData = [
        { isCheck: true, name: "John Doe", date: "4/5/23", source: "Career Site", application: "Application", resume: "Resume", assessment: "–", coverLetter: "–", iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/0803ca6ef7e569c4bf77e1abd7b553f4a262280219d6b3f1bf06b19cd605f4f5?apiKey=bb18ecc98f7243e0b35c0b167087ee07&", iconAlt: "Profile Picture", progressColor: "bg-red-600", progressPercentage: "20%" },
        { isCheck: true, name: "Robert", date: "4/5/23", source: "Career Site", application: "Application", resume: "Resume", assessment: "–", coverLetter: "–", iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/0803ca6ef7e569c4bf77e1abd7b553f4a262280219d6b3f1bf06b19cd605f4f5?apiKey=bb18ecc98f7243e0b35c0b167087ee07&", iconAlt: "Profile Picture", progressColor: "bg-yellow-400", progressPercentage: "40%" },
        { isCheck: true, name: "Lord", date: "4/5/23", source: "Career Site", application: "Application", resume: "Resume", assessment: "–", coverLetter: "–", iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/0803ca6ef7e569c4bf77e1abd7b553f4a262280219d6b3f1bf06b19cd605f4f5?apiKey=bb18ecc98f7243e0b35c0b167087ee07&", iconAlt: "Profile Picture", progressColor: "bg-green-600", progressPercentage: "60%" },
        { isCheck: true, name: "Sam", date: "4/5/23", source: "Career Site", application: "Application", resume: "Resume", assessment: "–", coverLetter: "–", iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/0803ca6ef7e569c4bf77e1abd7b553f4a262280219d6b3f1bf06b19cd605f4f5?apiKey=bb18ecc98f7243e0b35c0b167087ee07&", iconAlt: "Profile Picture", progressColor: "bg-red-600", progressPercentage: "20%" },
        { isCheck: true, name: "Donna", date: "4/5/23", source: "Career Site", application: "Application", resume: "Resume", assessment: "–", coverLetter: "–", iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/0803ca6ef7e569c4bf77e1abd7b553f4a262280219d6b3f1bf06b19cd605f4f5?apiKey=bb18ecc98f7243e0b35c0b167087ee07&", iconAlt: "Profile Picture", progressColor: "bg-red-600", progressPercentage: "20%" },
        // Other candidate data...
    ];
    const tableHeadingCols = ['Full Name', 'Date of Application', 'Source', 'Application', 'Resume', 'Assessment', 'Cover Letter']

    const interviewStages = [
        "Clarification",
        "1st Interview",
        "Post Interview Survey",
        "2nd Interview",
        "Post 2nd Interview Doc Review",
        "AI Evaluation",
        "Training Insights",
        "Offer Meeting",
    ];

    const candidates = [
        { isCheck: true, name: "John Doe", applicationDate: "3/28/2023" },
        { isCheck: true, name: "Alexander", applicationDate: "3/28/2023" },
        { isCheck: true, name: "William", applicationDate: "3/28/2023" },
    ];

    return (
        <>
            <BreadCrumbWhite crumbs={[crumbsConfig.sub, crumbsConfig.jobs, crumbsConfig.applicant, crumbsConfig.candidate]} />
            <section className="flex justify-center flex-auto w-full text-zinc-800">
                <div className="flex flex-col w-full pt-10 pb-20 lg:pb-24 lg:pt-14 max-w-[1432px] px-4">
                    <h1 className="w-full text-4xl font-bold text-zinc-800 max-lg:text-center">
                        Cleaning Technician
                    </h1>
                    <div className="flex gap-5 max-lg:w-full self-start mt-3 text-base text-stone-500 mb-9 lg:mb-11">
                        <div className="flex items-center max-lg:flex-auto max-lg:justify-center gap-1.5">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99999 1.66602C6.32415 1.66602 3.33332 4.65685 3.33332 8.32852C3.30915 13.6993 9.74665 18.1527 9.99999 18.3327C9.99999 18.3327 16.6908 13.6993 16.6667 8.33268C16.6667 4.65685 13.6758 1.66602 9.99999 1.66602ZM9.99999 11.666C8.15832 11.666 6.66665 10.1743 6.66665 8.33268C6.66665 6.49102 8.15832 4.99935 9.99999 4.99935C11.8417 4.99935 13.3333 6.49102 13.3333 8.33268C13.3333 10.1743 11.8417 11.666 9.99999 11.666Z" fill="#37B34A" />
                            </svg>
                            <span>Chicago, IL</span>
                        </div>
                        <div className="flex items-center max-lg:flex-auto max-lg:justify-center gap-0.5 pr-3 leading-5">
                            <div className="flex-auto justify-center flex">
                                <span className="font-bold">Status: </span>
                                <select name="status" id="status" value={formData.status} className="pr-[20px]" onChange={handleChange} style={{
                                    backgroundPosition: 'right 0 center',
                                    backgroundImage: `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.66675 8.33398L10.0001 11.6673L13.3334 8.33398" stroke="%2337B34A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')`,
                                    backgroundSize: '20px'
                                }}>
                                    {statusArr.map(item => (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-5 justify-between max-lg:flex-col mb-[32px]">
                        <div className="flex gap-0 lg:gap-5 max-lg:w-full lg:justify-between my-auto text-xl font-semibold text-center whitespace-nowrap text-zinc-800">
                            <Link to={'/SBM01_2'}
                                className={`flex items-center max-lg:flex-auto justify-center px-5 h-[46px] border-white  border-solid border-b-[5px] hover:bg-green-200`}
                            >
                                Applicants
                            </Link>
                            <Link to={'/SBM01_10'}
                                className={`flex items-center max-lg:flex-auto justify-center px-5 h-[46px] border-green-500  border-solid border-b-[5px] hover:bg-green-200`}
                            >
                                Candidates
                            </Link>
                        </div>
                        <div className="flex max-w-[500px] flex-auto gap-3 text-lg max-lg:flex-col">
                            <form action="" className="flex max-lg:flex-col flex-auto items-center gap-[14px] lg:gap-[16px]">
                                <input type="text"
                                    className="flex-auto pr-5 pl-[56px] max-lg:w-full py-2 bg-white rounded-md border border-solid border-zinc-300 text-stone-500 h-[60px] lg:h-[64px]"
                                    placeholder="Search applicants for this job..."
                                    style={{
                                        backgroundImage: `url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="11.0588" cy="11.058" r="7.06194" stroke="%23666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M20.0034 20.0024L16.0518 16.0508" stroke="%23666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')`,
                                        backgroundPosition: 'left 18px center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '24px'
                                    }} />
                                <Button className={'text-white bg-green-500 max-lg:w-full min-w-[130px] h-[60px] lg:h-[64px]'}>Find</Button>
                            </form>
                        </div>
                    </div>
                    <div className="flex gap-5 justify-between p-[18px] text-base whitespace-nowrap bg-gray-200 max-md:flex-wrap">
                        {/* <div className={`flex gap-2 font-semibold text-sky-900 ${visibleTab === 1 ? '' : 'hidden'}`}>
                            <button type="button" className="grow justify-center px-[12px] py-[12px] rounded-md border border-sky-900 border-solid" onClick={() => { setCallPopup(true) }}>
                                Call
                            </button>
                            <button type="button" className="grow justify-center px-[12px] py-[12px] rounded-md border border-sky-900 border-solid" onClick={() => { setSmsPopup(true) }}>
                                SMS
                            </button>
                            <button type="button" className="grow justify-center px-[12px] py-[12px] rounded-md border border-sky-900 border-solid" onClick={() => { setEmailPopup(true) }}>
                                Email
                            </button>
                            <button type="button" className="grow justify-center px-[12px] py-[12px] rounded-md border border-sky-900 border-solid" onClick={() => { setAddTagPopup(true) }}>
                                Add Tag
                            </button>
                        </div> */}
                        <div className="flex max-lg:w-full gap-4 ml-auto">
                            <div className="flex flex-auto max-lg:flex-col gap-2 lg:gap-3">
                                <div className="whitespace-nowrap items-center flex font-bold uppercase text-stone-500">
                                    Show
                                </div>
                                <SelectBox options={[ 
                                    {
                                        label: '10'
                                    },
                                    {
                                        label: '20'
                                    },
                                ]} svg={2} className='w-full lg:w-[74px] h-[50px]' />
                            </div>
                            <div className="flex flex-auto max-lg:flex-col gap-2 lg:gap-3">
                                <div className="whitespace-nowrap items-center flex font-bold uppercase text-stone-500">
                                    Sort by
                                </div>
                                <SelectBox options={[ 
                                    {
                                        label: 'Date'
                                    },
                                    {
                                        label: 'Name'
                                    },
                                ]} svg={2} className='w-full lg:w-[92px] h-[50px]' />
                            </div>
                        </div>
                    </div>
                    {/* <div className={`mt-[14px] overflow-auto ${visibleTab === 1 ? '' : 'hidden'}`}>
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th
                                        className="text-base font-semibold whitespace-nowrap bg-neutral-100 text-zinc-800"
                                        style={{
                                            width: '10px'
                                        }}
                                    ></th>
                                    {tableHeadingCols.map((tableTh, index) => (
                                        <TableHeadintTH text={tableTh} key={tableTh} />
                                    ))}
                                    <th
                                        className="text-base font-semibold whitespace-nowrap bg-neutral-100 text-zinc-800"
                                        style={{
                                            width: '10px'
                                        }}
                                    ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {candidatesData && candidatesData.map((data, index) => (
                                    <CandidateRow key={index} {...data} onClick={() => { setRejectPopup(true) }} />
                                )) || <tr><td colSpan={9}>No Data</td></tr>}
                            </tbody>
                        </table>
                    </div> */}
                    <div>
                        <div className="flex flex-col">
                            {candidates.map((candidate, index) => (
                                <React.Fragment key={index}>
                                    <CandidateCard {...candidate} />
                                    <div className="flex gap-0.5 text-base font-semibold text-neutral-400 w-full overflow-auto">
                                        {interviewStages.map((stage, i) => (
                                            <InterviewStage key={i} stageName={stage} isActive={i === 0} />
                                        ))}
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            {rejectPopup && <RejectCandidate open={rejectPopup} handleClose={() => setRejectPopup(false)} />}
            {callPopup && <CallModal open={callPopup} data={modalsDataCall} handleClose={() => setCallPopup(false)} />}
            {smsPopup && <SmsModal open={smsPopup} data={modalsDataSms} handleClose={() => setSmsPopup(false)} />}
            {emailPopup && <EmailModal open={emailPopup} data={modalsDataEmail} handleClose={() => setEmailPopup(false)} />}
            {addTagPopup && <AddTagModal open={addTagPopup} modalSize='modal__dialog--1000' handleClose={() => setAddTagPopup(false)} />}
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM01_10