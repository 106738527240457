import React, { useState, useRef, useEffect } from 'react'
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { Footer } from '../components/parts/Footer'
import BlueHedingTable from '../components/base/BlueHedingTable'
import AddUserModal from "../components/modals/AddUserModal"
import { SuccessModal } from '../components/modals/SuccessModal';
import { useSelector } from 'react-redux';
import { getUser, getUserCompanyId } from '../redux/slices/applicationSlice';
import api from '../api';
import PageLoader from '../components/PageLoader';
const SBM05_TEA = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [addTeamUser, setTeamUser] = useState(false)
    const [addInterviewer, setAddInterviewer] = useState(false)
    const [successPopup, setSuccesPopup] = useState(false)
    const user = useSelector(getUser);
    const companyId = useSelector(getUserCompanyId);
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!user || !companyId) return;
        setIsLoading(true)

        api.companyTeam.getTeamMembers(companyId).then(response => {
            if (!response.success) {
                return
            }
            setData(response.data.map(item => ({
                id: item.id,
                name: item.user.firstName,
                email: item.user.email,
                role: item.role,
                userId: item.userId,
                confirm: item.isVerified,
                companyId,
                editable: (item.role !== 'admin' || item.userId === user.id) ? {
                    edit: `/SBM05_1/${item.id}`
                } : false
            })))
        }).finally(() => {
            setIsLoading(false)
        });
    }, [user])

    const addItem = (item) => {
        setData(prev => ([...prev, {
            id: item.id,
            name: item.user.firstName,
            email: item.user.email,
            role: item.role,
            confirm: item.isVerified,
            editable: {
                edit: `/SBM05_1/${item.id}`
            },
            userId: item.userId
        }]))
    }
    const deleteItem = (id) => {
        setData(prev => prev.filter(item => item.id !== id))
    }
    const updateItem = (updatedItem) => {
        setData(prev => prev.map(item => item.id === updatedItem.id ? {
            ...item, ...{
                id: updatedItem.id,
                name: updatedItem.user.firstName,
                email: updatedItem.user.email,
                role: updatedItem.role,
                confirm: updatedItem.isVerified,
                editable: {
                    edit: `/SBM05_1/${updatedItem.id}`
                },
                userId: updatedItem.userId
            }
        } : item))
    }

    return (
        <div className='flex-auto flex flex-col'>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Team User Setting']}
                statusSelect={true}
                // onTeamUserClick={() => setTeamUser(true)}
                onInterviewUserClick={() => setAddInterviewer(true)}
                // teamUser={true}
                interviewUser={true}
            />
            <section className='py-[50px] px-[20px] md:py-[90px] flex-auto'>
                <div className="max-w-[1400px] mx-auto">
                    {
                        !isLoading && user ?
                            <BlueHedingTable tableData={data} companyId={companyId} deleteItem={deleteItem} updateItem={updateItem} />
                            :
                            <PageLoader />
                    }
                </div>
            </section>
            {
                addTeamUser &&
                <AddUserModal
                    onSubmitClick={() => setSuccesPopup(true)}
                    handleClose={() => setTeamUser(false)}
                    open={addTeamUser}
                    role={"member"}
                    title={"Add New Team User"}
                    addItem={addItem}
                />
            }
            {
                addInterviewer &&
                <AddUserModal
                    onSubmitClick={() => setSuccesPopup(true)}
                    handleClose={() => setAddInterviewer(false)}
                    open={addInterviewer}
                    role={"interviewer"}
                    title={"Add New Interview User"}
                    addItem={addItem}
                />
            }
            {
                successPopup &&
                <SuccessModal
                    open={successPopup}
                    handleClose={() => setSuccesPopup(false)}
                />}
            <Footer hideCopyright={true} />
        </div>
    )
}

export default SBM05_TEA
