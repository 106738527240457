import {useEffect} from "react";


export const ModalForm = ({children, onSave, formWrapClass}) => {

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0]

        body.style.overflow = 'hidden'

        return () => {
            body.style.overflow = 'visible'
        }
    }, []);
    const save = () => {
        onSave && onSave()
    }
    return (
        <div className="modal-sbm">
            <form onSubmit={save} className="w-full">

            <div className="modal-sbm-body">
                <div className={`flex flex-col gap-[12px] w-full max-w-[650px] ${formWrapClass ? formWrapClass : ''}`}>
                    {children}
                </div>
            </div>

            <button type="submit" className="flex items-center justify-center rounded-[6px] mt-[15px] w-full bg-[#37B34A] text-[18px] font-bold h-[52px] text-white" >
                Save
            </button>

            </form>
        </div>
    )
}