import React from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import Checkbox from "../components/base/Checkbox";
import { HiringProcess } from "../components/base/ProgressStepsSBM";
import ContactUsLinkBlock from "../components/parts/ContactUsLinkBlock";
import { Link } from "react-router-dom";

const NextStepSection = () => {
    return (
        <section>
            <hr className="self-stretch w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className="flex gap-4 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap">
                <Link to={'/SBM02_2'} className="flex justify-center items-center px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px]">
                    Back
                </Link>
                <Link to={'/SBM02_4'} className="flex justify-center items-center px-16 py-1 h-[52px] bg-green-500 rounded-md w-full lg:max-w-[162px]">
                    Next
                </Link>
            </div>
        </section>
    );
};


const SBM02_3 = () => {

    const stepsData = [
        { stepNumber: "1", label: "New Job Details", isActive: true },
        { stepNumber: "2", label: "Description", isActive: true },
        { stepNumber: "3", label: "Preview", isActive: true },
        { stepNumber: "4", label: "Pipelines", isActive: true },
        { stepNumber: "5", label: "Qualification Attributes", isActive: true },
        { stepNumber: "6", label: "Interview Questions", isActive: false },
        { stepNumber: "7", label: "Application Form", isActive: false },
        { stepNumber: "8", label: "Disposition Status", isActive: false }
    ];

    const data = [
        [
            'Stability',
            'Physicality',
            'Quality',
            `Client Service<br />- Empathy`,
            'Safety',
            'Team Work',
            'World View',
            'Manageability',
            `Trustworthiness<br/>Integrity`,
            `Attitude towards<br/>Cleaning`,
            `Fitness for <br/>the Job`
        ],
        [
            `Open<br/>Mindedness`,
            'Coachability',
            'Conscientiousness',
            `Travel<br/>Sensitivity`,
            `Comfortable<br/>with Pets`,
            'Team or Solo',
            `Criminal<br/>Background`,
            'Feedbacks',
            'Job Outlook',
            'Benefits of <br/> the Job'
        ]
    ]

    const data2 = [
        [
            'Stability',
            'Physicality',
            'Quality',
            `Client Service<br />- Empathy`,
            'Team Work',
            'Manageability',
            `Trustworthiness<br/>Integrity`,
            `Attitude towards<br/>Cleaning`
        ],
        [
            `Fitness for <br/>the Job`,
            `Open<br/>Mindedness`,
            'Coachability',
            'Organization',
            `Travel<br/>Sensitivity`,
            `Comfortable<br/>with Pets`,
            'Team or Solo',
            `Criminal<br/>Background`,
            'Feedbacks',
        ]
    ]

    return (
        <>
            <BreadCrumbWhite crumbs={[
                'Subscriber Dashboard',
                'Jobs',
                'New Job Details',
                "Description",
                "Preview",
                "Pipelines",
                'Qualification Attributes'
            ]} />

            <div className='flex-auto'>
                <div className='flex flex-col max-w-[1432px] px-4 w-full pb-20 pt-10 lg:pt-14 lg:pb-24 mx-auto'>
                    <section className="flex w-full text-center overflow-auto mb-10 lg:mb-[60px]">
                        <HiringProcess stepsData={stepsData} />
                    </section>

                    <div className='text-center flex gap-3 items-center justify-center flex-col mb-10 lg:mb-[60px]'>
                        <span className='lg:text-[40px] text-4xl font-bold'>Evaluating the Critical Requirements of Your Job</span>
                        <span className='lg:text-[18px] text-base leading-[22px] lg:leading-[30px]'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet,
                            bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut
                            consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi.
                        </span>
                    </div>

                    <div className="lg:border border-neutral-200 lg:px-[60px] lg:py-[50px] mb-20">
                        <div>
                            <div className="flex items-start gap-4">
                                <div className="flex">
                                    <Checkbox />
                                </div>
                                <span className="checkmark-label-optional font-semibold text-[#37B34A] text-lg lg:text-[26px]">Highly Recommended Pipeline - Bottle Tested & Optimized for Best Results</span>
                            </div>
                            {
                                data.map((table, index) => (
                                    <div key={index} className="lg:pl-12 mt-5 lg:mt-6 flex w-full overflow-auto">
                                        {table.map((el, idx) =>
                                            <div
                                                key={idx}
                                                className=" bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7] h-[68px] px-2 max-lg:min-w-32 flex flex-auto items-center justify-center text-center">
                                                <span className='text-[#003578] text-base leading-5 font-semibold' dangerouslySetInnerHTML={{ __html: el }}></span>
                                            </div>
                                        )}
                                    </div>
                                ))
                            }
                        </div>

                        <hr className="my-[50px] border-b border-neutral-200" />

                        <div>
                            <div className="flex items-start gap-4">
                                <div className="flex">
                                    <Checkbox />
                                </div>
                                <span className="checkmark-label-optional font-semibold text-lg lg:text-[26px]">Alternative Default Pipeline 2</span>
                            </div>
                            {
                                data.map((table, index) => (
                                    <div key={index} className="lg:pl-12 mt-5 lg:mt-6 flex w-full overflow-auto">
                                        {table.map((el, idx) =>
                                            <div
                                                key={idx}
                                                className=" bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7] h-[68px] px-2 max-lg:min-w-32 flex flex-auto items-center justify-center text-center">
                                                <span className='text-base leading-5 font-semibold' dangerouslySetInnerHTML={{ __html: el }}></span>
                                            </div>
                                        )}
                                    </div>
                                ))
                            }
                        </div>
                        <ContactUsLinkBlock to="/SBM02_3_1"/>
                    </div>
                    <NextStepSection />
                </div>
            </div>
        </>
    )
}

export default SBM02_3