import React from "react";
import Step6 from "../NewJobDetails/Step6";
import NextStepSection from "./NextStepSection";

const InterviewQuestions = ({
    payload,
    setPayload,
    setContactFormOpen,
    industriesList,
    qaPresetAttributesSelected,
    setQaPresetAttributesSelected,
    save,
    statusToggle,
}) => {
    return (
        <>
            <Step6
                payload={payload}
                setPayload={setPayload}
                setContactFormOpen={setContactFormOpen}
                industriesList={industriesList}
                qaPresetAttributesSelected={qaPresetAttributesSelected}
                setQaPresetAttributesSelected={setQaPresetAttributesSelected}
                bottomPaste={<div className="mb-[50px]">
                    <NextStepSection
                        save={save}
                        statusToggle={statusToggle}
                        payload={payload}
                    />
                </div>}
            />
            
        </>
    )
}

export default InterviewQuestions