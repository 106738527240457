import React from "react";

const MilestoneBar = ({ percentage, colorClass, label, index }) => (
	<div
		className={`overflow-hidden relative flex flex-col justify-center items-center  max-w-full text-center min-h-[44px] text-white`}
		style={{ width: `${100 - (11 - index) * 7}%` }}
		// style={{ width: `${106 + index * 20}px` }}
	>
		<div className="text-white" style={{ zIndex: 2, textShadow: '0px 1px 4px #00000052' }}>{label}</div>
		<div className="absolute inset-0 w-full h-full flex justify-center items-center">
			<div
				className={`w-full h-11 ${colorClass}`}
				style={{
					// clipPath: 'polygon(0px 0px, 100% 0px, calc(100% - 10px) 100%, 20px 100%)',
					clipPath: window.innerWidth > 1200 ? 
					'polygon(0px 0px, 100% 0px, calc(100% - 20px) 100%, 20px 100%)'
					: 'polygon(0px 0px, 100% 0px, calc(100% - 10px) 100%, 10px 100%)',
				}}
			></div>
		</div>

	</div>
);

const MilestoneVisualization = () => {
	const milestones = [
		{ percentage: 200, colorClass: 'bg-rose-800', label: '200', index: 11 },
		{ percentage: 180, colorClass: 'bg-red-600', label: '180', index: 10 },
		{ percentage: 90, colorClass: 'bg-orange-400', label: '90 (17%)', index: 9 },
		{ percentage: 60, colorClass: 'bg-orange-300', label: '60', index: 8 },
		{ percentage: 5, colorClass: 'bg-amber-200', label: '5', index: 7 },
		{ percentage: 200, colorClass: 'bg-yellow-100', label: '200', index: 6 },
		{ percentage: 180, colorClass: 'bg-lime-200', label: '180', index: 5 },
		{ percentage: 90, colorClass: 'bg-lime-300', label: '90', index: 4 },
		{ percentage: 60, colorClass: 'bg-green-400', label: '60', index: 3 },
		{ percentage: 5, colorClass: 'bg-green-600', label: '5', index: 2 },
		{ percentage: 5, colorClass: 'bg-emerald-800', label: '5', index: 1 },
	];

	return (
		<section className="flex flex-col flex-auto justify-center max-w-[320px] xl:max-w-[546px] items-center text-lg font-bold text-white whitespace-nowrap">
			<h2 className="text-xl leading-8 text-zinc-800 mb-4 montserrat max-md:text-[18px]">Candidates per Milestones</h2>
			{milestones.map((milestone, idx) => (
				<MilestoneBar
					key={idx}
					index={milestone.index}
					percentage={milestone.percentage}
					colorClass={milestone.colorClass}
					label={milestone.label}
				/>
			))}
		</section>
	);
};

export default MilestoneVisualization;