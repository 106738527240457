import React from 'react'
import InputField from '../../components/base/InputField'
import TextareaField from '../../components/base/TextareaField'
import FileInputField from '../../components/base/FileInputField'
import Editor from "../../components/CKEditor";

const PageEditorBlocks = ({ data, handleInputChange, preset }) => {
    const formatedLabel = (text) => {
        return (text.charAt(0).toUpperCase() + text.slice(1)).replaceAll("_", " ")
    }

    const getImageUrl = (image) => {
        return typeof image?.name === "string" ? URL.createObjectURL(image) : process.env.REACT_APP_API_URL + image;
    }
    return (
        <>
            {data.type === 'text' && (
                <InputField
                    label={formatedLabel(data.key)}
                    inputBg="bg-white border border-solid border-gray-300 rounded-none"
                    name={data.key}
                    id={data.key}
                    placeholder={data.value}
                    value={data.value}
                    onChange={(e) => handleInputChange(data.key, e.target.value, data.id, data)}
                />
            )}
            {data.type === 'textarea' && (
                <TextareaField
                    inputBg='bg-white border border-solid border-gray-300 rounded-none'
                    value={data.value}
                    onChange={(e) => handleInputChange(data.key, e.target.value, data.id, data)}
                />
            )}
            {data.type === 'image' && (
                <div className='flex flex-col gap-4'>
                    <label
                        className={"text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]"}>
                        {formatedLabel(data.key)}
                    </label>
                    <div className='flex items-center gap-5'>
                        <img
                            className='aspect-square object-contain w-20 mb-4'
                            src={getImageUrl(data.value)} alt={data.key} />
                        <FileInputField
                            name={data.key}
                            id={data.key}
                            handleChange={(file) => handleInputChange(data.key, file, data.id, data)}
                            value={data.value}
                        />
                    </div>
                </div>
            )}
            {data.type === 'html' && (
                <div className='flex flex-col gap-4'>
                    <label
                        className={"text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]"}>
                        {formatedLabel(data.key)}
                    </label>
                    <Editor wrprClassNames="" data={data.value} onChange={val => handleInputChange(data.key, val, data.id, data) }/>
                </div>
            )}
        </>
    )
}

export default PageEditorBlocks