import React from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import Checkbox from "../components/base/Checkbox";
import { Link } from "react-router-dom";
import ContactUsLinkBlock from "../components/parts/ContactUsLinkBlock";
import { HiringProcess } from "../components/base/ProgressStepsSBM";

const NextStepSection = () => {
    return (
        <section>
            <hr className="self-stretch w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className="flex gap-4 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap">
                <Link to={'/SBM02_1_4'} className="flex justify-center items-center px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px]">
                    Back
                </Link>
                <Link to={'/SBM02_3'} className="flex justify-center items-center px-16 py-1 h-[52px] bg-green-500 rounded-md w-full lg:max-w-[162px]">
                    Next
                </Link>
            </div>
        </section>
    );
};

// const ProgressBar = ({ completed, containerClasses = "" }) => {
//     const containerStyles = {
//         height: 4,
//         width: '100%',
//         backgroundColor: "#EFEEF0",
//     }

//     const fillerStyles = {
//         height: '100%',
//         width: `${completed}%`,
//         backgroundColor: '#003578',
//         borderRadius: 'inherit',
//         textAlign: 'right',
//         transition: 'width 1s ease-in-out',
//     }

//     const labelStyles = {
//         padding: 5,
//         color: 'white',
//         fontWeight: 'bold'
//     }

//     return (
//         <div style={containerStyles} className={containerClasses}>
//             <div style={fillerStyles}>
//                 <span style={labelStyles}>{`${completed}%`}</span>
//             </div>
//         </div>
//     );
// }

// const StepIndicatorItem = ({ stepNumber, label, isActive }) => {
//     const baseStyles = "justify-center items-center self-center w-10 h-10 text-xl leading-8 font-bold text-white border-4 border-white border-solid shadow-md rounded-[360px]";
//     const activeStyles = "bg-green-500";
//     const inactiveStyles = "bg-neutral-200";

//     return (
//         <div className="flex flex-col flex-auto max-w-40 px-2">
//             <div className={`${baseStyles} ${isActive ? activeStyles : inactiveStyles}`}>
//                 {stepNumber}
//             </div>
//             <div className="mt-3.5 text-base font-semibold text-neutral-600">
//                 {label.split("\n").map((line, index, arr) => (
//                     <React.Fragment key={index}>
//                         {line}{index < arr.length - 1 && <br />}
//                     </React.Fragment>
//                 ))}
//             </div>
//         </div>
//     );
// };

// const HiringProcess = ({ stepsData = [] }) => {
//     const mob = document.body.offsetWidth < 768;
//     const steps = stepsData;
//     const activeCount = steps.filter(step => step.isActive)
//     const progress = mob ? 50 : (activeCount.length / steps.length) * 100;

//     return (
//         <div className="relative flex shrink-0 flex-auto justify-between text-center">
//             <ProgressBar
//                 containerClasses='absolute z-[-1] top-[18px]'
//                 completed={progress}
//             />
//             {(mob ? steps.slice(activeCount.length - 1, activeCount.length + 1) : steps).map((step, index) => (
//                 <StepIndicatorItem
//                     key={index}
//                     stepNumber={step.stepNumber}
//                     label={step.label}
//                     isActive={step.isActive}
//                 />
//             ))}
//         </div>
//     );
// };

const SBM02_2 = () => {
    const steps = [
        { stepNumber: "1", label: "New Job Details", isActive: true },
        { stepNumber: "2", label: "Description", isActive: true },
        { stepNumber: "3", label: "Preview", isActive: true },
        { stepNumber: "4", label: "Pipelines", isActive: true },
        { stepNumber: "5", label: "Qualification Attributes", isActive: false },
        { stepNumber: "6", label: "Interview Questions", isActive: false },
        { stepNumber: "7", label: "Application Form", isActive: false },
        { stepNumber: "8", label: "Disposition Status", isActive: false }
    ];

    const data = [
        'Clarification',
        '1st Interview',
        `Post Interview <br/> Survey`,
        '2nd Interview',
        'Document<br/>Review',
        'Rating of the<br/> Job',
        'AI Evaluation',
        `Final Hiring<br/>Decisions`,
        `Training<br/>Insights`,
        `Offer<br/>Meeting`,
        `Training<br/>Milestones`
    ]

    const data2 = [
        '1st Interview',
        `Post Interview <br/> Survey`,
        '2nd Interview',
        'Rating of the<br/> Job',
        'AI Evaluation',
        `Final Hiring<br/>Decisions`,
        `Training<br/>Insights`,
        `Offer<br/>Meeting`,
        `Training<br/>Milestones`
    ]

    return (
        <>
            <BreadCrumbWhite crumbs={[
                'Subscriber Dashboard',
                'Jobs',
                'New Job Details',
                "Description",
                "Preview",
                "Pipelines"
            ]} />

            <div className='flex-auto'>
                <div className='flex flex-col max-w-[1432px] px-4 w-full pb-20 pt-10 lg:pt-14 lg:pb-24 mx-auto'>
                    <section className="flex w-full text-center overflow-auto mb-10 lg:mb-[60px]">
                        <HiringProcess stepsData={steps} />
                    </section>

                    <div className='text-center flex gap-3 items-center justify-center flex-col mb-10 lg:mb-[60px]'>
                        <span className='lg:text-[40px] text-4xl font-bold'>Let’s Get Started</span>
                        <span className='lg:text-[18px] text-base leading-[22px] lg:leading-[30px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet,<br />
                            bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut<br />
                            consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi.
                        </span>
                    </div>

                    <div className="border border-neutral-200 py-9 px-8 lg:px-[60px] lg:py-[50px] mb-20">
                        <div>
                            <div className="flex items-start gap-4">
                                <div className="flex">
                                    <Checkbox />
                                </div>
                                <span className="checkmark-label-optional font-semibold text-[#37B34A] text-lg lg:text-[26px]">Highly Recommended Pipeline - Bottle Tested & Optimized for Best Results</span>
                            </div>
                            <div className="lg:pl-12 mt-5 lg:mt-6 flex w-full overflow-auto">
                                {data.map((el, idx) =>
                                    <div
                                        key={idx}
                                        className=" bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7] h-[68px] px-2 max-lg:min-w-32 flex flex-auto items-center justify-center text-center">
                                        <span className='text-[#003578] text-base leading-5 font-semibold' dangerouslySetInnerHTML={{ __html: el }}></span>
                                    </div>
                                )}
                            </div>

                        </div>

                        <hr className="my-[50px] border-b border-neutral-200" />

                        <div>
                            <div className="flex items-start gap-4">
                                <div className="flex">
                                    <Checkbox />
                                </div>
                                <span className="checkmark-label-optional font-semibold text-lg lg:text-[26px]">Alternative Default Pipeline 2</span>
                            </div>
                            <div className="lg:pl-12 mt-5 lg:mt-6 flex w-full overflow-auto">
                                {data2.map((el, idx) =>
                                    <div key={idx} className=" bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7] h-[68px] px-2 max-lg:min-w-32 flex flex-auto items-center justify-center text-center">
                                        <span className='text-[#333333] text-base font-semibold' dangerouslySetInnerHTML={{ __html: el }}></span>
                                    </div>)}
                            </div>
                        </div>
                        
                        <ContactUsLinkBlock to="/SBM02_2_1"/>
                    </div>

                    <NextStepSection />

                </div>
            </div>
        </>
    )
}

export default SBM02_2