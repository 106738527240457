import { useState } from "react";
import Breadcrumbs from "../components/base/Breadcrumbs";
import InputField from "../components/base/InputField";
import BannerLight from "../components/parts/BannerLight";
import TextareaField from "../components/base/TextareaField";

import { ReactComponent as IconCheck } from "../assets/img/icon-check.svg"
import PrimaryButton from "../components/base/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentPage } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import PageLoader from "../components/PageLoader";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import api from "../api";
import { toast } from "react-toastify";
import bannerLightBg from '../assets/img/banner-light.png';

const validationSchema = object({
    firstName: string().required('Full Name is required'),
    lastName: string().required('Full Name is required'),
    email: string().email("Incorrect email format").required("Email is required"),
    message: string().required('Message is required').min(30, 'Message must be at least 30 symbols length'),
    companyName: string().required('Company Name is required')
});

const HOM_1_10 = () => {
    const currentPage = useSelector(getCurrentPage);
    const getValue = (key) => findValue(currentPage?.blocks, key);
    console.log(currentPage?.blocks)
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        message: '',
    });
    // const [errors, setErrors] = useState({});
    const [agree, setAgree] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Reset errors
    //     setErrors({});

    //     let tempErrors = {};

    //     if (!validateEmail(formData.email)) {
    //         tempErrors.email = 'Invalid email format';
    //     }

    //     // Here you can extend the validations for other fields as needed

    //     if (Object.keys(tempErrors).length > 0) {
    //         setErrors(tempErrors);
    //     } else {
    //         // Submit your form
    //         navigate("/HOM1_10_1")
    //         console.log('Form data:', formData);
    //     }
    // };

    const breadcrumbItems = [
        { name: 'Home', to: "/HOM1_1", isCurrent: false },
        { name: 'Contact', to: "/HOM1_10", isCurrent: true }
    ];
    const [policyError, setPolicyError] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    console.log(errors)
    const onSubmit = async (data) => {
        setPolicyError(false);
        if(!agree) {
            setPolicyError(true);
            return
        }
        console.log(data)
        const response = await api.users.publicContactUs(data);
        if(response.success) {
            toast.success('Message sent successfully!')
        } else {
            toast.error(response.message || 'Something went wrong')
        }
    };

    return (<>
        <div className="max-w-[1272px] w-full px-4 mx-auto pt-[20px] pb-[600px] lg:pb-[430px]">
            <Breadcrumbs links={breadcrumbItems} />
            <div className="flex justify-center items-center text-center px-16 pt-8 text-white max-lg:px-5">
                <div className="flex flex-col max-w-full">
                    <div className="text-5xl font-bold leading-[60px] max-lg:text-[40px max-lg:leading-[49px]">
                        {getValue('content_page_title')}
                    </div>
                    <div className="shrink-0 self-center mt-6 h-1 bg-green-500 w-[78px]" />
                    <div className="mt-6 text-2xl leading-9 text-center text-ellipsis">
                        {getValue('content_page_description')}
                    </div>
                </div>
            </div>
        </div>
        {currentPage ?
            <>
                <section className="flex flex-auto flex-col justify-between items-center px-[22px] pt-11 pb-[26px] lg:pb-[120px] bg-white max-w-lg:px-4">
                    <div className="flex lg:flex-row flex-col-reverse max-lg:gap-0 lg:-mt-[430px] -mt-[600px] w-full max-w-[1240px]">
                        <div className="flex flex-col flex-auto max-lg:ml-0 max-lg:w-full">
                            <form className="flex flex-col gap-[14px] lg:gap-5 grow px-14 py-12 w-full bg-white rounded-md shadow-lg max-lg:px-[32px] max-lg:max-w-full" onSubmit={handleSubmit(onSubmit)}>
                                <div className="flex flex-col lg:flex-row gap-[14px] lg:gap-5 justify-center text-lg font-bold text-zinc-800 max-lg:flex-wrap">
                                    <div className="flex flex-col grow shrink-0 basis-0 lg:w-fit">
                                        <InputField 
                                            label={getValue('content_page_form_first_name')}
                                            type="text"
                                            parentClassName={'mb-[0px]'}
                                            error={errors?.firstName?.message}
                                            {...register('firstName')}
                                        />
                                    </div>
                                    <div className="flex flex-col grow shrink-0 basis-0 lg:w-fit">
                                        <InputField 
                                            label={getValue('content_page_form_last_name')}
                                            type="text"
                                            parentClassName={'mb-[0px]'} 
                                            error={errors?.lastName?.message}
                                            {...register('lastName')}
                                        />
                                    </div>
                                </div>
                                <div className=" text-lg font-bold text-zinc-800 max-lg:max-w-full">
                                    <InputField 
                                        label={getValue('content_page_form_company')}
                                        type="text"
                                        error={errors?.companyName?.message}
                                        {...register('companyName')}
                                    />
                                </div>
                                <div className="text-lg font-bold text-zinc-800 max-lg:max-w-full">
                                    <InputField 
                                        label={getValue('content_page_form_email')} 
                                        type="email" 
                                        error={errors?.email?.message}
                                        {...register('email')}
                                    />
                                </div>
                                <div className="text-lg font-bold text-zinc-800 max-lg:max-w-full">
                                    <TextareaField 
                                        label={getValue('content_page_form_message')} 
                                        type="text"
                                        error={errors?.message?.message}
                                        {...register('message')}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex justify-start mt-8">
                                        <input type="checkbox" id="agreement" checked={agree} className="hidden"
                                            onChange={(e) => {
                                                setAgree(e.target.checked);
                                            }} />
                                        <label htmlFor="agreement" className="flex items-center cursor-pointer lg:gap-4 gap-[10px]">
                                            <span className={`flex h-[28px] w-[58px] basis-[58px] shrink-0 items-center rounded-full aspect-square p-1 transition-all ${agree ? 'bg-[#37B34A]' : 'bg-gray-300'}`} aria-hidden="true">
                                                <span className={`h-[20px] w-[20px] rounded-full transition-all ${agree ? 'ml-[30px] bg-white' : 'bg-[#37B34A]'}`}></span>
                                            </span>
                                            <span className="ml-2 text-[16px] text-zinc-800">By selecting this, you agree to the <a href="/" className="text-blue-500">Privacy Policy</a></span>
                                        </label>
                                    </div>
                                    {policyError && <p className="text-red-500 text-sm mt-1">You must agree the Privacy Policy</p>}
                                    <button type="submit" className="justify-center items-center px-16 py-[14px] lg:py-6 mt-8 text-2xl font-bold text-center text-white uppercase whitespace-nowrap bg-green-500 rounded-md max-lg:px-5 max-lg:max-w-full">
                                        {getValue('content_page_form_submit')}
                                    </button>
                                </div>
                            </form>
                            <div className="lg:hidden flex flex-col justify-center text-center items-center py-[44px] text-zinc-800 max-lg:max-w-full">
                                <div className="text-4xl leading-7 font-bold">
                                {getValue('title_support_block')}
                                </div>
                                <div className="mt-9 text-xl leading-8">
                                {getValue('description_support_block')}
                                </div>
                                <PrimaryButton className={"mt-9 uppercase w-full"}>{getValue('button_support_block')}</PrimaryButton>
                            </div>
                        </div>
                        <div className="flex flex-col shrink-0 lg:w-[450px] lg:basis-[450px] max-lg:w-full">
                            <div className="flex flex-col grow self-stretch max-lg:max-w-full">
                                <div className="flex flex-col justify-center pb-[64px] lg:py-16  pl-14 text-xl leading-8 text-white max-lg:pl-0 max-lg:max-w-full">
                                    <div className="lg:text-4xl text-[28px] mb-[24px] lg:mb-9 font-bold max-lg:leading-[30px] text-center lg:text-start">
                                        {getValue('content_page_title_list_helps')}
                                    </div>
                                    {getValue('help_items')?.data?.map((item, index) => <div key={index} className="flex gap-2.5 lg:mt-8 mt-[12px]">
                                        <IconCheck />
                                        <div className="flex-auto">{item.item}</div>
                                    </div>)}
                                </div>
                                <div className="hidden lg:flex flex-col justify-center py-16 pl-14 text-zinc-800 max-lg:pl-5 max-lg:max-w-full">
                                    <div className="text-4xl leading-7 font-bold">
                                        {getValue('title_support_block')}
                                    </div>
                                    <div className="mt-9 text-xl leading-8">
                                        {getValue('description_support_block')}
                                    </div>
                                    <button
                                        onClick={() => { }}
                                        className="justify-center px-10 py-8 mt-9 font-bold text-2xl text-center text-white uppercase bg-green-500 rounded-md max-lg:px-5">
                                        {getValue('button_support_block')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="flex flex-col justify-center text-center relative">
                    <div className="flex overflow-hidden flex-col items-center py-20 w-full min-h-[402px] max-md:px-5 max-md:max-w-full mx-auto max-w-[1080px]">
                        <h2 className="relative mt-2.5 lg:text-[50px] lg:leading-[52px] text-[40px] font-bold text-center leading-[49px] w-full">
                            {getValue('footer_content_title') || 'Lorem ipsum dolor sit amet consectetur'}
                        </h2>
                        <div className="mt-6 w-full text-lg lg:leading-[28px] leading-[30px] text-center text-stone-500 max-md:max-w-full">
                            {getValue('footer_content_description') ||
                            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare
                            scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida
                            imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit
                            non convallis magna scelerisque.`}
                        </div>
                        <Link
                        to={"/HOM1_5"}
                            tabIndex="0"
                            className="relative flex justify-center items-center px-16 py-6 max-md:py-3 mt-11 lg:mt-7 lg:text-2xl text-[19px] max-lg:w-full font-bold whitespace-nowrap bg-[#37B34A] rounded-md w-[276px] max-md:px-5 h-[70px] max-md:h-[60px] text-white hover:opacity-70"
                        >
                            {getValue('footer_content_button') || 'Book A Demo'}
                        </Link>
                    </div>
                    <img
                        src={bannerLightBg}
                        className="object-cover absolute inset-0 size-full -z-10 max-md:left-[0px]"
                        alt='banner'
                    />
                </div>
            </>
            :
            <div className="lg:-mt-[300px] -mt-[450px]">
                <PageLoader color="light"  />
            </div>

        }
    </>);
}

export default HOM_1_10;