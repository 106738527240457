import React, { useEffect } from 'react';
import Breadcrumbs from "../components/base/Breadcrumbs";
import BannerGift from "../components/parts/BannerGift";
import SidebarAnhors from "../components/parts/SidebarAnhors";
import { useSelector } from 'react-redux';
import { getCurrentPage } from '../redux/slices/applicationSlice';
import { useLocation } from 'react-router-dom';
import findValue from '../assets/helpers/findValue';
import PageLoader from '../components/PageLoader';

const HOM_1_13 = () => {
	const currentPage = useSelector(getCurrentPage);
    const { hash } = useLocation();
	
	useEffect(() => {
		const el = document.getElementById(decodeURI(hash.substring(1)));
		if(!el) return;
		el.scrollIntoView({behavior: 'smooth'})
	}, [hash])
	const getValue = (key) => findValue(currentPage?.blocks, key);
	const pageItemsData = getValue('content_page_items')?.data || [];
	if(!currentPage) return <PageLoader />
	return (
		<>
			<div className={`
				box-border flex pt-[120px] self-stretch w-full bg-center bg-no-repeat bg-cover
				lg:h-[584px]
				h-[500px]	
				bg-[url(https://cdn.builder.io/api/v1/image/assets%2F9106ba04c02e4dceae488e75e2ac4862%2F14a2333ed5504c789df293d766cde515)]
			`}
				style={{ zIndex: "-1" }}>
				<div className="max-w-[1280px] flex flex-col w-full px-5 mx-auto pt-6 lg:pt-[60px]">
					<Breadcrumbs links={[
						{ name: 'Home', to: "/HOM1_1" },
						{ name: 'Security', to: "/HOM1_13", isCurrent: true }
					]} />
					<div className="flex justify-center max-lg:flex-auto lg:mt-8 items-center text-center text-white whitespace-nowrap">
						<div className="flex flex-col">
							<div className="lg:text-5xl lg:leading-[60px] leading-[49px] text-[40px] font-bold max-md:text-4xl">
								{getValue('content_page_title')}
							</div>
							<div className="shrink-0 self-center mt-6 h-1 bg-green-500 w-[78px]" />
							<div dangerouslySetInnerHTML={{__html: getValue('content_page_description')}} />
						</div>
					</div>
				</div>
			</div>
			<div className='max-w-[1280px] px-5 mx-auto pt-11 lg:pt-[80px] pb-[120px]'>
				<div className='flex max-lg:flex-col gap-[50px] gap-y-[80px]'>
					<SidebarAnhors
						anhors={pageItemsData.map(({title}) => ({
							id: title,
							name: title
						}))}
					/>
					<div className="flex flex-col lg:text-3xl text-2xl font-bold lg:leading-8 leading-[28px] max-w-[810px] text-zinc-800 gap-[44px]">
						{pageItemsData.map(({title, content}) => <div key={title}>
							<div className="w-full max-md:max-w-full scroll-m-4" id={title}>{title}</div>
							<div dangerouslySetInnerHTML={{__html: content}} />
						</div>)}
					</div>
				</div>
			</div>
			<BannerGift />
		</>

	)
}

export default HOM_1_13