import axios from "axios";
import { apiConfig } from "./config";
import { auth } from "./parts/auth";
import { feedbacks } from "./parts/feedbacks";
import { users } from "./parts/users";
import { meetings } from "./parts/meetings";
import { jobApplications } from "./parts/jobApplications";
import { jobs } from "./parts/jobs";
import { surveys } from "./parts/surveys";
import { companies } from './parts/companies'
import { summary } from "./parts/summary";
import { companyTeam } from "./parts/companyTeam";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { support, supportSolutions } from "./parts/support";
import { pages } from "./parts/pages";
import { publicSolution } from "./parts/publicSolution";
import { supportSolution } from "./parts/supportSolution";
import { publicSolutions } from "./parts/public";
import { trainingMilestones } from "./parts/trainingMilestones";
import { communications } from "./parts/communications";
import { jobRatings } from "./parts/jobRatings";
import { applicationReferences } from "./parts/applicationReferences";
import { offerTemplates } from "./parts/offerTemplates";
import { jobApplicationDocuments } from "./parts/jobApplicationDocuments";
import { jobVideoInterview } from "./parts/jobVideoInterview";
import { hiringAlgorithm } from "./parts/hiringAlgorithm";
import { quantitativeAnalysis } from "./parts/quantitativeAnalysis";

const apiInstance = axios.create(apiConfig);
apiInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

const api = {
    auth: auth(apiInstance),
    feedbacks: feedbacks(apiInstance),
    users: users(apiInstance),
    meetings: meetings(apiInstance),
    jobApplications: jobApplications(apiInstance),
    jobs: jobs(apiInstance),
    surveys: surveys(apiInstance),
    companies: companies(apiInstance),
    summary: summary(apiInstance),
    companyTeam: companyTeam(apiInstance),
    support: support(apiInstance),
    pages: pages(apiInstance),
    publicSolution: publicSolution(apiInstance),
    supportSolution: supportSolution(apiInstance),

    supportSolutions: supportSolutions(apiInstance),
    publicSolutions: publicSolutions(apiInstance),
    trainingMilestones: trainingMilestones(apiInstance),
    communications: communications(apiInstance),
    jobRatings: jobRatings(apiInstance),
    applicationReferences: applicationReferences(apiInstance),
    offerTemplates: offerTemplates(apiInstance),
    jobApplicationDocuments: jobApplicationDocuments(apiInstance),
    jobVideoInterview: jobVideoInterview(apiInstance),
    hiringAlgorithm: hiringAlgorithm(apiInstance),
    quantitativeAnalysis: quantitativeAnalysis(apiInstance),
}

export default api;
