import React, {useEffect, useState} from "react";

export const VariantsQuestion = ({config, value, onChange, number, error, labelClassName = '', disabled}) => {
    const template = JSON.parse(config.answerTemplate);
  
    return <div className='flex gap-[24px] w-full flex-col max-lg:gap-[16px]'>
        <div
            className={`flex-auto my-auto text-lg font-semibold leading-7 text-[#333333] max-md:max-w-full ${labelClassName} ${error ? 'text-red-600' : null}`}
        >
            {number ? number + '. ' : null} 
            {config.question}
            {config.isRequired && <span className="text-teal-500">*</span>}
        </div>
        {template.type === 'radio' &&
            <div className="flex items-center justify-between gap-[16px]">
                {template.answers.map((item, index) => 
                    <label
                        key={config.id + index}
                        className="cursor-pointer custopm-radio flex items-center font-medium"
                    >
                        <input
                            type="radio"
                            className="radio-green-sub aspect-square w-5 mr-[10px]"
                            name={'variants-questions'+ config.id} 
                            onChange={() => onChange(item.answer)}
                            checked={value === item.answer}
                            hidden
                            disabled={disabled}
                        />
                        <span className="check-box-fake mr-[12px]"></span>
                        <p>{item.answer}</p>
                    </label>
                )}
            </div>
        }
    </div>
}