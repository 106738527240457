import React, { useEffect } from "react";
import { Footer } from "../components/parts/Footer";
import { useState } from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import {crumbsConfig} from "./SBM01_Form/config";
import { useParams } from "react-router-dom";
import api from "../api";
import PageLoader from "../components/PageLoader";
import { average } from "../utils";

const SBM01_2_3 = () => {
    const { jobId, applicationId } = useParams();

    const [jobData, setJobData] = useState(null);

    const [applicationData, setApplicationData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const getJobApplicationById = async () => {
        setIsLoading(true)
        const jobRes = await api.jobs.getJobsById(jobId);
        setJobData(jobRes?.data);

        const res = await api.jobApplications.getJobApplicationById(jobId, applicationId);
        setApplicationData(res?.data);
        setIsLoading(false)
    }

    useEffect(() => {
        getJobApplicationById();
    }, [])
    const avgAssessments = average(applicationData?.hiringAssessments?.assessments?.assessments, 'score')
    const avgXScores =  average(applicationData?.hiringAssessments?.assessments?.xscores, 'score')
    const asigmantsData = [
        {
            id: '12234',
            title: 'Assessments',
            
            category: applicationData?.hiringAssessments?.assessments?.assessments.map(item => ({ id: item.profile, categoryName: item.description, value: item.score + '%' })),
            isActive: true,
            color: avgAssessments < 30 ? 'bg-[#D43234]' : avgAssessments >= 60 ? 'bg-[#37B34A]' : 'bg-[#FFCC00]',
            totalScore: avgAssessments + '%'
        },
        {
            title: 'XScores (1-10)',
            id: '67878',
            category: applicationData?.hiringAssessments?.assessments?.xscores.map(item => ({ id: item.id, categoryName: item.description, value: item.score })),
            isActive: true,
            totalScore: avgXScores,
            color: avgXScores < 4 ? 'bg-[#D43234]' : avgXScores >= 8 ? 'bg-[#37B34A]' : 'bg-[#FFCC00]',
        }
    ]
    // const categories1 = ["Category", "Category", "Category", "Category", "Category", "Category"];
    // const categories2 = ["Category", "Category", "Category", "Category", "Category", "Category"];
    const CategoryScore = ({ category, score }) => (
        <div className="flex gap-5 justify-between mt-6 max-w-full whitespace-nowrap w-[400px]">
            <div className="flex-auto my-auto text-xl font-bold leading-7 overflow-hidden text-ellipsis">{category}</div>
            <div className="flex items-center justify-center px-2 py-1 lg:h-[54px] h-[52px] text-lg min-w-[96px] text-center bg-white border border-solid border-zinc-300 max-md:px-5">
                {score}
            </div>
        </div>
    );
    const TotalScore = ({ title, score, isGreen, color }) => (
        <div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
            <div className="grow flex justify-center items-center px-16 lg:h-[54px] h-[52px] font-semibold bg-neutral-100 w-fit">
                {title}
            </div>
            <div
                className="flex gap-2 justify-center items-center px-5 py-1 min-w-[96px] lg:h-[54px] h-[52px] bg-white border border-solid border-zinc-300"
            >
                {isGreen && (
                    <div className={`shrink-0 my-auto w-3 h-3 ${color} rounded-[360px]`} />
                )}
                <div>{score}</div>
            </div>
        </div>
    );
    const Assessment = ({ title, categories, totalScore, isGreen, color }) => (
        <>
            <div className="self-stretch mt-16 text-[28px] font-bold leading-8 max-md:mt-10 max-md:max-w-full">
                {title}
            </div>
            <div className="shrink-0 self-stretch mt-9 h-px border border-solid bg-neutral-200 border-neutral-200 max-md:max-w-full" />
            {categories.map((item, index) => (
                <CategoryScore key={item.id} category={item.categoryName} score={item.value} />
            ))}
            <TotalScore title="Total Score" score={totalScore} isGreen={isGreen} color={color} />
        </>
    );
    return (
        isLoading ? <PageLoader /> : applicationData ?
        <>

            <BreadCrumbWhite crumbs={[crumbsConfig.sub, crumbsConfig.jobs, crumbsConfig.applicant, crumbsConfig.spector, 'Assessment']} />
            <section className="flex justify-center flex-auto py-9 lg:py-11 w-full text-zinc-800 max-md:max-w-full max-md:pb-[80px]">
                <div className="flex flex-col w-full max-w-[1432px] px-4 max-md:max-w-full">
                    <div className="self-stretch text-4xl max-lg:text-center font-bold">{applicationData.fullName}</div>
                    {applicationData?.hiringAssessments ? 
                    asigmantsData.map(item => (
                        <Assessment key={item.id} title={item.title} categories={item.category} isGreen={item.isActive} totalScore={item.totalScore} color={item.color}/>
                    )) : <p className="text-center text-3xl">No Hiring Assessments Data</p>}
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>: null
    )
}

export default SBM01_2_3