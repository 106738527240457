import React, { useState } from "react";
import { ReactComponent as ChevronRight } from "../../assets/img/chevron-right.svg"
import { ReactComponent as ListIcon } from "../../assets/img/list-icon.svg"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";

export const initialAnswers = [
    { number: 1, isDone: false },
    { number: 2, isDone: false },
    { number: 3, isDone: false },
    { number: 4, isDone: false },
    { number: 5, isDone: false },
    { number: 6, isDone: false },
    { number: 7, isDone: false },
]

export const RegisterApplicantSteps = ({ step }) => {
    const size = useWindowSize();
    const { id } = useParams();
    const mob = size.width < 1200;
    const [isNavOpen, setIsNavOpen] = useState(false)

    const navigate = useNavigate()
    const { pathname } = useLocation();
    const jobLink = `/job/${id}`;

    const sectionItems = [
        { number: 1, content: "It Takes 15 Minutes", route: `${jobLink}/it-takes-x-minutes` },
        { number: 2, content: "Consent (E-signature, terms of conditions, phone texting, how did you hear about us)", route: `${jobLink}/consent`},
        { number: 3, content: "You! (education, most recent employment history, reference, referrals or relatives, job behavior)", route: `${jobLink}/you!` },
        { number: 4, content: "Critical Requirements (availability)", route: `${jobLink}/critical-requirement` },
        { number: 5, content: "Hiring Assessment", route: `${jobLink}/hiring-assesment` },
        { number: 6, content: "Mandatory - You Must Attach or Copy/Paste your Resume Here", route: `${jobLink}/you-must-submit-your-resume-here` },
        { number: 7, content: "The Next Step", route: `${jobLink}/the-next-step` },
    ]
    const currentItem = sectionItems.find(item => item.number === step)
    
    const rawSteps = localStorage.getItem(`job-${id}-steps`)
    const steps = rawSteps ? JSON.parse(rawSteps) : initialAnswers;
    console.log(steps)
    const SectionItem = ({ number, content, isCurrent, isDone, hideBb = false, classes = "", onClick = () => { } }) => {
        return (
            <>
                <div
                    onClick={() => isDone && onClick()}
                    className={`flex cursor-pointer items-center text-base lg:text-lg  gap-3.5 py-4 lg:py-[18px] lg:mt-6 leading-[26px] lg:leading-[30px] ${classes}`}
                >
                    {
                        mob && isDone ?
                            <span className="flex w-26 aspect-square">
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.5641 4.73015L20.7711 3.5114C20.275 3.17546 19.5953 3.30437 19.2633 3.79656L10.4742 16.7575L6.43518 12.7184C6.01331 12.2966 5.32581 12.2966 4.90393 12.7184L3.36877 14.2536C2.9469 14.6755 2.9469 15.363 3.36877 15.7887L9.57971 21.9997C9.92737 22.3473 10.4742 22.613 10.9664 22.613C11.4586 22.613 11.9547 22.3044 12.275 21.8395L22.8531 6.23406C23.1891 5.74187 23.0602 5.06609 22.5641 4.73015Z" fill="#06D6A0" />
                                </svg>
                            </span>
                            :
                            <div className={`flex justify-center items-center self-start px-2.5 font-semibold text-white whitespace-nowrap ${isDone ? '!bg-black' : ''} ${isCurrent ? 'bg-[#06D6A0]' : 'bg-[#333333] lg:bg-zinc-300'} rounded-[360px] w-[26px] h-[26px] lg:h-[30px] lg:w-[30px]`}>
                                {number}
                            </div>
                    }
                    <div
                        className={`flex-auto ${isDone ? 'font-semibold' : ''}
                     ${(isCurrent && !mob) ? 'text-[#06D6A0] font-semibold' : 'text-zinc-800'}
                      max-lg:font-semibold max-lg:leading-[20px]
                      ${(!isNavOpen && mob) ? "line-clamp-2" : ""}`
                        }
                    >
                        {content}
                    </div>
                    {
                        mob && isNavOpen ?
                            <span className="flex w-6 aspect-square">
                                <ChevronRight />
                            </span>
                            :
                            isDone ?
                                <span className="flex w-[26px] aspect-square">
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M22.5641 4.73015L20.7711 3.5114C20.275 3.17546 19.5953 3.30437 19.2633 3.79656L10.4742 16.7575L6.43518 12.7184C6.01331 12.2966 5.32581 12.2966 4.90393 12.7184L3.36877 14.2536C2.9469 14.6755 2.9469 15.363 3.36877 15.7887L9.57971 21.9997C9.92737 22.3473 10.4742 22.613 10.9664 22.613C11.4586 22.613 11.9547 22.3044 12.275 21.8395L22.8531 6.23406C23.1891 5.74187 23.0602 5.06609 22.5641 4.73015Z" fill="#06D6A0" />
                                    </svg>
                                </span>
                                : null
                    }

                </div>
                {!hideBb ? <div className="shrink-0 h-px border border-solid lg:border-dashed  border-stone-300" /> : null}
            </>
        )
    }

    return <>
        <aside className="flex flex-col flex-shrink-0 w-[35%] max-md:ml-0 max-md:w-full">
            <div className="flex lg:flex-col grow">
                <header>
                    <h2 className="text-[56px] max-md:leading-[68px] lg:text-6xl font-light leading-[60px] text-zinc-800">
                        What <br /> to Expect?
                    </h2>
                </header>
                <div className="max-lg:fixed z-10 top-[90px] left-0 right-0 max-lg:px-[22px] max-lg:bg-[#F7F7F7] max-lg:shadow-job-steps">
                    {
                        isNavOpen || !mob ?
                            <>
                                <div className="flex items-center justify-between pt-[18px] pb-2 lg:hidden">
                                    <h6 className="tracking-wider text-[#ACACAC] text-sm font-semibold uppercase">Navigation</h6>
                                    <button
                                        onClick={() => setIsNavOpen(false)}
                                        className="flex w-8 aspect-square"
                                    >
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.667 10.667L21.3337 21.3337" stroke="#ACACAC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M21.3337 10.667L10.667 21.3337" stroke="#ACACAC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                                {sectionItems.map((item, index) => (
                                    <SectionItem key={item.number} onClick={() => navigate(item.route)} isCurrent={step === item.number} number={item.number} content={item.content} 
                                    isDone={steps.find(({number}) => number === item.number).isDone} hideBb={index === (sectionItems?.length - 1)} />
                                ))}
                            </>
                            :
                            <div className="flex items-center justify-between gap-5">
                                {
                                    <SectionItem isCurrent={true} number={currentItem.number} content={currentItem.content} isDone={false} hideBb={true} />
                                }
                                <button
                                    onClick={() => setIsNavOpen(true)}
                                    className="flex items-center w-8 aspect-square"
                                >
                                    <ListIcon />
                                </button>
                            </div>
                    }
                </div>
            </div>
        </aside>
    </>
}