import * as React from "react";
import Confirmation from "../components/Confirmation";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { useSelector } from "react-redux";
import { getCurrentPage } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import PageLoader from "../components/PageLoader";
import { useLocation } from "react-router-dom";

const HOM1_5_1 = ({text}) => {
  const { state } = useLocation();
  console.log(state)
  const currentPage = useSelector(getCurrentPage);
  const getValue = (key) => findValue(currentPage?.blocks, key);
  console.log(text, currentPage)
  const breadcrumbItems = [
    { name: 'Home', to: "/HOM1_1", isCurrent: false },
    { name: 'Book a Demo', to: "/HOM1_5", isCurrent: false },
    { name: state.success ? 'Thank You' : 'Error', to: "/HOM1_5_1", isCurrent: true },
  ];
  const message = state.success ? (text || getValue('description_title_content_block')) : state.message
  console.log(message)
  const title = state.success ? getValue('title_content_block') : 'Error'
  return (!currentPage ? <PageLoader color="light" /> : <>
    <div>
      <Breadcrumbs
        links={breadcrumbItems}
      />
          <Confirmation colorStyle="white" text={message} title={title}/>
    </div>

  </>)
};

export default HOM1_5_1;