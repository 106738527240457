import {useState} from "react";
import HOM1_5 from "./HOM1_5";
import HOM1_5_1 from "./HOM1_5_1";
import { useNavigate } from "react-router-dom";

const WrapperHOM1_5 = () => {

    const [step, setStep] = useState(1)
    const [message, setMessage] = useState('')
    const navigate = useNavigate();
    return (
        <>
            {step === 1 && <HOM1_5 setMessage={(message) => {
                navigate('/HOM1_5_1')
            }} />}
            {/* {step === 2 && <div className='max-w-[1272px] px-4 mx-auto'><HOM1_5_1 text={message} /></div>} */}
        </>
    )

}

export default WrapperHOM1_5