import React, { useState } from 'react';
import TextareaField from '../../components/base/TextareaField';
import magicWandIcon from "../../assets/img/magic-wand.svg";

const Step2 = ({ payload, setPayload, inComponent = false, bottomPaste = null }) => {
    const whyWorkWithUsImages = [
        require('../../assets/img/book.png'),
        require('../../assets/img/pay.png'),
        require('../../assets/img/rocket.png'),
        require('../../assets/img/calendar.png'),
        require('../../assets/img/clock.png'),
        require('../../assets/img/money.png'),
        require('../../assets/img/dollar.png'),
        require('../../assets/img/covid.png'),
        require('../../assets/img/company-cares.png'),
        require('../../assets/img/fantastic-team.png'),
    ];

    const qualificationImages = [
        require('../../assets/img/like.png'),
        require('../../assets/img/heart.png'),
        require('../../assets/img/speak.png'),
        require('../../assets/img/drug-free.png'),
        require('../../assets/img/diploma.png'),
    ];

    const locationImages = [
        require('../../assets/img/car.png'),
        require('../../assets/img/heart.png'),
    ];

    return (
        <>
            <div className={`flex flex-col items-center w-full ${inComponent ? '' : 'mt-10'}`}>
                { !inComponent ? <div className='mx-auto text-center flex flex-col gap-2 mb-11'>
                    <div className="text-4xl font-bold text-zinc-800">
                        Create the Job Description
                    </div>
                    <p className='max-w-[783px] mx-auto'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum.
                        Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.
                        Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra.
                    </p>
                </div> : null }
                <div className={`flex flex-col w-full bg-white rounded-md ${inComponent ? 'gap-10' : 'p-[44px] lg:px-[60px] border border-solid border-[#D6D6D6] max-md:px-[32px] max-md:py-[36px]'}`}>
                    {/* <h2 className='text-[30px] font-bold text-[#333333] max-md:text-[28px] mb-9 lg:mb-11'>Job Details</h2> */}
                    <div className='flex items-center justify-between max-md:flex-col max-md:items-start'>
                        <h2 className={`text-[30px] font-bold text-[#333333] max-md:text-[28px] ${inComponent ? 'max-md:mb-[36px]' : 'mb-9 lg:mb-11'}`}>Job Description</h2>
                        { inComponent ? <a href="#" className='flex items-center gap-[10px] hover:opacity-70'>
                            <img src={magicWandIcon} alt='wand icon'/>
                            <p className='text-lg text-[#37B34A] max-md:text-[16px]'>Use our Job Description Wizard</p>
                        </a> : null }
                    </div>
                    <TextareaField
                        className='!bg-white py-[40px] px-[36px] border border-solid border-[#D6D6D6] rounded-none max-md:p-[20px] max-md:mb-[0px]'
                        value={payload.description}
                        onChange={(e) => setPayload({ ...payload, "description": e.target.value })}
                        height={330}
                    />

                    <div className="flex flex-col w-full max-w-[720px]">
                        <section className="flex flex-col pt-11 mt-24 border-t border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
                            <h2
                                className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800" contentEditable={true}
                                onBlur={(e) => setPayload({ ...payload, mainInfo: { ...payload.mainInfo, whyWorkWithUsTitle: e.target.textContent } })}
                            >
                                { payload.mainInfo.whyWorkWithUsTitle }
                            </h2>
                            <div className="mt-11 max-md:mt-10 max-md:max-w-full flex gap-5 flex-col">
                                { payload.mainInfo.whyWorkWithUsItems.map((item, index) => (
                                    <div className="flex gap-6 justify-between max-md:flex-wrap max-md:max-w-full" key={index}>
                                        <img loading="lazy" src={whyWorkWithUsImages[index]} alt="" className="self-start w-[65px] h-[65px] object-contain" />
                                        <div className="flex flex-col flex-1 max-md:max-w-full">
                                            <div
                                                className="text-xl font-bold leading-8 max-md:max-w-full" contentEditable={true}
                                                onBlur={(e) => setPayload({ ...payload, mainInfo: {
                                                    ...payload.mainInfo,
                                                    whyWorkWithUsItems: payload.mainInfo.whyWorkWithUsItems.map((contentItem, contentItemIndex) => contentItemIndex === index ? { ...contentItem, title: e.target.textContent } : contentItem)
                                                }})}
                                            >
                                                { item.title }
                                            </div>
                                            <div
                                                className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full" contentEditable={true}
                                                onBlur={(e) => setPayload({ ...payload, mainInfo: {
                                                    ...payload.mainInfo,
                                                    whyWorkWithUsItems: payload.mainInfo.whyWorkWithUsItems.map((contentItem, contentItemIndex) => contentItemIndex === index ? { ...contentItem, description: e.target.textContent } : contentItem)
                                                }})}
                                            >
                                                { item.description }
                                            </div>
                                        </div>
                                    </div>
                                )) }
                            </div>
                        </section>

                        <section className="flex flex-col pt-11 mt-24 border-t border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
                            <h2
                                className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800" contentEditable={true}
                                onBlur={(e) => setPayload({ ...payload, mainInfo: { ...payload.mainInfo, qualificationsTitle: e.target.textContent } })}
                            >
                                { payload.mainInfo.qualificationsTitle }
                            </h2>
                            <div className="grid grid-cols-3 gap-10 mt-10 w-full max-md:grid-cols-1">
                                { payload.mainInfo.qualificationsItems.map((item, index) => (
                                    <div className="flex lg:flex-col gap-3 max-lg:gap-5 items-center grow text-lg leading-8 whitespace-nowrap text-zinc-800" key={index}>
                                        <img loading="lazy" src={qualificationImages[index]} alt="" className="self-center w-[65px] h-[65px] object-contain" />
                                        <div
                                            contentEditable={true}
                                            onBlur={(e) => setPayload({ ...payload, mainInfo: {
                                                ...payload.mainInfo,
                                                qualificationsItems: payload.mainInfo.qualificationsItems.map((contentItem, contentItemIndex) => contentItemIndex === index ? e.target.textContent : contentItem)
                                            }})}
                                        >{ item }</div>
                                    </div>
                                )) }
                            </div>
                        </section>

                        <section className="flex flex-col items-start pt-11 mt-11 text-lg leading-8 border-t border-solid border-t-stone-300 text-zinc-800 max-md:mt-10 max-md:max-w-full">
                            <h2
                                className="lg:text-3xl text-[28px] leading-[38px] font-bold lg:leading-10 text-zinc-800" contentEditable={true}
                                onBlur={(e) => setPayload({ ...payload, mainInfo: { ...payload.mainInfo, locationRequisitesTitle: e.target.textContent } })}
                            >
                                { payload.mainInfo.locationRequisitesTitle }
                            </h2>
                            <div className="mt-8 max-md:max-w-full flex gap-[26px] flex-col">
                                { payload.mainInfo.locationRequisitesItems.map((item, index) => (
                                    <div className="flex gap-6 justify-between max-md:flex-wrap max-md:max-w-full">
                                        <img loading="lazy" src={locationImages[index]} alt="" className="self-start w-[65px] h-[65px] object-contain" />
                                        <div className="flex flex-col flex-1 max-md:max-w-full">
                                            <div
                                                className="text-xl font-bold leading-8 max-md:max-w-full" contentEditable={true}
                                                onBlur={(e) => setPayload({ ...payload, mainInfo: {
                                                    ...payload.mainInfo,
                                                    locationRequisitesItems: payload.mainInfo.locationRequisitesItems.map((contentItem, contentItemIndex) => contentItemIndex === index ? { ...contentItem, title: e.target.textContent } : contentItem)
                                                }})}
                                            >
                                                {item.title}
                                            </div>
                                            <div
                                                className="whitespace-pre-wrap mt-1.5 text-lg font-light leading-8 max-md:max-w-full" contentEditable={true}
                                                onBlur={(e) => setPayload({ ...payload, mainInfo: {
                                                    ...payload.mainInfo,
                                                    locationRequisitesItems: payload.mainInfo.locationRequisitesItems.map((contentItem, contentItemIndex) => contentItemIndex === index ? { ...contentItem, description: e.target.textContent } : contentItem)
                                                }})}
                                            >
                                                {item.description}
                                            </div>
                                        </div>
                                    </div>
                                )) }
                            </div>
                            <p
                                className="mt-[32px] text-lg text-[#333333] font-normal" contentEditable={true}
                                onBlur={(e) => setPayload({ ...payload, mainInfo: { ...payload.mainInfo, locationRequisitesDescription: e.target.textContent } })}
                            >
                                { payload.mainInfo.locationRequisitesDescription }
                            </p>
                        </section>
                    </div>

                    { bottomPaste }
                </div>
            </div>
        </>
    )
}

export default Step2