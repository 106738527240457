import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { EvaluationInterview } from '../../../components/parts/EvaluationsInterview';
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';


const Scores = () => {

    const navigate = useNavigate()
    const [answersConfig, setAnswersConfig] = useState({})

    const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
    useEffect(() => {
        setActiveStep(7)
    }, [])


    const handlePrev = () => {
        navigate(`/SBM01_10_2/${jobId}/${applicationId}`)
    }
    const handleNext = () => {
        navigate(`/SBM01_10_4/${jobId}/${applicationId}`)
    }




    useEffect(() => {
        const assessments = application?.criticalRequirements ? Object.keys(application?.criticalRequirements) : null
        if(!assessments.length) return
        

        const details = assessments.map(item => application?.criticalRequirements?.[item]).flat() 
        const answersScores = assessments.map(item => application?.assessments?.[item]?.scores).flat()

        const qualityQuestions = details?.filter(item => item?.question?.qualityAttribute).map(item => ({ qualityAttribute: item?.question?.qualityAttribute }))
        
        if (!qualityQuestions?.length) return

        const obj = {}
        const arr = qualityQuestions.reduce((res, curr, index) => {
            const key = res.find(r => r.key === curr?.qualityAttribute?.key)
            const score = { ...curr, score: answersScores?.[index] }
            if (key) {
                key.stack.push(score)
            } else {
                res.push({
                    key: curr?.qualityAttribute?.key,
                    stack: [score],
                    name: curr?.qualityAttribute?.name,
                })
            }
            return res
        }, []).map(item => {
            let avg = (item?.stack || []).reduce((r, it) => r + parseFloat(it.score), 0) / (item?.stack || []).length;
            return { ...item, avg };
        });
        arr?.forEach((item) => {
            obj[item?.key] = {
                score: item?.avg,
                name: item?.name,
                key: item?.key
            }
        })
        setAnswersConfig(obj)
    }, [application])



    return (
        <div className='flex-auto'>
            <div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-10 pb-20 lg:pt-14 lg:pb-24 mx-auto'>
            <div className="border-b border-neutral-200 pb-[24px] md:pb-9"><span className="text-[28px] md:text-3xl font-bold">Scores</span></div>
            {Object.keys(answersConfig)?.length ?
                <EvaluationInterview hideTotal config={answersConfig} />
                : <h2>There is no scores yet</h2>
             }
            </div>
            <NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
        </div>
    )
}

export default Scores