// function getImageUrl(url) {
//     if (url instanceof File) return URL.createObjectURL(url)
//     if (typeof url !== 'string') return null

//     return process.env.REACT_APP_API_URL + url?.slice(1)
// }

export const getImageUrl = (() => {
    const cache = new Map();
    return (image) => {
        if (!image) return;

        if (cache.has(image)) {
            return cache.get(image);
        }

        let src;
        if (typeof image === "string" && image.startsWith("data:image")) {
            src = image;
        } else {
            src = image instanceof File ? URL.createObjectURL(image) : process.env.REACT_APP_API_URL + image;
        }

        cache.set(image, src);

        return src;
    };
})();


export default getImageUrl