import React from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { JobDetailsNav } from "../components/base/JobDetailsNav";
import ContactUsLinkBlock from "../components/parts/ContactUsLinkBlock"

const NextStepSection = () => {
    return (
        <section>
            <div className="flex gap-4 justify-center self-stretch w-full font-bold text-center text-white whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <button className="flex justify-center py-[14px] px-[62px] bg-[#37B34A] rounded-md max-md:px-5 max-md:w-full hover:opacity-70">
                    Save
                </button>
                <button className="flex justify-center items-center py-[14px] px-[40px] bg-[#003578] rounded-md max-md:px-5 max-md:w-full hover:opacity-70">
                    Archive Job
                </button>
            </div>
        </section>
    );
};
const SBM02_7_2 = () => {
    const data = [
        'Clarification',
        '1st Interview',
        `Post Interview <br/> Survey`,
        '2nd Interview',
        'Document<br/>Review',
        'Rating of the<br/> Job',
        'AI Evaluation',
        `Final Hiring<br/>Decisions`,
        `Training<br/>Insights`,
        `Offer<br/>Meeting`,
        `Training<br/>Milestones`
    ]

    const data2 = [
        '1st Interview',
        `Post Interview <br/> Survey`,
        '2nd Interview',
        'Rating of the<br/> Job',
        'AI Evaluation',
        `Final Hiring<br/>Decisions`,
        `Training<br/>Insights`,
        `Offer<br/>Meeting`,
        `Training<br/>Milestones`
    ]

    const data3 = [
        'Clarification',
        'Interview',
        `Post Interview <br/> Survey`,
        'Rating of the<br/> Job',
        'AI Evaluation',
        `Final Hiring<br/>Decisions`,
        `Training<br/>Insights`,
        `Offer<br/>Meeting`,
        `Training<br/>Milestones`
    ]

    return (
        <>
            <BreadCrumbWhite crumbs={[
                'Subscriber Dashboard',
                'Job Setup',
                'Current Job Details',
                "Pipelines"
            ]} />

            <div className='flex-auto'>
                <div className='flex flex-col gap-[60px] max-md:gap-[40px] max-w-[1432px] px-4 w-full pt-14 max-md:pt-[40px] pb-24 max-md:pb-[80px] mx-auto'>
                    <JobDetailsNav />

                    <div className='text-center flex gap-3 items-center justify-center flex-col'>
                        <span className='text-5xl font-bold max-md:text-[36px] text-[#333333]'>Let’s Get Started</span>
                        <span className='text-[18px] leading-[30px] max-md:text-[16px] max-md:leading-[22px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet,<br className="max-md:hidden" />
                            bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis magna scelerisque. Mauris hendrerit erat sit amet enim pharetra ut<br className="max-md:hidden" />
                            consectetur odio pharetra. Nam ut mauris et purus fermentum suscipit vel eget mi.
                        </span>
                    </div>

                    <div className="border border-neutral-200 px-[60px] py-[50px] max-md:px-[32px] max-md:py-[36px]">
                        <div>
                            <label htmlFor="check" className="checkbox-green-container bigger mb-6">
                                <input type="checkbox" hidden id="check" />
                                <div className="checkmark-green scale-150 max-md:ml-[13px] !bg-[#F8F8F8]"></div>
                                <span className="checkmark-label-optional font-semibold text-[26px] flex-1 max-md:text-[18px]">Highly Recommended Pipeline - Bottle Tested & Optimized for Best Results</span>
                            </label>

                            <div className="lg:pl-12 flex max-lg:overflow-x-auto max-lg:overflow-y-hidden whitespace-nowrap">
                                {data.map((el, idx) => <div key={idx} className="bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7]  h-[68px] flex flex-auto items-center justify-center text-center max-md:px-[16px]">
                                    <span className='text-[#003578] font-semibold' dangerouslySetInnerHTML={{ __html: el }}></span>
                                </div>)}
                            </div>
                        </div>

                        <hr className="my-[50px] max-md:my-[36px] border-b border-neutral-200" />

                        <div>
                            <label htmlFor="check1" className="checkbox-green-container bigger mb-6">
                                <input type="checkbox" hidden id="check1" />
                                <div className="checkmark-green scale-150 max-md:ml-[13px] !bg-[#F8F8F8]"></div>
                                <span className="checkmark-label-optional font-semibold text-[26px] flex-1 max-md:text-[18px]">Alternative Default Pipeline 2</span>
                            </label>

                            <div className="lg:pl-12 flex max-lg:overflow-x-auto max-lg:overflow-y-hidden whitespace-nowrap">
                                {data2.map((el, idx) => <div key={idx} className="bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7]  h-[68px] flex flex-auto items-center justify-center text-center max-md:px-[16px]">
                                    <span className='text-[#333333] font-semibold' dangerouslySetInnerHTML={{ __html: el }}></span>
                                </div>)}
                            </div>
                        </div>

                        <hr className="my-[50px] max-md:my-[36px] border-b border-neutral-200" />

                        {/* <div>
                            <label htmlFor="check2" className="checkbox-green-container bigger mb-6">
                                <input type="checkbox" hidden id="check2"  />
                                <div className="checkmark-green scale-150 max-md:ml-[13px] !bg-[#F8F8F8]"></div>
                                <span className="checkmark-label-optional font-semibold text-[26px] flex-1 max-md:text-[18px]">Alternative Default Pipeline 3</span>
                            </label>

                            <div className="lg:pl-12 flex max-lg:overflow-x-auto max-lg:overflow-y-hidden whitespace-nowrap">
                                {data3.map((el,idx) => <div className=" bg-[#F7F7F7] border-r border-r-[#DEDED9] last:border-r-[#F7F7F7]  h-[68px] flex flex-auto items-center justify-center text-center max-md:px-[16px]">
                                    <span className='text-[#333333] font-semibold' dangerouslySetInnerHTML={{__html: el}}></span>
                                </div>)}
                            </div>
                        </div> */}


                        <hr className="my-[50px] max-md:my-[36px] border-b border-neutral-200" />

                        <NextStepSection />
                        <div className="mt-[50px] max:mt-[36px]">
                            <ContactUsLinkBlock to={"/SBM02_7_2_1"} hr={false} />
                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}

export default SBM02_7_2