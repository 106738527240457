import React, {useEffect, useState} from "react";
import {ElevenStepsWrapper} from "../../parts/ElevenStepsWrapper";
import {FitScaleEvaluationTable} from "../../../../components/base/FitScaleEvaluationTable";
import {crumbsConfig} from "../../config";

export const RatingOfTheJobStep = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
        ["Rating of The Job", '']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            {step === 1 && <ElevenStepsWrapper
                crumbs={crumbs}
                activePin={6}
                onNext={nextComponent}
                onBack={prevComponent}
            >
                <StepOne />
            </ElevenStepsWrapper>}
        </>
    )
}

const StepOne = () => {
    return (
        <>
            <div className="border-b border-neutral-200 pb-[24px] md:pb-9">
                <span className="text-[28px] md:text-3xl font-bold">Rating of the Job</span>
            </div>

            <div className="flex flex-col gap-8 pb-[50px] ">
                <div>
                    <span
                        className="font-semibold text-[18px] block mb-8">What is the applicant’s rating of the job?</span>

                    <FitScaleEvaluationTable labelLow="Poor Fit" labelHigh="Perfectly Fit"/>
                </div>

                <div className="max-w-[640px] w-full">
                    <label className="font-semibold text-[18px] mb-4 block">Comments</label>
                    <textarea className="grey-textarea !h-[164px]"></textarea>
                </div>

                <div className="max-w-[640px] w-full">
                    <label className="font-semibold text-[18px] mb-4 block">3 reasons why the rating does not go
                        higher</label>
                    <input type="text" className="grey-input-custom mb-4"/>
                    <input type="text" className="grey-input-custom mb-4"/>
                    <input type="text" className="grey-input-custom"/>
                </div>

                <div className="max-w-[640px] w-full">
                    <label className="font-semibold text-[18px] mb-4 block">3 reasons why the rating does not go
                        lower</label>
                    <input type="text" className="grey-input-custom mb-4"/>
                    <input type="text" className="grey-input-custom mb-4"/>
                    <input type="text" className="grey-input-custom"/>
                </div>

                <div className="flex justify-end">
                    <button
                        className="justify-center max-md:w-full py-[15px] px-[36px] font-bold text-white bg-sky-900 rounded-md max-md:px-5"
                        tabIndex="0">
                        Save
                    </button>
                </div>

            </div>
        </>
    )
}