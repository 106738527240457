const InputFieldHorisontal = ({ label, labelClassName, type = "text", name, id, value, onChange, error, inputBg = "bg-[#F0F3F8]", placeholder = "", afterLabel = null, afterInput = null, ...rest }) => (
	<div className="flex flex-col gap-[5px]">
		<div className="flex max-md:flex-col md:items-center gap-[12px] md:gap-[16px] flex-1">
			{label && <label htmlFor={id} className={labelClassName ? labelClassName : "min-w-[140px] text-[16px] font-semibold text-zinc-800 leading-[18px]"}>{label}</label>}
			{afterLabel}
			<input
				type={type}
				id={id}
				name={name}
				className={`flex-auto rounded-md min-h-[46px] text-[16px] px-[16px] focus:outline-none focus:ring-2 focus:ring-green-500 ${error ? 'border-red-500 bg-red-100' : inputBg} w-full`}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				{...rest}
			/>
			{afterInput}
		</div>
		{error && <p className="text-red-500 text-sm mt-1">{error}</p>}
	</div>
);

export default InputFieldHorisontal;