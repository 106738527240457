import React from "react";


const Question = ({ question, score, index }) => {
    return (
        <div className="flex flex-nowrap gap-0 justify-between text-lg lg:text-xl leading-7 lg:leading-8 text-zinc-800 w-full">
            <div className="flex-auto whitespace-normal">Question {index} : {question}</div>
            <div className="text-right lg:text-center basis-1/5 flex-shrink-0">{score}</div>
        </div>
    )
}

export const CandidateDetails = () => {
    const questionsData = [
        { question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?", score: 6 },
        { question: "Quisque sit amet tortor nulla?", score: 7 },
        { question: "Nullam laoreet tincidunt purus vel volutpat?", score: 8 },
        { question: "Suspendisse eget enim odio, quis congue dolor?", score: 9 },
        { question: "Nullam in ante nunc, eu auctor erat?", score: 4 },
        { question: "Pellentesque habitant morbi tristique senectus et netus?", score: 2 },
        { question: "Vivamus magna justo, lacinia eget consectetur sed?", score: 5 },
        { question: "Curabitur non nulla sit amet nisl tempus?", score: 8 },
        { question: "Praesent sapien massa, convallis a pellentesque nec?", score: 5 },
        { question: "Sed porttitor lectus nibh, vel egestas nulla?", score: 5 },
        { question: "Pellentesque habitant morbi tristique senectus et netus?", score: 2 },
        { question: "Vivamus magna justo, lacinia eget consectetur sed?", score: 5 },
        { question: "Curabitur non nulla sit amet nisl tempus?", score: 8 },
        { question: "Praesent sapien massa, convallis a pellentesque nec?", score: 5 },
        { question: "Sed porttitor lectus nibh, vel egestas nulla?", score: 5 },
        { question: "Pellentesque habitant morbi tristique senectus et netus?", score: 2 },
        { question: "Vivamus magna justo, lacinia eget consectetur sed?", score: 5 },
        { question: "Curabitur non nulla sit amet nisl tempus?", score: 8 },
        { question: "Praesent sapien massa, convallis a pellentesque nec?", score: 5 },
        { question: "Sed porttitor lectus nibh, vel egestas nulla?", score: 5 },
    ];
    return (
        <section className="flex flex-col w-full">
            <article>
                <h3 className="w-full text-3xl font-bold leading-8 text-zinc-800">1st Interview</h3>
                <div className="mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full max-md:mt-[24px]"></div>
                <div className="flex gap-0 justify-between mt-9 text-2xl font-bold leading-7 whitespace-nowrap text-zinc-800 max-md:flex-wrap">
                    <div className="flex-auto">Questions</div>
                    <div className="text-center basis-1/5 max-md:text-right">Score</div>
                </div>
                <div className='flex flex-col gap-3 w-full lg:mt-3 mt-6'>
                    {questionsData.map((question, index) => (
                        <Question
                            key={index}
                            {...question}
                            index={index + 1}
                        />
                    ))}
                </div>
            </article>
        </section>
    );
};