import React, {useEffect, useRef} from "react";
import classNames from "classnames";


export const EditableInput = ({ className, ...props }) => {
    const textareaRef = useRef(null);

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };
    useEffect(() => {
        adjustTextareaHeight()
    }, [])
    return <>
        <textarea
            ref={textareaRef}
            className={classNames(`text-[18px] text-[#333333] focus:outline-none focus:ring-2 focus:ring-green-500 bg-white w-full max-md:leading-[28px]`, className)}
            rows="1"
            style={{
                resize: "none",
                overflow: "hidden"
            }}
            onChange={adjustTextareaHeight}
            onKeyDown={adjustTextareaHeight}
            {...props}
        />
    </>
};