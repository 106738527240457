export const baseCrumbs = ({ jobId, applicationId, fullName = "Mark Spector" }) => {
    return [
        ['Subscriber Dashboard', '/'],
        ['Jobs', '/SBM01'],
        ['Applicants', `/SBM01_2/${jobId}?tab=applicants`],
        ["Candidates", `/SBM01_2/${jobId}?tab=candidates`],
        [fullName, `/SBM01_10_14(2)/${jobId}/${applicationId}`],
    ]
}

export const employmentTypes = [
	{ label: 'Full Time', value: 'Full-Time' },
	{ label: 'Part Time', value: 'Part-time' },
	{ label: 'Contract', value: 'Contract' },
];

export const payPeriods = [
	{ label: 'Hour', value: 'hour' },
	{ label: 'Day', value: 'Day' },
	{ label: 'Week', value: 'Week' },
	{ label: 'Month', value: 'Month' }
];

export const payFrequencies = [
	{ label: 'Daily', value: 'Daily' },
	{ label: 'Weekly', value: 'Weekly' },
	{ label: 'Biweekly', value: 'bi-weekly' },
	{ label: 'Monthly', value: 'Monthly' }
];