import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import api from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPageByPath } from '../redux/thunks/applicationThunks';
import { getPages } from '../redux/slices/applicationSlice';

const PublicPageLayout = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const pages = useSelector(getPages);
    useEffect(() => {
        if(!pages.length) return
        if(pathname === '/') {
            dispatch(fetchPageByPath('/HOM1_1'))
            return;
        }
        if(pages.find(page => page.url === pathname)) {
            dispatch(fetchPageByPath(pathname))
        } 
    }, [pages, pathname, dispatch])
    return (
        <Outlet />
    )
};

export default PublicPageLayout