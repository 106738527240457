import classNames from 'classnames';
import React from 'react';

import { ReactComponent as SpinnerIcon } from "../../assets/img/spinner.svg"
const PrimaryButton = ({
    onClick, disabled, className, children, isLoading, ...props
}) => {
    return <button
        className={classNames('flex justify-center items-center lg:text-[1.375rem] font-bold text-[19px] h-16 lg:h-[4.625rem] whitespace-nowrap bg-[#37B34A] rounded-md text-white hover:bg-[#002E67]', className)}
        onClick={onClick}
        disabled={disabled || isLoading}
        {...props}
    >
        {
           !isLoading ? children : <SpinnerIcon />
        }
    </button>
};

export default PrimaryButton;