import Application from "./Application";
import Assessment from "./Assessment";
import Communications from "./Communications";
import Resume from "./Resume";

export const HistoryPages = {
    Application,
    Communications,
    Assessment,
    Resume
}