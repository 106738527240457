export const publicSolutions = (instance) => ({
    async getPubSolPages() {
        const { data } = await instance.get(`api/public-solution-pages`);
        return data
    },

    async getPubSolCategories() {
        const { data } = await instance.get(`api/public-solution-categories`);
        return data
    },

    async getPubSolOnePage(id) {
        const { data } = await instance.get(`api/public-solution-pages/${id}`);
        return data
    },

    async createPubSolPage(payload) {
        const { data } = await instance.post(`/api/public-solution-pages`, payload)
        return data;
    },

    async updatePubSolPage(payload, id) {
        const { data } = await instance.put(`/api/public-solution-pages/${id}`, payload)
        return data;
    },

    async deletePubSolPage(id) {
        const { data } = await instance.delete(`/api/public-solution-pages/${id}`);
        return data;
    },

    async createPubSolCategory(payload) {
        const { data } = await instance.post(`api/public-solution-categories`, payload)
        return data;
    },

    async getPubSolTags() {
        const { data } = await instance.get(`/api/public-solution-tags`);
        return data
    },

    async createPubSolTag(payload) {
        const { data } = await instance.post(`api/public-solution-tags`, payload)
        return data;
    }

})