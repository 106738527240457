import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getAllComunicationsFetch } from '../../../redux/thunks/communicationsThunks';
import { Tooltip } from 'react-tooltip';
import PageLoader from '../../../components/PageLoader';
import { format } from 'date-fns';
import SelectBox from '../../../components/base/SelectBox';

const CommunicationTable = ({ isSorted = true, type }) => {
    const dispatch = useDispatch()
    const { jobId, applicationId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams()
    const { communications, isLoading: isCommunicationLoading } = useSelector(state => state.communications)

    const [shownCommunications, setShownCommunications] = useState([])
    console.log(shownCommunications)
    const [sortBy, setSortBy] = useState("")

    const sortOptions = [
        { label: 'Date (most recent first)', value: "desc" },
        { label: 'Date (oldest first)', value: "asc" },
    ]

    useEffect(() => {
        if (!applicationId) return
        dispatch(getAllComunicationsFetch({ applicationId, query: searchParams.toString() }))
    }, [applicationId, searchParams])

    useEffect(() => {
        if (!communications?.length) return
        if (!type) {
            setShownCommunications(communications)
        } else {
            setShownCommunications(communications.filter((item) => item?.type === type))
        }
    }, [communications])

    useEffect(() => {
        const copy = [...communications];
        const toComparableDate = (date) => Number(new Date(date))
        if (sortBy === "ask") {
            copy.sort((a, b) => toComparableDate(a.createdAt) - toComparableDate(b.createdAt))
        }
        if (sortBy === "desk") {
            copy.sort((a, b) => toComparableDate(b.createdAt) - toComparableDate(a.createdAt))
        }
        setShownCommunications(copy)
    }, [sortBy])


    const handleSortChange = (value) => {
        setSearchParams(prev => {
            prev.set("sort_by", value)
            return prev
        })
    }

    return (
        <>
            {
                isSorted ?
                    <div className="flex items-center gap-7 max-md:flex-col max-md:items-start max-md:gap-[12px]">
                        <span className="text-[16px] md:text-xl font-bold ">Sort by :</span>
                        <div className="w-full md:max-w-[276px]">
                            <SelectBox
                                options={sortOptions} svg={2}
                                onSelect={handleSortChange}
                                value={sortBy}
                            />
                        </div>
                    </div>
                    :
                    null
            }
            {
                !isCommunicationLoading && shownCommunications?.length ?
                    <div className="overflow-auto">
                        <table className="table-auto w-full text-center table-10_14">
                            <thead >
                                <tr>
                                    <th className="font-semibold px-4 py-3">Date</th>
                                    {!type && <th className="font-semibold px-4 py-3">Channel</th>}
                                    <th className="font-semibold px-4 py-3">Time</th>
                                    <th className="font-semibold px-4 py-3">Content</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    shownCommunications.map((item) => (
                                        <React.Fragment key={item?.id}>
                                            <tr>
                                                <td className="px-4 py-3">{format(item?.createdAt, "EEE, dd LLL yyyy")}</td>
                                                {!type && <td className="px-4 py-3 font-bold">{item?.type}</td>}
                                                <td className="px-4 py-3">{format(item?.createdAt, "hh.mm")}</td>
                                                <td className="px-4 py-3 font-bold">
                                                    {item?.type === 'call' ?
                                                        item?.recordingURL ? <Link target='_blank' to={process.env.REACT_APP_API_URL + item.recordingURL} className="flex items-center justify-center gap-[6px]">
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9.625 11V8.25L11.9167 9.625L14.2083 11L11.9167 12.375L9.625 13.75V11Z" fill="#37B34A" stroke="#37B34A" strokeWidth="1.5" strokeLinejoin="round" />
                                                                <path d="M5.16633 16.8337C5.93157 17.6009 6.84088 18.2093 7.84202 18.624C8.84316 19.0387 9.91638 19.2514 11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44371 15.5563 2.75 11 2.75C8.72208 2.75 6.65958 3.67354 5.16633 5.16633C4.40642 5.92625 2.75 7.79167 2.75 7.79167" stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M2.75 4.125V7.79167H6.41667" stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            Replay
                                                        </Link> : 'No reply'
                                                        :
                                                        <div className="flex items-center justify-center gap-[6px]" data-tooltip-id={`details-anchor-element-${item?.id}`} >
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_341_11182)">
                                                                    <path d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z" stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M10.3125 10.3125C10.4948 10.3125 10.6697 10.3849 10.7986 10.5139C10.9276 10.6428 11 10.8177 11 11V14.4375C11 14.6198 11.0724 14.7947 11.2014 14.9236C11.3303 15.0526 11.5052 15.125 11.6875 15.125" stroke="#37B34A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M10.6562 8.25C11.2258 8.25 11.6875 7.78829 11.6875 7.21875C11.6875 6.64921 11.2258 6.1875 10.6562 6.1875C10.0867 6.1875 9.625 6.64921 9.625 7.21875C9.625 7.78829 10.0867 8.25 10.6562 8.25Z" fill="#37B34A" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_341_11182">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            Details
                                                        </div>
                                                    }
                                                </td>
                                            </tr>

                                        </React.Fragment>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                    : isCommunicationLoading ?
                        <PageLoader className={"!m-0"} /> :
                        <h2 className="text-2xl font-bold text-center">No data</h2>


            }
            {
                shownCommunications?.length ?
                    shownCommunications.map(item => (
                        <Tooltip
                            key={item?.id}
                            id={`details-anchor-element-${item?.id}`}
                            clickable
                            style={{
                                borderRadius: 12,
                                padding: 0,
                                background: 'white',
                                boxShadow: '0px 4px 8px 0px #5B68713D, 0px 0px 1px 0px #1A202452',
                                marginTop: -15,
                                zIndex: 999
                            }}

                            place='right-start'
                            offset={14}
                            opacity='inherit'
                        >
                            <div className='px-[12px] py-[36px] w-[672px] h-[434px]'>
                                <p className="text-black text-lg text-start">{item?.content}</p>
                            </div>
                        </Tooltip >

                    ))
                    :
                    null
            }
        </>
    )
}

export default CommunicationTable