import { NavLink } from "react-router-dom";

export const Footer = ({ hideCopyright = false }) => {
    return (
        <footer className="flex flex-col mt-auto w-full">
            <div className="flex justify-center items-center px-16 py-12 w-full text-lg text-center text-white uppercase whitespace-nowrap bg-[#37B34A] max-md:px-5 max-md:max-w-full">
                <nav className="flex gap-5 flex-col gap-y-12 md:flex-row justify-between my-2.5 max-w-full w-[1015px] flex-wrap max-md:gap-y-[38px]">
                    <NavLink to="/HOM1_2" className="hover:text-green-600">About Us</NavLink>
                    <NavLink to="/HOM1_8" className="hover:text-green-600">Careers</NavLink>
                    <NavLink to="/HOM_1_10" className="hover:text-green-600">Contact</NavLink>
                    <NavLink to="/HOM_1_11" className="hover:text-green-600">Terms</NavLink>
                    <NavLink to="/HOM_1_12" className="hover:text-green-600">Privacy</NavLink>
                    <NavLink to="/HOM_1_13" className="hover:text-green-600">Security</NavLink>
                    <NavLink to="/HOM1_15" className="hover:text-green-600">Support Login</NavLink>
                </nav>
            </div>
            {!hideCopyright &&
                <div className="bg-[#F7F7F7] w-full self-center text-[16px] text-base font-light text-center text-neutral-400 py-[32px] max-md:py-[21px]">
                    <div className="max-w-64 md:max-w-full mx-auto">
                        Copyright © 2024 EmployJoy.ai, All rights reserved.
                    </div>
                </div>
            }
        </footer>
    )
}
