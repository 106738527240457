import React, {useEffect, useState} from "react";
import {TotalScore} from "../../base/TotalScoreSBM01";

export const StepOne = () => {
    const [questions, setQuestions] = useState([
        { category: "Client Service", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Client Service", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Client Service", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Client Service", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Drug Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Drug Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Drug Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Drug Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Supervisory Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Supervisory Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Supervisory Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Supervisory Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Theft Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Theft Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Theft Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Theft Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Work Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Work Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Work Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Work Attitude", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "World View", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "World View", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "World View", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "World View", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Safety", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Safety", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Safety", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
        { category: "Safety", fitRating: 0, question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." }
    ])
    const categories = [
        { name: "Client Service", score: 90 },
        { name: "Drug Attitude", score: 90 },
        { name: "Supervisory Attitude", score: 90 },
        { name: "Theft Attitude", score: 90 },
        { name: "Work Attitude", score: 90 },
        { name: "World View", score: 90 },
        { name: "Safety", score: 90 }
    ];
    const handleChange = (formData) => {
        const { question, fitRating, comment } = formData
        const questionIndex = questions.findIndex(item => item.question === formData.question);

        if (questionIndex !== -1) {
            const newData = [...questions];
            newData[questionIndex] = { question, fitRating, comment };
            setQuestions(newData);
        } else {
            setQuestions(prev => [...prev, { question, fitRating, comment }]);
        }
    };

    return (
        <div className="flex flex-col items-stretch">
            <div className="self-stretch w-full text-3xl font-bold leading-8 text-zinc-800 max-md:max-w-full">
                Post Interview Survey
            </div>
            <div className="self-stretch mt-9 max-md:mt-[24px] w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <TotalScore title="Total Score" score={90} />
            {
                categories.map(({ name, score }, idx) => (
                    <div key={idx}>
                        <SectionHeader
                            title={name}
                            score={score}
                        />
                        {
                            questions.filter(({ category }) => category === name).map((question, idx) => (
                                <InterviewQuestion
                                    key={idx}
                                    index={idx + 1}
                                    {...question}
                                    onChange={handleChange}
                                />
                            ))
                        }
                    </div>
                ))
            }
        </div>
    );
};

function InterviewQuestion({ question, fitRating, isRequired, onChange, index }) {
    const [formData, setFormData] = useState({
        question: question,
        fitRating: fitRating,
    })

    useEffect(() => {
        if (formData, onChange) {
            onChange(formData)
        }
    }, [formData])

    return (
        <>
            <div className="mt-11 text-lg font-semibold text-[#333333] max-md:mt-10 max-md:max-w-full">
                {index ? index : ""} {question}{" "}
                {isRequired && <span className="text-red-600">*</span>}
            </div>
            <FitScaleEvaluationTable
                value={formData.fitRating}
                onChange={(value) => setFormData(prevState => ({ ...prevState, fitRating: value }))}
            />
        </>
    )
};


const FitScaleEvaluationTable = ({ value = 1, onChange }) => {
    const createRatingScale = (selectedIdx) => Array.from({ length: 6 }).map((_, idx) => {
        return idx + 1 === selectedIdx;
    });

    const [selectedRatings, setSelectedRatings] = useState(createRatingScale(value));


    const handleRatingClick = (index) => {
        setSelectedRatings(createRatingScale(index));
        onChange && onChange(index)
    };

    return (
        <div className="flex flex-col max-w-[400px] w-full">
            <div className="flex gap-5 max-md:gap-[16px] justify-between mt-4 text-base font-semibold text-center whitespace-nowrap">
                {selectedRatings.map((isSelected, idx) => (
                    <div
                        key={idx}
                        className={`cursor-pointer`}
                        onClick={() => handleRatingClick(idx + 1)}
                    >
                        <div
                            key={idx}
                            className={`flex justify-center items-center px-4 bg-white border border-solid ${isSelected ? "border-green-500" : "border-zinc-300"}  h-[42px] rounded-[460px] w-[42px]`}>{idx + 1}</div>
                    </div>
                ))}
            </div>
            <div className="flex gap-5 justify-between mt-5 w-full text-base">
                <div className="flex-auto">Totally Disagree</div>
                <div className="flex-auto text-right">Totally Agree</div>
            </div>
        </div>
    );
}
const SectionHeader = ({ title, score }) => (
    <header className="flex gap-5 justify-between mt-11 w-full max-w-[400px]">
        <h2 className="flex-auto my-auto text-2xl font-bold text-[#333333]">{title}</h2>
        <div className="justify-center px-8 py-4 max-md:py-3 text-lg whitespace-nowrap bg-white border border-solid border-zinc-300 max-md:px-5 max-md:min-w-[96px] text-center">{score}%</div>
    </header>
);
