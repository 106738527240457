import React from "react";

export const SalesAssociateHeaderSBM01 = ({ name }) => {
    return (
        <header className=" bg-neutral-100">
            <div className='max-w-[1432px] w-full px-[22px] mx-auto'>
                <div className='flex flex-col items-start py-[24px] md:py-14'>
                    <h2 className="text-lg font-semibold text-sky-900">
                        Sales Associate / Candidates
                    </h2>
                    <h1 className="mt-1.5 text-[36px] md:text-4xl font-bold leading-10 text-zinc-950">
                        {name}
                    </h1>
                </div>
            </div>
        </header>
    )
}