const applicationReferencesBase = 'api/job-application-references';

export const applicationReferences = (instance) => ({
    async create(jobApplicationId, payload) {
        const { data } = await instance.post(`${applicationReferencesBase}/${jobApplicationId}`, payload);
        return data;
    },
    async get(jobApplicationId) {
        const { data } = await instance.get(`${applicationReferencesBase}/${jobApplicationId}`);
        return data;
    },
    async update(jobApplicationId, payload) {
        try {
            const { data } = await instance.put(`${applicationReferencesBase}/${jobApplicationId}`, payload);
            return data;
        } catch(e) {
            return e.response.data;
        }
    },
    async delete(jobApplicationId, referenceId) {
        try {
            const { data } = await instance.delete(`${applicationReferencesBase}/${jobApplicationId}/${referenceId}`);
            return data;
        } catch(e) {
            return e.response.data;
        }
    },
});

