import Select, { components } from 'react-select';

const MultiSelect = ({ options, value, placeholder = '', showRemoveButton = true, onChange }) => {
    const Placeholder = () => {
        return (
            <div className="placeholder">{placeholder}</div>
        );
    };
    let selectComponents = {
        DropdownIndicator: () => null,
        MultiValueContainer: ({ children, ...props }) => {
            return (
                <components.MultiValueContainer {...props} className="select-multi-value-container" >
                    {children}
                </components.MultiValueContainer>
            )
        },
        Input: ({ children, ...props }) => {
            return (
                <components.Input {...props} className='select-multi-input-wrapper' placeholder={placeholder} >
                    {children}
                </components.Input>
            )
        },
        MultiValueRemove: ({ children, ...props }) => (
            showRemoveButton ?
                <components.MultiValueRemove {...props}>{children}</components.MultiValueRemove>
                : null
        ),
        MultiValue: ({ children, ...props }) => {
            return (
                <components.MultiValue {...props} className="select-multi-value" >
                    {children}
                </components.MultiValue>
            )
        },
        IndicatorSeparator: () => null,
        IndicatorsContainer: () => null,
    };

    let classNames = {
        control: (e) => {
            return `select-selection ${e.menuIsOpen ? 'select-selection-focused' : ''} ${e.getValue().value || value ? 'has-value' : ''}`
        },
        singleValue: (e) => {
            return e.getValue()?.value ? 'aaa has-value' : 'aaa';
        },
        valueContainer: () => 'bbb',
        menu: () => 'ccc select2-dropdown select2-dropdown--below',
        container: () => 'ddd'
    }

    return (
        <Select
            isMulti options={options}
            classNames={classNames}
            placeholder={placeholder}
            value={value}
            data-value="customData1" 
            components={{
                ...selectComponents
            }}
            onChange={onChange}
        />
    )
};

export default MultiSelect;