import React, { useState } from 'react'
import CallModal from "../modals/CallModal";
import SmsModal from "../modals/SmsModal";
import EmailModal from "../modals/EmailModal";
import AddTagModal from "../modals/AddTagModal";
const ModalGrop = ({theme = '1',callModal = true, tagModal = true, cmcModal = true, emailModal = true, selectedApplicant = null}) => {
    const [callPopup, setCallPopup] = useState(false) 
    const [smsPopup, setSmsPopup] = useState(false)
    const [emailPopup, setEmailPopup] = useState(false)
    const [addTagPopup, setAddTagPopup] = useState(false)
    const modalsDataCall = [
        {
            name: 'Call',
            data: [
                { date: "Wed, 25 May 2023", time: "14.00", },
                { date: "Mon, 22 May 2023", time: "14.00", },
                { date: "Fri, 19 May 2023", time: "14.00", },
                { date: "Wed, 03 May 2023", time: "14.00", },
            ]
        },
    ]
    const modalsDataEmail = [
        {
            name: 'Email',
            data: [
                { date: "Wed, 25 May 2023", time: "14.00", },
                { date: "Mon, 22 May 2023", time: "14.00", },
                { date: "Fri, 19 May 2023", time: "14.00", },
                { date: "Wed, 03 May 2023", time: "14.00", },
            ]
        },
    ]
    const modalsDataSms = [
        {
            name: 'Sms',
            data: [
                { date: "Wed, 25 May 2023", time: "14.00", },
                { date: "Mon, 22 May 2023", time: "14.00", },
                { date: "Fri, 19 May 2023", time: "14.00", },
                { date: "Wed, 03 May 2023", time: "14.00", },
                { date: "Mon, 22 April 2023", time: "14.00", },
                { date: "Fri, 19 April 2023", time: "14.00", },
                { date: "Wed, 25 March 2023", time: "14.00", },
                { date: "Mon, 22 March 2023", time: "14.00", },
                { date: "Fri, 19 March 2023", time: "14.00", },
                { date: "Wed, 25 February 2023", time: "14.00", },
            ]
        }
    ]
    return (
    <> 
        {theme === '2' &&  <>
            {callModal && <button type="button" className="grow justify-center text-[#003578] font-semibold px-[12px] py-[12px] rounded-md border border-[#003578] border-solid" onClick={() => { setCallPopup(true) }}>
                Call
            </button>
            }
            {cmcModal && <button type="button" className="grow justify-center text-[#003578] font-semibold px-[12px] py-[12px] rounded-md border border-[#003578] border-solid" onClick={() => { setSmsPopup(true) }}>
                SMS
            </button>}
            {emailModal && <button type="button" className="grow justify-center text-[#003578] font-semibold px-[12px] py-[12px] rounded-md border border-[#003578] border-solid" onClick={() => { setEmailPopup(true) }}>
                Email
            </button>
            }
            {tagModal &&  <button type="button" disabled={!selectedApplicant} className="grow justify-center text-[#003578] font-semibold px-[12px] py-[12px] rounded-md border border-[#003578] border-solid" onClick={() => { setAddTagPopup(true) }}>
                Add Tag
            </button>
            }
        </>
        }
        { theme === '1' && <>
            {callModal && <ActionButton text="Call" onClick={() => { setCallPopup(true) }} /> }
            {cmcModal && <ActionButton text="SMS" onClick={() => { setSmsPopup(true) }} /> }
            {emailModal && <ActionButton text="Email" onClick={() => { setEmailPopup(true) }} /> }
            {tagModal && <ActionButton text="Add Tag" onClick={() => { setAddTagPopup(true) }} /> }
        </>
        }
        {callPopup && <CallModal selectedApplicant={selectedApplicant} open={callPopup} tableHeding={true} data={modalsDataCall} handleClose={() => setCallPopup(false)} />} 
        {smsPopup && <SmsModal open={smsPopup} data={modalsDataSms} handleClose={() => setSmsPopup(false)} />}
        {emailPopup && <EmailModal open={emailPopup} data={modalsDataEmail} handleClose={() => setEmailPopup(false)} />}
        {addTagPopup && <AddTagModal open={addTagPopup} modalSize='modal__dialog--1000' handleClose={() => setAddTagPopup(false)} type='candidate' selectedApplicant={selectedApplicant}/>}
    </>
  )
}
const ActionButton = ({ text, onClick }) => (
    <button type="button" className="justify-center text-green-500 text-lg h-[50px] w-[110px] font-semibold rounded-md border border-green-500 border-solid hover:opacity-70" onClick={onClick}>
        {text}
    </button>
);
export default ModalGrop
