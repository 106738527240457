import {ProgressBarFour} from "../../../components/base/ProgressBarFour";
import React, {useEffect, useState} from "react";

export const ProgressBarSBM01 = ({activeTab=1}) => {

    const progressData = [
        { stepNumber: "1", label: "Application", isActive: false },
        { stepNumber: "2", label: "Communications", isActive: false },
        { stepNumber: "3", label: "Assessment", isActive: false },
        { stepNumber: "4", label: "Resume", isActive: false },
    ]

    const [modifyStep, setModifyStep] = useState(null)

    useEffect(() => {
        const a = progressData.map((step, index) => {
            if (step.stepNumber == activeTab) {
                step.isActive = true
                return step
            } else {
                return step
            }
        })

        setModifyStep(a)
    }, []);

    return (
        <section className="flex w-full text-center overflow-auto py-4">
            {modifyStep && <ProgressBarFour stepsData={modifyStep}/>}
        </section>
    )
}