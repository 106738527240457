import * as React from "react";
import HomePage from "./SUP1_5";
import { useSelector } from "react-redux";
import { getCurrentPage } from "../redux/slices/applicationSlice";
import findValue from "../assets/helpers/findValue";
import getImageUrl from "../assets/helpers/getImageUrl";
import PageLoader from "../components/PageLoader";

const ContentBlock = ({ imageUrl, imageAlt, aspectRatio, text }) => (
	<section className="flex flex-col grow justify-between px-14 py-11 w-full text-base font-light leading-7 text-center border border-solid bg-[#F5F9FF] border-slate-200 text-zinc-800 md:px-5 md:mt-10">
		<img
			loading="lazy"
			src={imageUrl}
			alt={imageAlt}
			className={`self-center mt-7 max-w-full ${aspectRatio}`}
		/>
		<p className="mt-16 md:mt-10">{text}</p>
	</section>
);

const InfoSection = () => {
	const currentPage = useSelector(getCurrentPage);
	const getValue = (key) => findValue(currentPage?.blocks, key);
	const contentBlocks = [
		{
			imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/b0faaeec427315bb3bf2338dd3341875722aec16c7bf548a936aca183abd8216?apiKey=9106ba04c02e4dceae488e75e2ac4862&",
			imageAlt: "First content image",
			aspectRatio: "aspect-[4.35] w-[257px] mt-[40px]",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		},
		{
			imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/9000b9677320c4da627697ced8df3b0b133a67e6d2f9eff2678fe63ab1a26d59?apiKey=9106ba04c02e4dceae488e75e2ac4862&",
			imageAlt: "Second content image",
			aspectRatio: "aspect-[6.25] w-[281px] mt-[45px]",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		},
		{
			imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/972922bcfb964e93025eca34c3eacfd8a123219f884bee2bc95b9b7f3e9533ed?apiKey=9106ba04c02e4dceae488e75e2ac4862&",
			imageAlt: "Third content image",
			aspectRatio: "aspect-[1.96] w-52",
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		},
	];

	return (
		<>
			{
				currentPage ?
					<section className="mt-20 md:mt-10 md:max-w-full">
						<div className="flex gap-5 sm:flex-col md:gap-10 md:flex-row">
							{getValue('block_content_items')?.data?.map((block, index) => (
								<div key={index} className="flex flex-col w-[33%] md:ml-0 md:w-full">
									<ContentBlock
										text={block.description}
										imageUrl={getImageUrl(block.logo)}
									/>
								</div>
							))}
						</div>
					</section>
					:
					<PageLoader color="light" />
			}
		</>
	);
};

const TextSection = () => {
	const currentPage = useSelector(getCurrentPage);
	const getValue = (key) => findValue(currentPage?.blocks, key);
	return (
		<section className="self-center mt-20 text-4xl font-light text-center leading-[52px] text-zinc-800 max-md:mt-10 max-md:max-w-full">
			{currentPage ?
				<article>
					<p className="max-w-[980px] mx-auto">
						{getValue('block_content_title_blocks')}
					</p>
				</article>
				:
				<PageLoader color="light" />
			}
		</section>
	);
}

const TestimonialCard = ({ quote, author, position, imageUrl, altText }) => {
	return (
		<article className="flex items-start">
			<div className="text-[60px] leading-[52px] font-[700]">“ </div>
			<div className="flex flex-col ml-[10px]">
				<p className="text-4xl leading-[52px] font-[700]">{quote}</p>
				<div className="mt-14">
					<div className="text-[24px] font-[700] text-[#333333]">{author}</div>
					<div className="mt-[10px] font-light text-[#333333]">{position}</div>
				</div>
				<img
					loading="lazy"
					src={imageUrl}
					alt={altText}
					className="mt-14 max-w-full aspect-[4.35] w-[257px]"
				/>
			</div>
		</article>
	);
}

const TestimonialsSection = () => {
	const currentPage = useSelector(getCurrentPage);
	const getValue = (key) => findValue(currentPage?.blocks, key);
	console.log(currentPage?.blocks);
	const testimonials = currentPage ? [
		{
			quote: getValue('block_content_quote'),
			author: getValue('block_content_author'),
			position: getValue('block_content_author_role'),
			imageUrl: getImageUrl(getValue('block_content_image_logo')),
			altText: 'Portrait of Shauna Geraghty',
		},
	] : [];

	return (
		<section className="mt-20 max-md:pr-5 max-md:mt-10 max-md:max-w-full">
			{
				currentPage ?
					<div className="flex max-md:flex-col max-md:gap-0">
						<div className="flex flex-col w-6/12 max-md:ml-0 w-full max-w-[590px]">
							{currentPage && <img
								loading="lazy"
								src={getImageUrl(getValue('block_content_image'))}
								alt="Large decorative"
								className="w-full aspect-[0.76] max-md:mt-10 max-md:max-w-full"
							/>}
						</div>
						<div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full grow p-16 pl-[56px] pr-[36px] lg:max-w-[595px]">
							{testimonials.map((testimonial, index) => (
								<TestimonialCard
									key={index}
									quote={testimonial.quote}
									author={testimonial.author}
									position={testimonial.position}
									imageUrl={testimonial.imageUrl}
									altText={testimonial.altText}
								/>
							))}
						</div>
					</div>
					:
					<PageLoader color="light" />
			}
		</section>
	);
}


const HOM1_7 = () => {
	return (
		<HomePage>
			<TestimonialsSection />
			<TextSection />
			<InfoSection />
		</HomePage>
	);
}

export default HOM1_7;