import React, {useEffect, useState} from "react";
import {ElevenStepsWrapper} from "../../parts/ElevenStepsWrapper";
import {StepOne} from "../FirstInterviewSurvey/StepOne";
import {CandidateDetails} from "../../parts/CandidateDetails";
import {StepTwo} from "./StepTwo";
import {crumbsConfig} from "../../config";

export const SecondInterviewSteps = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
        ['1st Interview'],
        ['Post 1st Interview Survey'],
        ['2nd Interview']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            {step === 1 && <ElevenStepsWrapper
                onNext={() => setStep(2)}
                onBack={prevComponent}
                crumbs={crumbs}
                activePin={4}
            >
                {/*<StepOne />*/}
                <CandidateDetails />
            </ElevenStepsWrapper>}

            {step === 2 && <ElevenStepsWrapper
                onNext={nextComponent}
                onBack={() => setStep(1)}
                crumbs={crumbs}
                activePin={4}
            >
                <StepTwo />
            </ElevenStepsWrapper>}
        </>
    )
}