import React, { useState } from 'react';
import { Footer } from "../components/parts/Footer"
import { ReactComponent as PlayIcon } from "../assets/img/play-icon.svg";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";



const HeroSection = () => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
    };

    return (
        <section className="flex overflow-hidden relative flex-col justify-center items-stretch w-full lg:px-[70px] mb-11 lg:mb-[70px]">
            <div className="relative w-full h-full aspect-[1.6]">
                <video
                    className="object-cover absolute inset-0 size-full"
                    autoPlay={isPlaying}
                    loop
                    muted
                    poster={require('../assets/img/video-poster-img.png')}
                >
                    <source src="{{ext_4}}" type="video/mp4" />
                </video>
                <button
                    className="absolute w-full inset-0 flex items-center justify-center hover:opacity-70"
                    onClick={handlePlayPause}
                >
                    {!isPlaying
                        ?
                        (
                            <span className='flex aspect-square w-[70px] lg:w-[138px]'>
                                <PlayIcon />
                            </span>
                        )
                        :
                        null}
                </button>
            </div>
        </section>
    );
};

const SBM01_10_1_a = () => {
    const [interviewer, setInterviewer] = useState()
    const interviewers = [
        {
            value: "interviewer_01",
            label: "Interviewer 01"
        },
        {
            value: "interviewer_02",
            label: "Interviewer 02"
        },
    ]
    const handleSelect = (value) => {
        setInterviewer(value)
    }
    return (
        <>
            <BreadCrumbWhite
                crumbs={[
                    'Subscriber Dashboard',
                    'Jobs',
                    'Applicants',
                    'Candidates',
                    'Marc Spector',
                    'Report Card',
                    '1st Interview',
                    'Assignment',
                ]} />
            <section className='flex flex-col w-full flex-auto pt-10 pb-20 lg:pt-20 lg:pb-24 mx-auto max-w-[1440px] px-5'>
                <HeroSection />

                <div className="flex max-lg:flex-col justify-center gap-[12px] md:gap-[32px] self-stretch w-full">
                    <div className="my-auto font-semibold text-zinc-800 text-[26px] max-md:text-[16px]">
                        Assign to
                    </div>
                    <div className='w-full max-w-[560px]'>
                        <SelectField
                            inputBg="bg-white !rounded-[0px] border border-solid border-zinc-300 w-full"
                            name="department"
                            id="department"
                            parentClass=''
                            value={interviewer}
                            onChange={handleSelect}
                            options={interviewers}
                        />
                    </div>
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM01_10_1_a