import {useEffect, useState} from "react";
import {FirstInterviewSteps} from "./steps/firstInterviewSteps/FirstInterviewSteps";
import {FirstInterviewSurvey} from "./steps/FirstInterviewSurvey/FirstInterviewSteps";
import {SecondInterviewSteps} from "./steps/SecondInterview/SecondInterviewSteps";
import {DocumentReviewStep} from "./steps/documentReviewStep/DocumentReviewStep";
import {AIRecommendationStep} from "./steps/AIRecommendationStep/AIRecommendationStep";
import {TrainingInsightsStep} from "./steps/TrainingInsightsStep/TrainingInsightsStep";
import {OfferMeetingStep} from "./steps/OfferMeetingStep/OfferMeetingStep";
import {TrainingMilestonesStep} from "./steps/TrainingMilestonesStep/TrainingMilestonesStep";
import {ApplicationStep} from "./steps/applicationStep/ApplicationStep";
import {CommunicationsStep} from "./steps/communicationsStep/CommunicationsStep";
import {AssessmentStep} from "./steps/assessmentStep/AssessmentStep";
import {ResumeStep} from "./steps/resumeStep/ResumeStep";
import {ClarificationsStep} from "./steps/clarificationsStep/ClarificationsStep";
import {RatingOfTheJobStep} from "./steps/ratingOfTheJobStep/RatingOfTheJobStep";
import {stepsPath} from "./config";
import {useSearchParams} from "react-router-dom";
import {FinalHiringDecisionSteps} from "./steps/finalHiringDecisionStep/FinalHiringDecisionSteps";

export const MainContainerSBM01 = (props) => {
    const [step, setStep] = useState(stepsPath["1/11"])

    const [search, setSearch] = useSearchParams()
    const stepName = search.get('step')

    useEffect(() => {
        if (stepName) {
            const a=  Object.entries(stepsPath).find(el => el[1] === stepName)

            if (a) {
                setStep(stepsPath[a[0]])
            }
        } else {
            setStep(stepsPath['1/11'])
        }
    }, []);

    useEffect(() => {
        setSearch(`step=${step}`)
    }, [step]);


    return (
        <>
            {step === stepsPath["1/11"] && <ClarificationsStep
                prevComponent={() => {}}
                nextComponent={() => setStep(stepsPath["2/11"])} /> }

            {step === stepsPath["2/11"] && <FirstInterviewSteps
                prevComponent={() => setStep(stepsPath["1/11"])}
                nextComponent={() => setStep(stepsPath["3/11"])} />}

            {step === stepsPath["3/11"] && <FirstInterviewSurvey
                prevComponent={() => setStep(stepsPath["2/11"])}
                nextComponent={() => setStep(stepsPath["4/11"])} />}

            {step === stepsPath["4/11"] && <SecondInterviewSteps
                prevComponent={() => setStep(stepsPath["3/11"])}
                nextComponent={() => setStep(stepsPath["5/11"])} />}

            {step === stepsPath["5/11"] && <DocumentReviewStep
                prevComponent={() => setStep(stepsPath["4/11"])}
                nextComponent={() => setStep(stepsPath["6/11"])} />}

            {step === stepsPath["6/11"] && <RatingOfTheJobStep
                prevComponent={() => setStep(stepsPath["5/11"])}
                nextComponent={() => setStep(stepsPath["7/11"])} />}



            {step === stepsPath["7/11"] && <AIRecommendationStep
                prevComponent={() => setStep(stepsPath["6/11"])}
                nextComponent={() => setStep(stepsPath["8/11"])} />}

            {step === stepsPath["8/11"] && <FinalHiringDecisionSteps
                prevComponent={() => setStep(stepsPath["7/11"])}
                nextComponent={() => setStep(stepsPath["9/11"])} />}


            {step === stepsPath["9/11"] && <TrainingInsightsStep
                prevComponent={() => setStep(stepsPath["8/11"])}
                nextComponent={() => setStep(stepsPath["10/11"])} />}

            {step === stepsPath["10/11"] && <OfferMeetingStep
                prevComponent={() => setStep(stepsPath["9/11"])}
                nextComponent={() => setStep(stepsPath["11/11"])} />}

            {step === stepsPath["11/11"] && <TrainingMilestonesStep
                prevComponent={() => setStep(stepsPath["10/11"])}
                nextComponent={() => setStep(stepsPath["1/4"])} />}

            {/*  Second form  */}

            {step === stepsPath["1/4"] && <ApplicationStep
                prevComponent={() => setStep(stepsPath["11/11"])}
                nextComponent={() => setStep(stepsPath["2/4"])} />}

            {step === stepsPath["2/4"] && <CommunicationsStep
                prevComponent={() => setStep(stepsPath["1/4"])}
                nextComponent={() => setStep(stepsPath["3/4"])} /> }

            {step === stepsPath["3/4"] && <AssessmentStep
                prevComponent={() => setStep(stepsPath["2/4"])}
                nextComponent={() => setStep(stepsPath["4/4"])} /> }

            {step === stepsPath["4/4"] && <ResumeStep
                prevComponent={() => setStep(stepsPath["3/4"])}
                nextComponent={() => {}} /> }


        </>
    )
}