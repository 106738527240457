import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";

export const ConclusionSection = ({ payload, setPayload }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false);

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsEditing(false);
    }

    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.the_next_step_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        the_next_step_active: !payload.applicationForm.the_next_step_active
                    }
                });
            }
        },
    ]

    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <div className='flex flex-col gap-8 max-w-[650px] w-full'>
                    <div>
                        <EditableInput
                            className="font-bold lg:text-[30px] text-[24px]"
                            type="text"
                            name="the_next_step_title"
                            id="the_next_step_title"
                            value={payload.applicationForm.the_next_step_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        { payload.applicationForm.the_next_step_active ?
                            <EditableInput
                                className="leading-[30px] text-lg text-[#6D6D6D]"
                                type="text"
                                name="the_next_step_text"
                                id="the_next_step_text"
                                value={payload.applicationForm.the_next_step_text}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                        : null }
                    </div>
                    { payload.applicationForm.the_next_step_active ?
                        <div>
                            <EditableInput
                                className="font-bold text-[22px] leading-7"
                                type="text"
                                name="the_next_step_you_should_hear_title"
                                id="the_next_step_you_should_hear_title"
                                value={payload.applicationForm.the_next_step_you_should_hear_title}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                            <EditableInput
                                className="leading-[30px] text-lg text-[#6D6D6D]"
                                type="text"
                                name="the_next_step_you_should_hear_text"
                                id="the_next_step_you_should_hear_text"
                                value={payload.applicationForm.the_next_step_you_should_hear_text}
                                onChange={handleChange}
                                disabled={!isEditing}
                            />
                        </div>
                    : null }
                </div>
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-between">
                <EditableInput
                    className="font-bold lg:text-[30px] text-[24px]"
                    type="text"
                    name="the_next_step_title"
                    id="the_next_step_title"
                    value={payload.applicationForm.the_next_step_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton> :
                        <ButtonOptions options={options} />
                }
            </div>
            { payload.applicationForm.the_next_step_active ?
                <div className='flex flex-col gap-8 max-w-[650px] w-full'>
                    <div>
                        <EditableInput
                            className="leading-[30px] text-lg text-[#6D6D6D]"
                            type="text"
                            name="the_next_step_text"
                            id="the_next_step_text"
                            value={payload.applicationForm.the_next_step_text}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                    </div>
                    <div>
                        <EditableInput
                            className="font-bold text-[22px] leading-7"
                            type="text"
                            name="the_next_step_you_should_hear_title"
                            id="the_next_step_you_should_hear_title"
                            value={payload.applicationForm.the_next_step_you_should_hear_title}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                        <EditableInput
                            className="leading-[30px] text-lg text-[#6D6D6D]"
                            type="text"
                            name="the_next_step_you_should_hear_text"
                            id="the_next_step_you_should_hear_text"
                            value={payload.applicationForm.the_next_step_you_should_hear_text}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                    </div>
                </div>
            : null }
        </form>
    )
}