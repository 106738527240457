import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { NextStepSectionSBM01 } from "../base/NextStepSectionSBM01";



const Assessment = () => {
	const navigate = useNavigate()
	const { job: jobData, application: applicationData, jobId, applicationId, setActiveStep } = useOutletContext()
	useEffect(() => {
		setActiveStep(3)
	}, [])

	const handlePrev = () => {
		navigate(`/SBM01_10_14_2_2/${jobId}/${applicationId}`)
	}
	const handleNext = () => {
		navigate(`/SBM01_10_14_2_12/${jobId}/${applicationId}`)
	}

	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
				<div>
					<p className="text-[28px] md:text-3xl font-bold">Assessment 1</p>

					<hr className=" border-b-[#E2E2E2] my-[24px] md:my-9" />

					<div className="flex flex-col gap-6 w-full max-w-[400px]">
						{new Array(6).fill('').map((_, idx) => <div key={idx} className="flex items-center justify-between">
							<span className="text-xl font-bold">Category</span>
							<input type="text" className="grey-input-custom !w-[96px] !text-[#333333] text-center" value={'90%'} />

						</div>)}

						<div className="flex items-center justify-between gap-[20px]">
							<button className="flex font-semibold justify-center py-[15px] px-[15px] w-full bg-[#F7F7F7] rounded-md">Total Score</button>
							<input type="text" className="grey-input-custom !w-[96px] !text-[#333333] text-center" value={'90%'} />
						</div>

					</div>

				</div>

				<div>
					<p className="text-[28px] md:text-3xl font-bold">Assessment 2</p>

					<hr className=" border-b-[#E2E2E2] my-[24px] md:my-9" />

					<div className="flex flex-col gap-6 w-full max-w-[400px]">
						{new Array(6).fill('').map((_, idx) => <div key={idx} className="flex items-center justify-between">
							<span className="text-xl font-bold">Category</span>
							<input type="text" className="grey-input-custom !w-[96px] !text-[#333333] text-center" value={'90%'} />

						</div>)}

						<div className="flex items-center justify-between gap-[20px]">
							<button className="flex font-semibold justify-center py-[15px] px-[15px] w-full bg-[#F7F7F7] rounded-md">Total Score</button>
							<div className="input-green-point">
								<input type="text" className="grey-input-custom !w-[96px] !text-[#333333] text-center" value={'90%'} />
							</div>
						</div>

					</div>

				</div>
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={handleNext} />
		</div>
	)
}

export default Assessment



