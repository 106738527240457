import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from "../components/parts/Header";
import { Footer } from "../components/parts/Footer";

const TermsPrivacyLayout = () => {
    return (
        <>
            <div className="box-border flex relative flex-col shrink-0 min-h-[100dvh]">
                <Header colorStyle={"transparent"} />
                <main className={`flex relative flex-col -mt-[120px] z-[1] w-full`}>
                    <Outlet />
                </main>
                <Footer />
            </div>
        </>
    )
}

export default TermsPrivacyLayout