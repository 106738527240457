const base = 'api/communications';

export const communications = (instance) => ({
    async getAllComunications({ applicationId, query }) {
        const { data } = await instance.get(`${base}/${applicationId}?limit=100&${query}`);
        return data;
    },
    async sendMessageToApplicant({ applicationId, body }) {
        const { data } = await instance.post(`${base}/${applicationId}/send-message`, body);
        return data;
    },
    async getCallToken(applicationId) {
        const { data } = await instance.post(`api/twilio/token/${applicationId}`);
        return data;
    },
});

