import React from "react";
import { Footer } from "../components/parts/Footer";
import { useState } from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import SelectField from "../components/base/SelectField";
import InputField from "../components/base/InputField";
import ExportIcon from "../assets/img/export-icon.svg"
import { Link } from "react-router-dom";

const ButtonExport = ({ className, ...rest }) => {
    return (
        <Link to={"/SBM04_1_1"} className={`flex gap-2 items-center justify-center px-8 py-1 h-[52px] lg:h-[54px] text-lg font-[600] 
            text-center whitespace-nowrap bg-white rounded-md border border-solid
            border-zinc-300 max-md:px-5 text-[#666666] ${className ? className : ""}`}
            {...rest}
        >
            <img loading="lazy" src={ExportIcon} alt="" className="shrink-0 w-6 aspect-square" />
            <span className="md:grow md:my-auto">Export</span>
        </Link>
    )
}


const SBM04_2 = () => {
    const [formData, setFormData] = useState({
        job: '',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };
    const sourceData = [
        { sourceName: 'Indeed - Organic', count: '262', persectage: '100%' },
        { sourceName: 'Hireology Smart Post', count: '262', persectage: '80%' },
        { sourceName: 'Career Builder - Sponsored', count: '262', persectage: '50%' },
    ];
    const SourceItem = ({ sourceName, count, persectage }) => (
        <div className="flex gap-5 justify-between mt-6 max-md:flex-wrap max-md:max-w-full max-md:mt-[16px] max-md:gap-[12px]">
            <div className="my-auto lg:text-right text-stone-500 whitespace-nowrap min-w-[240px]"> {sourceName} </div>
            <div className="flex flex-auto">
                <div
                    className="h-[46px] flex items-center justify-end px-[12px] text-white text-end bg-[#003578] rounded-md max-md:pl-5 max-md:max-w-full"
                    style={{ width: persectage, }}
                >
                    {count}
                </div>
            </div>
        </div>
    );
    return (
        <>

            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Insights', 'Applicants Volume & Source']} />
            <section className="flex justify-center flex-auto w-full text-zinc-800">
                <div className="flex flex-col w-full max-w-[1432px] px-4">
                    <div className="flex max-lg:flex-col items-center justify-between lg:py-11 py-9 w-full lg:gap-[16px] gap-3 max-md:pb-[18px]">
                        <div className="flex flex-col gap-[16] max-w-[550px] w-full">
                            <SelectField
                                label="Job"
                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                name="job"
                                id="job"
                                value={formData.job}
                                onChange={handleChange}
                                options={[
                                    { value: '1-10', label: 'Cleaning Technician' },
                                    { value: '11-50', label: 'Cleaning Technician 2' },
                                    { value: '51-200', label: 'Cleaning Technician 3' },
                                    // Add more options here
                                ]}
                            />
                        </div>
                        <div className="flex flex-col gap-[16] max-w-[550px] w-full">
                            <InputField
                                label="Time Period" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                type="date"
                            />
                        </div>
                    </div>
                    <h3 className="text-[28px] font-normal text-start w-full">Which source gives me the most quality applicants?</h3>
                    <section className="flex flex-col my-9 lg:my-11 px-8 lg:px-[52px] py-9 lg:py-11 rounded-md border border-solid border-zinc-300">
                        <div className="flex gap-9 max-lg:flex-col">
                            <div className="flex flex-col w-[43%] max-md:ml-0 max-md:w-full">
                                <h2 className="flex-auto my-auto lg:text-[26px] text-2xl mb-8 lg:mb-[52px] text-[#666666]">Quality Rate</h2>
                                <div className="flex flex-col grow items-start text-stone-500 max-md:max-w-full">
                                    <div className="grid max-lg:grid-cols-2 gap-9">
                                        <div className="flex flex-col text-center items-center whitespace-nowrap">
                                            <div className="text-4xl leading-8">898</div>
                                            <h2 className="mt-4 text-xl whitespace-nowrap"> Total Applicants </h2>
                                        </div>
                                        <div className="flex flex-col text-center items-center whitespace-nowrap">
                                            <div className="text-4xl leading-8">12</div>
                                            <h3 className="mt-4 text-xl">Total Sources</h3>
                                        </div>
                                    </div>
                                    <div className="self-stretch mt-9 text-base leading-7 max-md:max-w-full">
                                        <b>Lorem ipsum dolor sit amet</b>, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus.
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col ml-5 w-[57%] max-md:ml-0 max-md:w-full">
                                <div className="flex items-center lg:justify-end lg:gap-14 mb-8 lg:mb-[52px] max-md:mb-[8px]">
                                    <div className="lg:text-[26px] text-2xl text-[#666666]"> Total Quality Applicants </div>
                                    <ButtonExport className={"max-lg:hidden"} />
                                </div>
                                <div className="flex flex-col grow self-stretch md:pb-11 text-lg max-md:max-w-full">
                                    {sourceData.map((source, index) => (
                                        <SourceItem key={index} sourceName={source.sourceName} count={source.count} persectage={source.persectage} />
                                    ))}
                                    <a href="#" className="self-center mt-6 text-base font-semibold text-green-500 whitespace-nowrap hover:opacity-70 max-md:self-start">Show top 10 sources</a>
                                    <ButtonExport className={"lg:hidden mt-9"} />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM04_2