import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const getPublicSolutionPagesFetch = createAsyncThunk(
    '/get-public-solution/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.publicSolution.getPublicSolutionPages(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getPublicSolutionPageByIdFetch = createAsyncThunk(
    '/get-public-solution-by-id/',
    async (pageId, { rejectWithValue }) => {
        try {
            const res = await api.publicSolution.getPublicSolutionPages(pageId);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getSupportSolutionPagesFetch = createAsyncThunk(
    '/get-support-solution/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.supportSolution.getSupportSolutionPages(payload)
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const getSupportSolutionPageByIdFetch = createAsyncThunk(
    '/get-support-solution-by-id/',
    async (pageId, { rejectWithValue }) => {
        try {
            const res = await api.supportSolution.getSupportSolutionPages(pageId)
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);