import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../api';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import BlueHedingTable from '../components/base/BlueHedingTable';
import { Footer } from '../components/parts/Footer';
import { getUser, getUserCompanyId } from '../redux/slices/applicationSlice';
import PageLoader from '../components/PageLoader';

const SBM05_INT = () => {
    const [isLoading, setIsLoading] = useState(false)
    const user = useSelector(getUser);
    const [data, setData] = useState([]);
    const companyId = useSelector(getUserCompanyId);
    useEffect(() => {
        if (!user || !companyId) return;
        setIsLoading(true)
        api.companyTeam.getTeamMembers(companyId).then(response => {
            if (!response.success) {
                return
            }
            setData(response.data.filter(item => item.userId === user.id).map(item => ({
                id: item.id,
                name: item.user.firstName,
                email: item.user.email,
                role: item.role,
                confirm: item.isVerified,
                editable: {
                    edit: `/SBM05_1/${item?.id}`
                },
                userId: item.userId
            })))
        }).finally(() => {
            setIsLoading(false)
        });
    }, [user])
    return (
        <div className='flex-auto flex flex-col'>
            <BreadCrumbWhite
                crumbs={['Subscriber Dashboard', 'Interview Setting']}
                statusSelect={true}
            />
            <section className='py-[50px] px-[20px] md:py-[90px] flex-auto'>
                <div className="max-w-[1400px] mx-auto">
                    {
                        !isLoading && user ?
                            <BlueHedingTable tableData={data} companyId={companyId} />
                            :
                            <PageLoader />
                    }
                </div>
            </section>

            <Footer hideCopyright={true} />
        </div>
    )
}

export default SBM05_INT
