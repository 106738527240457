const surveysBase = 'api/surveys';

export const surveys = (instance) => ({
    async setAnswers(payload) {
        const { data } = await instance.post(`${surveysBase}`, payload);
        return data;
    },
    async createDefaultSurveys(jobApplicationId, interviewerId, payload) {
        const { data } = await instance.post(`${surveysBase}/${jobApplicationId}/${interviewerId}`, payload);
        return data;
    },
    async getSurveysByJobApplicationId(jobApplicationId, payload) {
        const { data } = await instance.get(`${surveysBase}/${jobApplicationId}`, payload);
        return data;
    },
    async getSurveysForInterviewerByJobApplicationId(jobApplicationId, payload) {
        const { data } = await instance.get(`${surveysBase}/interviewer/${jobApplicationId}`, payload);
        return data;
    },
});
