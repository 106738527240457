import React, {useEffect, useState} from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import InputField from "../components/base/InputField";
import TextareaField from "../components/base/TextareaField";
import SelectField from "../components/base/SelectField";
import FileInputField from "../components/base/FileInputField";
import MultiSelect from "../components/base/MultiSelect";
import { useForm } from 'react-hook-form';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCreateSupSolCategories,
    fetchCreateSupSolPage, fetchCreateSupSolTags, fetchDeleteSupSolPage,
    fetchSupportSolutionsOnePages, fetchSupSolCategories, fetchSupSolTags,
    fetchUpdateSupSolPage
} from "../redux/thunks/supportThunks";
import SelectBox from "../components/base/SelectBox";
import {getSupportSolutionsCategories, getSupportSolutionsTags} from "../redux/slices/supportSolutionsSlice";
import getImageUrl from "../assets/helpers/getImageUrl";
import Editor from "../components/CKEditor";


const Heading = ({text, className}) => (
    <div className={`text-[22px] leading-[22px] font-bold text-zinc-800 ${className}`}>{text}</div>
);

const CategoryAddPart = ({onSuccess}) => {
    const dispatch = useDispatch()
    
    const [val, setVal] = useState('')
    const [disabled, setDisabled] = useState(false)
    
    const create = async (e) => {
        e.stopPropagation()
        e.preventDefault()
        setDisabled(true)
        const res = await dispatch(fetchCreateSupSolCategories({'name': val}))
        
        console.log(res, 'inside')

        if (res?.payload?.id) {
            setVal('')
            onSuccess && onSuccess(res.payload.id)
        }
        
        setDisabled(false)
    }
    
    
    


    return (
        <div className={'flex items-end gap-[10px] flex-wrap'} onClick={e => e.stopPropagation()}>
            <InputField
                label="New Category"
                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none "
                parentClassName={'!mb-[0px]'}
                name="category" id="category" placeholder="new category"
                value={val} onChange={(e) => setVal(e.currentTarget.value)}
                disabled={disabled}
            />
            <button onClick={create}
                className={`${disabled ? 'disabled' : ''} min-w-[150px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[54px] md:h-[54px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70`}>
                Add
            </button>
        </div>
    )
}

const TagsPart = () => {
    const dispatch = useDispatch()
    const [val, setVal] = useState('')


    const [disabled, setDisabled] = useState(false)

    const create = async (e) => {
        e.stopPropagation()
        e.preventDefault()
        setDisabled(true)
        const res = await dispatch(fetchCreateSupSolTags({'name': val}))

        console.log(res, 'inside')

        if (res?.payload?.id) {
            setVal('')
            // onSuccess && onSuccess(res.payload.id)
        }

        setDisabled(false)
    }

    return (
        <>


            <div className={'flex items-end mt-[18px] gap-[10px] flex-wrap'} onClick={e => e.stopPropagation()}>
                <InputField
                    label="New Tag"
                    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none "
                    parentClassName={'!mb-[0px]'}
                    name="category" id="category" placeholder="new tag"
                    value={val} onChange={(e) => setVal(e.currentTarget.value)}
                    disabled={disabled}
                />
                <button onClick={create}
                        className={`${disabled ? 'disabled' : ''} min-w-[150px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[54px] md:h-[54px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70`}>
                    Add
                </button>
            </div>

        </>

    )
}


const SUP01_3_1 = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const postId = params.id ? params.id : false;
    const categ = useSelector(getSupportSolutionsCategories)
    const sliceTags = useSelector(getSupportSolutionsTags)
    const mode = postId ? 'update' : 'create'


    const navigate = useNavigate()


    const [pageError, setPageError] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const [isSelectImageChange, setIsSelectImageChange] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const {register, handleSubmit} = useForm();

    const [categoryOptions, setCategoryOptions] = useState([{label: 'Choose', value: ''}])
    const [tags, setTags] = useState([])

    const [choosedTags, setChoosedTags] = useState(null)


    // console.log(selectedImage, 'selectedImage')

    useEffect(() => {
        if (!categ && !categ?.length > 0) return
        setCategoryOptions([{label: 'Choose', value: ''}, ...categ.map(el => ({label: el.name, value: el.id}))])
    }, [categ]);

    useEffect(() => {
        if (!sliceTags || sliceTags?.length === 0) return
            setTags([ ...sliceTags.map(el => ({label: el.name, value: el.id}))])

    }, [sliceTags]);



    const [formData, setFormData] = useState({
        name: '',
        slug: '',
        categoryId: '',
        postTitle: '',
        subTitle: '',
        postBody: '',
        postSummary: '',
        mainVideo: '',
    });
    
    // console.warn(choosedTags)
    
    // console.log(formData, 'formData')

    useEffect(() => {

        if (!categ) {
            dispatch(fetchSupSolCategories())
        }

        if (!sliceTags) {
            dispatch(fetchSupSolTags())
        }


        const req = async () => {
            setIsLoading(true)
            const res = await dispatch(fetchSupportSolutionsOnePages(postId))
            setIsLoading(false)

            if (res?.payload?.id) {
                const d = res?.payload
                setFormData({
                    name: d.name,
                    slug: d.slug,
                    postTitle: d.postTitle,
                    postBody: d.postBody,
                    postSummary: d.postSummary,
                    subTitle: d.subTitle,
                    categoryId: d.categoryId,
                })

                if (d.tags) {
                    setChoosedTags(d.tags.map(el => ({label: el.name, value: el.id})))
                }
                
                if (d.mainImage) {
                    fetch(`${getImageUrl(d.mainImage)}`).then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], "main-image.jpg", {type: blob.type});
                            
                            console.log(file, 'file')

                            setSelectedImage(prev => ({...prev, 'main': file}))
                        })
                }

                if (d.thumbailImage) {
                    fetch(`${getImageUrl(d.thumbailImage)}`).then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], "thumbail-image.jpg", {type: blob.type});

                            console.log(file, 'file')

                            setSelectedImage(prev => ({...prev, 'thumbail': file}))
                        })
                }

                // setSelectedImage(prev => ({...prev, 'main': URL.createObjectURL(d.mainImage)}))

                // preview: URL.createObjectURL(file)
            } else {
                setPageError(true)
            }
        }

        if (postId) {
            req()
        }
    }, []);

    const [isVideoMain, setIsVideoMain] = useState(false);


    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
        setErrors(prevState => ({ ...prevState, [name]: false }));
    };

    const deletePage = async () => {
        setIsLoading(true)
        const res = await dispatch(fetchDeleteSupSolPage(postId))

        if (res?.payload) {
            navigate(`/SUP01_3_1_1?id=${res?.payload}&status=deleted`)
        }

        setIsLoading(false)
    }

    const onSubmit = async (data) => {

        const fields = Object.entries(formData)
        let anyErrors = false

        fields.forEach(el => {
            if (el[0] === 'mainVideo') {
                return
            }

            if (!el[1]) {
                setErrors(prevState => ({...prevState, [el[0]]: true}))
                anyErrors = true
            }
        })

        if (anyErrors) return

        const dataWithImage = new FormData()

        console.log(isSelectImageChange?.main, 'isSelectImageChange?.main')
        console.log(selectedImage.main, 'selectedImage.main')
        console.log(isVideoMain, 'isVideoMain')
        
        if (isSelectImageChange?.main) {
            if (selectedImage.main && !isVideoMain) {
                dataWithImage.append('mainImage', selectedImage.main, selectedImage.main.name)
            } else if (isVideoMain) {
                dataWithImage.append('mainImage', formData.mainVideo)
            } else {
                dataWithImage.append('mainImage', '')
            }

        }

        if (isSelectImageChange?.thumbail) {
            if (selectedImage.thumbail) {
                dataWithImage.append('thumbailImage', selectedImage.thumbail, selectedImage.thumbail.name)
            } else {
                dataWithImage.append('thumbailImage', '')
            }

        }
        
        if (choosedTags) {
            dataWithImage.append('tags', choosedTags.map(el => el.value))
        }

        Object.entries(formData).forEach(([key, value]) => {
            dataWithImage.append(key, value);
        });


        if (mode === 'create') {
            const res = await dispatch(fetchCreateSupSolPage(dataWithImage))

            if (res?.payload?.id) {
                navigate(`/SUP01_3_1_1?id=${res?.payload?.id}&status=submitted`)
            }
        }

        if (mode === 'update') {
            const res = await dispatch(fetchUpdateSupSolPage({data: dataWithImage, id: postId}))

            if (res?.payload?.id) {
                navigate(`/SUP01_3_1_1?id=${res?.payload?.id}&status=updated`)
            }
        }

        


    };

    const handleImageChange = (val, type) => {
        // if (e.target.files.length > 0) {
            setSelectedImage(prev => ({...prev, [type]: val}));
            setIsSelectImageChange(prev => ({...prev, [type]: true}))
        //     setFormData(prevState => ({...prevState, thumbailImage: val}))
        // }
    };

    
    return (
        <>
            <BreadCrumbWhite crumbs={[['EJ Support', '/SUP01'], ['Subscriber Support Center List', '/SUP01_3'], 'Individual Input Page']} />

            <main className="flex relative flex-col py-[90px] z-[1] max-w-[1240px] w-full m-auto flex-1 max-md:pt-[40px] max-md:pb-[80px]">
                {pageError && <>
                    <h2 className="lg:text-[40px] text-4xl leading-[44px] text-center lg:leading-[40px] mb-[8px] font-bold text-zinc-800">Individual
                        Input Page Not Found</h2>
                    <p className="text-[18px] text-center">Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit.</p>

                    <div className="flex justify-center w-full">
                        <button onClick={() => navigate("/SUP01_3")}
                                className="md:mt-11 mt-10 min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70">
                            Back
                        </button>
                    </div>
                </>}

                {!pageError &&
                    <form className="flex justify-center items-center w-full px-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col items-center w-full max-w-[1400px]">
                            <h2 className="lg:text-[40px] text-4xl leading-[44px] text-center lg:leading-[40px] mb-[8px] font-bold text-zinc-800">Individual
                                Input Page</h2>
                            <p className="text-[18px] text-center">Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.</p>
                        <div
                            className="flex flex-col self-stretch py-9 px-8 lg:p-[60px] mt-11 bg-white rounded-md border border-solid border-zinc-300">
                            <h3 className="lg:text-[32px] text-3xl leading-[36px] lg:leading-[40px] mb-[16px] font-bold text-zinc-800">Basic
                                Info</h3>
                            <hr className="lg:mb-10 mb-9"/>

                            <InputField
                                label="Name*" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"
                                name="name" id="name" placeholder="Go Chemical Free For Spring"
                                value={formData.name} onChange={handleChange} error={errors.name}
                                disabled={isLoading}
                            />

                            <InputField
                                label="Slug*" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"
                                name="slug" id="slug" placeholder="Go Chemical Free For Spring"
                                value={formData.slug} onChange={handleChange} error={errors.slug} disabled={isLoading}
                            />

                            {/*<InputField*/}
                            {/*    label="Category*" inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"*/}
                            {/*    name="category" id="category" placeholder="Go Chemical Free For Spring"*/}
                            {/*    value={formData.category} onChange={handleChange} error={errors.category}*/}
                            {/*/>*/}
                            <div key={formData.categoryId}>
                                <SelectBox options={categoryOptions} label="Category*"
                                           onSelect={val => {
                                               setFormData(prevState => ({...prevState, 'categoryId': val}))
                                               setErrors(prevState => ({...prevState, 'categoryId': false }));

                                           }}
                                           parentClass={'flex-1 mb-[18px]'}
                                           value={formData.categoryId} svg={2}
                                           error={errors.categoryId}
                                />
                            </div>


                            <CategoryAddPart onSuccess={val => setFormData(prevState => ({...prevState, 'categoryId': val}))} />


                            <h3 className="lg:text-[32px] mt-6 lg:mt-[26px] text-3xl leading-[36px] lg:leading-[40px] mb-[16px] font-bold text-zinc-800">Custom
                                Fields</h3>
                            <hr className="lg:mb-10 mb-9"/>

                            <InputField
                                label="Post Title"
                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"
                                name="postTitle" id="postTitle" placeholder="Go Chemical Free For Spring"
                                value={formData.postTitle} onChange={handleChange} error={errors.postTitle}
                                disabled={isLoading}
                            />

                            <InputField
                                label="Sub Title"
                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"
                                name="subTitle" id="subTitle" placeholder="Go Chemical Free For Spring"
                                value={formData.subTitle} onChange={handleChange} error={errors.subTitle}
                                disabled={isLoading}
                            />

                            {/*<TextareaField*/}
                            {/*    label="Post Body"*/}
                            {/*    inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"*/}
                            {/*    name="postBody" id="postBody" placeholder="Go Chemical Free For Spring"*/}
                            {/*    value={formData.postBody} onChange={handleChange} error={errors.postBody}*/}
                            {/*    disabled={isLoading}*/}
                            {/*    className="min-h-[378px] max-md:min-h-[250px]"*/}
                            {/*/>*/}

                            <Heading text="Post Body" className="text-[16px] md:text-[18px] font-bold text-zinc-800 leading-[18px]"/>
                            <Editor data={formData.postBody} onChange={val => setFormData(prevState => ({...prevState, postBody: val}))} />


                            <InputField
                                label="Post Summary"
                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"
                                name="postSummary" id="postSummary" placeholder="Go Chemical Free For Spring"
                                value={formData.postSummary} onChange={handleChange} error={errors.postSummary}
                                afterLabel={<p>A Summary of the blog post that appears on blog post grid</p>}
                                disabled={isLoading}
                            />

                            <Heading text="Main Image or Video?" className="mt-[16px] mb-[8px] max-md:text-[16px]"/>
                            <p className="mb-[8px]">Set to On if you want to have a video instead of the “main
                                image”</p>
                            <div className="flex items-center">
                                <input type="checkbox" id="agreement" className="hidden" onChange={(e) => {
                                    setIsVideoMain(e.target.checked);
                                }}/>
                                <label htmlFor="agreement" className="flex items-center cursor-pointer">
                                    <span
                                        className={`flex h-[28px] w-[58px] items-center rounded-full aspect-square p-1 transition-all border border-solid border-[#DEDED9] ${isVideoMain ? 'bg-[#37B34A]' : 'bg-[#fff]'}`}
                                        aria-hidden="true">
                                        <span
                                            className={`h-[20px] w-[20px] rounded-full transition-all ${isVideoMain ? 'ml-[30px] bg-white' : 'bg-[#DADADA]'}`}></span>
                                    </span>
                                </label>
                            </div>


                            <Heading text="Main Image" className="mt-8 mb-2 max-md:text-[16px]"/>
                            <div onClick={e => e.preventDefault()}>

                                <FileInputField fileProp={selectedImage?.main} boxClassName="md:mb-[32px] max-md:mb-[8px]" handleChange={(val) => handleImageChange(val, 'main')}/>
                            </div>

                            <InputField
                                label="Main Video"
                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] rounded-none"
                                labelClassName="text-[22px] leading-[22px] font-bold text-zinc-800 max-md:text-[16px]"
                                name="mainVideo" id="mainVideo" placeholder="Paste VIdeo URL"
                                value={formData.mainVideo} onChange={handleChange} error={errors.mainVideo}
                                disabled={isLoading}
                            />

                            <Heading text="Thumbnail Image" className="md:mt-5 mb-2 max-md:text-[16px]"/>
                            <div onClick={e => e.preventDefault()}>
                                <FileInputField fileProp={selectedImage?.thumbail} loadBtnShow={true} handleChange={(val) => handleImageChange(val, 'thumbail')}/>
                            </div>

                            <Heading text="Add Key Tags" className="md:mt-5 mb-2 max-md:text-[16px]"/>
                            <MultiSelect value={choosedTags} options={tags} showRemoveButton={true} onChange={(val) => setChoosedTags(val)}/>

                            <TagsPart />
                        </div>
                        <div className="flex justify-center w-full">
                            <button type="submit"
                                    className="md:mt-11 mt-10 min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md bg-[#37B34A] max-md:px-5 hover:opacity-70">
                                Submit
                            </button>
                        </div>

                            {postId && <div className="flex justify-center w-full">
                                <button onClick={deletePage}
                                        className="md:mt-11 mt-10 min-w-[236px]  text-[19px] md:text-[22px] font-[700] uppercase text-[#fff] justify-center items-center px-16 py-1 max-md:w-full h-[60px] md:h-[64px] rounded-md bg-[#e74229] max-md:px-5 hover:opacity-70">
                                    Delete
                                </button>
                            </div>}
                    </div>
                </form>}
            </main>
        </>
    );
};

export default SUP01_3_1;