import React, { useEffect } from "react";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { useState } from "react";
import InputField from "../components/base/InputField";
import SelectField from "../components/base/SelectField";
import { Footer } from "../components/parts/Footer";
import { object, ref, string } from 'yup'

import { ReactComponent as EyeSlashIcon } from "../assets/img/EyeSlash.svg"
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSubscriberRole, getUser, getUserCompanyId } from "../redux/slices/applicationSlice";
import api from "../api";
import { toast } from "react-toastify";
import { State } from "country-state-city";
import PrimaryButton from "../components/base/PrimaryButton";
import PageLoader from "../components/PageLoader";

const RadioButton = ({ label, id, name, onChange, checked, value }) => {
    return (
        <label
            className="flex gap-2.5 md:pr-5 cursor-pointer items-center"
        >
            <span
                className="flex flex-col justify-center items-center px-1 border border-solid bg-stone-50 border-stone-300 h-[18px] rounded-[360px] w-[18px] cursor-pointer"
            >
                {checked && (
                    <div className="shrink-0 w-2.5 h-2.5 bg-green-500 rounded-[360px]" />
                )}
                <input
                    className="hidden"
                    type="radio"
                    name={name}
                    id={id}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                />
            </span>
            {label ? <div className="my-auto text-lg font-semibold text-zinc-800">{label}</div> : null}
        </label>
    )
};

const SBM05_1 = () => {
    const { memberId } = useParams();
    const user = useSelector(getUser);
    const companyId = useSelector(getUserCompanyId);
    const userRole = useSelector(getSubscriberRole);

    const [member, setMember] = useState(null)
    const [isPageLoading, setIsPageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        currentEmail: '',
        newEmail: '',
        confirmEmail: '',
        firstName: '',
        lastName: '',
        role: 'admin',
        currentPassword: '•••••••••••••',
        newPassword: '',
        confirmPassword: '',
        address: '',
        city: '',
        postalCode: '',
        phone: '',
        state: ''
    });
    const [errors, setErrors] = useState({});
    const [isChangingField, setIsChangingField] = useState({
        email: false,
        password: false
    })
    const states = State.getStatesOfCountry('US')?.map(item => ({ value: item.isoCode, label: item.name }));
    const [availableRoles, setAvailableRoles] = useState([])
    const roleOptions = [
        {
            id: 1,
            name: "Admin",
            value: 'admin',
            link: "/SBM05_ADM",
            description: "Best for business owners and company managers",
            disabled: true,
            availableRoles: ["admin", "member", "interviewer"]
        },
        {
            id: 2,
            name: "Team Member",
            value: "member",
            link: "/SBM05_TEA",
            description: "Best for business owners and company managers",
            availableRoles: ["member", "interviewer"]
        },
        {
            id: 3,
            name: "Interviewer",
            value: 'interviewer',
            link: "/SBM05_INT",
            description: "Best for interviewers",
            availableRoles: ["interviewer"]
        },
    ];


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleChangeEmail = async () => {
        try {
            setIsLoading(true)
            const validationSchema = object({
                newEmail: string()
                    .email('Incorrect email format')
                    .required('Please, enter New Email'),
                confirmEmail:
                    string()
                        .oneOf([ref('newEmail'), null], 'Confirm Email must match to New Email')
                        .required('Please, enter Confirm Email'),
            });
            await validationSchema.validate(formData, {
                abortEarly: false
            });
            const response = await api.companyTeam.updateTeamMembers(companyId, member.userId, {
                email: formData.newEmail
            });
            if (response.success) {
                toast.success('Updated successfully!')
                setMember(response?.data)
                setFormData(prev => ({
                    ...prev,
                    currentEmail: member.user.email,
                    newEmail: '',
                    confirmEmail: '',

                }))
            } else {
                toast.error(response.message)
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                console.log(err.inner)
                const errors = err.inner.reduce((acc, innerError) => {
                    acc[innerError.path] = innerError.message;
                    toast.error(innerError.message)
                    return acc;
                }, {});
            }
        } finally {
            setIsLoading(false)
        }

    }
    const handleChangePassword = async () => {
        try {
            setIsLoading(true)
            const validationSchema = object({
                newPassword: string().nullable().transform((value, originalValue) => originalValue === '' ? null : value)
                    .min(8, 'Password must contain minimum 6 characters'),
                confirmPassword:
                    string()
                        .nullable()
                        .transform((value, originalValue) => originalValue === '' ? null : value)
                        .oneOf([ref('newPassword'), null], 'Confirm Password must match to New Password'),
                firstName: string().required('First Name is required'),
                lastName: string().required('Last Name is required')
            });
            await validationSchema.validate(formData, {
                abortEarly: false
            });
            const response = await api.companyTeam.updateTeamMembers(companyId, member.userId, {
                ...formData.newPassword && { password: formData.newPassword },
                firstName: formData.firstName,
                lastName: formData.lastName,
                ...(formData.role !== member.role ? { role: formData.role } : {})
            });
            if (response.success) {
                setMember(response?.data)
                setFormData(prev => ({
                    ...prev,
                    firstName: response?.data.user.firstName,
                    lastName: response?.data.user.lastName,
                    role: response?.data.role,
                    newPassword: '',
                    confirmPassword: '',
                }))
                toast.success('Updated successfully!')
            } else {
                if (response?.errors?.length) {
                    response.errors.forEach(error => {
                        toast.error(error?.msg)
                    });

                } else {
                    toast.error(response.message || "Something went wrong")
                }
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                console.log(err.inner)
                const errors = err.inner.reduce((acc, innerError) => {
                    acc[innerError.path] = innerError.message;
                    toast.error(innerError.message)
                    return acc;
                }, {});
            }
        }
        finally {
            setIsLoading(false)
        }
    }
    const handleChangeAddress = async () => {
        try {
            setIsLoading(true)
            const validationSchema = object({
                address: string().required('Please, enter Address'),
                city: string().required('Please, enter City'),
                state: string().required('Please, enter State'),
                postalCode: string().required('Please, enter Zip Code'),
                phone: string().required('Please, enter Phone'),
            });
            await validationSchema.validate(formData, {
                abortEarly: false
            });
            const response = await api.companyTeam.updateTeamMembers(companyId, member.userId, {
                addressLine: formData.address,
                city: formData.city,
                state: formData.state,
                zip: formData.postalCode,
                phone: formData.phone
            });
            if (response.success) {
                setMember(response?.data)
                setFormData(prev => ({
                    ...prev,
                    address: response?.data.addressLine,
                    city: response?.data.city,
                    state: response?.data.state,
                    phone: response?.data.phone,
                    postalCode: response?.data.zip,
                }))
                toast.success('Updated successfully!')
            } else {
                toast.error(response.message)
            }
        } catch (err) {
            if (err.name === 'ValidationError') {
                console.log(err.inner)
                const errors = err.inner.reduce((acc, innerError) => {
                    acc[innerError.path] = innerError.message;
                    toast.error(innerError.message)
                    return acc;
                }, {});
            }
        } finally {
            setIsLoading(false)
        }
    }
    const handleChangeTwoFactor = async () => {
        try {
            setIsLoading(true)

            const response = await api.companyTeam.updateTeamMembers(companyId, member.userId, {
                twofactor: !member?.user?.twofactor ? "email" : null
            });
            if (response.success) {
                setMember(response?.data)

                toast.success('Updated successfully!')
            } else {
                toast.error(response.message)
            }
        } catch (err) {
            console.log(err);
            toast.error(err.message || err || "Something went wrong")
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleCancelEmail = () => {
        setFormData(prev => ({
            ...prev,
            newEmail: '',
            confirmEmail: ''
        }))
        setIsChangingField(prev => ({ ...prev, email: false }))
    }
    const handleCancelPassword = () => {
        setFormData(prev => ({
            ...prev,
            newPassword: '',
            confirmPassword: '',
            role: member?.role,
            firstName: member.user?.firstName,
            lastName: member.user?.lastName,
        }))
        setIsChangingField(prev => ({ ...prev, password: false }))
    }
    const handleCancelAddress = () => {
        setFormData(prev => ({
            ...prev,
            address: member.addressLine,
            city: member.city,
            state: member.state,
            phone: member.phone,
            postalCode: member.zip,
        }))
    }

    useEffect(() => {
        if (!user || !companyId) return;
        setIsPageLoading(true)
        api.companyTeam.getTeamMembers(companyId).then(response => {
            if (!response.success) {
                return
            }
            const member = response.data.find(item => item.id.toString() === memberId);
            console.log(member);
            setMember(member)
            setFormData(prev => ({
                ...prev,
                currentEmail: member.user.email,
                firstName: member.user.firstName,
                lastName: member.user.lastName,
                role: member.role,
                address: member.addressLine,
                city: member.city,
                state: member.state,
                phone: member.phone,
                postalCode: member.zip,
                role: member?.role
            }))
        }).finally(() => {
            setIsPageLoading(false)
        })
        if (userRole) {
            setAvailableRoles(getRoleOption(userRole).availableRoles)
        }
    }, [user])

    const getRoleOption = (role) => {
        return roleOptions.find(({ value }) => value === role) || { name: "Team Member" }
    }

    return (
        <>
            {
                !isPageLoading ?
                    <>
                        <BreadCrumbWhite crumbs={['Subscriber Dashboard', ['Settings', getRoleOption(userRole)?.link], `${getRoleOption(member?.role)?.name} Settings`]} />
                        <main className="flex relative flex-col py-[90px] px-4 z-[1] max-w-[1432px] w-full m-auto flex-1 max-md:pt-[40px] max-md:pb-[80px]">
                            <div className="flex flex-col justify-center bg-white rounded-md shadow-lg">
                                <header className="justify-center items-center px-16 py-9 w-full text-3xl font-bold text-white uppercase whitespace-nowrap bg-[#003578] max-md:px-5 max-md:max-w-full max-md:py-[12px] max-md:text-[18px] max-md:text-center max-md:rounded-t-[10px]">
                                    Contact info
                                </header>
                                <main className="flex flex-col px-20 py-16 w-full bg-white max-md:max-w-full max-md:px-[32px] max-md:py-[36px]">
                                    <h2 className="pb-6 text-3xl mb-12 font-bold whitespace-nowrap border-b-2 border-green-500 border-solid text-zinc-800 max-md:max-w-full max-md:text-[24px] max-md:leading-[29px] max-md:mb-[36px]">
                                        Name
                                    </h2>
                                    <section className="grid md:grid-cols-2 gap-8 max-md:gap-[0px]">
                                        <div>
                                            <InputField
                                                label={<p>Current <br className="max-md:hidden" /> Email</p>}
                                                labelClassName={"min-w-[100px] text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                                parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:gap-[12px]"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                type="email"
                                                name="currentEmail"
                                                id="currentEmail"
                                                placeholder="youremailaddress@mail.com"
                                                value={formData.currentEmail}
                                                onChange={handleChange}
                                                error={errors.currentEmail}
                                                disabled={true}
                                            />
                                        </div>
                                        {
                                            !isChangingField.email ?
                                                <button
                                                    onClick={() => setIsChangingField(prev => ({ ...prev, email: !isChangingField.email }))}
                                                    className="justify-center px-12 items-center !text-lg !h-[54px] font-semibold text-white whitespace-nowrap bg-red-600 rounded-md w-fit max-md:w-full hover:opacity-70"
                                                >
                                                    Change
                                                </button>
                                                : null
                                        }
                                    </section>
                                    {
                                        isChangingField.email ?
                                            <>
                                                <section className="grid md:grid-cols-2 gap-8 mt-[10px] max-md:mt-[26px]">
                                                    <div>
                                                        <InputField
                                                            label={<p>New <br className="max-md:hidden" /> Email</p>}
                                                            labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                                            parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:m-[0px]"
                                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                            type="email"
                                                            name="newEmail"
                                                            id="newEmail"
                                                            placeholder="youremailaddress@mail.com"
                                                            value={formData.newEmail}
                                                            onChange={handleChange}
                                                            error={errors.newEmail}
                                                        />
                                                    </div>
                                                    <div>
                                                        <InputField
                                                            label={<p>Confirm <br className="max-md:hidden" /> Email</p>}
                                                            labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                                            parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start"
                                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                            type="email"
                                                            name="confirmEmail"
                                                            id="confirmEmail"
                                                            placeholder="youremailaddress@mail.com"
                                                            value={formData.confirmEmail}
                                                            onChange={handleChange}
                                                            error={errors.confirmEmail}
                                                        />
                                                    </div>
                                                </section>
                                                <div className="flex gap-3 justify-center self-start mt-[10px] text-lg font-semibold whitespace-nowrap max-md:flex-col max-md:w-full">
                                                    <PrimaryButton
                                                        isLoading={isLoading}
                                                        className="grow justify-center px-9 items-center !text-lg !h-[54px] text-white bg-green-500 rounded-md max-md:px-5 hover:opacity-70"
                                                        onClick={handleChangeEmail}
                                                    >
                                                        Save Changes
                                                    </PrimaryButton>
                                                    <button onClick={handleCancelEmail} className="grow justify-center px-12 items-center !text-lg !h-[54px] rounded-md bg-zinc-100 text-zinc-800 max-md:px-5 hover:opacity-70">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </>
                                            : null
                                    }
                                    <section className="grid md:grid-cols-2 gap-8 mt-[50px] max-md:gap-[26px]">
                                        <div className="">
                                            <InputField
                                                label={"Name"}
                                                labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                                parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:m-[0px]"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                placeholder="youremailaddress@mail.com"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                error={errors.firstName}
                                            />
                                        </div>
                                        <div>
                                            <InputField
                                                parentClassName="!flex-row items-center"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                type="text"
                                                name="lastName"
                                                id="lastName"
                                                placeholder="youremailaddress@mail.com"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                error={errors.lastName}
                                            />
                                        </div>
                                    </section>
                                    <section className="flex gap-[16px] justify-between mt-[10px] max-md:flex-col my-7">
                                        <label className="flex min-w-[100px] md:h-[70px] items-center md:text-right md:justify-end text-lg font-bold leading-6">
                                            Role
                                        </label>
                                        <div className="flex flex-col grow shrink-0 justify-center basis-0 w-fit max-md:max-w-full">
                                            {roleOptions.map((option) => (
                                                <>
                                                    {
                                                        availableRoles.includes(option.value) ?
                                                            <div
                                                                key={option.id}
                                                                className={`flex gap-5 md:h-[70px] md:justify-between items-center px-6 border-t border-solid border-zinc-300 max-md:px-[0px] max-md:gap-[12px] max-md:py-[16px]`}
                                                            >
                                                                <RadioButton
                                                                    checked={option.value === formData.role}
                                                                    onChange={() => {
                                                                        if (userRole !== "admin" || option.disabled || formData.role === 'admin' || !availableRoles.includes(option.value)) return
                                                                        setFormData(prevState => ({ ...prevState, role: option.value }))

                                                                    }}
                                                                />
                                                                <div className="min-w-44 self-stretch my-auto text-lg font-semibold text-zinc-800 max-md:min-w-[initial] max-md:w-full">
                                                                    {option.name}
                                                                </div>
                                                                <div className="flex-auto self-stretch my-auto text-lg text-zinc-800 max-md:max-w-full max-md:w-full max-md:text-[16px] max-md:leading-[19px]">
                                                                    {option.description}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </>
                                            ))}
                                        </div>
                                    </section>
                                    <section className="grid md:grid-cols-2 gap-8 max-md:gap-[12px]">
                                        <div>
                                            <InputField
                                                label={<p>Current <br className="max-md:hidden" /> Password</p>}
                                                labelClassName={"min-w-[100px] text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                                parentClassName="!flex-row items-center relative max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                type="password"
                                                name="currentPassword"
                                                id="currentPassword"
                                                placeholder="youremailaddress@mail.com"
                                                value={formData.currentPassword}
                                                onChange={handleChange}
                                                error={errors.currentPassword}
                                                disabled={true}
                                                afterLabel={<button className="absolute right-4 z-[1] bottom-[15px]"><EyeSlashIcon /></button>}
                                            />
                                        </div>
                                        <div className="flex gap-3 max-md:flex-col">
                                            {
                                                !isChangingField.password ?
                                                    <button
                                                        onClick={() => setIsChangingField(prev => ({ ...prev, password: !isChangingField.password }))}
                                                        className="justify-center px-12 items-center !text-lg !h-[54px] font-semibold text-white whitespace-nowrap bg-red-600 rounded-md w-fit max-md:w-full hover:opacity-70"
                                                    >
                                                        Change
                                                    </button>
                                                    : null
                                            }
                                            <PrimaryButton
                                                isLoading={isLoading}
                                                onClick={handleChangeTwoFactor}
                                                className="justify-center px-4 lg:px-12 items-center !text-sm lg:!text-lg !h-[54px] font-semibold text-white whitespace-nowrap rounded-md w-fit bg-sky-900 max-md:w-full hover:opacity-70"
                                            >
                                                {!member?.user?.twofactor ?
                                                    "2-Factor Authentication"
                                                    :
                                                    "Cencel 2-Factor Authentication"
                                                }
                                            </PrimaryButton>
                                        </div>
                                    </section>
                                    {
                                        isChangingField.password ?
                                            <>
                                                <section className="grid md:grid-cols-2 gap-8 mt-[10px] max-md:mt-[26px] max-md:gap-[26px]">
                                                    <div>
                                                        <InputField
                                                            label={<p>New <br className="max-md:hidden" /> Password</p>}
                                                            labelClassName={"min-w-[100px] text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                                            parentClassName="!flex-row items-center relative max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                            type="password"
                                                            name="newPassword"
                                                            id="newPassword"
                                                            placeholder=""
                                                            value={formData.newPassword}
                                                            onChange={handleChange}
                                                            error={errors.newPassword}
                                                            afterLabel={<button className="absolute right-4 z-[1] bottom-[15px]"><EyeSlashIcon /></button>}
                                                        />
                                                    </div>
                                                    <div>
                                                        <InputField
                                                            label={<p>Confirm <br className="max-md:hidden" /> Password</p>}
                                                            labelClassName={"min-w-[100px] text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                                            parentClassName="!flex-row items-center relative max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                            type="password"
                                                            name="confirmPassword"
                                                            id="confirmPassword"
                                                            placeholder=""
                                                            value={formData.confirmPassword}
                                                            onChange={handleChange}
                                                            error={errors.confirmPassword}
                                                            afterLabel={<button className="absolute right-4 z-[1] bottom-[15px]"><EyeSlashIcon /></button>}
                                                        />
                                                    </div>
                                                </section>

                                            </>
                                            : null
                                    }
                                    <div className="flex gap-3 justify-center self-start mt-7 text-lg font-semibold whitespace-nowrap max-md:flex-col max-md:w-full">
                                        <PrimaryButton
                                            isLoading={isLoading}
                                            onClick={handleChangePassword}
                                            className="grow justify-center px-9 flex items-center !h-[54px] !text-lg text-white bg-green-500 rounded-md max-md:px-5 max-md:py-3 hover:opacity-70">
                                            Save Changes
                                        </PrimaryButton>
                                        <button onClick={handleCancelPassword} className="grow justify-center px-12 flex items-center h-[54px] rounded-md bg-zinc-100 text-zinc-800 max-md:px-5 max-md:py-3 hover:opacity-70">
                                            Cancel
                                        </button>
                                    </div>
                                    <h2 className="pb-6 mt-12 text-3xl font-bold whitespace-nowrap border-b-2 border-green-500 border-solid text-zinc-800 max-md:mt-10 max-md:max-w-full max-md:text-[24px] max-md:pb-[24px] max-md:mt-[36px] max-md:leading-[29px]">
                                        Address
                                    </h2>
                                    <section className="grid md:grid-cols-1 gap-8 mt-[50px] max-md:mt-[36px]">
                                        <InputField
                                            label={<p>Address</p>}
                                            labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                            parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                            type="text"
                                            name="address"
                                            id="address"
                                            placeholder=""
                                            value={formData.address || ""}
                                            onChange={handleChange}
                                            error={errors.address}
                                        />
                                    </section>
                                    <section className="grid md:grid-cols-3 gap-8 mt-[10px] max-md:gap-[16px]">
                                        <div className="flex gap-4">
                                            <div className="min-w-[100px] max-md:hidden"></div>
                                            <InputField
                                                labelClassName={"hidden"}
                                                inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                                parentClassName="max-md:m-[0px]"
                                                type="text"
                                                name="city"
                                                id="city"
                                                placeholder="City"
                                                value={formData.city || ""}
                                                onChange={handleChange}
                                                error={errors.city}
                                            />
                                        </div>
                                        <SelectField
                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                            parentClass="max-md:m-[0px]"
                                            options={states}
                                            placeholder='State'
                                            value={formData.state || ""}
                                            name='state'
                                            onChange={handleChange}
                                        />
                                        <InputField
                                            labelClassName={"hidden"}
                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9]"
                                            type="text"
                                            name="postalCode"
                                            id="postalCode"
                                            placeholder="Zip Code"
                                            value={formData.postalCode || ""}
                                            onChange={handleChange}
                                            error={errors.postalCode}
                                        />
                                    </section>
                                    <section className="grid md:grid-cols-1 gap-8 mt-[10px]">
                                        <InputField
                                            label={<p>Phone</p>}
                                            labelClassName={"min-w-[100px] md:text-right text-lg font-bold leading-6 max-md:text-[16px]"}
                                            parentClassName="!flex-row items-center max-md:!flex-col max-md:items-start max-md:m-[0px] max-md:gap-[12px]"
                                            inputBg="bg-[#FFFFFF] border border-solid border-[#DEDED9] max-w-[484px]"
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            placeholder=""
                                            value={formData.phone || ""}
                                            onChange={handleChange}
                                            error={errors.phone}
                                        />
                                    </section>
                                    <section className="flex gap-5 justify-between mt-7 w-full text-lg font-semibold whitespace-nowrap max-md:flex-wrap max-md:max-w-full max-md:flex-col max-md:gap-[12px]">
                                        <div className="flex gap-3 justify-center max-md:flex-col">
                                            <PrimaryButton
                                                isLoading={isLoading}
                                                onClick={handleChangeAddress}
                                                className="grow justify-center px-9 flex items-center !h-[54px] !text-lg text-white bg-green-500 rounded-md max-md:px-5 max-md:py-3 hover:opacity-70">
                                                Save Changes
                                            </PrimaryButton>
                                            <button onClick={handleCancelAddress} className="grow justify-center px-12 flex items-center rounded-md bg-zinc-100 text-zinc-800 max-md:px-5 max-md:py-3 hover:opacity-70">
                                                Cancel
                                            </button>
                                        </div>
                                        <Link
                                            to={"/offer-templates"}
                                            className="justify-center px-5 !h-[54px] !text-lg flex items-center text-white bg-red-600 rounded-md max-md:py-3 hover:opacity-70"
                                        >
                                            Edit Offer Templates
                                        </Link>
                                        <Link
                                            to={"/SBM05_1_1"}
                                            className="justify-center px-5 !h-[54px] !text-lg flex items-center text-white bg-red-600 rounded-md max-md:py-3 hover:opacity-70"
                                        >
                                            Edit Company Info
                                        </Link>
                                    </section>
                                </main>
                            </div >
                        </main >
                    </>
                    : <PageLoader />
            }
            <Footer hideCopyright={true} />
        </>
    );
};

export default SBM05_1;