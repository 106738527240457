import { useSelector } from "react-redux";
import { HiringProcess } from "../../../components/base/ProgressStepsSBM";
import React, { useEffect, useState } from "react";
import { getSubscriberRole } from "../../../redux/slices/applicationSlice";
import { useParams } from "react-router-dom";

export const HiringProcessSBM01 = ({ activeTab = 1 }) => {
    const subscriberRole = useSelector(getSubscriberRole)

    const [modifyStep, setModifyStep] = useState(null)
    const { jobId, applicationId } = useParams();

    const steps = [
        { stepNumber: "1", label: "Clarifications", isActive: false, link: `/SBM01_10_18/${jobId}/${applicationId}` },
        { stepNumber: "2", label: "1st Interview", isActive: false, link: `/SBM01_10_1/${jobId}/${applicationId}` },
        { stepNumber: "3", label: "Post 1st Interview\nSurvey", isActive: false, link: `/SBM01_10_2/${jobId}/${applicationId}` },
        { stepNumber: "4", label: "2nd Interview", isActive: false, link: `/SBM01_10_3/${jobId}/${applicationId}` },
        { stepNumber: "5", label: "Document\nReview", isActive: false, link: `/SBM01_10_4/${jobId}/${applicationId}` },
        { stepNumber: "6", label: "Rating of\nThe Job", isActive: false, link: `/SBM01_10_16/${jobId}/${applicationId}` },
        { stepNumber: "7", label: "Scores", isActive: false, link: `/scores/${jobId}/${applicationId}` },
        { stepNumber: "8", label: "AI\nRecommendation", isActive: false, link: `/SBM01_10_5/${jobId}/${applicationId}` },
        { stepNumber: "9", label: "Final Hiring Decision", isActive: false, link: `/SBM01_10_5_5/${jobId}/${applicationId}` },
        { stepNumber: "10", label: "Training Insights", isActive: false, link: `/SBM01_10_6/${jobId}/${applicationId}` },
        { stepNumber: "11", label: "Offer Meeting", isActive: false, link: `/SBM01_10_7/${jobId}/${applicationId}` },
        { stepNumber: "12", label: "Training\nMilestones", isActive: false, link: `/SBM01_10_8/${jobId}/${applicationId}` },
    ];

    useEffect(() => {
        let modifiedStep = steps.map((step, index) => {
            if (step.stepNumber <= activeTab) {
                step.isActive = true
                return step
            } else {
                return step
            }
        })
        if (subscriberRole === "interviewer" && modifiedStep.length) {
            modifiedStep = modifiedStep.slice(0, 4)
        }
        setModifyStep(modifiedStep)
    }, [activeTab]);

    return (
        <section className="flex w-full text-center overflow-auto">
            {modifyStep && <HiringProcess stepsData={modifyStep} />}
        </section>
    )
}