import { createSlice } from '@reduxjs/toolkit';
import { getAllComunicationsFetch } from '../thunks/communicationsThunks';
import { createJobRatingsFetch, getAllJobRatingsFetch, updateJobRatingsFetch } from '../thunks/jobRatingsThunks';

const initialState = {
    jobRatings: [],
    isLoading: false
}

const jobRatings = createSlice({
    name: 'jobRatings',
    initialState,
    reducers: {},

    extraReducers(builder) {
        builder.addCase(getAllJobRatingsFetch.fulfilled, (state, { payload }) => {
            state.jobRatings = payload;
            state.isLoading = false
        });

        builder.addCase(getAllJobRatingsFetch.pending, (state, action) => {
            state.jobRatings = [];
            state.isLoading = true
        });

        builder.addCase(getAllJobRatingsFetch.rejected, (state, action) => {
            state.jobRatings = [];
            state.isLoading = false
        });
        builder.addCase(createJobRatingsFetch.fulfilled, (state, { payload }) => {
            state.jobRatings = payload;
            state.isLoading = false
        });

        builder.addCase(createJobRatingsFetch.pending, (state, action) => {
            state.jobRatings = [];
            state.isLoading = true
        });

        builder.addCase(createJobRatingsFetch.rejected, (state, action) => {
            state.jobRatings = [];
            state.isLoading = false
        });
        builder.addCase(updateJobRatingsFetch.fulfilled, (state, { payload }) => {
            state.jobRatings = payload;
            state.isLoading = false
        });

        builder.addCase(updateJobRatingsFetch.pending, (state, action) => {
            state.jobRatings = [];
            state.isLoading = true
        });

        builder.addCase(updateJobRatingsFetch.rejected, (state, action) => {
            state.jobRatings = [];
            state.isLoading = false
        });
    }
})

export const {
} = jobRatings.actions;

export default jobRatings.reducer