import React, {useEffect, useState} from "react";
import {ElevenStepsWrapper} from "../../parts/ElevenStepsWrapper";
import {FitScaleEvaluationTable} from "../../../../components/base/FitScaleEvaluationTable";
import {crumbsConfig} from "../../config";

export const TrainingMilestonesStep = ({nextComponent, prevComponent}) => {
    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
        ['Training Milestones']
    ]

    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>
            {step === 1 && <ElevenStepsWrapper
                onNext={() => nextComponent()}
                onBack={() => prevComponent()}
                crumbs={crumbs}
                activePin={11}
            >
                <StepOne />
            </ElevenStepsWrapper>}
        </>
    )
}

const StepOne = () => {

    return (
        <>
            <section className="pb-[60px] border-b border-b-[#DBDBDB] ">
                <h2 className="font-bold text-[28px] mdLtext-3xl">How to set up your milestones:</h2>
                <div className="flex items-center mt-[32px]">
                    <span className="green-box"></span>
                    <span className="ml-[16px] font-semibold text-[18px] md:text-xl">Must be measurable.</span>
                </div>
                <div className="flex items-center mt-[32px]">
                    <span className="green-box"></span>
                    <span className="ml-[16px] font-semibold text-[18px] md:text-xl">Must be meaningful training progress</span>
                </div>
                <div className="flex items-center mt-[32px]">
                    <span className="green-box"></span>
                    <span className="ml-[16px] font-semibold text-[18px] md:text-xl">Must have a clear and reasonable timeline</span>
                </div>

            </section>

            <Form/>
            <Form btn num={2} noHr/>
        </>
    )
}

const Form = ({btn=false, num=1, noHr = false}) => {

    return (
        <section className={`pb-[60px] ${noHr ? '' : 'border-b border-b-[#DBDBDB]'}`}>
            <div className="max-w-[800px]">
                <h2 className="font-bold text-[22px] mb-[24px]">Milestone {num}:</h2>

                <div className="mb-[24px] flex flex-col md:flex-row md:items-center">
                    <span className="text-[16px] max-md:mb-[12px] md:text-xl font-semibold w-[208px]">What is it</span>
                    <div className="max-w-[652px] w-full">
                        <input type="text" className="grey-input-custom" />
                    </div>
                </div>

                <div className="mb-[24px] flex flex-col md:flex-row md:items-center">
                    <span className="text-[16px] max-md:mb-[12px] md:text-xl font-semibold w-[208px]">How to measure</span>
                    <div className="max-w-[652px] w-full">
                        <input type="text" className="grey-input-custom" />
                    </div>
                </div>

                <div className="mb-[24px] flex flex-col md:flex-row md:items-center">
                    <span className="text-[16px] max-md:mb-[12px] md:text-xl font-semibold w-[208px]">Timeline</span>
                    <div className="max-w-[652px] w-full date-green-input-container">
                        <input type="text" className="grey-input-custom" placeholder="04/06/2023" />
                    </div>
                </div>

                <div className="mb-[24px] flex flex-col md:flex-row md:items-center">
                    <span className="text-[16px] max-md:mb-[20px] md:text-xl font-semibold w-[208px]">Achieved or Not</span>
                    <FitScaleEvaluationTable labelLow="Poor fit" labelHigh="Perfectly Fit" />
                </div>
            </div>

            {btn && <div className="flex justify-end">
                <button className="justify-center py-[15px] max-md:mt-[20px] max-md:flex-auto px-[36px] font-bold text-white bg-sky-900 rounded-md max-md:px-5" tabIndex="0">
                    Add a Milestone
                </button>
            </div>}


        </section>
    )
}
