import React from 'react'
import bannerDarkBg from '../../assets/img/banner-dark.png';
import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getCurrentPage } from '../../redux/slices/applicationSlice';
import findValue from '../../assets/helpers/findValue';

const BannerGift = () => {
    const currentPage = useSelector(getCurrentPage);
    const getValue = (key) => findValue(currentPage?.blocks, key);
    return (
        <div className="flex flex-col justify-center font-bold text-white">
            <div className="flex overflow-hidden flex-col items-center p-20 w-full min-h-[402px] max-md:px-5 max-md:max-w-full z-[1px] relative">
                <img
                    src={bannerDarkBg}
                    className="object-cover absolute inset-0 size-full"
                    alt='banner'
                />
                <div className="relative mt-2.5 lg:text-5xl text-4xl text-center leading-[44px] lg:leading-[60px] max-w-[850px] w-full">
                    {getValue('home_description_bottom') || 'Delight Your Valentine with the Gift of Free Time, Relaxation, and Joy!'}
                </div>
                <NavLink
                    to="/HOM1_5"
                    className="relative flex justify-center items-center px-16 py-6 max-md:py-3 mt-11 lg:mt-7 lg:text-2xl text-[19px] max-lg:w-full font-bold whitespace-nowrap bg-[#37B34A] rounded-md w-[276px] max-md:px-5 h-[70px] max-md:h-[60px] text-white hover:opacity-70"
                >
                    {getValue('home_book_a_demo_bottom_botton') || 'Book A Demo'}
                
                    
                </NavLink>
            </div>
        </div>
    );
}

export default BannerGift