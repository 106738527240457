import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";


export const AboutTitleSection = ({ payload, setPayload }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        title: "You! Yes, You!",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare scelerisque erat vel rutrum. Duis sem enim, tempor sed gravida imperdiet, bibendum et purus. Donec egestas lectus at sapien hendrerit non convallis`,
        subTitle: "You should hear back from us in 3 business days",
        subDescription: "Please do make sure that you have submitted or copy/paste your resume."
    })

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setFormData(prevState => ({ ...prevState, [name]: value }));
        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }

    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.you_yes_you_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        you_yes_you_active: !payload.applicationForm.you_yes_you_active
                    }
                });
            }
        },
    ]

    if (isEditing && isModal) {

        return (
            <ModalForm onSave={(e) => { setIsModal(false); setIsEditing(false) }} formWrapClass="m-auto">
                <div>
                    <EditableInput
                        className="font-bold text-[30px]"
                        type="text"
                        name="you_yes_you_title"
                        id="you_yes_you_title"
                        value={payload.applicationForm.you_yes_you_title}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                    { payload.applicationForm.you_yes_you_active ?
                        <EditableInput
                            className="leading-[30px] text-lg text-[#6D6D6D]"
                            type="text"
                            name="you_yes_you_text"
                            id="you_yes_you_text"
                            value={payload.applicationForm.you_yes_you_text}
                            onChange={handleChange}
                            disabled={!isEditing}
                        />
                    : null }
                </div>
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-between">
                <EditableInput
                    className="font-bold lg:text-[30px] text-[24px]"
                    type="text"
                    name="you_yes_you_title"
                    id="you_yes_you_title"
                    value={payload.applicationForm.you_yes_you_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton>
                        :
                        <ButtonOptions options={options} />
                }
            </div>
            { payload.applicationForm.you_yes_you_active ?
            <div className="w-full max-w-[650px]">
                <EditableInput
                    className="leading-[30px] text-lg text-[#6D6D6D]"
                    type="text"
                    name="you_yes_you_text"
                    id="you_yes_you_text"
                    value={payload.applicationForm.you_yes_you_text}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
            </div> : null }
        </form>
    )
}