import React, {useCallback, useEffect, useRef, useState} from "react";

const Editor = ({data, onChange, wrprClassNames = "mt-[16px] mb-[18px]", setInstanse, readOnly = false}) => {
   
    // const refdiv=useRef(null);
    const [rte, setRte] = useState(null)
    useEffect(() => {
        if(!rte || data === rte.getHTMLCode()) return
        rte.setHTMLCode(data)
    }, [data, rte]);

    const editorRedf = useCallback((node) => {
        if (node !== null) {
            let rte1 = new window.RichTextEditor(node, {
                editorResizeMode: 'none',
                readOnly,
                toolbar: 'basic',
                skin: 'gray',
                enableObjectResizing: false,
                focusOnLoad: true
            });
            setRte(rte1);
            setInstanse && setInstanse(rte1)
            rte1.setHTMLCode(data); 
            rte1.attachEvent("change", function () {
                onChange && onChange(rte1.getHTMLCode())
            })
            // rte1.setReadOnly(readOnly)
        }
    }, []);
  

    // useEffect(() => {
    //     setTimeout(() => {
    //         let rte1 = new window.RichTextEditor(refdiv.current, {
    //             editorResizeMode: 'none'
    //         });
    //         setRte(rte1);
            
    //         setInstanse && setInstanse(rte1)
    //         rte1.setHTMLCode(data); 
    //         rte1.attachEvent("change", function () {
    //             onChange && onChange(rte1.getHTMLCode())
    //         })
    //         rte1.setReadOnly(readOnly)

    //     }, 0)
    // }, []);


    return (
        <div className={wrprClassNames}>
            <div ref={editorRedf}></div>
        </div>
    );
}

export default Editor