import { useState } from "react";

const Search = ({ parentClassName, onChange, onSubmit }) => {
    const [searchTerm, setSearchTerm] = useState('')

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault()
        onSubmit && onSubmit(searchTerm)
        console.log(`Searching for: ${searchTerm}`)
    };

    return (
        <form
            className={`flex items-center bg-white rounded-md border border-solid border-zinc-300 outline-none ${parentClassName ? parentClassName : ""}`}
            onSubmit={handleSearchSubmit}
        >
            <input
                type="search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="flex-1 px-4 py-2 max-md:py-3 rounded-md outline-none"
                placeholder="Search..."
                aria-label="Search through site content"
            />
            <button
                type="submit"
                className="p-2 outline-none"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                </svg>
            </button>
        </form>
    )
}

export default Search;