import React, {useEffect, useState} from "react";
import {ElevenStepsWrapper} from "../../parts/ElevenStepsWrapper";
import {CandidateDetails} from "../../parts/CandidateDetails";
import {FirstInterviewVideo} from "./FirstInterviewVideo";
import {FirstInterviewStepTwo} from "./FirstInterviewStepTwo";
import {crumbsConfig} from "../../config";

export const FirstInterviewSteps = ({nextComponent, prevComponent}) => {

    const [step, setStep] = useState(1)

    const crumbs = [
        crumbsConfig.sub,
        crumbsConfig.jobs,
        crumbsConfig.applicant,
        crumbsConfig.candidate,
        crumbsConfig.spector,
        crumbsConfig.report,
    ]
    const cruOne = [...crumbs, ["1st Interview", '/']]
    const cruTwo = [...crumbs, ['Assigment', '/']]


    useEffect(() => {
        window.scroll({top: 0, behavior: "smooth"})
    }, [step]);

    return (
        <>

            {step === 1 && <ElevenStepsWrapper
                crumbs={cruOne}
                activePin={2}
                onNext={() => setStep(4)}
                onBack={prevComponent}
            >
                <CandidateDetails />
            </ElevenStepsWrapper>}


            {step === 2 && <FirstInterviewVideo crumbs={cruTwo}/>}
            {step === 3 && <FirstInterviewVideo crumbs={cruTwo}/>}
            {step === 4 && <ElevenStepsWrapper
                crumbs={cruOne}
                activePin={2}
                onBack={() => setStep(1)}
                onNext={() => nextComponent()}
            >
                <FirstInterviewStepTwo />
            </ElevenStepsWrapper>}


        </>
    )
}