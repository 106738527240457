import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getJobsByCompanyFetch } from './jobsThunks';

export const getUserFetch = createAsyncThunk(
    '/get-user/',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const res = await api.users.getUser();
            if (res.success) {
                if(res.data.role === 'subscriber' && res.data?.company?.id) {
                    dispatch(getJobsByCompanyFetch({companyId: res.data.company.id}))
                }
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchPages = createAsyncThunk(
    '/fetch-pages/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.pages.getPages();
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchPageByPath = createAsyncThunk(
    '/fetch-page-by-path/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.pages.getPageByPath(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const updateUserFetch = createAsyncThunk(
    '/update-user/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.users.updateUser(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const fetchLogout = createAsyncThunk(
    '/fetch-logout/',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.auth.logout();
            if (res.success) {
                return res;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
