import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import getImageUrl from "../assets/helpers/getImageUrl";
import SearchIcon from "../assets/img/search-icon.svg";
import PageLoader from "../components/PageLoader";
import Breadcrumbs from "../components/base/Breadcrumbs";
import BannerGift from "../components/parts/BannerGift";
import { getPublicSolutionPagesFetch } from "../redux/thunks/solutionThunks";


const formatTags = (tags, length = 2) => {
	return tags?.length ? tags.slice(0, length || tags.length).map(tag => tag?.tag?.name).join(", ") : ""
}

const HOM1_4 = () => {
	const { data, isLoading, categories } = useSelector(state => state.solution)
	const dispatch = useDispatch()

	const breadcrumbs = [
		{ name: 'Home', to: "/HOM1_1" },
		{ name: 'Solution', to: "/HOM1_4", isCurrent: true }
	]

	useEffect(() => {
		dispatch(getPublicSolutionPagesFetch())
	}, [])




	return (
		<>
			<div className="max-w-[1280px] overflow-hidden w-full px-5 mx-auto lg:pb-[150px] pb-20">
				<Breadcrumbs links={breadcrumbs} />
				{
					isLoading ?
						<PageLoader color="light" />
						: data && data.length ?
							<>
								<ArticleComponent article={data[0]} />
								<ContentLibrary data={data} categories={categories} />
							</>
							:
							<h2>There is no solutions yet</h2>
				}
			</div>
			<BannerGift />
		</>
	)
}

const ArticleComponent = ({ article }) => {
	return (
		<section className="p-[3.375rem] mt-[32px] rounded-md bg-[#001C3F] max-md:p-9">
			<header className="flex gap-[3.375rem] max-md:flex-col max-md:gap-0">
				<div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
					<article className="flex flex-col text-xl text-white max-md:max-w-full">
						<h1 className="lg:text-[40px] text-[32px] font-bold tracking-tighter leading-[39px] lg:leading-[50px] max-md:max-w-full">{article.name}</h1>
						<div className="mt-9 h-1 bg-[#37B34A] w-[78px]" />
						<div className="flex gap-5 justify-between mt-9 leading-[150%] max-md:flex-wrap max-md:max-w-full">
							<div className="flex flex-col whitespace-nowrap max-md:w-full">
								<div className="font-bold">{article.user.firstName + " " + article.user.lastName}</div>
								<div className="max-md:hidden">{formatTags(article?.aggregateTags)}</div>
								<div className="hidden max-md:flex items-center justify-between">
									<div>{formatTags(article?.aggregateTags)}</div>
									<div className="flex-auto self-end text-right">{format(article.createdAt, "MMMM dd yyyy")}</div>
								</div>
							</div>
							<time className="flex-auto self-end mt-8 text-right max-md:hidden">{format(article.createdAt, "MMMM dd yyyy")}</time>
						</div>
						<p className="mt-9 leading-9 max-md:max-w-full max-md:text-[18px] max-md:leading-[29px]">
							{article.postTitle}
						</p>
						<Link
							to={`/HOM1_14/${article.id}`}
							tabIndex="0"
							className="max-md:mt-[28px] relative flex justify-center items-center px-[16px] py-6 mt-11 lg:mt-7 text-[19px] lg:text-[20px] font-bold whitespace-nowrap bg-[#37B34A] rounded-md lg:max-w-[196px] w-full max-md:px-5 h-[70px] lg:h-[70px] text-white hover:opacity-70"
						>
							Read More
						</Link>
					</article>
				</div>
				<div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
					<img loading="lazy" src={getImageUrl(article.mainImage)} alt={article.name} className="mt-1 w-full aspect-square max-md:mt-10 max-md:max-w-full" />
				</div>
			</header>
		</section>
	);
};

function FilterButton({ label, active, action }) {
	return (
		<button onClick={() => { action() }} className={active ? "grow justify-center px-[16px] py-[5px] font-semibold text-white bg-[#37B34A] rounded-md hover:opacity-70" : "self-stretch my-auto text-white hover:text-[#37B34A]"}>
			{label}
		</button>
	);
}

function PressReleaseCard({ article }) {
	return (
		<Link to={`/HOM1_14/${article.id}`} className="flex flex-col grow text-xl leading-8 hover:opacity-90">
			<div className="block w-full">
				<img loading="lazy" src={getImageUrl(article.mainImage)} alt={article.name} className="w-full aspect-square" />
			</div>
			<div className="flex gap-5 justify-between mt-6">
				<div className="font-bold text-green-500">
					{article.category?.name}
				</div>
				<div className="font-light text-white">
					{format(article.createdAt, "MMMM dd yyyy")}
				</div>
			</div>
			<p className="mt-4 text-[28px] leading-9 lg:text-4xl font-bold text-white lg:leading-[50px]  line-clamp-2">
				{article?.name}
			</p>
			<div className="self-start mt-5 font-bold text-white whitespace-nowrap">
				{article?.user.firstName + " " + article?.user.lastName}
			</div>
			<div className="self-start text-white">
				{formatTags(article?.aggregateTags)}
			</div>
		</Link>
	);
}

function ContentLibrary({ data, categories }) {
	const [searchValue, setSearchValue] = useState("");
	const [activeCategory, setActiveCategory] = useState(categories[0]?.id || null);
	const [searchParams, setSearchParams] = useState(['name', 'author'])
	const [filteredData, setFilteredData] = useState(data);

	useEffect(() => {
		setFilteredData((prevData) => {
			return data.filter(item => {
				if (searchValue) {
					return searchParams.some(param => {
						return item?.category?.id === activeCategory && item[param]?.toLocaleLowerCase()?.includes(searchValue?.toLocaleLowerCase())
					})
				}
				else {
					return item?.category?.id === activeCategory
				}
			});
		})
	}, [searchValue, activeCategory])

	return (
		<div className="flex flex-col items-center">
			<header className="flex justify-center items-center self-stretch pt-[84px] pb-[62px] w-full overflow-hidden">
				<div className="flex lg:gap-[32px] gap-4 w-full max-w-full max-lg:flex-wrap">
					<h1 className="text-4xl font-bold leading-10 text-white whitespace-nowrap">Filter By</h1>
					<nav className="flex flex-auto lg:gap-3 gap-[22px] items-center self-start text-lg flex-wrap w-full">
						<div className="relative w-full md:max-w-[180px]">
							<img
								loading="lazy" src={SearchIcon}
								alt="Search Icon" className="w-6 aspect-square absolute right-[10px] top-[7px]"
							/>
							<input
								className="flex gap-5 justify-between px-[16px] py-[5px] bg-white rounded-md text-zinc-800 w-full"
								placeholder="Search..."
								value={searchValue}
								onInput={(e) => {
									setSearchValue(e.target.value);
								}}
							/>
						</div>
						<div className="flex items-center gap-4 whitespace-nowrap overflow-auto">
							{
								categories.map((item) => (
									<FilterButton label={item.name} key={item.id} active={activeCategory === item.id} action={() => setActiveCategory(item.id)} />
								))
							}
						</div>
					</nav>
				</div>
			</header>
			<section className="max-w-full w-[1240px]">
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[40px] gap-y-11 lg:gap-y-[80px]">
					{
						filteredData.length ?
							filteredData.map(article => (
								<PressReleaseCard key={article.id} article={article} />
							))
							:
							<p className="text-center md:col-span-3 text-white text-[30px]">No Results</p>
					}
				</div>
			</section>
		</div>
	);
}

export default HOM1_4