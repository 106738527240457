import classNames from "classnames";
import {useEffect, useRef, useState} from "react";


// опции приходят в таком формате [{label: "Choose", value: 1}]
// вернуть в onSelect нужно только value

function SelectBox({ options, svg = 1, className, value = null, onSelect, label, parentClass, labelClassName, error, disabled, placeholder }) {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(placeholder ? {label: placeholder} : options[0] || {label: ''})
    const toggleSelect = () => setIsOpen(!isOpen);
    const handleSelectOption = (option) => {
      setSelectedOption(option)
      setIsOpen(false)
      if(onSelect){
        onSelect(option.value);
      }
    };

    const wrapper = useRef(null);

    const handleClose = (e) => {
        if (wrapper.current && !wrapper.current.contains(e.target)) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (value) {
            setSelectedOption(options.find(el => el.value === value))
        } else {
          setSelectedOption({label: placeholder})
        }
    }, [options, value]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClose)
        } else {
            document.removeEventListener('click', handleClose)
        }

        return () => {document.removeEventListener('click', handleClose)}
    }, [isOpen]);



    return (
      <div className={`relative w-full ${parentClass ? parentClass : ''}`} ref={wrapper}>
          {label && <label className={`text-[18px] font-bold text-zinc-800 leading-[18px]  ${labelClassName}`}>{label}</label>}
        <div
          className={classNames(`input-override w-full px-4 py-3 bg-white border border-gray-300 rounded-md cursor-pointer hover:bg-gray-100 flex justify-between items-center h-[54px] ${selectedOption?.label ? '!text-[#000]' : ''}`, className, label && "mt-[16px]", error && '!bg-red-100', disabled && 'disabled bg-zinc-100 ')}
          onClick={toggleSelect}
        >
          {selectedOption && <p className="line-clamp-1">{selectedOption?.label}</p>}
          {svg === 1 && <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {/* d={isOpen ? 'M19 9l-7 7-7-7' : 'M9 5l7 7-7 7'} */}
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={'M19 9l-7 7-7-7'} />
          </svg>}
          {svg === 2 && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_236_482)">
            <path d="M11.7795 5.08266C11.7464 5.00271 11.6903 4.93437 11.6184 4.88628C11.5465 4.83819 11.4619 4.81252 11.3753 4.8125H2.62534C2.53877 4.81243 2.45411 4.83805 2.3821 4.88612C2.31009 4.93419 2.25396 5.00254 2.22082 5.08253C2.18768 5.16251 2.17902 5.25053 2.19593 5.33545C2.21284 5.42036 2.25456 5.49834 2.31581 5.55953L6.69081 9.93453C6.73145 9.97521 6.7797 10.0075 6.83281 10.0295C6.88592 10.0515 6.94285 10.0628 7.00034 10.0628C7.05784 10.0628 7.11477 10.0515 7.16788 10.0295C7.22099 10.0075 7.26924 9.97521 7.30988 9.93453L11.6849 5.55953C11.746 5.49831 11.7877 5.42033 11.8045 5.33545C11.8214 5.25056 11.8126 5.16259 11.7795 5.08266Z" fill="#999999"/>
            </g>
            <defs>
            <clipPath id="clip0_236_482">
            <rect width="14" height="14" fill="white"/>
            </clipPath>
            </defs>
          </svg>}
        </div>
  
        {isOpen && (
          <ul className="absolute w-full bg-white border border-gray-300 rounded-md z-10 mt-1 max-h-60 overflow-auto">
            {options.map((option, index) => (
              <li
                key={index}
                className={classNames("px-4 py-2 hover:bg-gray-100 cursor-pointer truncate", option.className)}
                onClick={() => !option.customOption && handleSelectOption(option)}
              >
                {option.customOption ? <option.customOption handleSelectOption={handleSelectOption}/> : option.label}
              </li>
            ))}
          </ul>
        )}
          {error && error.length && <p className="text-red-500 text-sm mt-1">{error}</p>}
      </div>
    );
}

export default SelectBox;