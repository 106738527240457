import React, { useState } from "react";

export const FitScaleEvaluationTable = ({ value = 1, onChange, labelLow = "Failed", labelHigh = "Succeed" }) => {
    const createRatingScale = (selectedIdx) => Array.from({ length: 7 }).map((_, idx) => {
        return idx + 1 === selectedIdx;
    });

    const [selectedRatings, setSelectedRatings] = useState(createRatingScale(value));


    const handleRatingClick = (index) => {
        setSelectedRatings(createRatingScale(index));
        onChange && onChange(index)
    };

    return (
        <div className="flex flex-col w-full overflow-auto whitespace-nowrap">
            <table className="max-w-[480px] border-collapse text-center">
                <thead>
                    <tr>
                        <th className='border-neutral-300 border-b border-solid'></th>
                        {Array.from({ length: 7 }).map((_, idx) => (
                            <th key={idx} className="px-2.5 py-2 text-lg font-semibold text-zinc-800 border-neutral-300 border-b border-solid">{idx + 1}</th>
                        ))}
                        <th className='border-neutral-300 border-b border-solid'></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="px-2.5 py-2 text-base font-semibold text-zinc-800">{labelLow}</td>
                        {selectedRatings.map((isSelected, idx) => (
                            <td
                                key={idx}
                                className={`p-2 cursor-pointer text-center`}
                                onClick={() => handleRatingClick(idx + 1)}
                            >
                                <div
                                    className="flex flex-col justify-center items-center px-1 border border-solid bg-stone-50 border-stone-300 h-[18px] rounded-[360px] w-[18px] cursor-pointer mx-[auto]"
                                >
                                    {isSelected && (
                                        <div className="shrink-0 w-2.5 h-2.5 bg-green-500 rounded-[360px]" />
                                    )}
                                </div>
                            </td>
                        ))}
                        <td className="px-2.5 py-2 text-base font-semibold text-zinc-800">{labelHigh}</td>
                    </tr>
                </tbody>
            </table>
            {/* <table className="w-full border-collapse text-center lg:hidden">
                <tbody>
                    {selectedRatings.map((isSelected, idx) => (
                        <tr key={idx}>
                            <th className="pr-3 py-[5px] h-[32px] text-lg font-semibold text-zinc-800 border-neutral-300 border-r border-solid max-md:min-w-[44px]">{idx + 1}</th>
                            <td className="px-[22px] text-base text-left font-semibold text-zinc-800 max-md:w-full">
                                {
                                    idx === 0 ?
                                        labelLow :
                                        idx === selectedRatings.length - 1 ? labelHigh : null
                                }
                            </td>
                            <td
                                className={`cursor-pointer`}
                                onClick={() => handleRatingClick(idx + 1)}
                            >
                                <div
                                    className="flex flex-col justify-center items-center border border-solid bg-stone-50 border-stone-300 h-[24px] aspect-square rounded-[360px] cursor-pointer"
                                >
                                    {isSelected && (
                                        <div className="shrink-0 w-2.5 h-2.5 bg-green-500 rounded-[360px]" />
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table> */}
        </div>
    );
}