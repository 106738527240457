import { createSlice } from '@reduxjs/toolkit';
import { getAllComunicationsFetch, sendMessageToApplicantFetch } from '../thunks/communicationsThunks';

const initialState = {
    communications: [],
    isLoading: false
}

const communicationsSlice = createSlice({
    name: 'communications',
    initialState,
    reducers: {},

    extraReducers(builder) {
        builder.addCase(getAllComunicationsFetch.fulfilled, (state, { payload }) => {
            state.communications = payload;
            state.isLoading = false
        });

        builder.addCase(getAllComunicationsFetch.pending, (state, action) => {
            state.communications = [];
            state.isLoading = true
        });

        builder.addCase(getAllComunicationsFetch.rejected, (state, action) => {
            state.communications = [];
            state.isLoading = false
        });
        builder.addCase(sendMessageToApplicantFetch.fulfilled, (state, { payload }) => {
            state.communications.push(payload);
            state.isLoading = false
        });

        builder.addCase(sendMessageToApplicantFetch.pending, (state, action) => {
            state.isLoading = true
        });

        builder.addCase(sendMessageToApplicantFetch.rejected, (state, action) => {
            state.isLoading = false
        });
    }
})

export const {
} = communicationsSlice.actions;

export default communicationsSlice.reducer