import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { jobApplications } from '../../../api/parts/jobApplications';
import { RangeQuestion } from "../../AP_01_Form/parts/RangeQuestion";
import { TextQuestion } from "../../AP_01_Form/parts/TextQuestion";
import { VariantsQuestion } from "../../AP_01_Form/parts/VariantsQuestion";
import { YesNoQuestion } from "../../AP_01_Form/parts/YesNoQuestion";
import { NextStepSectionSBM01 } from '../base/NextStepSectionSBM01';
import CommunicationTable from '../parts/CommunicationTable';
import PrimaryButton from '../../../components/base/PrimaryButton';
import api from '../../../api';
import { toast } from 'react-toastify';
import InputField from '../../../components/base/InputField';
import InputFieldHorisontal from '../../../components/base/InputFieldHorisontal';
import { EvaluationInterview } from '../../../components/parts/EvaluationsInterview';
import { useDispatch } from 'react-redux';
import { getApplicationByIdFetch, getJobByIdFetch } from '../../../redux/thunks/jobsThunks';


const Score = ({ title = "Score", onChange, value }) => (
	<div className="flex gap-5 mt-6 max-w-full text-lg whitespace-nowrap w-[400px] text-center">
		<div className="grow flex justify-center items-center px-16 lg:h-[54px] h-[52px] font-semibold bg-neutral-100 w-fit text-[#333333]">
			{title}
		</div>
		<InputFieldHorisontal
			className="flex gap-2 justify-center items-center px-5 py-1 min-w-[96px] lg:h-[54px] h-[52px] bg-white border border-solid border-zinc-300"
			error={null}
			min={0}
			max={10}
			type="number"
			onChange={onChange}
			value={parseInt(value) || null}
		/>
	</div>
);

const Clarifications = () => {
	const navigate = useNavigate()
	const { job, application, jobId, applicationId, setActiveStep } = useOutletContext()
	const [isAILoading, setIsAILoading] = useState(false)
	useEffect(() => {
		setActiveStep(1)
	}, [])

	const [questions, setQuestions] = useState([])
	const filter = job.applicationForm.critical_requirements_of_the_job_questions.filter(item => item.questionCode.includes('CL'))
	const questionsData = job && application && filter?.length ? filter : questions;

	const getQuestions = async () => {
		setIsLoading(true);
		try {
			const res = await api.jobVideoInterview.getInterviewQuestions('Clarification');
			console.log(res);

			if (!res.success) {
				toast.error(res?.message || "An error occurred while fetching the interview questions");
			}

			setQuestions(res.data)
			setAnswers(application?.criticalRequirements?.clarifications || res.data.map(question => ({
				question,
				answer: ''
			})))
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		getQuestions()
	}, [])
	const QuestionTypes = {
		'yes-no': YesNoQuestion,
		'text': TextQuestion,
		'range': RangeQuestion,
		'variants': VariantsQuestion
	}

	const handleNext = () => {
		navigate(`/SBM01_10_1/${jobId}/${applicationId}`)
	}

	console.log(questionsData)
	const initialAnswers = questionsData.map(question => ({
		question,
		answer: ''
	}))


	const [isLoading, setIsLoading] = useState(false)
	const [answers, setAnswers] = useState(application?.criticalRequirements?.clarifications || initialAnswers)

	// const [score, setScore] = useState(application?.assessments?.clarifications || { ftm_score: { value: 50, title: "Total Score" } })

	const [answersScores, setAnswersScores] = useState([])
	const [transcript, setTranscript] = useState([])
	const [answersConfig, setAnswersConfig] = useState({})

	useEffect(() => {
		const clarifications = application?.criticalRequirements?.clarifications
		if (!clarifications?.length) return
		const qualityQuestions = clarifications.map(item => ({ qualityAttribute: item?.question?.qualityAttribute }))

		if (!qualityQuestions.length) return
		const obj = {}
		const arr = qualityQuestions.reduce((res, curr, index) => {
			const key = res.find(r => r.key === curr?.qualityAttribute?.key)
			const score = { ...curr, score: answersScores?.[index] }
			if (key) {
				key.stack.push(score)
			} else {
				res.push({
					key: curr?.qualityAttribute?.key,
					stack: [score],
					name: curr?.qualityAttribute?.name,
				})
			}
			return res
		}, []).map(item => {
			let avg = (item?.stack || []).reduce((r, it) => r + parseFloat(it.score), 0) / (item?.stack || []).length;
			return { ...item, avg };
		});
		arr?.forEach((item) => {
			obj[item?.key] = {
				score: item?.avg,
				name: item?.name,
				key: item?.key
			}
		})

		setAnswersConfig(obj)

	}, [application, answersScores])

	useEffect(() => {
		setTranscript(application?.assessments?.["clarifications"]?.transcript)
		setAnswersScores(application?.assessments?.["clarifications"]?.scores || Array.from({ length: questionsData?.length || 0 }).fill("NONE"))
	}, [application])

	const changeAnswerHandler = (index, val) => {
		const copy = [...answersScores]
		copy[index] = val
		setAnswersScores(copy)
	}
	const dispatch = useDispatch()
	const handleSave = async () => {
		setIsLoading(true)
		// TODO add score to clarifications part
		try {
			const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
				criticalRequirements: {
					// ...application?.criticalRequirements || {},
					clarifications: answers
				},
				assessments: {
					...application?.assessments || {},
					"clarifications": {
						...application?.assessments?.clarifications,
						scores: answersScores
					}
				}
			})
			if (resp.success) {
				Promise.all([
					dispatch(getJobByIdFetch(jobId)),
					dispatch(getApplicationByIdFetch({ applicationId, jobId }))
				]);
				toast.success("Saved")
			} else {
				toast.error(resp.message || "Something went wrong")
			}
		} catch (err) {
			console.log(err)
			toast.error("Something went wrong")
		}
		setIsLoading(false)
	}

	const sendToAIRecomendationRequestFetch = async () => {
		const body = {
			"type": "clarification" // 'clarification', '1st_interview', '2nd_interview'
		}
		try {
			setIsAILoading(true)
			const res = await api.jobApplications.sendToAIRecomendationRequest({ applicationId, body })
			if (res) {
				console.log(res);
			}
		} catch (error) {
			console.log(error);
		}
		setIsAILoading(false)
	}



	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
				<div className="flex flex-col gap-[36px]">
					<div className='w-full flex justify-end'>
						<PrimaryButton
							isLoading={isAILoading}
							onClick={sendToAIRecomendationRequestFetch}
							className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-2 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
						>
							AI Agent
						</PrimaryButton>
					</div>
					<div className="flex flex-col gap-[32px] self-stretch max-w-[720px]">
						{answers.map((item, index) => {
							const Question = QuestionTypes["text"];
							if (!Question) return null
							return (
								<div className='flex flex-col'>
									<Question
										key={item.question.id}
										error={null}
										config={item.question}
										number={index + 1}
										onChange={val => setAnswers((prev) => prev.map((item, itemIndex) => index === itemIndex ? { ...item, answer: val } : item))}
										value={item.answer || null}
										className="mt-[0px]"
										labelClassName="!text-[16px] !leading-[20px]"
										showAnswer={false}
									/>
									<Score
										onChange={(e => changeAnswerHandler(index, e.target.value))}
										value={parseInt(answersScores[index]) || null}
									/>
								</div>
							)
						})}
					</div>
					<EvaluationInterview config={answersConfig} transcript={transcript} totalOnly />
					<PrimaryButton
						isLoading={isLoading}
						type='button'
						onClick={handleSave}
						className={"justify-center ml-auto max-md:flex-1 min-w-[150px] px-4 py-3 md:py-5 font-bold text-white rounded-md max-md:px-5 hover:opacity-70 !h-14"}
					>
						Save
					</PrimaryButton>
				</div>
				<div className="flex flex-col gap-7">
					{
						application?.clarification ?
							<>
								<h1 className="self-stretch w-full text-3xl font-bold leading-8 max-md:max-w-full">Clarification</h1>
								<div
									className="self-stretch w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full"></div>
							</>
							: null
					}
					<p className="text-2xl font-bold">Communication History</p>
					<CommunicationTable />
				</div>
			</div>
			<NextStepSectionSBM01 next={handleNext} />
		</div>
	)
}

export default Clarifications