import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";


const ProgressBar = ({ completed, containerClasses = "", startOverride = false }) => {

    const containerStyles = {
        height: 4,
        width: '100%',
        backgroundColor: "#EFEEF0",
    }

    const fillerStyles = {
        position: 'absolute',
        left: startOverride + 'px',
        height: '100%',
        width: `${completed}%`,
        backgroundColor: '#003578',
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s ease-in-out',
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    return (
        <div style={containerStyles} className={containerClasses}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${completed}%`}</span>
            </div>
        </div>
    );
}

const StepIndicatorItem = forwardRef(({ stepNumber, label, link, isActive, onRefUpdate }, ref) => {
    const baseStyles = "justify-center items-center self-center w-10 h-10 text-xl leading-8 font-bold text-white border-4 border-white border-solid shadow-md rounded-[360px]";
    const activeStyles = "bg-green-500";
    const inactiveStyles = "bg-neutral-200";

    useEffect(() => {
        if (onRefUpdate && ref) {
            onRefUpdate(ref);
        }
    }, [onRefUpdate, ref]);
    return (
        <Link to={link} onClick={(e) => !link && e.preventDefault()} className="flex flex-col shrink-0 flex-1 px-2" ref={ref}>
            <div className={`${baseStyles} ${isActive ? activeStyles : inactiveStyles}`}>
                {stepNumber}
            </div>
            <div className="mt-3.5 text-base font-semibold text-neutral-600">
                {label.split("\n").map((line, index, arr) => (
                    <React.Fragment key={index}>
                        {line}{index < arr.length - 1 && <br />}
                    </React.Fragment>
                ))}
            </div>
        </Link>
    );
});

export const ProgressBarFour = ({ stepsData }) => {
    const mob = document.body.offsetWidth < 768;
    const steps = stepsData ? stepsData : [
        { stepNumber: "1", label: "Application", isActive: false },
        { stepNumber: "2", label: "Communications", isActive: true },
        { stepNumber: "3", label: "Assessment", isActive: false },
        { stepNumber: "4", label: "Resume", isActive: false },
    ];

    const [oneElWidth, setOneElWidth] = useState(null)

    const ref = useRef(null)

    useEffect(() => {
        if (ref.current) {
            setOneElWidth(ref.current.offsetWidth)
        }
    }, [ref]);

    const handleRefUpdate = (childRef) => {
        ref.current = childRef.current
    };


    const activeCount = steps.filter(step => step.isActive)
    const activeCountNum = +activeCount[0].stepNumber

    const a = activeCountNum >= steps.length ? activeCountNum - 2 : activeCountNum - 1
    const b = activeCountNum >= steps.length ? activeCountNum : activeCountNum + 1

    const progress = (activeCount.length / (mob ? steps.slice(a, b) : steps).length) * 100;

    let countWidth = oneElWidth * +activeCount[0].stepNumber - oneElWidth

    if (mob) {
        if (activeCountNum === steps.length) {
            countWidth = oneElWidth
        } else {
            countWidth = 0
        }
    }

    return (
        <div
            //  className="relative flex shrink-0 justify-between text-center w-full"
            className={`relative flex-auto flex shrink-0 justify-between text-center`}
            style={{
                gridTemplateColumns: `repeat(${steps.length}, minmax(0, 1fr))`
            }}>
            <ProgressBar
                containerClasses='absolute z-[-1] top-[18px]'
                completed={progress}
                startOverride={countWidth}
            />
            {(mob ? steps.slice(a, b) : steps).map((step, index) => {

                return (
                    <StepIndicatorItem
                        ref={index === 0 ? ref : null}
                        onRefUpdate={handleRefUpdate}
                        key={index}
                        stepNumber={step.stepNumber}
                        label={step.label}
                        isActive={step.isActive}
                        link={step.link}
                    />
                )
            })}
        </div>
    );
};