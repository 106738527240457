import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
// import InputField from "../../components/base/InputField";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
// import { ReactComponent as CalendarIcon } from "../../assets/img/calendar-ico.svg";
import { ModalForm } from "./ModalForm";


const AvailabilityInput = ({ label, placeholder, className, value, disabled, fieldName, handleChange  }) => (
    <>
        <div className="flex justify-start relative border-[#DEDED9] border bg-white border-solid rounded-md h-[64px] max-md:w-[50%]">
            <input
                // type="time"
                type="text"
                className="indicator-hide text-lg px-[18px] bg-transparent text-[#333333] outline-none h-full w-full lg:w-[190px]"
                placeholder={placeholder}
                value={value}
                id={fieldName}
                name={fieldName}
                onInput={handleChange}
                disabled={disabled}
            />
            <span className="absolute top-[-5.5px] left-[10px] bg-white leading-[11px] whitespace-nowrap lg:px-[8px] left-1/2 text-base font-light">
                { label }
            </span>
        </div>
    </>
);


const DayAvailability = ({ isEditing, titleField, beginningAtField, endingAtField, payload, setPayload }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };
    
    return <div className="flex gap-5 justify-between max-md:flex-wrap">
        <EditableInput
            className="grow my-auto text-xl font-bold leading-8 uppercase max-lg:w-full max-lg:text-[18px]"
            type="text"
            name={titleField}
            id={titleField}
            value={payload.applicationForm[titleField]}
            onChange={handleChange}
            disabled={!isEditing}
        />
        <div className='flex gap-5 justify-between max-lg:grid grid-cols-2'>
            <AvailabilityInput
                label="Available Beginning at"
                placeholder="8:30 AM"
                value={payload.applicationForm[beginningAtField]}
                disabled={!isEditing}
                fieldName={beginningAtField}
                handleChange={handleChange}
            />
            <AvailabilityInput
                label="Available Ending at"
                placeholder="8:30 AM"
                value={payload.applicationForm[endingAtField]}
                disabled={!isEditing}
                fieldName={endingAtField}
                handleChange={handleChange}
            />
        </div>

    </div>   
};



export const AvailabilityFormSection = ({ payload, setPayload, }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsEditing(false);
    }

    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.your_availability_for_work_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        your_availability_for_work_active: !payload.applicationForm.your_availability_for_work_active
                    }
                });
            }
        },
    ];

    const days = {
        0: "sunday",
        1: "monday",
        2: "tuesday",
        3: "wednesday",
        4: "thursday",
        5: "friday",
        6: "saturday",
    };

    if (isEditing && isModal) {
        return (
            <ModalForm onSave={(e) => {
                setIsModal(false);
                setIsEditing(false)
            }} formWrapClass="m-auto">
                <div>
                    <EditableInput
                        className="text-[22px] font-bold"
                        type="text"
                        name="your_availability_for_work_title"
                        id="your_availability_for_work_title"
                        value={payload.applicationForm.your_availability_for_work_title}
                        onChange={handleChange}
                        disabled={!isEditing}
                    />
                    { payload.applicationForm.your_availability_for_work_active ? 
                        <div className='flex flex-col gap-6  max-w-[580px] w-full items-stretch'>
                            { Object.entries(days).map((el, idx) => (
                                <DayAvailability
                                    key={el[0]} isEditing={isEditing}
                                    titleField={`your_availability_for_work_${el[1]}`}
                                    beginningAtField={`your_availability_for_work_${el[1]}_available_beginning_at`}
                                    endingAtField={`your_availability_for_work_${el[1]}_available_ending_at`}
                                    payload={payload}
                                    setPayload={setPayload}
                                />
                            )) }
                        </div>
                    : null }
                </div>
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-between mb-[32px]">
                <EditableInput
                    className="text-[22px] font-bold"
                    type="text"
                    name="your_availability_for_work_title"
                    id="your_availability_for_work_title"
                    value={payload.applicationForm.your_availability_for_work_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton> :
                        <ButtonOptions options={options} />
                }
            </div>
            {
                payload.applicationForm.your_availability_for_work_active ? 
                <div>
                    <div className='flex flex-col max-w-[580px] gap-6  w-full items-stretch'>
                        { Object.entries(days).map((el, idx) => (
                            <DayAvailability
                                key={el[0]} isEditing={isEditing}
                                titleField={`your_availability_for_work_${el[1]}`}
                                beginningAtField={`your_availability_for_work_${el[1]}_available_beginning_at`}
                                endingAtField={`your_availability_for_work_${el[1]}_available_ending_at`}
                                payload={payload}
                                setPayload={setPayload}
                            />
                        )) }
                    </div>
                </div>
            : null }
        </form>
    );
};