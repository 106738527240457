import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getAuthorized, getUser } from '../redux/slices/applicationSlice';

const protectedPages = {
    "/jobs-setup": ['admin', 'member']
    // "/jobs-setup": ['subscriber',]
}

const ProtectedRouteLayout = () => {
    const user = useSelector(getUser);
    const authorized = useSelector(getAuthorized);
    const { pathname } = useLocation();

    if (authorized === false) {
        return <Navigate to="/HOM1_6" />
    }
    if (protectedPages[pathname] && user?.role && !protectedPages[pathname].includes(user.companies[0]?.role)) {
        return <Navigate to="/" />
    }

    return (
        <Outlet />
    )
};

export default ProtectedRouteLayout