import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes.js";
import "./assets/css/global.css"
import 'react-toastify/dist/ReactToastify.css';
import api from './api';
import { ToastContainer } from 'react-toastify';

import { setAuthorized, setToken, } from "./redux/slices/applicationSlice.js";
import { fetchPages, getUserFetch } from "./redux/thunks/applicationThunks.js";

function App() {
  const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchPages())
		const token = localStorage.getItem('authToken');
		if (!token) {
			dispatch(setAuthorized(false));
			return;
		}
		dispatch(setToken(token));
		dispatch(getUserFetch({ token }));
	}, [dispatch])

	return (
		<div className="App">
			<RouterProvider router={router} />
			<ToastContainer />
		</div>
	);
}

export default App;
