import React, { useEffect, useState } from "react";
import { EditableInput } from "./EditableInput";
import PrimaryButton from "../../components/base/PrimaryButton";
import { ButtonOptions } from "./ButtonOptions";
import { ModalForm } from "./ModalForm";


export const ConsentSection = ({ payload, setPayload }) => {
    const [isEditing, setIsEditing] = useState(false);

    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        if (isEditing && (1024 > window.innerWidth)) {
            setIsModal(true)
        } else {
            setIsModal(false)
        }
    }, [isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setFormData(prevState => ({ ...prevState, [name]: value }));

        setPayload({
            ...payload,
            applicationForm: {
                ...payload.applicationForm,
                [name]: value
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsEditing(false)
    }
    const options = [
        {
            label: "Edit the title / texts...",
            handler: () => setIsEditing(true)
        },
        {
            label: payload.applicationForm.consent_active ? "Disable the entire section..." : "Enable the entire section...",
            handler: () => {
                setPayload({
                    ...payload,
                    applicationForm: {
                        ...payload.applicationForm,
                        consent_active: !payload.applicationForm.consent_active
                    }
                });
            }
        },
        {
            label: "The 2 choice setup is fixed...",
            handler: () => { }
        },
    ]

    if (isEditing && isModal) {

        return (
            <ModalForm onSave={(e) => { setIsModal(false); setIsEditing(false) }} formWrapClass="m-auto">
                <EditableInput
                    className="text-[32px] font-bold"
                    type="text"
                    name="consent_title"
                    id="consent_title"
                    value={payload.applicationForm.consent_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                <EditableInput
                    className="text-lg text-[#6D6D6D] leading-[30px] max-w-[566px] w-full"
                    type="text"
                    name="consent_text"
                    id="consent_text"
                    value={payload.applicationForm.consent_text}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
            </ModalForm>
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex justify-between mb-3">
                <EditableInput
                    className="lg:text-[32px] text-[24px] font-bold max-md:leading-[28px]"
                    type="text"
                    name="consent_title"
                    id="consent_title"
                    value={payload.applicationForm.consent_title}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
                {
                    isEditing ?
                        <PrimaryButton
                            type="submit"
                            className={"px-9 lg:h-[52px] lg:text-lg"}
                        >
                            Save
                        </PrimaryButton>
                        :
                        <ButtonOptions options={options} />
                }
            </div>
            { payload.applicationForm.consent_active ? <div className='flex flex-col gap-[12px] w-full max-w-[650px]'>
                <EditableInput
                    className="text-lg text-[#6D6D6D] leading-[30px] max-w-[566px] w-full max-md:leading-[28px]"
                    type="text"
                    name="consent_text"
                    id="consent_text"
                    value={payload.applicationForm.consent_text}
                    onChange={handleChange}
                    disabled={!isEditing}
                />
            </div> : null }

        </form>
    )
}