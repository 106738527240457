import React, { useState } from 'react';
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import { HiringProcess } from '../components/base/ProgressStepsSBM';
import { Footer } from '../components/parts/Footer';
import ModalGrop from '../components/parts/ModalGrop';
const NextStepSection = () => {
    return (
        <section>
            <hr className="self-stretch mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full" />
            <div className="flex gap-4 justify-between self-stretch mt-9 w-full font-bold text-center text-white whitespace-nowrap">
                <button className="flex justify-center items-center px-3 md:px-12 py-1 h-[52px] bg-[#B2B2B2] rounded-md w-full lg:max-w-[162px] hover:opacity-70 max-md:flex-1">
                    Back
                </button>
                <button className="flex justify-center items-center px-3 md:px-16 py-1 h-[52px] bg-green-500 rounded-md w-full lg:max-w-[162px] hover:opacity-70 max-md:flex-1">
                    Next
                </button>
            </div>
        </section>
    );
};

const Question = ({ question, score, index }) => {
    return (
        <div className="flex flex-nowrap gap-0 justify-between text-lg lg:text-xl leading-7 lg:leading-8 text-zinc-800 w-full">
            <div className="flex-auto whitespace-normal">Question {index} : {question}</div>
            <div className="text-right lg:text-center basis-1/5 flex-shrink-0">{score}</div>
        </div>
    )
}

const CandidateDetails = () => {
    const questionsData = [
        { question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?", score: 6 },
        { question: "Quisque sit amet tortor nulla?", score: 7 },
        { question: "Nullam laoreet tincidunt purus vel volutpat?", score: 8 },
        { question: "Suspendisse eget enim odio, quis congue dolor?", score: 9 },
        { question: "Nullam in ante nunc, eu auctor erat?", score: 4 },
        { question: "Pellentesque habitant morbi tristique senectus et netus?", score: 2 },
        { question: "Vivamus magna justo, lacinia eget consectetur sed?", score: 5 },
        { question: "Curabitur non nulla sit amet nisl tempus?", score: 8 },
        { question: "Praesent sapien massa, convallis a pellentesque nec?", score: 5 },
        { question: "Sed porttitor lectus nibh, vel egestas nulla?", score: 5 },
        { question: "Pellentesque habitant morbi tristique senectus et netus?", score: 2 },
        { question: "Vivamus magna justo, lacinia eget consectetur sed?", score: 5 },
        { question: "Curabitur non nulla sit amet nisl tempus?", score: 8 },
        { question: "Praesent sapien massa, convallis a pellentesque nec?", score: 5 },
        { question: "Sed porttitor lectus nibh, vel egestas nulla?", score: 5 },
        { question: "Pellentesque habitant morbi tristique senectus et netus?", score: 2 },
        { question: "Vivamus magna justo, lacinia eget consectetur sed?", score: 5 },
        { question: "Curabitur non nulla sit amet nisl tempus?", score: 8 },
        { question: "Praesent sapien massa, convallis a pellentesque nec?", score: 5 },
        { question: "Sed porttitor lectus nibh, vel egestas nulla?", score: 5 },
    ];
    return (
        <section className="flex flex-col w-full">
            <article>
                <h3 className="w-full text-3xl font-bold leading-8 text-zinc-800">1st Interview</h3>
                <div className="mt-9 w-full border border-solid bg-neutral-200 border-neutral-200 min-h-[1px] max-md:max-w-full max-md:mt-[24px]"></div>
                <div className="flex gap-0 justify-between mt-9 text-2xl font-bold leading-7 whitespace-nowrap text-zinc-800 max-md:flex-wrap">
                    <div className="flex-auto">Questions</div>
                    <div className="text-center basis-1/5 max-md:text-right">Score</div>
                </div>
                <div className='flex flex-col gap-3 w-full lg:mt-3 mt-6'>
                    {questionsData.map((question, index) => (
                        <Question
                            key={index}
                            {...question}
                            index={index + 1}
                        />
                    ))}
                </div>
            </article>
        </section>
    );
};

const TabButton = ({ onClick = () => { }, isActive, label, id }) => {
    return (
        <button
            type='button'
            onClick={() => onClick(id)}
            className={`flex items-center justify-center px-5 h-[46px] ${isActive ? 'border-green-500' : 'border-white'}  border-solid border-b-[5px] hover:bg-green-200`}
        >
            {label}
        </button>
    )
}

const SBM01_10_1_5 = () => {
    
    const tabsData = [
        { label: 'Report Card', id: 1 },
        { label: 'History', id: 2 },
        { label: 'Reference', id: 3 },
        { label: 'Reject', id: 4 },
        { label: 'Offer Letter', id: 5 },
    ];
    const [activeTab, setActiveTab] = useState(tabsData.length ? tabsData[0].id : null);
  
    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const steps = [
        { stepNumber: "1", label: "Clarifications", isActive: true },
        { stepNumber: "2", label: "1st Interview", isActive: true },
        { stepNumber: "3", label: "Post 1st Interview\nSurvey", isActive: false },
        { stepNumber: "4", label: "2nd Interview", isActive: false },
        { stepNumber: "5", label: "Document\nReview", isActive: false },
        { stepNumber: "6", label: "Rating of\nThe Job", isActive: false },
        { stepNumber: "7", label: "AI\nRecommendation", isActive: false },
        { stepNumber: "8", label: "Final Hiring Decision", isActive: false },
        { stepNumber: "9", label: "Training Insights", isActive: false },
        { stepNumber: "10", label: "Offer Meeting", isActive: false },
        { stepNumber: "11", label: "Training\nMilestones", isActive: false },
    ];
    return (
        <>
            <BreadCrumbWhite crumbs={['Subscriber Dashboard', 'Jobs', 'Applicants', "Candidates", "Marc Spector", "Report Card", "1st Interview"]} />
            <div className='flex-auto'>
                <div className='flex flex-col gap-11 max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto max-md:pt-[40px] max-md:pb-[80px]'>
                    <header className="flex gap-5 justify-between w-full whitespace-nowrap max-md:flex-wrap max-md:max-w-full max-md:gap-[40px]">
                        <div>
                            <h1 className="text-lg font-semibold text-sky-900">Sales Associate / Candidates</h1>
                            <h2 className="mt-1.5 text-4xl font-bold leading-10 text-zinc-950">Marc Spector</h2>
                        </div>
                        <div className="flex flex-wrap gap-2 my-auto">
                            <ModalGrop tagModal={false}/>
                        </div>
                    </header>
                    <nav className="flex overflow-auto w-full gap-2 items-center self-start text-xl font-semibold text-center whitespace-nowrap text-zinc-800">
                        {tabsData.map(tab => (
                            <TabButton
                                key={tab.id}
                                id={tab.id}
                                label={tab.label}
                                isActive={activeTab === tab.id}
                                onClick={handleTabClick}
                            />
                        ))}
                    </nav>
                    <section className="flex w-full text-center overflow-auto">
                        <HiringProcess stepsData={steps} />
                    </section>
                    <CandidateDetails />
                    <NextStepSection />
                </div>
            </div>
            <Footer hideCopyright={true} />
        </>
    )
}

export default SBM01_10_1_5