import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import TextareaField from "../../../components/base/TextareaField";
import { NextStepSectionSBM01 } from "../base/NextStepSectionSBM01";

const Resume = () => {
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)

	const { job: jobData, application, jobId, applicationId, setActiveStep } = useOutletContext()
	const [applicationData, setApplicationData] = useState(application);

	useEffect(() => {
		setActiveStep(4)
	}, [])


	const handlePrev = () => {
		navigate(`/SBM01_10_14_2_3/${jobId}/${applicationId}`)
	}
	const handleSubmit = async () => {
		if (!applicationData?.resume?.length) return
		setIsLoading(true)
		const resp = await api.jobApplications.updateApplication(jobId, applicationId, {
			resume: applicationData?.resume
		})
		if (!resp.success) {
			toast.error(resp?.message || "Something went wrong")
			return;
		}
		toast.success("updated")
		setIsLoading(false)
	}

	return (
		<div className='flex-auto'>
			<div className='flex flex-col gap-[40px] md:gap-[60px] max-w-[1432px] px-4 w-full pt-14 pb-24 mx-auto'>
				<TextareaField
					className="grey-textarea !bg-white !h-[500px]"
					value={applicationData?.resume}
					onChange={(e) => setApplicationData(prev => ({ ...prev, resume: e.target.value }))}
				/>
			</div>
			<NextStepSectionSBM01 prev={handlePrev} next={!isLoading ? handleSubmit : () => { } } />
		</div>
	)
}

export default Resume