import { createSlice } from '@reduxjs/toolkit';

import {
    fetchCreatePubSolCategories, fetchCreatePubSolPage,
    fetchCreatePubSolTags, fetchDeletePubSolPage,
    fetchPublicSolutionsPages,
    fetchPubSolCategories, fetchPubSolTags
} from "../thunks/publicThunks";

const initialState = {
    pages: null,
    categories: null,
    tags: null,
}

const publicSolutionsSlice = createSlice({
    name: 'publicSolution',
    initialState,
    reducers: {
    },

    extraReducers(builder) {
        builder.addCase(fetchPubSolCategories.fulfilled, (state, action) => {
            state.categories = action.payload
        });

        builder.addCase(fetchPublicSolutionsPages.fulfilled, (state, action) => {
            state.pages = action.payload
        });

        builder.addCase(fetchCreatePubSolPage.fulfilled, (state, action) => {
            state.pages = state.pages?.length ? [...state.pages, action.payload] : [action.payload];
        });

        builder.addCase(fetchCreatePubSolCategories.fulfilled, (state, action) => {
            state.categories = state.categories.length ? [...state.categories, action.payload] : [action.payload];
        });

        builder.addCase(fetchDeletePubSolPage.fulfilled, (state, action) => {
            state.pages = state.pages.filter((page) => +page.id !== +action.payload);
        });

        builder.addCase(fetchPubSolTags.fulfilled, (state, action) => {
            state.tags = action.payload;
        });

        builder.addCase(fetchCreatePubSolTags.fulfilled, (state, action) => {
            state.tags = [...state.tags, action.payload];
        });



    }
})

export const {
} = publicSolutionsSlice.actions;

export const getPublicSolutionsPages = (state) => state.publicSolutions.pages;
export const getPublicSolutionsCategories = (state) => state.publicSolutions.categories;
export const getPublicSolutionsTags = (state) => state.publicSolutions.tags

export default publicSolutionsSlice.reducer