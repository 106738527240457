import React, { useEffect, useState } from "react";

export const RangeQuestion = ({ config, number, value, onChange, error, labelClassName = '', disabled }) => {
    const answerTemplate = JSON.parse(config.answerTemplate);


    return <div className='flex gap-[24px] w-full flex-col max-lg:gap-[16px]'>
        <div className={`flex-auto my-auto text-lg font-semibold leading-7 text-[#333333] max-md:max-w-full ${error ? 'text-red-600' : null} ${labelClassName}`}>
            {number && number + '. '}
            {config.question}
            {config.isRequired && <span className="text-teal-500">*</span>}
        </div>
        <FitScaleEvaluationTable value={value} maxOptions={answerTemplate.max} disabled={disabled} onChange={onChange} />
    </div>

}

const FitScaleEvaluationTable = ({ value = 1, onChange, maxOptions = 6, disabled }) => {
    const createRatingScale = (selectedIdx) => Array.from({ length: maxOptions }).map((_, idx) => {
        return idx + 1 === selectedIdx;
    });

    const [selectedRatings, setSelectedRatings] = useState(createRatingScale(value));


    const handleRatingClick = (index) => {
        setSelectedRatings(createRatingScale(index));
        onChange && onChange(index)
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex gap-5 max-md:gap-[8px] justify-between mt-4 text-base font-semibold text-center whitespace-nowrap">
                {selectedRatings.map((isSelected, idx) => (
                    <div
                        key={idx}
                        className={`cursor-pointer`}
                        onClick={disabled ? () => { } : () => handleRatingClick(idx + 1)}
                    >
                        <div
                            key={idx}
                            className={`flex justify-center items-center px-4  border border-solid ${isSelected ? "border-white border-[3px] bg-green-500 box-shadow text-white" : "bg-white border-zinc-300"} rounded-[460px]  h-9 w-9 md:h-[42px] md:w-[42px]`}>{idx + 1}</div>
                    </div>
                ))}
            </div>
            <div className="flex gap-5 justify-between mt-5 w-full text-base">
                <div className="flex-auto">Totally Disagree</div>
                <div className="flex-auto text-right">Totally Agree</div>
            </div>
        </div>
    );
}