import React, { useCallback, useEffect, useState } from "react"
import logo from "../assets/img/logo.png"
import { EditableInput } from "./SBM02_5/EditableInput";
import SelectBox from "../components/base/SelectBox"
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Breadcrumbs from "../components/base/Breadcrumbs";
import { RegisterApplicantSteps, initialAnswers } from "../components/parts/RegisterApplicantSteps";
import { useSelector } from "react-redux";
import { YesNoQuestion } from "./AP_01_Form/parts/YesNoQuestion";
import { TextQuestion } from "./AP_01_Form/parts/TextQuestion";
import { RangeQuestion } from "./AP_01_Form/parts/RangeQuestion";
import { VariantsQuestion } from "./AP_01_Form/parts/VariantsQuestion";
import { getDaysInMonth } from "date-fns";
import { array, lazy, object, string } from "yup";
import { toast } from "react-toastify";
import InputError from "../components/base/InputError";
import api from "../api";
import PageLoader from "../components/PageLoader";

const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const monthOptions = month.map(item => ({
    label: item,
    value: item
}))
const currentYear = new Date().getFullYear();
const yearOptions = Array.from({ length: 30 }, (_, i) => ({
    label: currentYear - i,
    value: currentYear -i
}));
const QuestionForm = ({ question, required }) => (
	<div className="flex gap-5 justify-between mt-10 w-full max-md:flex-wrap max-md:max-w-full max-md:flex-col">
		<div className="flex-auto my-auto text-lg font-semibold leading-7 text-[#333333] max-md:max-w-full">
			{question}{" "}
			{required && <span className="text-teal-500">*</span>}
		</div>
		<AnswerOptions />
	</div>
);

const AnswerOptions = () => (
	<div className="flex flex-col justify-center basis-0 max-md:flex-row">
		<label htmlFor="asd123" className="cursor-pointer radio-green-container lg:mr-[24px] font-medium max-md:w-[50%]">
			<input type="radio" className="radio-green-sub mr-[10px]" name="refference" id="asd123" />
			<span>Yes</span>
		</label>

		<label htmlFor="aaa123" className="cursor-pointer radio-green-container lg:mr-[24px] font-medium max-md:w-[50%]">
			<input type="radio" className="radio-green-sub mr-[10px]" name="refference" id="aaa123" />
			<span>No</span>
		</label>
	</div>
);


const AvailabilityInput = ({ label, value, onChange }) => (
	<div className="flex justify-start relative border-[#DEDED9] border bg-white border-solid rounded-md h-[64px] max-md:w-[50%]">
		<input
			value={value}
			onChange={(e) => onChange(e.target.value)}
			type="time"
			className="indicator-hide text-lg px-[18px] bg-transparent text-[#333333] outline-none h-full w-full lg:w-[190px]"
		/>
		<span className="absolute top-[-5.5px] bg-white leading-[11px] whitespace-nowrap lg:px-[8px] left-1/2 -translate-x-1/2 text-[14px]">{label}</span>
	</div>
);

const AvailabilityRow = ({ day, startTime, endTime, onStartTimeChange, onEndTimeChange }) => (
	<div className="flex gap-5 justify-between w-full lg:w-[560px] mt-8 max-md:flex-wrap max-md:flex-col">
		<div className="flex-auto my-auto text-xl font-bold leading-8 uppercase">
			{day}
		</div>
		<div className="flex gap-4">
			<AvailabilityInput
				label="Available Beginning at"
				value={startTime}
				onChange={onStartTimeChange}
			/>
			<AvailabilityInput
				label="Available Ending at"
				value={endTime}
				onChange={onEndTimeChange}
			/>
		</div>
	</div>
);

const AvailabilityCalendar = ({handleChange, formData}) => {
	const { job: {applicationForm} } = useSelector(state => state.jobs);
	const convertTime = (timeString) => {
		const [hours, minutes, period] = timeString.match(/\d+|\w+/g);
		let hours24 = parseInt(hours) % 12;
		if (period === 'PM') {
			hours24 += 12;
		}
		return hours24.toString().padStart(2, '0') + ":" + minutes;
	}
	
	const [availabilityData, setAvailabilityData] = React.useState(formData.availabilityData || [
		{ day: applicationForm.your_availability_for_work_sunday, startTime: convertTime(applicationForm.your_availability_for_work_sunday_available_beginning_at), endTime: convertTime(applicationForm.your_availability_for_work_sunday_available_ending_at),  },
		{ day: applicationForm.your_availability_for_work_monday, startTime: convertTime(applicationForm.your_availability_for_work_monday_available_beginning_at), endTime: convertTime(applicationForm.your_availability_for_work_monday_available_ending_at) },
		{ day: applicationForm.your_availability_for_work_tuesday, startTime: convertTime(applicationForm.your_availability_for_work_tuesday_available_beginning_at), endTime: convertTime(applicationForm.your_availability_for_work_tuesday_available_ending_at) },
		{ day: applicationForm.your_availability_for_work_wednesday, startTime: convertTime(applicationForm.your_availability_for_work_wednesday_available_beginning_at), endTime: convertTime(applicationForm.your_availability_for_work_wednesday_available_ending_at) },
		{ day: applicationForm.your_availability_for_work_thursday, startTime: convertTime(applicationForm.your_availability_for_work_thursday_available_beginning_at), endTime: convertTime(applicationForm.your_availability_for_work_thursday_available_ending_at) },
		{ day: applicationForm.your_availability_for_work_friday, startTime: convertTime(applicationForm.your_availability_for_work_friday_available_beginning_at), endTime: convertTime(applicationForm.your_availability_for_work_friday_available_ending_at) },
		{ day: applicationForm.your_availability_for_work_saturday, startTime: convertTime(applicationForm.your_availability_for_work_saturday_available_beginning_at), endTime: convertTime(applicationForm.your_availability_for_work_saturday_available_ending_at) },
	]);
	useEffect(() => {
		console.log(availabilityData)
		handleChange('availabilityData', availabilityData)
	}, [availabilityData, handleChange])

	const handleStartTimeChange = (day, newTime) => {
		console.log(newTime)
		setAvailabilityData(
			availabilityData.map((item) =>
				item.day === day ? { ...item, startTime: newTime } : item
			)
		);
	};

	const handleEndTimeChange = (day, newTime) => {
		setAvailabilityData(
			availabilityData.map((item) =>
				item.day === day ? { ...item, endTime: newTime } : item
			)
		);
	};

	return (
		<main className="mt-[44px] flex flex-col self-stretch pt-11 border-t border-solid border-stone-300 lg:max-w-[720px] text-zinc-800">
			<h1 className="w-full text-3xl font-bold leading-10 max-md:max-w-full max-md:text-[28px] max-md:leading-[36px]">
				Your Availability for Work
			</h1>
			{availabilityData.map(({ day, startTime, endTime }) => (
				<AvailabilityRow
					key={day}
					day={day}
					startTime={startTime}
					endTime={endTime}
					onStartTimeChange={(newTime) => handleStartTimeChange(day, newTime)}
					onEndTimeChange={(newTime) => handleEndTimeChange(day, newTime)}
				/>
			))}
		</main>
	);
};



const DateInput = ({ label, type }) => (
	<div className="flex flex-col flex-1 justify-center">
		<div>{label}</div>
		<input
			type={type}
			className="shrink-0 mt-2 bg-white rounded-md border border-solid border-zinc-300 h-[54px]"
		/>
	</div>
);
const FitScaleEvaluationTable = ({ value = 1, onChange, maxOptions = 6 }) => {
    const createRatingScale = (selectedIdx) => Array.from({ length: maxOptions }).map((_, idx) => {
        return idx + 1 === selectedIdx;
    });

    const [selectedRatings, setSelectedRatings] = useState(createRatingScale(value));


    const handleRatingClick = (index) => {
        setSelectedRatings(createRatingScale(index));
        onChange && onChange(index)
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex gap-5 max-md:gap-[8px] justify-between mt-4 text-base font-semibold text-center whitespace-nowrap">
                {selectedRatings.map((isSelected, idx) => (
                    <div
                        key={idx}
                        className={`cursor-pointer`}
                        onClick={() => handleRatingClick(idx + 1)}
                    >
                        <div
                            key={idx}
                            className={`flex justify-center items-center px-4  border border-solid ${isSelected ? "border-white border-[3px] bg-green-500 box-shadow text-white" : "bg-white border-zinc-300"} rounded-[460px]  h-9 w-9 md:h-[42px] md:w-[42px]`}>{idx + 1}</div>
                    </div>
                ))}
            </div>
            <div className="flex gap-5 justify-between mt-5 w-full text-base">
                <div className="flex-auto">Totally Disagree</div>
                <div className="flex-auto text-right">Totally Agree</div>
            </div>
        </div>
    );
}
const DatePicker = () => {
	return (
		<div className="flex flex-col self-stretch max-w-[720px]">
			<h2 className="w-full text-2xl font-bold leading-7 text-zinc-800 max-md:max-w-full">
				What is your earliest date to start?
			</h2>
			<div className="flex gap-3.5 pr-20 mt-6 text-lg whitespace-nowrap text-neutral-400 max-md:flex-wrap max-md:pr-5">
				<DateInput label="Month" type="month" />
				<div className="shrink-0 my-auto flex justify-center w-2 h-px bg-zinc-300">
					<div className="w-1 h-1 rounded-full bg-zinc-300"></div>
				</div>
				<DateInput label="Day" type="number" />
				<div className="shrink-0 my-auto flex justify-center w-2 h-px bg-zinc-300">
					<div className="w-1 h-1 rounded-full bg-zinc-300"></div>
				</div>
				<DateInput label="Year" type="number" />
				<img
					loading="lazy"
					src="https://cdn.builder.io/api/v1/image/assets/TEMP/b30165afd981972977a71f964d86be9c027c9ff8c66dbfbfedacb0e7d715cd3b?apiKey=d7b3afa51f454b40b57bf7bd36e9abaf&"
					alt=""
					className="shrink-0 self-end mt-11 w-6 aspect-square max-md:mt-10"
				/>
			</div>
		</div>
	);
};

const AP_hiring = () => {
	const { id } = useParams();
    const navigate = useNavigate();
    const { job: {applicationForm} } = useSelector(state => state.jobs);
    const [formData, setFormData] = useOutletContext();
    const handleChange = useCallback((field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }))
    }, [setFormData]) 
    const { pathname } = useLocation();
    const jobLink = pathname.split('/hiring-assesment')[0]
    const breadcrumbItems = [
        {
            name: 'Job',
            to: jobLink,
            isCurrent: false
        },
        {
            name: 'Hiring Assesment',
            isCurrent: true
        },
    ]
	const [formErrors, setFormErrors] = useState(null)
	const [reqErrors, setReqErrors] = useState(null)
	console.log(formErrors)
	const validateAssessments = () => {
		let hasErrors = false;
		const result = formData?.hiringAssessments.map((value, index) => {
			if(!value?.answer) {
				hasErrors = true
				return 'This field id required'
			} else {
				return ''
			}
		})
		if (hasErrors) {
			return result;
		} else {
			return true
		}

	}
	const handleSaveChanges = async () => {
		
		try {
            setFormErrors(null);
			setReqErrors(null);
			const isvalidreq = validateAssessments();
			if(isvalidreq === true) {
				
				localStorage.setItem(`job-${id}-answers`, JSON.stringify(formData));
				localStorage.setItem(`job-${id}-steps`, JSON.stringify(initialAnswers.map(answer => ({...answer, isDone: answer.number <= 5 ? true : false}))));
				navigate(`${jobLink}/you-must-submit-your-resume-here`)
			} else {
				setReqErrors(isvalidreq);
				toast.error('Please, fill in all filelds to continue')
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
			}
            
        } catch(err) {
			console.log(err)
            if (err.name === 'ValidationError') {
                console.log(err.inner)
                const errors = err.inner.reduce((acc, innerError) => {
                  acc[innerError.path] = innerError.message;
                  return acc;
                }, {});
                setFormErrors(errors);
                toast.error('Please, fill in all filelds to continue')
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            }
            
        }
    }
	const QuestionTypes = {
		'yes-no': YesNoQuestion,
		'text':  TextQuestion,
		'range': RangeQuestion,
		'variants': VariantsQuestion
	}
	const handleChangeArrayItem= (arrayName, itemIndex, itemValue) => {
        handleChange(arrayName, formData[arrayName].map((item, index) =>  index === itemIndex ? itemValue : item));
    }

	const substrings = ['CL', 'FS']
	const includeQuestionCode = 'AQ'
	
	console.log(formData, 'applicationForm')
	console.log(applicationForm.critical_requirements_of_the_job_questions.filter(item => item.questionCode.includes(includeQuestionCode)).length)
	
	const [hiringAssessmentsQuestions, setHiringAssessmentsQuestions] = useState([])
	useEffect(() => {
		if(formData.hiringAssessments || !hiringAssessmentsQuestions.length) return
		handleChange('hiringAssessments', hiringAssessmentsQuestions.map(item => ({
			id: item.id,
			answer: ''
		})));
	}, [formData, handleChange, hiringAssessmentsQuestions])
	const [isLoading, setIsLoading] = useState();
	
	useEffect(() => {
		setIsLoading(true);
		api.jobApplications.getHiringAssessments().then(response => {
			console.log(response)
			if(!response.success) {
				return
			}
			setHiringAssessmentsQuestions(response.data)
		}).finally(() => setIsLoading(false))
	}, [])
	const getDayOptions = () => {
        return Array.from({ length: getDaysInMonth(new Date(formData.what_is_your_earliest_date_to_start_year_answer + formData.what_is_your_earliest_date_to_start_month)) || 30}, (_, i) => ({
            label: i+1,
            value: i+1
        }));
    }
	return (<>
		<Breadcrumbs links={breadcrumbItems} colorStyle="secondary" />
		<main className="flex gap-[60px] max-lg:flex-col max-md:gap-0 pt-[24px] lg:pt-[28px] pb-[80px] lg:pb-[90px]">
			<RegisterApplicantSteps step={5} />
			<section className="flex flex-col max-md:ml-0 w-full">
				<div className="flex flex-col  text-zinc-800 max-md:mt-10 max-md:max-w-full">
					<h1 className="text-3xl font-bold leading-10 max-md:max-w-full">
						Hiring Assesment
					</h1>
					{isLoading ? <PageLoader /> :
						<div className="flex flex-col gap-9 mt-8 self-stretch max-w-[720px]">
							{hiringAssessmentsQuestions.map((item, index) => {
								const labelClassName = ''
								return <div key={item.id} className='flex gap-[24px] w-full flex-col max-lg:gap-[16px]'>
									<div
										className={`flex-auto my-auto text-lg font-semibold leading-7 text-[#333333] max-md:max-w-full ${labelClassName} ${reqErrors?.[index] ? 'text-red-600' : null}`}
									>
										{item.order + '. '} 
										{item.question}
										{<span className="text-teal-500">*</span>}
									</div>
									<div className="flex flex-col justify-between gap-[16px]">
										{item.answers.map((answer, answerIndex) => 
											<label
												key={answerIndex}
												className="cursor-pointer custopm-radio flex items-center font-medium"
											>
												<input
													type="radio"
													className="radio-green-sub aspect-square w-5 mr-[10px]"
													// name={'variants-questions'+ config.id} 
													onChange={() => handleChangeArrayItem('hiringAssessments', index, {
														id: item.id,
														answer: answer.value
													})}
													checked={formData.hiringAssessments?.[index]?.answer === answer.value}
													hidden
													// disabled={disabled}
												/>
												<span className="check-box-fake mr-[12px]"></span>
												<p>{answer.label}</p>
											</label>
										)}
									</div>
								</div>
							})}
						</div>
					}
					<footer className="flex flex-col justify-center mt-11 text-lg font-bold text-center text-white whitespace-nowrap border-t border-solid border-stone-300 max-md:mt-[80px] max-md:max-w-full">
						<div className="flex gap-5 justify-between pt-10 max-md:flex-wrap max-md:max-w-full">
							<button
								className="flex items-center h-[52px] justify-center px-12 py-5 bg-gray-500 rounded-md max-md:px-5 max-md:flex-1"
								onClick={(e) => { e.preventDefault(); navigate(`${jobLink}/critical-requirement!`); }}
							>Previous</button>
							<button
								className="flex items-center h-[52px] justify-center px-9 py-5 bg-[#06D6A0] rounded-md max-md:px-5 max-md:flex-1"
								onClick={handleSaveChanges}
							>Save & Continue</button>
						</div>
					</footer>
				</div>
			</section>
		</main>
	</>)
}


export default AP_hiring