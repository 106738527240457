import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BreadCrumbWhite from "../components/BreadCrumbWhite";
import PrimaryLink from "../components/base/PrimaryLink";
import { Footer } from "../components/parts/Footer";
import SupportFilters from "../components/parts/SupportFilters";
import { getPagesList } from "../redux/thunks/supportThunks";
import PageLoader from "../components/PageLoader";

function PostTable({ data }) {
	const currentDate = new Date();
	const navigate = useNavigate()

	const handleClick = (post) => {
		if (!post?.id) return
		navigate(`/SUP01_4_1/${post.id}`)
	}

	return (
		<>
			<div className="w-full flex flex-col gap-7 mt-9 lg:hidden">
				{data.map((post, index) => (
					<div className="flex flex-col gap-3" key={index} onClick={() => handleClick(post)}>
						<div className="flex items-baseline justify-between gap-4">
							<div className="text-base font-bold w-2/5 text-[#333333]">Name</div>
							<div className="text-lg text-[#003578] font-bold text-wrap text-right max-md:w-full">{post.name}</div>
						</div>
						<div className="flex items-baseline justify-between gap-4">
							<div className="text-base font-bold w-2/5 text-[#333333]">Status</div>
							<div className="text-lg text-[#003578] font-bold text-wrap text-right">{post.status}</div>
						</div>
						<div className="flex items-baseline justify-between gap-4">
							<div className="text-base font-bold w-2/5 text-[#333333]">Date</div>
							<div className="text-lg text-[#003578] font-bold text-wrap text-right">{currentDate.toLocaleDateString()}</div>
						</div>
						<div className="flex items-baseline justify-between gap-4" onClick={(e) => e.stopPropagation()}>
							<div className="text-base font-bold w-2/5 text-[#333333]">View</div>
							<PrimaryLink className={"!font-bold"} to={post.url}>View</PrimaryLink>
						</div>
						{/* <div className="flex items-baseline justify-between gap-4">
							<div className="text-base font-bold w-2/5 text-[#333333]">Edit</div>
							<div className="text-lg text-[#003578] font-bold text-wrap text-right" onClick={(e) => handleClick(post)}>Edit</div>
						</div> */}
					</div>
				))}
			</div>
			<div className="overflow-x-auto max-lg:hidden">
				<table className="w-full mt-8 mb-36 sm:mb-10 min-w-max">
					<thead>
						<tr className="text-left">
							<th className="pb-4 text-[30px] font-bold text-[#333333]">Name</th>
							<th className="pb-4 text-[30px] font-bold text-[#333333]">Status</th>
							<th className="pb-4 text-[30px] font-bold text-[#333333]">Date</th>
							<th className="pb-4 text-[30px] font-bold text-[#333333]"></th>
						</tr>
					</thead>
					<tbody>
						{data.map((post, index) => (
							<tr key={index} className="cursor-pointer" onClick={(e) => handleClick(post)}>
								<td className="py-[12px] text-[24px] text-[#003578] font-bold">{post.name}</td>
								<td className="py-[12px] text-[24px] text-[#003578] font-bold">{post.status}</td>
								<td className="py-[12px] text-[24px] text-[#003578] font-bold">{currentDate.toLocaleDateString()}</td>
								<td className="py-[12px] text-[24px] text-[#003578] font-bold" onClick={(e) => e.stopPropagation()}><PrimaryLink className={"!font-bold !text-2xl"} to={post.url}>View</PrimaryLink></td>
								{/* <td className="py-[12px] text-[24px] text-[#003578] font-bold" onClick={() => handleClick(post)}>Edit</td> */}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
}




function PostsPage() {
	const dispatch = useDispatch();
	const { pages, isLoading } = useSelector(state => state.support)
	const [filteredData, setFilteredData] = useState(pages)

	useEffect(() => {
		dispatch(getPagesList())
	}, [])

	useEffect(() => {
		console.log(pages)
		setFilteredData(pages)
	}, [pages])
	return (
		<section className="flex justify-center items-center px-4 lg:py-12 pt-10 pb-20 mt-1.5 w-full text-zinc-800 max-md:px-5 max-md:max-w-full">
			<div className="flex flex-col items-center lg:my-10 w-full max-w-[1400px] max-md:max-w-full">
				<h1 className="text-4xl font-bold whitespace-nowrap">List of Pages</h1>
				<p className="mt-2 text-center max-md:max-w-full">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
				<div className="flex flex-col self-stretch py-[50px] max-md:py-[36px] px-[60px] lg:px-16 mt-11 whitespace-nowrap bg-white rounded-md border border-solid border-zinc-300 text-neutral-400 max-md:px-[32px] max-md:mt-10 max-md:max-w-full">
					<SupportFilters data={pages} setData={setFilteredData} status search searchField="name" />

					<div className="shrink-0 mt-12 h-px bg-neutral-200 max-md:mt-10 max-md:max-w-full" />
					{!isLoading ?

						filteredData ?
							<PostTable data={filteredData} />
							:
							<div className='text-4xl pt-5 md:text-5xl font-bold text-sky-950 text-center'>No Posts yet</div>
						:
						<PageLoader />
					}

				</div>
			</div>
		</section>
	);
}



const SUP01_4 = () => {
	return (
		<>
			<BreadCrumbWhite crumbs={['EJ Support', 'Public Home Page List']} />
			<PostsPage />
			<Footer hideCopyright={true} />
		</>
	);
};

export default SUP01_4;
