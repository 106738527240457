import {NavLink, useNavigate} from "react-router-dom";
import LogoBlack from "../../assets/img/logo-black.png"
import { ReactComponent as BurgerIcon } from "../../assets/img/burger.svg"
import {useEffect, useRef, useState} from "react";
import {getUser, setLogOut,} from '../../redux/slices/applicationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogout } from "../../redux/thunks/applicationThunks";

const userAvatarPlaceholder = "https://cdn.builder.io/api/v1/image/assets/TEMP/163c2d226fc8456e0b676e882ce1c6248ffa45a572786bc2c7f3da287c35a10b?apiKey=bbf94b715ef442f4991a974fece2b44a&";

const UserGreeting = ({ userName, userAvatar, className }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const ref = useRef(null);

    const closeHandler = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setOpen(false);
        }
    }

    useEffect(() => {
        if (open) {
            document.addEventListener('click', closeHandler)
        } else {
            document.removeEventListener('click', closeHandler)
        }

        return () => {document.removeEventListener('click', closeHandler)}
    }, [open]);

    const logout = async () => {
        const res = await dispatch(fetchLogout());
        if(res.payload.success) {
            navigate('/HOM1_1')
        }
    }

    return (
        <div className={'relative '} ref={ref}>
            <a href="#" onClick={handleOpen}
               className={`flex gap-2 justify-between items-center py-3 font-bold whitespace-nowrap ${className ? className : ""}`}>
                {userAvatar ? <img
                    loading="lazy"
                    src={userAvatar}
                    alt="User avatar"
                    className="aspect-square w-[22px]"
                /> : <img
                    loading="lazy"
                    src={userAvatarPlaceholder}
                    alt="User avatar"
                    className="aspect-square w-[22px]"
                />}
                <div className="grow">{`Hi, ${userName}`}</div>
            </a>

            <div className={`header-dropdown ${open ? 'open' : ''}`}>
                <NavLink to="/SBM05_1_1"> <p className={'text-center header-drop-links'}>Account</p></NavLink>

                <p onClick={logout} className={'text-center header-drop-links'}>Log Out</p>
            </div>

        </div>

    );
}

export const Header = ({colorStyle}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const user = useSelector(getUser);
    let settingsPage = "/SBM05_TEA"
    switch (user?.role) {
        case "admin":
            settingsPage = "/SBM05_ADM"
            break;

        case "applicant":
            settingsPage = "/SBM05_TEA"
            break;

        case "support":
            settingsPage = "/SBM05_INT"
            break;

        default:
            break;
    }
    const loginedLinks = [
        {
            label: "Jobs",
            route: "/SBM01"
        },
        {
            label: "Jobs Setup",
            route: "/jobs-setup"
        },
        {
            label: "Support Center",
            route: "/SBM03"
        },
        {
            label: "Insights",
            route: "/SBM04"
        },
        {
            label: "Setting",
            route: settingsPage
        },

    ]
    useEffect(() => {
        console.log('user:', user);
    }, [user])

    useEffect(() => {
        document.querySelector("body").style.overflow = isMenuOpen ? "hidden" : "visible"
    }, [isMenuOpen])
    return (
        <header className={`${colorStyle === 'black' ? 'text-black bg-white' : 'text-white'} flex relative justify-center items-center self-stretch pl-4 pr-5 py-[14px] w-full text-lg whitespace-nowrap border-b border-solid border-b-[#1F4B84] border-b-opacity-10 z-50`}>
            <nav className="flex items-center gap-5 justify-between full w-[1240px]">
                <NavLink to="/HOM1_1" className="flex">
                    {colorStyle !== 'black' ? <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/93718b1fa9850a912acec012c7bea638ca293464e1ad432c61a7496242da640a?apiKey=9106ba04c02e4dceae488e75e2ac4862&" className="max-w-full aspect-[3.23] xl:w-[293px] w-36" alt="GreatHire Logo" />
                        : <img loading="lazy" src={LogoBlack} className="max-w-full aspect-[3.23] xl:w-[293px] w-36" alt="GreatHire Logo" />}

                </NavLink>

                <div className={`ml-auto flex gap-[28px] flex-col lg:flex-row items-center my-auto lg:static fixed inset-0 top-[73px] transition-transform ${isMenuOpen ? "" : "-translate-x-full"} lg:translate-x-0 lg:p-0 py-7 px-4 lg:w-auto w-full ${colorStyle === "black" ? "bg-white" : "bg-[url(https://cdn.builder.io/api/v1/image/assets%2F9106ba04c02e4dceae488e75e2ac4862%2F14a2333ed5504c789df293d766cde515)]"} lg:bg-none`}>
                    {
                        user ?
                            <>
                                {/* {loginedLinks.map(({ label, route }, index) => (
                                    <NavLink className={({isActive}) => (isActive ? "header-link active" : "")} to={route} key={index}>{label}</NavLink>
                                ))} */}
                                <NavLink to="/HOM1_2" className="items-center flex self-stretch hover:text-green-600">Why EmployJoy</NavLink>
                                <NavLink to="/HOM1_4" className="items-center flex self-stretch hover:text-green-600">Solutions</NavLink>
                                <NavLink to="/HOM1_5" className="lg:flex hidden justify-center px-[20px] py-[12px] font-bold bg-[#37B34A] hover:bg-green-600 rounded-md text-white">Book a Demo</NavLink>
                                <UserGreeting userName={user.firstName} userAvatar={null} />
                            </> : <>
                                <NavLink to="/HOM1_2" className="items-center flex self-stretch hover:text-green-600">Why EmployJoy</NavLink>
                                <NavLink to="/HOM1_4" className="items-center flex self-stretch hover:text-green-600">Solutions</NavLink>
                                <NavLink to="/HOM1_5" className="lg:flex hidden justify-center px-[20px] py-[12px] font-bold bg-[#37B34A] hover:bg-green-600 rounded-md text-white">Book a Demo</NavLink>
                                <NavLink to="/HOM1_6" className="flex gap-2 self-stretch mt-auto px-2.5 py-[8px] font-bold border-b border-solid border-b-[#37B34A] hover:text-green-600" role="button" tabIndex="0">
                                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/621d3720a376991868dc92e67bd77bc886086e2343503a84d57157d332fac905?apiKey=9106ba04c02e4dceae488e75e2ac4862&" className="aspect-square w-[22px]" alt="User Log In" />
                                    <div className="grow">Log In</div>
                                </NavLink>
                            </>
                    }
                </div>
                <div className="flex items-center gap-[10px] lg:hidden">
                    <NavLink to="/HOM1_5" className="flex items-center justify-center px-[16px] h-11 text-sm font-bold bg-[#37B34A] hover:bg-green-600 rounded-md text-white">Book a Demo</NavLink>
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className="flex items-center justify-center rounded-full aspect-square h-11 border-solid border border-1 border-transparent hover:border-white hover:bg-white hover:bg-opacity-20"
                    >
                        <span className="flex items-center justify-center aspect-square w-6">
                            <BurgerIcon />
                        </span>
                    </button>
                </div>
            </nav>
        </header>
    )
}
