import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../api';

export const getAllDocumentsFetch = createAsyncThunk(
    '/getAllDocumentsFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobApplicationDocuments.getAllDocuments(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const getAllDocumentsOfApplicationFetch = createAsyncThunk(
    '/getAllDocumentsOfApplicationFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobApplicationDocuments.getAllDocumentsOfApplication(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const uploadDocumentsToAppicationFetch = createAsyncThunk(
    '/uploadDocumentsToAppicationFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobApplicationDocuments.uploadDocumentsToAppication(payload);
            if (res.success) {
                return res.data;
            }
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
export const sendToApplicantLinkOnDocumentsReviewFetch = createAsyncThunk(
    '/sendToApplicantLinkOnDocumentsReviewFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobApplicationDocuments.sendToApplicantLinkOnDocumentsReview(payload);
            if (res.success) {
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);

export const deleteDocumentByIdFetch = createAsyncThunk(
    '/deleteDocumentByIdFetch',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await api.jobApplicationDocuments.deleteDocumentById(payload);
            if (res.success) {
                console.log(res);
                toast.success(res.message)
                return res.data;
            }
            toast.error(res.message)
        } catch (err) {
            console.log(err);
            return rejectWithValue(err?.response.data);
        }
    },
);
